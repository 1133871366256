import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import colors from "../utils/colors";
import "react-datepicker/dist/react-datepicker.css";
import { currencyFormat } from "../utils/InputPatterns";
import {
    FiltersContainer,
    FilterLabel,
    InputDatePicker,
    ClearableInputDatePickerContainer,
    Select
} from "../styles/Inputs";
import {
    TabHeaderContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    TabHeaderSpacer
} from "styles/Tabs";
import {
    Table,
    TableHeader,
    TableLinkRow,
    TableRowViewAction,
    TableCell,
    Pagination
} from "../styles/Tables";
import { PayStatus } from "styles/Status";
import {
    PesoSign,
} from "styles/Containers";

const paymentsPerPage = 10;

const filterStatusOptions = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "Inactive",
        label: "Inactive"
    },
    {
        value: "Processing",
        label: "Processing"
    },
    {
        value: "Completed",
        label: "Completed"
    },
    {
        value: "Failed",
        label: "Failed"
    }
];

const PaymentsGrandTotalContainer = styled.div`
    width: 100%;
    text-align: right;
    font-family: "PT Mono", monospace;
    font-size: small;
    margin-top: 12px;
    padding-right: 10px;
    color: ${colors.PRIMARY};
`;

class OwnerPaymentsContainer extends Component {
    state = {
        paymentsFilter: {
            dateFrom: null,
            dateTo: null,
            status: {
                value: "All",
                label: "All"
            }
        },
        filteredPayments: null,
        paymentsOffset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        this.setState({
            filteredPayments: this.filterPayments({
                dateFrom: null,
                dateTo: null,
                status: {
                    value: "All",
                    label: "All"
                }
            })
        });
    }

    componentDidUpdate(prevProps) {
        const { tabVisible, modalVisible } = this.props;
        if (
            tabVisible !== prevProps.tabVisible ||
            modalVisible !== prevProps.modalVisible
        ) {
            if (tabVisible && !modalVisible) {
                document.addEventListener("keydown", this.handleKeyDown);
            } else {
                document.removeEventListener("keydown", this.handleKeyDown);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredPayments, paymentsOffset } = this.state;
        this.setState({
            paymentsOffset:
                paymentsOffset <
                Math.ceil(filteredPayments.length / paymentsPerPage)
                    ? paymentsOffset + 1
                    : paymentsOffset
        });
    }

    handlePreviousPage() {
        const { paymentsOffset } = this.state;
        this.setState({
            paymentsOffset:
                paymentsOffset > 1 ? paymentsOffset - 1 : paymentsOffset
        });
    }

    handleFilterPaymentsDateFromChange(dateFrom) {
        const { paymentsFilter } = this.state;
        const dateTo = moment(dateFrom).isSameOrAfter(
            paymentsFilter.dateTo,
            "day"
        )
            ? null
            : paymentsFilter.dateTo;
        this.setState({
            paymentsFilter: Object.assign({}, paymentsFilter, {
                dateFrom,
                dateTo
            }),
            filteredPayments: this.filterPayments({
                dateFrom,
                dateTo,
                status: paymentsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterPaymentsDateToChange(dateTo) {
        const { paymentsFilter } = this.state;
        this.setState({
            paymentsFilter: Object.assign({}, paymentsFilter, {
                dateTo
            }),
            filteredPayments: this.filterPayments({
                dateFrom: paymentsFilter.dateFrom,
                dateTo,
                status: paymentsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterPaymentsStatusChange(status) {
        const { paymentsFilter } = this.state;
        this.setState({
            paymentsFilter: Object.assign({}, paymentsFilter, {
                status
            }),
            filteredPayments: this.filterPayments({
                dateFrom: paymentsFilter.dateFrom,
                dateTo: paymentsFilter.dateTo,
                status
            }),
            offset: 1
        });
    }

    filterPayments({ dateFrom, dateTo, status }) {
        const { payments } = this.props;

        return (
            payments &&
            payments.filter(
                payment =>
                    (dateFrom
                        ? moment(payment.date).isSameOrAfter(dateFrom, "day")
                        : true) &&
                    (dateFrom && dateTo
                        ? moment(payment.date).isSameOrBefore(dateTo, "day")
                        : true) &&
                    (status
                        ? status.value === "All" ||
                          payment.status === status.value
                        : true)
            )
        );
    }

    render() {
        const { tabVisible, onTabChange, ownerId } = this.props;
        const { paymentsFilter, filteredPayments, paymentsOffset } = this.state;

        const paymentsRange = paymentsOffset * paymentsPerPage;

        const paymentRows =
            filteredPayments &&
            filteredPayments.map((payment, i) => {
                if (i >= paymentsRange - paymentsPerPage && i < paymentsRange) {
                    return (
                        <TableLinkRow
                            key={payment.id}
                            to={{
                                pathname: `/admin/payments/${payment.id}`,
                                state: {
                                    backTo: {
                                        label: "Back to Owner",
                                        value: `/admin/owners/${ownerId}`
                                    }
                                }
                            }}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>
                                {payment.date
                                    ? moment(payment.date).format(
                                          "MM/DD/YYYY HH:mm:ss"
                                      )
                                    : ""}
                            </TableCell>
                            <TableCell>{payment.referenceNumber}</TableCell>
                            <TableCell>{payment.billCategory}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {currencyFormat(payment.amount)}
                            </TableCell>
                            <TableCell>{payment.method}</TableCell>
                            <TableCell>{payment.remarks}</TableCell>
                            <TableCell>
                                <PayStatus status={payment.status}>
                                    {payment.status}
                                </PayStatus>
                            </TableCell>
                            <TableRowViewAction text="VIEW BILL" />
                        </TableLinkRow>
                    );
                }
            });

        const paymentsGrandTotal = filteredPayments
            ? filteredPayments.reduce(
                  (acc, curr) => acc + (Number(curr.amount) || 0),
                  0
              )
            : 0;

        return (
            <Fragment>
                {tabVisible && (
                    <Fragment>
                        <TabHeaderContainer>
                            <InactiveTabHeader
                                onClick={() => onTabChange("units")}
                            >
                                Units
                            </InactiveTabHeader>
                            <InactiveTabHeader
                                onClick={() => onTabChange("bills")}
                            >
                                Bills
                            </InactiveTabHeader>
                            <InactiveTabHeader
                                onClick={() => onTabChange("reservations")}
                            >
                                Reservations
                            </InactiveTabHeader>
                            <ActiveTabHeader>Payments</ActiveTabHeader>
                            <TabHeaderSpacer />
                        </TabHeaderContainer>
                        <FiltersContainer>
                            <div style={{ width: "130px" }}>
                                <FilterLabel>Date From</FilterLabel>
                                <ClearableInputDatePickerContainer>
                                    <InputDatePicker
                                        selected={paymentsFilter.dateFrom}
                                        selectsStart
                                        startDate={paymentsFilter.dateFrom}
                                        endDate={paymentsFilter.dateTo}
                                        isClearable={true}
                                        onChange={date =>
                                            this.handleFilterPaymentsDateFromChange(
                                                date
                                            )
                                        }
                                    />
                                </ClearableInputDatePickerContainer>
                            </div>
                            <div style={{ width: "130px" }}>
                                <FilterLabel>Date To</FilterLabel>
                                <ClearableInputDatePickerContainer>
                                    <InputDatePicker
                                        selected={paymentsFilter.dateTo}
                                        disabled={!paymentsFilter.dateFrom}
                                        selectsEnd
                                        minDate={paymentsFilter.dateFrom}
                                        startDate={paymentsFilter.dateFrom}
                                        endDate={paymentsFilter.dateTo}
                                        isClearable={true}
                                        onChange={date =>
                                            this.handleFilterPaymentsDateToChange(
                                                date
                                            )
                                        }
                                    />
                                </ClearableInputDatePickerContainer>
                            </div>
                            <div style={{ width: "150px" }}>
                                <FilterLabel>Status</FilterLabel>
                                <Select
                                    value={paymentsFilter.status}
                                    onChange={selectedStatus =>
                                        this.handleFilterPaymentsStatusChange(
                                            selectedStatus
                                        )
                                    }
                                    options={filterStatusOptions}
                                />
                            </div>
                        </FiltersContainer>
                        <Table>
                            <TableHeader>
                                <TableCell>Date and Time</TableCell>
                                <TableCell>Reference Number</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    Amount
                                </TableCell>
                                <TableCell>Method</TableCell>
                                <TableCell>Remarks</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableHeader>
                            {paymentRows}
                        </Table>
                        {filteredPayments &&
                            filteredPayments.length > paymentsPerPage && (
                                <Pagination
                                    itemsPerPage={paymentsPerPage}
                                    offset={paymentsOffset}
                                    length={filteredPayments.length}
                                    handlePreviousPage={() =>
                                        this.handlePreviousPage()
                                    }
                                    handleNextPage={() => this.handleNextPage()}
                                />
                            )}
                        <PaymentsGrandTotalContainer>
                            TOTAL {paymentsFilter.status.value.toUpperCase()}{" "}
                            AMOUNT PHP {currencyFormat(paymentsGrandTotal)}
                        </PaymentsGrandTotalContainer>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default connect()(OwnerPaymentsContainer);
