import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import AdminReservationsReservationsContainer from "./AdminReservationsReservationsContainer";
import AdminReservationsPaymentsContainer from "./AdminReservationsPaymentsContainer";
import { fetchReservations } from "reducers/reservationReducer";
import { ContentContainer } from "styles/Containers";

class AdminReservationsContainer extends Component {
    state = {
        tabVisible: "bills"
    };

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchReservations());
        }
    }

    render() {
        const { filters, isLoading } = this.props;
        const { tabVisible } = this.state;

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ContentContainer>
                        <AdminReservationsReservationsContainer
                            tabVisible={tabVisible === "bills"}
                            onTabChange={tabSelected =>
                                this.setState({
                                    tabVisible: tabSelected
                                })
                            }
                            filters={filters}
                        />
                        <AdminReservationsPaymentsContainer
                            tabVisible={tabVisible === "payments"}
                            onTabChange={tabSelected =>
                                this.setState({
                                    tabVisible: tabSelected
                                })
                            }
                        />
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AdminReservationsContainer);
