import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { messageActions } from "reducers/messageReducer";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { addUnit, updateUnit } from "reducers/unitReducer";
import { selectBuilding } from "reducers/buildingReducer";
import { positiveNumber, isValidEmail } from "utils/InputPatterns";
import { InfoContainer, InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { Input, Select } from "styles/Inputs";
import { Button, PrimaryButton, PrimaryAltButton } from "styles/Buttons";

const newOwnerTypeOptions = [
    {
        value: "Individual",
        label: "Individual"
    },
    {
        value: "Company",
        label: "Company"
    }
];

class UnitModal extends Component {
    state = {
        unitId: null,
        unitNumber: "",
        owner: null,
        floor: "",
        floorArea: "",
        unitType: null,
        newOwner: {
            isSelected: false,
            name: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            type: null,
            contactFirstName: "",
            contactLastName: "",
            contactEmail: "",
            contactPhoneNumber: ""
        },
        hasError: false
    };

    componentDidMount() {
        const { unit } = this.props;
        if (unit) {
            this.setState({
                unitId: unit.id,
                unitNumber: unit.number,
                owner: {
                    value: { id: unit.ownerId, name: unit.ownerName },
                    label: unit.ownerName
                },
                floor: unit.floor,
                floorArea: unit.floorArea,
                unitType: { value: unit.unitType, label: unit.unitType }
            });
        }
    }

    async handleSubmit() {
        const {
            onSubmit,
            building,
            unitNumbersInBuilding,
            ownerEmails,
            closeModal,
            dispatch
        } = this.props;
        const {
            unitId,
            unitNumber,
            owner,
            floor,
            floorArea,
            unitType,
            newOwner
        } = this.state;

        let errorMessage = "";
        const requiredFieldsFilled =
            building &&
            unitNumber &&
            unitNumber.trim().length > 0 &&
            floor &&
            floor.trim().length > 0 &&
            floorArea &&
            unitType &&
            (newOwner.isSelected
                ? newOwner.email.trim().length > 0 &&
                  newOwner.phoneNumber.trim().length > 0 &&
                  newOwner.contactFirstName.trim().length > 0 &&
                  newOwner.contactLastName.trim().length > 0 &&
                  newOwner.contactEmail.trim().length > 0 &&
                  newOwner.contactPhoneNumber.trim().length > 0 &&
                  (newOwner.type && newOwner.type.value === "Company"
                      ? newOwner.name.trim().length > 0
                      : newOwner.type && newOwner.type.value === "Individual"
                      ? newOwner.firstName.trim().length > 0 &&
                        newOwner.lastName.trim().length > 0
                      : true)
                : Boolean(owner));
        const unitNumberNotUnique =
            !unitId &&
            unitNumber &&
            unitNumber.trim().length > 0 &&
            unitNumbersInBuilding.includes(unitNumber);
        const emailNotUnique =
            newOwner.isSelected &&
            newOwner.email &&
            newOwner.email.trim().length > 0 &&
            ownerEmails.includes(newOwner.email.trim().toLowerCase());

        errorMessage = !requiredFieldsFilled
            ? "Please fill up all required fields."
            : errorMessage;
        errorMessage = unitNumberNotUnique
            ? errorMessage.length > 0
                ? errorMessage + " Unit number must be unique."
                : "Unit number must be unique."
            : errorMessage;
        if (newOwner.isSelected) {
            errorMessage =
                newOwner.contactEmail.trim().length > 0 &&
                !isValidEmail(newOwner.contactEmail)
                    ? errorMessage.length > 0
                        ? errorMessage + " Invalid email."
                        : "Invalid email."
                    : emailNotUnique
                    ? errorMessage.length > 0
                        ? errorMessage + " Email must be unique."
                        : "Email must be unique."
                    : errorMessage;
        }

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        } else {
            if (unitId) {
                await dispatch(updateUnit(this.state));
            } else {
                await dispatch(addUnit(this.state));
            }
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
                if (Boolean(onSubmit)) {
                    onSubmit();
                }
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    handleOwnerChange(selectedOwner) {
        const { newOwner } = this.state;
        this.setState({
            owner: selectedOwner,
            newOwner: Object.assign({}, newOwner, { isSelected: false })
        });
    }

    handleUnitTypeChange(selectedUnitType) {
        this.setState({ unitType: selectedUnitType });
    }

    handleBuildingChange(selectedBuilding) {
        const { dispatch } = this.props;
        dispatch(selectBuilding(selectedBuilding.value));
        this.setState({ unitType: null });
    }

    render() {
        const {
            buildingOptions,
            building,
            ownerOptions,
            ownerEmails,
            unitTypes,
            unitNumbersInBuilding,
            isModalLoading,
            modalVisible
        } = this.props;
        const {
            unitNumber,
            owner,
            floor,
            floorArea,
            unitType,
            newOwner,
            unitId,
            hasError
        } = this.state;

        const selectedBuilding = building && {
            value: building.id,
            label: building.name
        };

        const unitTypeOptions = unitTypes
            ? unitTypes.map(ut => {
                  return {
                      value: ut,
                      label: ut
                  };
              })
            : [];

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({
                    height:
                        newOwner.isSelected && newOwner.type
                            ? newOwner.type.value === "Company"
                                ? 634
                                : 714
                            : 550,
                    width: newOwner.isSelected ? 890 : 470
                })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>{unitId ? "Update" : "New"} Unit</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody
                    height={
                        newOwner.isSelected && newOwner.type
                            ? newOwner.type.value === "Company"
                                ? "364px"
                                : "444px"
                            : "280px"
                    }
                    style={{ display: "flex" }}
                >
                    <div style={{ width: "380px", marginTop: 0 }}>
                        <InfoContainer style={{ marginTop: 0 }}>
                            UNIT INFORMATION
                        </InfoContainer>
                        <InfoContainer>
                            <div style={{ width: "195px" }}>
                                <InfoLabel required>BUILDING</InfoLabel>
                                <Select
                                    required={!building && hasError}
                                    disabled={isModalLoading}
                                    value={selectedBuilding}
                                    onChange={this.handleBuildingChange.bind(
                                        this
                                    )}
                                    options={buildingOptions}
                                    isSearchable
                                    placeholder="Select Building"
                                />
                            </div>
                            <div style={{ width: "170px" }}>
                                <InfoLabel required>UNIT TYPE</InfoLabel>
                                <Select
                                    required={!unitType && hasError}
                                    disabled={!building || isModalLoading}
                                    value={unitType}
                                    onChange={this.handleUnitTypeChange.bind(
                                        this
                                    )}
                                    options={unitTypeOptions}
                                    isSearchable
                                    placeholder="Select Unit Type"
                                />
                            </div>
                        </InfoContainer>
                        <InfoContainer>
                            <div style={{ width: "140px" }}>
                                <InfoLabel required>NUMBER</InfoLabel>
                                <Input
                                    placeholder="e.g. 305"
                                    value={unitNumber}
                                    required={
                                        (!unitNumber ||
                                            unitNumber.trim().length === 0 ||
                                            (!unitId &&
                                                unitNumbersInBuilding.includes(
                                                    unitNumber
                                                ))) &&
                                        hasError
                                    }
                                    disabled={!building || isModalLoading}
                                    onChange={e =>
                                        this.setState({
                                            unitNumber: e.target.value
                                        })
                                    }
                                />
                            </div>
                            <div style={{ width: "100px", marginLeft: "15px" }}>
                                <InfoLabel required>FLOOR</InfoLabel>
                                <Input
                                    placeholder="e.g. 15"
                                    value={floor}
                                    required={!floor && hasError}
                                    disabled={isModalLoading}
                                    onChange={e =>
                                        this.setState({
                                            floor: e.target.value
                                        })
                                    }
                                />
                            </div>
                            <div style={{ width: "100px", marginLeft: "15px" }}>
                                <InfoLabel>
                                    AREA
                                    <span
                                        style={{
                                            fontSize: "xx-small",
                                            color: "rgba(0,0,0,0.5)"
                                        }}
                                    >
                                        {" "}
                                        in sqm
                                    </span>
                                </InfoLabel>
                                <Input
                                    placeholder="e.g. 32"
                                    value={floorArea}
                                    required={!floorArea && hasError}
                                    disabled={isModalLoading}
                                    type="number"
                                    onKeyDown={e => positiveNumber(e)}
                                    onChange={e =>
                                        this.setState({
                                            floorArea: e.target.value
                                        })
                                    }
                                />
                            </div>
                        </InfoContainer>
                        <InfoContainer>
                            <div style={{ width: "200px" }}>
                                <InfoLabel required={!newOwner.isSelected}>
                                    OWNER
                                </InfoLabel>
                                <Select
                                    required={
                                        !newOwner.isSelected &&
                                        !owner &&
                                        hasError
                                    }
                                    disabled={isModalLoading}
                                    value={owner}
                                    onChange={this.handleOwnerChange.bind(this)}
                                    options={ownerOptions}
                                    isSearchable
                                    placeholder="Select Owner"
                                />
                            </div>
                            {!newOwner.isSelected && (
                                <Fragment>
                                    <div
                                        style={{
                                            fontSize: "small",
                                            margin: "0 32px",
                                            paddingTop: "37px"
                                        }}
                                    >
                                        OR
                                    </div>
                                    <Button
                                        disabled={isModalLoading}
                                        onClick={() =>
                                            this.setState({
                                                owner: null,
                                                newOwner: Object.assign(
                                                    {},
                                                    newOwner,
                                                    {
                                                        isSelected: true
                                                    }
                                                )
                                            })
                                        }
                                        style={{
                                            height: "34px",
                                            marginTop: "28px",
                                            width: "100px",
                                            backgroundColor: "#000000d1"
                                        }}
                                    >
                                        ADD OWNER
                                    </Button>
                                </Fragment>
                            )}
                        </InfoContainer>
                    </div>
                    {newOwner.isSelected && (
                        <Fragment>
                            <div
                                style={{
                                    wdith: "1px",
                                    margin: "15px 20px 0",
                                    border: "solid 0.5px #ddd"
                                }}
                            />
                            <div style={{ width: "380px" }}>
                                {newOwner.type && (
                                    <InfoContainer style={{ marginTop: 0 }}>
                                        OWNER INFORMATION
                                    </InfoContainer>
                                )}
                                <InfoContainer
                                    style={
                                        !newOwner.type
                                            ? {
                                                  position: "relative",
                                                  width: "185px",
                                                  top: "102px",
                                                  left: "100px"
                                              }
                                            : null
                                    }
                                >
                                    <div style={{ width: "182.5px" }}>
                                        <InfoLabel required>
                                            OWNER TYPE
                                        </InfoLabel>
                                        <Select
                                            value={newOwner.type}
                                            onChange={selectedOwnerType =>
                                                this.setState({
                                                    newOwner: Object.assign(
                                                        {},
                                                        newOwner,
                                                        {
                                                            type: selectedOwnerType
                                                        }
                                                    )
                                                })
                                            }
                                            options={newOwnerTypeOptions}
                                            placeholder="Select Owner Type"
                                        />
                                    </div>
                                    {newOwner.type &&
                                        newOwner.type.value === "Company" && (
                                            <div style={{ width: "182.5px" }}>
                                                <InfoLabel required>
                                                    COMPANY NAME
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. Ipsum"
                                                    value={newOwner.name}
                                                    required={
                                                        (!newOwner.name ||
                                                            newOwner.name.trim()
                                                                .length ===
                                                                0) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newOwner: Object.assign(
                                                                {},
                                                                newOwner,
                                                                {
                                                                    name:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                        )}
                                </InfoContainer>
                                {newOwner.type && (
                                    <Fragment>
                                        {newOwner.type.value ===
                                            "Individual" && (
                                            <InfoContainer>
                                                <div
                                                    style={{ width: "182.5px" }}
                                                >
                                                    <InfoLabel required>
                                                        FIRST NAME
                                                    </InfoLabel>
                                                    <Input
                                                        placeholder="e.g. Joe"
                                                        value={
                                                            newOwner.firstName
                                                        }
                                                        required={
                                                            (!newOwner.firstName ||
                                                                newOwner.firstName.trim()
                                                                    .length ===
                                                                    0) &&
                                                            hasError
                                                        }
                                                        disabled={
                                                            isModalLoading
                                                        }
                                                        onChange={e =>
                                                            this.setState({
                                                                newOwner: Object.assign(
                                                                    {},
                                                                    newOwner,
                                                                    {
                                                                        firstName:
                                                                            e
                                                                                .target
                                                                                .value
                                                                    }
                                                                )
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{ width: "182.5px" }}
                                                >
                                                    <InfoLabel required>
                                                        LAST NAME
                                                    </InfoLabel>
                                                    <Input
                                                        placeholder="e.g. Star"
                                                        value={
                                                            newOwner.lastName
                                                        }
                                                        required={
                                                            (!newOwner.lastName ||
                                                                newOwner.lastName.trim()
                                                                    .length ===
                                                                    0) &&
                                                            hasError
                                                        }
                                                        disabled={
                                                            isModalLoading
                                                        }
                                                        onChange={e =>
                                                            this.setState({
                                                                newOwner: Object.assign(
                                                                    {},
                                                                    newOwner,
                                                                    {
                                                                        lastName:
                                                                            e
                                                                                .target
                                                                                .value
                                                                    }
                                                                )
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </InfoContainer>
                                        )}
                                        <InfoContainer>
                                            <div style={{ width: "182.5px" }}>
                                                <InfoLabel required>
                                                    EMAIL
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. example@mail.com"
                                                    value={newOwner.email}
                                                    required={
                                                        (!newOwner.email ||
                                                            newOwner.email.trim()
                                                                .length === 0 ||
                                                            ownerEmails.includes(
                                                                newOwner.email
                                                                    .trim()
                                                                    .toLowerCase()
                                                            )) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newOwner: Object.assign(
                                                                {},
                                                                newOwner,
                                                                {
                                                                    email:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div style={{ width: "182.5px" }}>
                                                <InfoLabel required>
                                                    NUMBER
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. +6398765432100"
                                                    type="number"
                                                    value={newOwner.phoneNumber}
                                                    required={
                                                        (!newOwner.phoneNumber ||
                                                            newOwner.phoneNumber.trim()
                                                                .length ===
                                                                0) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newOwner: Object.assign(
                                                                {},
                                                                newOwner,
                                                                {
                                                                    phoneNumber:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                        </InfoContainer>
                                        <InfoContainer>
                                            <div
                                                style={{
                                                    width: "182.5px"
                                                }}
                                            >
                                                <InfoLabel required>
                                                    CONTACT FIRST NAME
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. Joe"
                                                    value={
                                                        newOwner.contactFirstName
                                                    }
                                                    required={
                                                        (!newOwner.contactFirstName ||
                                                            newOwner.contactFirstName.trim()
                                                                .length ===
                                                                0) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newOwner: Object.assign(
                                                                {},
                                                                newOwner,
                                                                {
                                                                    contactFirstName:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    width: "182.5px"
                                                }}
                                            >
                                                <InfoLabel required>
                                                    CONTACT LAST NAME
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. Star"
                                                    value={
                                                        newOwner.contactLastName
                                                    }
                                                    required={
                                                        (!newOwner.contactLastName ||
                                                            newOwner.contactLastName.trim()
                                                                .length ===
                                                                0) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newOwner: Object.assign(
                                                                {},
                                                                newOwner,
                                                                {
                                                                    contactLastName:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                        </InfoContainer>
                                        <InfoContainer>
                                            <div
                                                style={{
                                                    width: "182.5px"
                                                }}
                                            >
                                                <InfoLabel required>
                                                    CONTACT EMAIL
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. example@mail.com"
                                                    value={
                                                        newOwner.contactEmail
                                                    }
                                                    required={
                                                        (!newOwner.contactEmail ||
                                                            newOwner.contactEmail.trim()
                                                                .length ===
                                                                0) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newOwner: Object.assign(
                                                                {},
                                                                newOwner,
                                                                {
                                                                    contactEmail:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    width: "182.5px"
                                                }}
                                            >
                                                <InfoLabel required>
                                                    CONTACT NUMBER
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. +6398765432100"
                                                    type="number"
                                                    value={
                                                        newOwner.contactPhoneNumber
                                                    }
                                                    required={
                                                        (!newOwner.contactPhoneNumber ||
                                                            newOwner.contactPhoneNumber.trim()
                                                                .length ===
                                                                0) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newOwner: Object.assign(
                                                                {},
                                                                newOwner,
                                                                {
                                                                    contactPhoneNumber:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                        </InfoContainer>
                                    </Fragment>
                                )}
                            </div>
                        </Fragment>
                    )}
                </ModalBody>
                <MessageContainer />
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text={unitId ? "UPDATE UNIT" : "ADD UNIT"}
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text={"CANCEL"}
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        ownerOptions:
            state.owners &&
            state.owners.map(o => {
                return {
                    value: o,
                    label: o.name
                };
            }),
        ownerEmails: state.owners
            ? state.owners.map(o => o.email && o.email.toLowerCase())
            : [],
        unitNumbersInBuilding:
            state.units && state.building
                ? state.units.map(
                      unit =>
                          unit.buildingId === state.building.id && unit.number
                  )
                : [],
        buildingOptions:
            state.buildings &&
            state.buildings.map(building => {
                return { value: building.id, label: building.name };
            }),
        building: state.building,
        unitTypes: state.building && state.building.unitTypes,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};

export default connect(mapStateToProps)(UnitModal);
