import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const MainContainer = styled.div`
    height: 20px;
    color: red;
    font-size: 14px;
    margin: 10px 0;
`;

class MessageContainer extends Component {
    render() {
        const { errorMessage, style } = this.props;
        return (
            <MainContainer style={style}>
                {Array.isArray(errorMessage)
                    ? errorMessage.reduce((acc, curr) => acc + " " + curr)
                    : errorMessage}
            </MainContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        errorMessage: state.errorMessage
    };
};

export default connect(mapStateToProps)(MessageContainer);
