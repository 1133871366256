import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import colors from "utils/colors";
import { FiX, FiInfo } from "react-icons/fi";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import ConfirmationModal from "containers/ConfirmationModal";
import { currencyFormat } from "utils/InputPatterns";
import {
    fetchReservation,
    cancelReservation
} from "reducers/reservationReducer";
import { paypalRedirect, dragonPayRedirect } from "reducers/paymentReducer";
import {
    ContentContainer,
    MainHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    PesoSign,
} from "styles/Containers";
import {
    Button,
    PrimaryButton,
    PaypalButton,
    DragonPayButton,
    NewItemLink,
    ClickPayNowbutton,
} from "styles/Buttons";
import { ReserveStatus } from "styles/Status";
import OnlinePaymentModal from "../OnlinePaymentModal";


const ActionsContainer = styled.div`
    width: 100px;
    padding: 8px;
    margin-top: 18px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
`;

const PaymentInfoText = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.PRIMARY};
    font-size: 12px;
    margin: 20px 0;
`;

class AccountReservationContainer extends Component {
    state = {
        cancelConfirmModalVisible: false,
        confirmationModalVisible: false,
        onlinePaymentModalVisible: false,
    };

    componentDidMount() {
        const { reservationId, dispatch } = this.props;
        dispatch(fetchReservation(reservationId));
    }

    handlePaypalClick() {
        const { reservation, dispatch } = this.props;
        if (reservation && reservation.billId) {
            dispatch(paypalRedirect(reservation.billId)).then(() =>
                this.setState({ confirmationModalVisible: true })
            );
        }
    }

    handleDragonPayClick() {
        const { reservation, dispatch } = this.props;
        if (reservation && reservation.billId) {
            dispatch(dragonPayRedirect(reservation.billId)).then(() =>
                this.setState({ confirmationModalVisible: true })
            );
        }
    }

    handleCreditCardPaymentSuccess() {
        const { reservationId, dispatch } = this.props;
        dispatch(fetchReservation(reservationId));
    }

    handleCancelReservation() {
        const { reservationId, dispatch } = this.props;
        dispatch(cancelReservation(reservationId));
    }

    showOnlinePaymentModal() {
        this.setState({ onlinePaymentModalVisible: true });
    }

    hideOnlinePaymentModal() {
        this.setState({ onlinePaymentModalVisible: false });
    }

    render() {
        const { reservation, isLoading, isModalLoading, backTo } = this.props;
        const {
            cancelConfirmModalVisible,
            confirmationModalVisible,
            onlinePaymentModalVisible,
        } = this.state;

        const sortedBookings =
            reservation &&
            reservation.timeSlots.sort(
                (a, b) =>
                    moment(a, "MMMM D, YYYY h:mm A") -
                    moment(b, "MMMM D, YYYY h:mm A")
            );

        const compiledBookings =
            sortedBookings &&
            sortedBookings.reduce((acc, curr) => {
                const currMoment = moment(curr, "MMMM D, YYYY h:mm A");
                const lastBooking = acc.length > 0 && acc[acc.length - 1];

                return lastBooking &&
                    lastBooking.date + " " + lastBooking.endTime === curr
                    ? acc.map((a, i) =>
                          i === acc.length - 1
                              ? Object.assign({}, a, {
                                    endTime: currMoment
                                        .add(1, "h")
                                        .format("h:mm A")
                                })
                              : a
                      )
                    : acc.concat({
                          date: currMoment.format("MMMM D, YYYY"),
                          startTime: currMoment.format("h:mm A"),
                          endTime: currMoment.add(1, "h").format("h:mm A")
                      });
            }, []);

        const bookingRows =
            compiledBookings &&
            compiledBookings.map((b, i) => (
                <InfoText key={"booking_" + i}>
                    {b.date} {b.startTime} - {b.endTime}
                </InfoText>
            ));

        // console.log(reservation);

        return (
            <Fragment>
                <PageHeader
                    title={(backTo && backTo.label) || "Back to Reservations"}
                    backTo={(backTo && backTo.value) || "/account/reservations"}
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    reservation && (
                        <ContentContainer>
                            <Fragment>
                                <MainHeader>
                                    {reservation.amenityName}
                                </MainHeader>
                                <InfoContainer>
                                    <div>
                                        <InfoLabel>Name</InfoLabel>
                                        <InfoText>{reservation.name}</InfoText>
                                    </div>
                                    <div>
                                        <InfoLabel>Email</InfoLabel>
                                        <InfoText>{reservation.email}</InfoText>
                                    </div>
                                </InfoContainer>
                                <InfoContainer>
                                    <div>
                                        <InfoLabel>Bookings</InfoLabel>
                                        {bookingRows}
                                    </div>
                                </InfoContainer>
                                <InfoContainer>
                                    <div>
                                        <InfoLabel>Amount</InfoLabel>
                                        <InfoText>
                                            <PesoSign />
                                            {currencyFormat(reservation.amount)}
                                        </InfoText>
                                    </div>
                                    <div>
                                        <InfoLabel>Status</InfoLabel>
                                        <InfoText>
                                            <ReserveStatus
                                                style={{ fontSize: `12px` }}
                                                status={reservation.status}>
                                                    {reservation.status}
                                            </ReserveStatus>
                                        </InfoText>
                                    </div>
                                </InfoContainer>
                                {(!reservation.status ||
                                    reservation.status === "Pending") && (
                                    <ActionsContainer>
                                        <Button
                                            style={{
                                                backgroundColor: colors.RED_PINK
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    cancelConfirmModalVisible: true
                                                })
                                            }
                                        >
                                            <FiX
                                                style={{ marginRight: "2px" }}
                                            />
                                            <span>CANCEL</span>
                                        </Button>
                                    </ActionsContainer>
                                )}
                            </Fragment>
                            {reservation.status ===
                                "Approved (Pending Payment)" && (
                                <Fragment>
                                    <PaymentInfoText>
                                        <FiInfo
                                            style={{ marginRight: "2px" }}
                                        />
                                        <span>
                                            Online transactions has a fee of{" "}
                                            <PesoSign/>
                                            {reservation.transactionFee
                                                ? currencyFormat(
                                                      reservation.transactionFee
                                                  )
                                                : "0.00"}
                                        </span>
                                    </PaymentInfoText>
                                    <ClickPayNowbutton
                                        onClick={() =>
                                            this.showOnlinePaymentModal()
                                            }
                                        style={{
                                            width: "fit-content",
                                        }}
                                        >
                                            Click here to pay now!
                                    </ClickPayNowbutton>
                                    {confirmationModalVisible && (
                                        <ConfirmationModal
                                            body="Page needs to be refreshed to show changes after payment."
                                            modalVisible={
                                                confirmationModalVisible
                                            }
                                            submitText="Refresh now"
                                            cancelText="Maybe later"
                                            onSubmit={() => location.reload()}
                                            closeModal={() =>
                                                this.setState({
                                                    confirmationModalVisible: false
                                                })
                                            }
                                        />
                                    )}
                                    {onlinePaymentModalVisible && (
                                        <OnlinePaymentModal
                                            transactionFee=
                                                {reservation.transactionFee
                                                    ? currencyFormat(reservation.transactionFee)
                                                    : "0.00"}
                                            totalAmountDue={reservation.amount}
                                            modalVisible={onlinePaymentModalVisible}
                                            submitText="Close"
                                            onSubmit={this.hideOnlinePaymentModal.bind(
                                                this
                                            )}
                                            closeModal={this.hideOnlinePaymentModal.bind(
                                                this
                                            )}
                                            handlePaypalClick={this.handlePaypalClick.bind(
                                                this
                                            )}
                                            handleDragonPayClick={this.handleDragonPayClick.bind(
                                                this
                                            )}
                                        />
                                    )}
                                </Fragment>
                            )}
                            {cancelConfirmModalVisible && (
                                <ConfirmationModal
                                    body="Are you sure you want to cancel this reservation?"
                                    modalVisible={cancelConfirmModalVisible}
                                    onSubmit={() =>
                                        this.handleCancelReservation()
                                    }
                                    submitText="YES"
                                    closeModal={() =>
                                        this.setState({
                                            cancelConfirmModalVisible: false
                                        })
                                    }
                                    cancelText="NO"
                                />
                            )}
                        </ContentContainer>
                    )
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        reservation: state.reservation,
        isLoading: state.isLoading,
        isModalLoading: state.isModalLoading
    };
};

export default connect(mapStateToProps)(AccountReservationContainer);
