import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { FiCheckCircle } from "react-icons/fi";
import {
    modalStyle,
    CloseButton,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { PrimaryButton } from "styles/Buttons";
import colors from "utils/colors";

class AccountRequisitionSuccessModal extends Component {
    render() {
        const { modalVisible, closeModal, isModalLoading } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={isModalLoading ? null : closeModal}
                style={modalStyle({ height: 300, width: 400 })}
            >
                <CloseButton onClick={isModalLoading ? null : closeModal} />
                <ModalBody
                    height="200px"
                    style={{ textAlign: "center", padding: "42px 0" }}
                >
                    <FiCheckCircle
                        style={{
                            fontSize: "60px",
                            color: colors.BLUE_GREEN
                        }}
                    />
                    <div>Your claim has been sent for approval.</div>
                </ModalBody>
                <ModalFooter>
                    <PrimaryButton
                        style={{
                            height: "38px",
                            width: "100%",
                            fontSize: "14px"
                        }}
                        onClick={closeModal}
                        text="OK"
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(AccountRequisitionSuccessModal);
