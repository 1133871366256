import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import ChangePasswordModal from "containers/ChangePasswordModal";
import colors from "utils/colors";
import {
    ContentContainer,
    MainHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    ProfileImage,
    HeaderContainer,
    HRContainer,
    ProfileHeader,
    ProfileNameHolder,
    ProfileContent,
} from "styles/Containers";

var md5 = require("md5");

class ProfileContainer extends Component {
    state = {
        changePasswordModalvisible: false
    };

    showChangePasswordModal() {
        this.setState({ changePasswordModalvisible: true });
    }

    hideChangePasswordModal() {
        this.setState({ changePasswordModalvisible: false });
    }

    render() {
        const { user, isLoading } = this.props;
        const { changePasswordModalvisible } = this.state;

        const hashedEmail = user && user.email && md5(user.email);

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : (
                    user && (
                        <Fragment>
                            <ProfileHeader style={{
                                padding: "30px",
                            }}>
                               <ProfileImage
                                    background={`https://www.gravatar.com/avatar/${hashedEmail}?s=150&d=mm`}
                                />
                                <ProfileNameHolder>
                                    {
                                        (user.name ? user.name : user.email)+
                                        " " +
                                        (user.lastName ? user.lastName : "")
                                    }
                                    <p>{user.email}</p>
                                </ProfileNameHolder>
                            </ProfileHeader>
                            <ProfileContent>
                                <h3>Account</h3>
                                <div>
                                    <p>Email</p>
                                    <span>{user.email}</span>
                                </div>
                                <div>
                                    <p>Name</p>
                                    <span>
                                        {
                                            (user.name ? user.name : user.email)+
                                            " " +
                                            (user.lastName ? user.lastName : "")
                                        }
                                    </span>
                                </div>
                                <div>
                                    <p>Password</p>
                                    <span
                                        style={{
                                            width: `15%`
                                        }}>
                                            •••••••••••
                                    </span>
                                    <span>
                                        <a
                                            style={{
                                                color: colors.BLUE,
                                                textDecoration: "none",
                                                cursor: "pointer"
                                            }}
                                            onClick={() =>
                                                this.showChangePasswordModal()
                                            }
                                        >
                                            Change password
                                        </a>
                                    </span>
                                </div>
                                <div>
                                    <p>Role</p>
                                    <span></span>
                                </div>
                            </ProfileContent>
                        </Fragment>
                    )
                )}
                {changePasswordModalvisible && (
                    <ChangePasswordModal
                        modalVisible={changePasswordModalvisible}
                        closeModal={this.hideChangePasswordModal.bind(this)}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        isLoading: state.isLoading
    };
};

export default connect(mapStateToProps)(ProfileContainer);
