import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "containers/Spinner";
import { currencyFormat } from "utils/InputPatterns";
import PageHeader from "containers/PageHeader";
import { FiSearch } from "react-icons/fi";
import {
    Input,
    IconInputContainer,
    Select,
    FiltersContainer,
    FilterLabel
} from "styles/Inputs";
import {
    TabHeaderContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    TabHeaderSpacer
} from "styles/Tabs";
import {
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination,
    EmptyListContainer
} from "styles/Tables";
import {
    PesoSign,
} from "styles/Containers";

const filterStatusOptions = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "Pending",
        label: "Pending"
    },
    {
        value: "Approved (Pending Payment)",
        label: "Approved (Pending Payment)"
    },
    {
        value: "Reserved (Paid)",
        label: "Reserved (Paid)"
    },
    {
        value: "Denied",
        label: "Denied"
    },
    {
        value: "Cancelled",
        label: "Cancelled"
    }
];

const reservationsPerPage = 10;

class TenantReservationsContainer extends Component {
    state = {
        filter: {
            amenity: "",
            email: "",
            name: "",
            status: {
                value: "All",
                label: "All"
            }
        },
        filteredReservations: null,
        offset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { reservations } = this.props;
        this.setState({
            filteredReservations: reservations || []
        });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        const { tabVisible, modalVisible } = this.props;
        if (
            tabVisible !== prevProps.tabVisible ||
            modalVisible !== prevProps.modalVisible
        ) {
            if (tabVisible && !modalVisible) {
                document.addEventListener("keydown", this.handleKeyDown);
            } else {
                document.removeEventListener("keydown", this.handleKeyDown);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredReservations, offset } = this.state;
        this.setState({
            offset:
                offset <
                Math.ceil(filteredReservations.length / reservationsPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    handleFilterAmenityChange(amenity) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                amenity
            }),
            filteredReservations: this.filterReservations({
                amenity,
                email: filter.email,
                name: filter.name,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterEmailChange(email) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                email
            }),
            filteredReservations: this.filterReservations({
                amenity: filter.amenity,
                email,
                name: filter.name,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterNameChange(name) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                name
            }),
            filteredReservations: this.filterReservations({
                amenity: filter.amenity,
                email: filter.email,
                name,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterStatusChange(status) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                status
            }),
            filteredReservations: this.filterReservations({
                amenity: filter.amenity,
                email: filter.email,
                name: filter.name,
                status
            }),
            offset: 1
        });
    }

    filterReservations({ amenity, email, name, status }) {
        const { reservations } = this.props;

        return (
            reservations &&
            reservations.filter(
                reservation =>
                    (amenity
                        ? reservation.amenityName
                              .toLowerCase()
                              .includes(amenity.toLowerCase())
                        : true) &&
                    (email
                        ? reservation.email &&
                          reservation.email
                              .toLowerCase()
                              .includes(email.toLowerCase())
                        : true) &&
                    (name
                        ? reservation.name &&
                          reservation.name
                              .toLowerCase()
                              .includes(name.toLowerCase())
                        : true) &&
                    (status
                        ? status.value === "All" ||
                          reservation.status === status.value
                        : true)
            )
        );
    }

    render() {
        const {
            tenantId,
            reservations,
            tabVisible,
            onTabChange,
            isAdmin
        } = this.props;
        const { filter, filteredReservations, offset } = this.state;
        const range = offset * reservationsPerPage;

        const reservationRows =
            filteredReservations &&
            filteredReservations.map((reservation, i) => {
                if (i >= range - reservationsPerPage && i < range) {
                    return (
                        <TableLinkRow
                            key={reservation.id}
                            to={{
                                pathname: `/${
                                    isAdmin ? "admin" : "account"
                                }/reservations/${reservation.id}`,
                                state: {
                                    backTo: {
                                        label: "Back to Tenant",
                                        value: `/${
                                            isAdmin ? "admin" : "account"
                                        }/tenants/${tenantId}`
                                    }
                                }
                            }}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{reservation.amenityName}</TableCell>
                            <TableCell>{reservation.email}</TableCell>
                            <TableCell>{reservation.name}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {currencyFormat(reservation.amount)}
                            </TableCell>
                            <TableCell>{reservation.status}</TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        return (
            <Fragment>
                {tabVisible && (
                    <Fragment>
                        <TabHeaderContainer>
                            <InactiveTabHeader
                                onClick={() => onTabChange("units")}
                            >
                                Units
                            </InactiveTabHeader>
                            <InactiveTabHeader
                                onClick={() => onTabChange("bills")}
                            >
                                Bills
                            </InactiveTabHeader>
                            <ActiveTabHeader>Reservations</ActiveTabHeader>
                            <InactiveTabHeader
                                onClick={() => onTabChange("payments")}
                            >
                                Payments
                            </InactiveTabHeader>
                            <TabHeaderSpacer />
                        </TabHeaderContainer>
                        {reservations && reservations.length > 0 ? (
                            <Fragment>
                                <FiltersContainer>
                                    <div style={{ width: "220px" }}>
                                        <FilterLabel>Amenity</FilterLabel>
                                        <IconInputContainer>
                                            <FiSearch />
                                            <Input
                                                placeholder="e.g. Function Hall Room"
                                                value={filter.amenity}
                                                onChange={e =>
                                                    this.handleFilterAmenityChange(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </IconInputContainer>
                                    </div>
                                    <div style={{ width: "200px" }}>
                                        <FilterLabel>Email</FilterLabel>
                                        <IconInputContainer>
                                            <FiSearch />
                                            <Input
                                                placeholder="e.g. joe@mail.com"
                                                value={filter.email}
                                                onChange={e =>
                                                    this.handleFilterEmailChange(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </IconInputContainer>
                                    </div>
                                    <div style={{ width: "200px" }}>
                                        <FilterLabel>Name</FilterLabel>
                                        <IconInputContainer>
                                            <FiSearch />
                                            <Input
                                                placeholder="e.g. Joe"
                                                value={filter.name}
                                                onChange={e =>
                                                    this.handleFilterNameChange(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </IconInputContainer>
                                    </div>
                                    <div style={{ width: "250px" }}>
                                        <FilterLabel>Status</FilterLabel>
                                        <Select
                                            value={filter.status}
                                            onChange={selectedStatus =>
                                                this.handleFilterStatusChange(
                                                    selectedStatus
                                                )
                                            }
                                            options={filterStatusOptions}
                                        />
                                    </div>
                                </FiltersContainer>
                                <Table>
                                    <TableHeader>
                                        <TableCell>Amenity</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell
                                            style={{ textAlign: "right" }}
                                        >
                                            Amount
                                        </TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell />
                                    </TableHeader>
                                    {reservationRows}
                                </Table>
                                {filteredReservations &&
                                    filteredReservations.length >
                                        reservationsPerPage && (
                                        <Pagination
                                            itemsPerPage={reservationsPerPage}
                                            offset={offset}
                                            length={filteredReservations.length}
                                            handlePreviousPage={() =>
                                                this.handlePreviousPage()
                                            }
                                            handleNextPage={() =>
                                                this.handleNextPage()
                                            }
                                        />
                                    )}
                            </Fragment>
                        ) : (
                            <EmptyListContainer style={{ margin: "30px 0" }}>
                                There are no reservations yet.
                            </EmptyListContainer>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        reservations: state.reservations,
        isLoading: state.isLoading,
        isAdmin: state.isAdmin,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(TenantReservationsContainer);
