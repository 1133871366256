import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { messageActions } from "reducers/messageReducer";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalSubHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import {
    MainHeader,
    InfoContainer,
    InfoLabel,
    InfoText
} from "styles/Containers";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";
import { accountClaimUnit } from "reducers/unitReducer";

class AccountRequisitionModal extends Component {
    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
    }

    async handleSubmit() {
        const { dispatch, unit, type, onSuccess } = this.props;
        await dispatch(
            accountClaimUnit({ unitId: unit.id, requisitionType: type.value })
        );
        if (!this.props.errorMessage) {
            onSuccess();
        }
    }

    render() {
        const { unit, modalVisible, closeModal, isModalLoading } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={isModalLoading ? null : closeModal}
                style={modalStyle({ height: 430, width: 500 })}
            >
                <CloseButton onClick={isModalLoading ? null : closeModal} />
                <ModalHeaderContainer>
                    <ModalHeader>Claim Unit</ModalHeader>
                    <ModalSubHeader>{unit.propertyName}</ModalSubHeader>
                </ModalHeaderContainer>
                <ModalBody height="210px">
                    <MainHeader>Unit {unit.number}</MainHeader>
                    <InfoContainer>
                        <div>
                            <InfoLabel>Building</InfoLabel>
                            <InfoText>{unit.buildingName}</InfoText>
                        </div>
                        <div>
                            <InfoLabel>Floor</InfoLabel>
                            <InfoText>{unit.floor}</InfoText>
                        </div>
                        <div>
                            <InfoLabel>Area</InfoLabel>
                            <InfoText>{unit.floorArea}sqm</InfoText>
                        </div>
                    </InfoContainer>
                    <InfoContainer>
                        <div>
                            <InfoLabel>Type</InfoLabel>
                            <InfoText>{unit.unitType}</InfoText>
                        </div>
                        {unit.status && (
                            <div>
                                <InfoLabel>Status</InfoLabel>
                                <InfoText>{unit.status}</InfoText>
                            </div>
                        )}
                    </InfoContainer>
                    <MessageContainer />
                </ModalBody>
                {!unit.status || unit.status === "Denied" ? (
                    <ModalFooter style={{ height: "110px" }}>
                        {isModalLoading ? (
                            <Loader text="PROCESSING..." />
                        ) : (
                            <Fragment>
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px"
                                    }}
                                    onClick={() => this.handleSubmit()}
                                    text="CLAIM"
                                />
                                <PrimaryAltButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                        marginTop: "8px"
                                    }}
                                    onClick={closeModal}
                                    text={"CANCEL"}
                                />
                            </Fragment>
                        )}
                    </ModalFooter>
                ) : (
                    unit.status === "Proposed" && (
                        <ModalFooter>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={closeModal}
                                text="OK"
                            />
                        </ModalFooter>
                    )
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(AccountRequisitionModal);
