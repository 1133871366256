import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import BuildingUniTypesContainer from "./BuildingUnitTypesContainer";
import BuildingBillingCategoriesContainer from "./BuildingBillingCategoriesContainer";
import BuildingUploadsContainer from "./BuildingUploadsContainer";
import { setFirstBuilding } from "reducers/buildingReducer";
import { ContentContainer } from "styles/Containers";

class BuildingContainer extends Component {
    state = {
        tabVisible: "unitTypes"
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(setFirstBuilding());
    }

    render() {
        const { isLoading } = this.props;
        const { tabVisible } = this.state;

        return (
            <Fragment>
                <PageHeader title="Settings" />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ContentContainer>
                        <BuildingUniTypesContainer
                            tabVisible={tabVisible === "unitTypes"}
                            onTabChange={tabSelected =>
                                this.setState({
                                    tabVisible: tabSelected
                                })
                            }
                        />
                        <BuildingBillingCategoriesContainer
                            tabVisible={tabVisible === "billingCategories"}
                            onTabChange={tabSelected =>
                                this.setState({
                                    tabVisible: tabSelected
                                })
                            }
                        />
                        <BuildingUploadsContainer
                            tabVisible={tabVisible === "uploads"}
                            onTabChange={tabSelected =>
                                this.setState({
                                    tabVisible: tabSelected
                                })
                            }
                        />
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading
    };
};

export default connect(mapStateToProps)(BuildingContainer);
