import React, { Component, Fragment } from "react";
import styled from "styled-components";
import {
    MdClose,
} from "react-icons/md";
import { SideNavLinkContainer } from "styles/Containers";
import colors from "utils/colors";
// import { FiMail } from "react-icons/fi";


const RightSideModalContainer = styled.div`
    width: 300px;
    height: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 0;
`

const OverlayContainer = styled.div`
    background-color: rgba(0,0,0,0.5);;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: fixed;
`

const CloseContainer = styled.div`
    display: flex;
    position: absolute;
    right: 15px;
    cursor: pointer;
    color: ${colors.GRAY};
`

const HelpTitle = styled.h3`
    text-align: center;
    font-weight: 600;
    border-bottom: 2px solid ${colors.GRAY};
    padding-bottom: 0.5rem;
    margin: 0;
`

const HelpBodyContainer = styled.div`
    padding: 15px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
`

const HelpFooterContainer = styled.div`
    position: absolute;
    height: 45px;
    width: 100%;
    bottom: 0;
    border-top: 2px solid ${colors.GRAY};
    background-color: white;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
`

const HelpContentBox = styled.div`
    padding: 15px;

    :hover {
        background-color: ${colors.LIGHT_GRAY};
    }

    h4 {
        margin: 0;
        margin-bottom: 10px;
    }

    p {
        display: flex;
        font-weight: 400;
        margin: 0;
    }

    a {
       text-decoration: none;
       color: ${colors.BLUE};
       font-weight: 400;
    }
`


const HelpFooterLink = styled.a`
    font-size: x-small;
    text-decoration: none;
    color: ${colors.LIGHT_BLACK};

    :hover {
        color: ${colors.GRAY};
    }
`

class HelpModalContainer extends Component {

    render() {

        return (
                <Fragment>
                    <OverlayContainer />
                    <RightSideModalContainer >
                        <CloseContainer>
                            <MdClose
                                onClick={this.props.hideModal}
                                style={{
                                    strokeWidth: `1`,
                                }}
                            />
                        </CloseContainer>
                        <HelpTitle>
                            Help
                        </HelpTitle>
                        <HelpBodyContainer>
                            <HelpContentBox>
                                <h4>
                                    What is Onlinedooze?
                                </h4>
                                <p>
                                    The on the go, on the dot, one-stop online
                                    portal for an organized and efficient property management.
                                </p>
                            </HelpContentBox>
                            <HelpContentBox>
                                <h4>
                                    On The Go
                                </h4>
                                <p>
                                    OnlineDooze works with you, and keeps itself busy so you don’t have to —
                                    from bill reminders to renewal notices, rental application to lease
                                    payments, facility reservations to complaint resolutions.
                                    Your powerful stress-free dose of efficacy in few simple clicks.
                                </p>
                            </HelpContentBox>
                            <HelpContentBox>
                                <h4>
                                    On The Dot
                                </h4>
                                <p>
                                    Manage properties and its facilities’ bills, rentals,
                                    dues, and payments right when you need them.
                                    OnlineDooze provides a real-time and efficient platform for
                                    all property stakeholders without worrying about limited office hours,
                                    unorganized files, delayed notifications, or missing communications.
                                </p>
                            </HelpContentBox>
                            <HelpContentBox>
                                <h4>
                                    One-Stop
                                </h4>
                                <p>
                                    Keep track of multiple properties and accounts in a single portal, 24/7.
                                    OnlineDooze is the go-to organizer to monitor, maintain, and
                                    evaluate property-related transactions and records.
                                    Everything in one place, online, anytime.
                                </p>
                            </HelpContentBox>
                            <HelpContentBox>
                                <h4>
                                    Need Help?
                                </h4>
                                <p>
                                    Contact us at
                                </p>
                                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@intelimina.com">info@intelimina.com</a>
                            </HelpContentBox>

                        </HelpBodyContainer>
                        <HelpFooterContainer>
                            <HelpFooterLink
                                href="https://onlinedooze.com/"
                                target="_blank"
                            >
                                Visit the website
                            </HelpFooterLink>
                            <HelpFooterLink
                                href="https://onlinedooze.com/privacy-policy"
                                target="_blank"
                            >
                                Privacy Policy
                            </HelpFooterLink>
                        </HelpFooterContainer>
                    </RightSideModalContainer>
                </Fragment>
            )
    }
}

export default HelpModalContainer;