import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import { FiCheck, FiX } from "react-icons/fi";
import styled from "styled-components";
import colors from "utils/colors";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import ConfirmationModal from "containers/ConfirmationModal";
import AdminPaymentReservationModal from "./AdminPaymentReservationModal";
import { currencyFormat } from "utils/InputPatterns";
import {
    fetchReservation,
    approveReservation,
    denyReservation
} from "reducers/reservationReducer";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableHeader,
    TableRow,
    TableCell
} from "styles/Tables";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import {
    ContentContainer,
    MainHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    PesoSign,
} from "styles/Containers";
import { TextArea } from "styles/Inputs";
import {
    Button,
    PrimaryButton,
    PrimaryAltButton,
    AddItemButton,
    NewItemLink,
    NewItemContainer
} from "styles/Buttons";
import { ReserveStatus, PayStatus } from "styles/Status";

const ActionsContainer = styled.div`
    display: flex;
    width: 185px;
    padding: 8px;
    margin-top: 18px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
`;

class AdminReservationContainer extends Component {
    state = {
        paymentModalVisible: false,
        confirmationModalVisible: false,
        denyModalVisible: false,
        denyRemarks: ""
    };

    componentDidMount() {
        const { isAuthorized, reservationId, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchReservation(reservationId));
        }
    }

    showPaymentModal() {
        this.setState({ paymentModalVisible: true });
    }

    hidePaymentModal() {
        this.setState({ paymentModalVisible: false });
    }

    showConfirmationModal() {
        this.setState({ confirmationModalVisible: true });
    }

    hideConfirmationModal() {
        this.setState({ confirmationModalVisible: false });
    }

    submitApproveHandler() {
        const { reservationId, dispatch } = this.props;
        dispatch(approveReservation(reservationId));
    }

    async submitDenyHandler() {
        const { reservationId, dispatch } = this.props;
        const { denyRemarks } = this.state;
        await dispatch(
            denyReservation({ id: reservationId, remarks: denyRemarks })
        );
        if (!this.props.errorMessage) {
            this.setState({ hasError: false });
            this.closeDenyModalHandler();
        }
    }

    closeDenyModalHandler() {
        this.setState({ denyModalVisible: false, denyRemarks: "" });
    }

    render() {
        const { reservation, isLoading, isModalLoading, backTo } = this.props;
        const {
            paymentModalVisible,
            confirmationModalVisible,
            denyModalVisible
        } = this.state;

        const sortedBookings =
            reservation &&
            reservation.timeSlots.sort(
                (a, b) =>
                    moment(a, "MMMM D, YYYY h:mm A") -
                    moment(b, "MMMM D, YYYY h:mm A")
            );

        const compiledBookings =
            sortedBookings &&
            sortedBookings.reduce((acc, curr) => {
                const currMoment = moment(curr, "MMMM D, YYYY h:mm A");
                const lastBooking = acc.length > 0 && acc[acc.length - 1];

                return lastBooking &&
                    lastBooking.date + " " + lastBooking.endTime === curr
                    ? acc.map((a, i) =>
                          i === acc.length - 1
                              ? Object.assign({}, a, {
                                    endTime: currMoment
                                        .add(1, "h")
                                        .format("h:mm A")
                                })
                              : a
                      )
                    : acc.concat({
                          date: currMoment.format("MMMM D, YYYY"),
                          startTime: currMoment.format("h:mm A"),
                          endTime: currMoment.add(1, "h").format("h:mm A")
                      });
            }, []);

        const bookingRows =
            compiledBookings &&
            compiledBookings.map((b, i) => (
                <InfoText key={"booking_" + i}>
                    {b.date} {b.startTime} - {b.endTime}
                </InfoText>
            ));

        const anonymousPaymentLink =
            reservation &&
            window.location.origin.concat(`/bills/${reservation.billId}`);

        const paymentRows =
            reservation &&
            reservation.payments &&
            reservation.payments.map((payment, i) => (
                <TableRow gray={i % 2 === 0} key={"payment_" + i}>
                    <TableCell>
                        {payment.date
                            ? moment(payment.date).format("MM/DD/YYYY HH:mm:ss")
                            : ""}
                    </TableCell>
                    <TableCell>{payment.referenceNumber}</TableCell>
                    <TableCell>{payment.billCategory}</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <PesoSign />{currencyFormat(payment.amount)}
                    </TableCell>
                    <TableCell>{payment.remarks}</TableCell>
                    <TableCell>
                        <PayStatus
                            status={payment.status}>
                                {payment.status}
                        </PayStatus>
                    </TableCell>
                </TableRow>
            ));

        return (
            <Fragment>
                <PageHeader
                    title={(backTo && backTo.label) || "Back to Reservations"}
                    backTo={(backTo && backTo.value) || "/admin/reservations"}
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    reservation && (
                        <ContentContainer>
                            <MainHeader>{reservation.amenityName}</MainHeader>
                            <InfoContainer>
                                <div>
                                    <InfoLabel>Name</InfoLabel>
                                    <InfoText>{reservation.name}</InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Email</InfoLabel>
                                    <InfoText>{reservation.email}</InfoText>
                                </div>
                            </InfoContainer>
                            <InfoContainer>
                                <div>
                                    <InfoLabel>Bookings</InfoLabel>
                                    {bookingRows}
                                </div>
                            </InfoContainer>
                            <InfoContainer>
                                <div>
                                    <InfoLabel>Total Amount</InfoLabel>
                                    <PesoSign />{currencyFormat(reservation.amount)}
                                </div>
                                <div>
                                    <InfoLabel>Remaining Balance</InfoLabel>
                                    <InfoText>
                                        <PesoSign />
                                        {currencyFormat(
                                            reservation.remainingBalance
                                        )}
                                    </InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Status</InfoLabel>
                                    <InfoText>
                                        <ReserveStatus
                                            status={reservation.status}
                                            style={{ fontSize: `12px` }}>
                                                {reservation.status}
                                        </ReserveStatus>
                                    </InfoText>
                                </div>
                            </InfoContainer>
                            {!reservation.status ||
                            reservation.status === "Pending" ? (
                                <ActionsContainer>
                                    <Button
                                        style={{
                                            float: "none",
                                            backgroundColor: colors.RED_PINK,
                                            marginRight: "10px"
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                denyModalVisible: true
                                            })
                                        }
                                    >
                                        <FiX style={{ marginRight: "2px" }} />
                                        <span>DENY</span>
                                    </Button>
                                    <Button
                                        style={{ float: "none" }}
                                        onClick={() =>
                                            this.submitApproveHandler()
                                        }
                                    >
                                        <FiCheck
                                            style={{ marginRight: "2px" }}
                                        />
                                        <span>APPROVE</span>
                                    </Button>
                                </ActionsContainer>
                            ) : (
                                (reservation.status ===
                                    "Approved (Pending Payment)" ||
                                    reservation.status ===
                                        "Reserved (Paid)") && (
                                    <div
                                        style={{
                                            marginTop: "20px"
                                        }}
                                    >
                                        {reservation.payments &&
                                        reservation.payments.length > 0 ? (
                                            <Fragment>
                                                <TableTextHeaderContainer>
                                                    <TableTextHeader>
                                                        PAYMENTS
                                                    </TableTextHeader>
                                                    {reservation.status !==
                                                        "Reserved (Paid)" && (
                                                        <NewItemContainer>
                                                            <NewItemLink
                                                                onClick={() =>
                                                                    this.showConfirmationModal()
                                                                }
                                                            >
                                                                Generate Payment
                                                                Link
                                                            </NewItemLink>
                                                            <span
                                                                style={{
                                                                    margin:
                                                                        "0 8px"
                                                                }}
                                                            >
                                                                |
                                                            </span>
                                                            <AddItemButton
                                                                onClick={() =>
                                                                    this.showPaymentModal()
                                                                }
                                                                text="NEW PAYMENT"
                                                            />
                                                        </NewItemContainer>
                                                    )}
                                                </TableTextHeaderContainer>
                                                <Table>
                                                    <TableHeader>
                                                        <TableCell>
                                                            Date and Time
                                                        </TableCell>
                                                        <TableCell>
                                                            Reference Number
                                                        </TableCell>
                                                        <TableCell>
                                                            Category
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                textAlign:
                                                                    "right"
                                                            }}
                                                        >
                                                            Amount
                                                        </TableCell>
                                                        <TableCell>
                                                            Remarks
                                                        </TableCell>
                                                        <TableCell>
                                                            Status
                                                        </TableCell>
                                                    </TableHeader>
                                                    {paymentRows}
                                                </Table>
                                            </Fragment>
                                        ) : (
                                            <div>
                                                <span>
                                                    This bill does not have any
                                                    payments yet.{" "}
                                                </span>
                                                <NewItemLink
                                                    onClick={() =>
                                                        this.showPaymentModal()
                                                    }
                                                >
                                                    Create one now
                                                </NewItemLink>
                                                <span> or </span>
                                                <NewItemLink
                                                    onClick={() =>
                                                        this.showConfirmationModal()
                                                    }
                                                >
                                                    generate a payment link
                                                </NewItemLink>
                                                <span>.</span>
                                            </div>
                                        )}
                                    </div>
                                )
                            )}
                            {paymentModalVisible && (
                                <AdminPaymentReservationModal
                                    modalVisible={paymentModalVisible}
                                    closeModal={this.hidePaymentModal.bind(
                                        this
                                    )}
                                    bill={{
                                        balanceWithOverdue: reservation.amount,
                                        id: reservation.billId
                                    }}
                                />
                            )}
                            {confirmationModalVisible && (
                                <ConfirmationModal
                                    body={
                                        <a
                                            href={anonymousPaymentLink}
                                            target="_blank"
                                        >
                                            {anonymousPaymentLink}
                                        </a>
                                    }
                                    modalVisible={confirmationModalVisible}
                                    submitText="OK"
                                    onSubmit={this.hideConfirmationModal.bind(
                                        this
                                    )}
                                    closeModal={this.hideConfirmationModal.bind(
                                        this
                                    )}
                                />
                            )}
                            {denyModalVisible && (
                                <Modal
                                    isOpen={denyModalVisible}
                                    onRequestClose={
                                        isModalLoading
                                            ? null
                                            : this.closeDenyModalHandler.bind(
                                                  this
                                              )
                                    }
                                    style={modalStyle({
                                        height: 360,
                                        width: 400
                                    })}
                                >
                                    <CloseButton
                                        onClick={() =>
                                            isModalLoading
                                                ? null
                                                : this.closeDenyModalHandler()
                                        }
                                    />
                                    <ModalHeaderContainer>
                                        <ModalHeader>
                                            Deny Reservation
                                        </ModalHeader>
                                    </ModalHeaderContainer>
                                    <ModalBody height="140px">
                                        <InfoLabel>REMARKS</InfoLabel>
                                        <TextArea
                                            rows={4}
                                            disabled={isModalLoading}
                                            onChange={e =>
                                                this.setState({
                                                    denyRemarks: e.target.value
                                                })
                                            }
                                        />
                                        <MessageContainer />
                                    </ModalBody>
                                    <ModalFooter style={{ height: "110px" }}>
                                        {isModalLoading ? (
                                            <Loader text="PROCESSING..." />
                                        ) : (
                                            <Fragment>
                                                <PrimaryButton
                                                    style={{
                                                        height: "38px",
                                                        width: "100%",
                                                        fontSize: "14px"
                                                    }}
                                                    onClick={() =>
                                                        this.submitDenyHandler()
                                                    }
                                                    text="SUBMIT"
                                                />
                                                <PrimaryAltButton
                                                    style={{
                                                        height: "38px",
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        marginTop: "8px"
                                                    }}
                                                    onClick={() =>
                                                        this.closeDenyModalHandler()
                                                    }
                                                    text="CANCEL"
                                                />
                                            </Fragment>
                                        )}
                                    </ModalFooter>
                                </Modal>
                            )}
                        </ContentContainer>
                    )
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        reservation: state.reservation,
        isLoading: state.isLoading,
        isModalLoading: state.isModalLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AdminReservationContainer);
