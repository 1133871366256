import * as loadImage from "blueimp-load-image";

export const modifyImage = obj => {
    return new Promise((resolve, reject) => {
        let loadImageOptions = { canvas: true, maxWidth: 600 };
        loadImage.parseMetaData(obj.file, data => {
            if (data.exif && data.exif.get("Orientation")) {
                loadImageOptions.orientation = data.exif.get("Orientation");
            }
            loadImage(
                obj.file,
                canvas =>
                    resolve(
                        Object.assign({}, obj, { preview: canvas.toDataURL() })
                    ),
                loadImageOptions
            );
        });
    });
};

export const readFile = file => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            const obj = {
                preview: reader.result,
                file: file
            };
            resolve(obj);
        };
        reader.readAsDataURL(file);
    });
};

export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}

export function download({ url, fileName }) {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = url;
    link.download = fileName;
    link.click();
    document.body.removeChild(link);
}
