import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "react-modal";
import { messageActions } from "reducers/messageReducer";
import MessageContainer from "./MessageContainer";
import { Loader } from "./Loader";
import { isValidEmail } from "utils/InputPatterns";
import { InfoContainer, InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import {
    Input,
    FlippedInputDatePickerContainer,
    Select,
    InputDatePicker
} from "styles/Inputs";
import { Button, PrimaryButton, PrimaryAltButton } from "styles/Buttons";
import { updateUnitTenant } from "reducers/tenantReducer";

const newTenantTypeOptions = [
    {
        value: "Individual",
        label: "Individual"
    },
    {
        value: "Company",
        label: "Company"
    }
];

class UnitTenantModal extends Component {
    state = {
        tenant: null,
        contractStart: new Date(),
        contractEnd: null,
        newTenant: {
            isSelected: false,
            type: null,
            name: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            contactFirstName: "",
            contactLastName: "",
            contactEmail: "",
            contactPhoneNumber: ""
        },
        hasError: false
    };

    componentDidMount() {
        const { tenant } = this.props;
        if (tenant) {
            this.setState({
                tenant: {
                    value: tenant.id,
                    label: tenant.name
                }
            });
        }
    }

    async handleSubmit() {
        const {
            tenantEmails,
            unitId,
            onSubmit,
            closeModal,
            dispatch
        } = this.props;
        const { tenant, contractStart, contractEnd, newTenant } = this.state;

        let errorMessage = "";
        const requiredFieldsFilled =
            contractStart &&
            contractEnd &&
            (newTenant.isSelected
                ? newTenant.type &&
                  newTenant.email.trim().length > 0 &&
                  newTenant.phoneNumber.trim().length > 0 &&
                  (newTenant.type && newTenant.type.value === "Company"
                      ? newTenant.name.trim().length > 0 &&
                        newTenant.contactFirstName.trim().length > 0 &&
                        newTenant.contactLastName.trim().length > 0 &&
                        newTenant.contactEmail.trim().length > 0 &&
                        newTenant.contactPhoneNumber.trim().length > 0
                      : newTenant.type && newTenant.type.value === "Individual"
                      ? newTenant.firstName.trim().length > 0 &&
                        newTenant.lastName.trim().length > 0
                      : true)
                : Boolean(tenant));
        const emailNotUnique =
            newTenant.isSelected &&
            tenantEmails.includes(newTenant.email.trim().toLowerCase());

        errorMessage = !requiredFieldsFilled
            ? "Please fill up all required fields."
            : errorMessage;
        errorMessage =
            newTenant.isSelected &&
            newTenant.email &&
            !isValidEmail(newTenant.email)
                ? errorMessage.length > 0
                    ? errorMessage + " Invalid email."
                    : "Invalid email."
                : emailNotUnique
                ? errorMessage.length > 0
                    ? errorMessage + " Email must be unique."
                    : "Email must be unique."
                : errorMessage;

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(
                updateUnitTenant(Object.assign({}, this.state, { unitId }))
            );
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
                if (Boolean(onSubmit)) {
                    onSubmit();
                }
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    handleTenantChange(selectedTenant) {
        const { newTenant } = this.state;
        this.setState({
            tenant: selectedTenant,
            newTenant: Object.assign({}, newTenant, { isSelected: false })
        });
    }

    render() {
        const {
            tenantOptions,
            tenantEmails,
            isModalLoading,
            modalVisible
        } = this.props;
        const {
            tenant,
            contractStart,
            contractEnd,
            newTenant,
            hasError
        } = this.state;
        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({
                    height: newTenant.isSelected
                        ? newTenant.type
                            ? 680
                            : 525
                        : 500,
                    width: 450
                })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>UPDATE TENANT</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody
                    height={
                        newTenant.isSelected
                            ? newTenant.type
                                ? "450px"
                                : "300px"
                            : "270px"
                    }
                    style={{
                        overflowX:
                            newTenant.isSelected && newTenant.type
                                ? "auto"
                                : "none"
                    }}
                >
                    <InfoContainer style={{ marginTop: 0 }}>
                        <div style={{ width: "380px" }}>
                            <InfoLabel required={!newTenant.isSelected}>
                                TENANT
                            </InfoLabel>
                            <Select
                                required={
                                    !newTenant.isSelected && !tenant && hasError
                                }
                                disabled={isModalLoading}
                                value={tenant}
                                onChange={this.handleTenantChange.bind(this)}
                                options={tenantOptions}
                                isSearchable
                                placeholder="Select Tenant"
                            />
                        </div>
                    </InfoContainer>
                    {newTenant.isSelected && (
                        <Fragment>
                            <InfoContainer>NEW TENANT</InfoContainer>
                            <InfoContainer>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>TENANT TYPE</InfoLabel>
                                    <Select
                                        value={newTenant.type}
                                        required={!newTenant.type && hasError}
                                        onChange={selectedTenantType =>
                                            this.setState({
                                                newTenant: Object.assign(
                                                    {},
                                                    newTenant,
                                                    {
                                                        type: selectedTenantType
                                                    }
                                                )
                                            })
                                        }
                                        options={newTenantTypeOptions}
                                        placeholder="Select Tenant Type"
                                    />
                                </div>
                                {newTenant.type &&
                                    newTenant.type.value === "Company" && (
                                        <div
                                            style={{
                                                width: "182.5px"
                                            }}
                                        >
                                            <InfoLabel required>
                                                COMPANY NAME
                                            </InfoLabel>
                                            <Input
                                                placeholder="e.g. Ipsum"
                                                value={newTenant.name}
                                                required={
                                                    (!newTenant.name ||
                                                        newTenant.name.trim()
                                                            .length === 0) &&
                                                    hasError
                                                }
                                                disabled={isModalLoading}
                                                onChange={e =>
                                                    this.setState({
                                                        newTenant: Object.assign(
                                                            {},
                                                            newTenant,
                                                            {
                                                                name:
                                                                    e.target
                                                                        .value
                                                            }
                                                        )
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                            </InfoContainer>
                            {newTenant.type && (
                                <Fragment>
                                    {newTenant.type.value === "Individual" && (
                                        <InfoContainer>
                                            <div style={{ width: "182.5px" }}>
                                                <InfoLabel required>
                                                    FIRST NAME
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. Joe"
                                                    value={newTenant.firstName}
                                                    required={
                                                        (!newTenant.firstName ||
                                                            newTenant.firstName.trim()
                                                                .length ===
                                                                0) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newTenant: Object.assign(
                                                                {},
                                                                newTenant,
                                                                {
                                                                    firstName:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div style={{ width: "182.5px" }}>
                                                <InfoLabel required>
                                                    LAST NAME
                                                </InfoLabel>
                                                <Input
                                                    placeholder="e.g. Star"
                                                    value={newTenant.lastName}
                                                    required={
                                                        (!newTenant.lastName ||
                                                            newTenant.lastName.trim()
                                                                .length ===
                                                                0) &&
                                                        hasError
                                                    }
                                                    disabled={isModalLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            newTenant: Object.assign(
                                                                {},
                                                                newTenant,
                                                                {
                                                                    lastName:
                                                                        e.target
                                                                            .value
                                                                }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                        </InfoContainer>
                                    )}
                                    <InfoContainer>
                                        <div style={{ width: "182.5px" }}>
                                            <InfoLabel required>
                                                EMAIL
                                            </InfoLabel>
                                            <Input
                                                placeholder="e.g. example@mail.com"
                                                value={newTenant.email}
                                                required={
                                                    (!newTenant.email ||
                                                        newTenant.email.trim()
                                                            .length === 0 ||
                                                        tenantEmails.includes(
                                                            newTenant.email
                                                                .trim()
                                                                .toLowerCase()
                                                        )) &&
                                                    hasError
                                                }
                                                disabled={isModalLoading}
                                                onChange={e =>
                                                    this.setState({
                                                        newTenant: Object.assign(
                                                            {},
                                                            newTenant,
                                                            {
                                                                email:
                                                                    e.target
                                                                        .value
                                                            }
                                                        )
                                                    })
                                                }
                                            />
                                        </div>
                                        <div style={{ width: "182.5px" }}>
                                            <InfoLabel required>
                                                NUMBER
                                            </InfoLabel>
                                            <Input
                                                placeholder="e.g. +6398765432100"
                                                type="number"
                                                value={newTenant.phoneNumber}
                                                required={
                                                    (!newTenant.phoneNumber ||
                                                        newTenant.phoneNumber.trim()
                                                            .length === 0) &&
                                                    hasError
                                                }
                                                disabled={isModalLoading}
                                                onChange={e =>
                                                    this.setState({
                                                        newTenant: Object.assign(
                                                            {},
                                                            newTenant,
                                                            {
                                                                phoneNumber:
                                                                    e.target
                                                                        .value
                                                            }
                                                        )
                                                    })
                                                }
                                            />
                                        </div>
                                    </InfoContainer>
                                    {newTenant.type.value === "Company" && (
                                        <Fragment>
                                            <InfoContainer>
                                                <div
                                                    style={{ width: "182.5px" }}
                                                >
                                                    <InfoLabel required>
                                                        CONTACT FIRST NAME
                                                    </InfoLabel>
                                                    <Input
                                                        placeholder="e.g. Joe"
                                                        value={
                                                            newTenant.contactFirstName
                                                        }
                                                        required={
                                                            (!newTenant.contactFirstName ||
                                                                newTenant.contactFirstName.trim()
                                                                    .length ===
                                                                    0) &&
                                                            hasError
                                                        }
                                                        disabled={
                                                            isModalLoading
                                                        }
                                                        onChange={e =>
                                                            this.setState({
                                                                newTenant: Object.assign(
                                                                    {},
                                                                    newTenant,
                                                                    {
                                                                        contactFirstName:
                                                                            e
                                                                                .target
                                                                                .value
                                                                    }
                                                                )
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{ width: "182.5px" }}
                                                >
                                                    <InfoLabel required>
                                                        CONTACT LAST NAME
                                                    </InfoLabel>
                                                    <Input
                                                        placeholder="e.g. Star"
                                                        value={
                                                            newTenant.contactLastName
                                                        }
                                                        required={
                                                            (!newTenant.contactLastName ||
                                                                newTenant.contactLastName.trim()
                                                                    .length ===
                                                                    0) &&
                                                            hasError
                                                        }
                                                        disabled={
                                                            isModalLoading
                                                        }
                                                        onChange={e =>
                                                            this.setState({
                                                                newTenant: Object.assign(
                                                                    {},
                                                                    newTenant,
                                                                    {
                                                                        contactLastName:
                                                                            e
                                                                                .target
                                                                                .value
                                                                    }
                                                                )
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </InfoContainer>
                                            <InfoContainer>
                                                <div
                                                    style={{ width: "182.5px" }}
                                                >
                                                    <InfoLabel required>
                                                        CONTACT EMAIL
                                                    </InfoLabel>
                                                    <Input
                                                        placeholder="e.g. example@mail.com"
                                                        value={
                                                            newTenant.contactEmail
                                                        }
                                                        required={
                                                            (!newTenant.contactEmail ||
                                                                newTenant.contactEmail.trim()
                                                                    .length ===
                                                                    0) &&
                                                            hasError
                                                        }
                                                        disabled={
                                                            isModalLoading
                                                        }
                                                        onChange={e =>
                                                            this.setState({
                                                                newTenant: Object.assign(
                                                                    {},
                                                                    newTenant,
                                                                    {
                                                                        contactEmail:
                                                                            e
                                                                                .target
                                                                                .value
                                                                    }
                                                                )
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{ width: "182.5px" }}
                                                >
                                                    <InfoLabel required>
                                                        CONTACT NUMBER
                                                    </InfoLabel>
                                                    <Input
                                                        placeholder="e.g. +6398765432100"
                                                        type="number"
                                                        value={
                                                            newTenant.contactPhoneNumber
                                                        }
                                                        required={
                                                            (!newTenant.contactPhoneNumber ||
                                                                newTenant.contactPhoneNumber.trim()
                                                                    .length ===
                                                                    0) &&
                                                            hasError
                                                        }
                                                        disabled={
                                                            isModalLoading
                                                        }
                                                        onChange={e =>
                                                            this.setState({
                                                                newTenant: Object.assign(
                                                                    {},
                                                                    newTenant,
                                                                    {
                                                                        contactPhoneNumber:
                                                                            e
                                                                                .target
                                                                                .value
                                                                    }
                                                                )
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </InfoContainer>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                    <InfoContainer>
                        <div style={{ width: "187.5px" }}>
                            <InfoLabel required>CONTRACT START</InfoLabel>
                            <InputDatePicker
                                selected={contractStart}
                                required={!contractStart && hasError}
                                disabled={isModalLoading}
                                selectsStart
                                startDate={contractStart}
                                endDate={contractEnd}
                                onChange={date =>
                                    this.setState({
                                        contractStart: date,
                                        contractEnd:
                                            moment(date) >= moment(contractEnd)
                                                ? null
                                                : contractEnd
                                    })
                                }
                            />
                        </div>
                        <div style={{ width: "187.5px" }}>
                            <InfoLabel required>CONTRACT END</InfoLabel>
                            <FlippedInputDatePickerContainer>
                                <InputDatePicker
                                    selected={contractEnd}
                                    selectsEnd
                                    required={!contractEnd && hasError}
                                    disabled={isModalLoading || !contractStart}
                                    minDate={contractStart}
                                    startDate={contractStart}
                                    endDate={contractEnd}
                                    popperPlacement={
                                        newTenant.isSelected && newTenant.type
                                            ? "top-end"
                                            : "bottom-end"
                                    }
                                    onChange={date =>
                                        this.setState({
                                            contractEnd: date
                                        })
                                    }
                                />
                            </FlippedInputDatePickerContainer>
                        </div>
                    </InfoContainer>
                    {!newTenant.isSelected && (
                        <Fragment>
                            <InfoContainer
                                style={{
                                    fontSize: "small",
                                    justifyContent: "center"
                                }}
                            >
                                OR
                            </InfoContainer>
                            <InfoContainer style={{ justifyContent: "center" }}>
                                <Button
                                    disabled={isModalLoading}
                                    onClick={() =>
                                        this.setState({
                                            tenant: null,
                                            newTenant: Object.assign(
                                                {},
                                                newTenant,
                                                {
                                                    isSelected: true
                                                }
                                            )
                                        })
                                    }
                                    style={{
                                        height: "34px",
                                        width: "100px",
                                        backgroundColor: "#000000d1"
                                    }}
                                >
                                    NEW TENANT
                                </Button>
                            </InfoContainer>
                        </Fragment>
                    )}
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text="UPDATE TENANT"
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text="CANCEL"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        tenantOptions:
            state.tenants &&
            state.tenants.map(t => {
                return {
                    value: t.id,
                    label: t.name
                };
            }),
        tenantEmails: state.tenants
            ? state.tenants.map(t => t.email && t.email.toLowerCase())
            : [],
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(UnitTenantModal);
