import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";
import { titleize } from "utils/InputPatterns";

const FETCH_BUILDINGS = "FETCH_BUILDINGS";
const FETCH_BUILDINGS_SUCCESS = "FETCH_BUILDINGS_SUCCESS";
const FETCH_BUILDINGS_FAIL = "FETCH_BUILDINGS_FAIL";

const FETCH_BUILDING = "FETCH_BUILDING";
const FETCH_BUILDING_SUCCESS = "FETCH_BUILDING_SUCCESS";
const FETCH_BUILDING_FAIL = "FETCH_BUILDING_FAIL";

const ADD_UNIT_TYPE = "ADD_UNIT_TYPE";
const ADD_UNIT_TYPE_SUCCESS = "ADD_UNIT_TYPE_SUCCESS";
const ADD_UNIT_TYPE_FAIL = "ADD_UNIT_TYPE_FAIL";

const UPDATE_UNIT_TYPE = "UPDATE_UNIT_TYPE";
const UPDATE_UNIT_TYPE_SUCCESS = "UPDATE_UNIT_TYPE_SUCCESS";
const UPDATE_UNIT_TYPE_FAIL = "UPDATE_UNIT_TYPE_FAIL";

const REMOVE_UNIT_TYPE = "REMOVE_UNIT_TYPE";
const REMOVE_UNIT_TYPE_SUCCESS = "REMOVE_UNIT_TYPE_SUCCESS";
const REMOVE_UNIT_TYPE_FAIL = "REMOVE_UNIT_TYPE_FAIL";

const ADD_BILLING_CATEGORY = "ADD_BILLING_CATEGORY";
const ADD_BILLING_CATEGORY_SUCCESS = "ADD_BILLING_CATEGORY_SUCCESS";
const ADD_BILLING_CATEGORY_FAIL = "ADD_BILLING_CATEGORY_FAIL";

const UPDATE_BILLING_CATEGORY = "UPDATE_BILLING_CATEGORY";
const UPDATE_BILLING_CATEGORY_SUCCESS = "UPDATE_BILLING_CATEGORY_SUCCESS";
const UPDATE_BILLING_CATEGORY_FAIL = "UPDATE_BILLING_CATEGORY_FAIL";

const REMOVE_BILLING_CATEGORY = "REMOVE_BILLING_CATEGORY";
const REMOVE_BILLING_CATEGORY_SUCCESS = "REMOVE_BILLING_CATEGORY_SUCCESS";
const REMOVE_BILLING_CATEGORY_FAIL = "REMOVE_BILLING_CATEGORY_FAIL";

const SELECT_BUILDING = "SELECT_BUILDING";
const SET_FIRST_BUILDING = "SET_FIRST_BUILDING";
const RESET_BUILDING = "RESET_BUILDING";

export const buildingActions = {
    FETCH_BUILDINGS,
    FETCH_BUILDINGS_SUCCESS,
    FETCH_BUILDINGS_FAIL,
    FETCH_BUILDING,
    FETCH_BUILDING_SUCCESS,
    FETCH_BUILDING_FAIL,
    ADD_UNIT_TYPE,
    ADD_UNIT_TYPE_SUCCESS,
    ADD_UNIT_TYPE_FAIL,
    ADD_BILLING_CATEGORY,
    ADD_BILLING_CATEGORY_SUCCESS,
    ADD_BILLING_CATEGORY_FAIL,
    UPDATE_UNIT_TYPE,
    UPDATE_UNIT_TYPE_SUCCESS,
    UPDATE_UNIT_TYPE_FAIL,
    UPDATE_BILLING_CATEGORY,
    UPDATE_BILLING_CATEGORY_SUCCESS,
    UPDATE_BILLING_CATEGORY_FAIL,
    REMOVE_UNIT_TYPE,
    REMOVE_UNIT_TYPE_SUCCESS,
    REMOVE_UNIT_TYPE_FAIL,
    REMOVE_BILLING_CATEGORY,
    REMOVE_BILLING_CATEGORY_SUCCESS,
    REMOVE_BILLING_CATEGORY_FAIL,
    SELECT_BUILDING,
    SET_FIRST_BUILDING,
    RESET_BUILDING
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case ADD_UNIT_TYPE_SUCCESS:
            return state.map(building =>
                building.id === payload.buildingId
                    ? Object.assign({}, building, {
                          unitTypes: building.unitTypes.concat(payload.name)
                      })
                    : building
            );
        case ADD_BILLING_CATEGORY_SUCCESS:
            return state.map(building =>
                building.id === payload.buildingId
                    ? Object.assign({}, building, {
                          billingCategories: building.billingCategories.concat({
                              name: payload.name,
                              type: payload.type
                          })
                      })
                    : building
            );
        case UPDATE_UNIT_TYPE_SUCCESS:
            return Object.assign({}, state, {
                unitTypes: state.unitTypes.map(unitType =>
                    unitType === payload.unitType
                        ? payload.newUnitType
                        : unitType
                )
            });
        case UPDATE_BILLING_CATEGORY_SUCCESS:
            return Object.assign({}, state, {
                billingCategories: state.billingCategories.map(
                    billingCategory =>
                        billingCategory.name === payload.billingCategory
                            ? Object.assign({}, billingCategory, {
                                  name: payload.newBillingCategory,
                                  type: payload.billType
                              })
                            : billingCategory
                )
            });
        case REMOVE_UNIT_TYPE_SUCCESS:
            return Object.assign({}, state, {
                unitTypes: state.unitTypes.filter(
                    unitType => unitType !== payload
                )
            });
        case REMOVE_BILLING_CATEGORY_SUCCESS:
            return Object.assign({}, state, {
                billingCategories: state.billingCategories.filter(
                    billingCategory => billingCategory.name !== payload
                )
            });
        case SELECT_BUILDING:
            return state.find(building => building.id === payload);
        default:
            return state;
    }
};

export const fetchBuildings = propertyId => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_BUILDINGS });
        return axios
            .request({
                url: `/api/buildings`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    propertyId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_BUILDINGS_SUCCESS,
                        payload: res.buildings
                    });
                } else {
                    dispatch({ type: FETCH_BUILDINGS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_BUILDINGS_FAIL });
            });
    };
};

export const fetchBuilding = buildingId => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_BUILDING });
        return axios
            .request({
                url: `/api/building`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    id: buildingId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_BUILDING_SUCCESS,
                        payload: res.building
                    });
                } else {
                    dispatch({ type: FETCH_BUILDING_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_BUILDING_FAIL });
            });
    };
};

export const addUnitType = ({ buildingId, name }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: ADD_UNIT_TYPE });
        return axios
            .request({
                url: `/api/admin/create_unit_type`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    buildingId,
                    categoryName: name
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: ADD_UNIT_TYPE_SUCCESS,
                        payload: {
                            buildingId,
                            name: titleize(name)
                        }
                    });
                    dispatch({
                        type: SELECT_BUILDING,
                        payload: buildingId
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully added new unit type.");
                } else {
                    dispatch({ type: ADD_UNIT_TYPE_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ADD_UNIT_TYPE_FAIL });
            });
    };
};

export const updateUnitType = ({ buildingId, unitType, newUnitType }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: UPDATE_UNIT_TYPE });
        return axios
            .request({
                url: `/api/admin/update_unit_type`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    buildingId,
                    oldUnitType: unitType,
                    newUnitType
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: UPDATE_UNIT_TYPE_SUCCESS,
                        payload: {
                            unitType,
                            newUnitType: titleize(newUnitType)
                        }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully updated unit type.");
                } else {
                    dispatch({ type: UPDATE_UNIT_TYPE_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: UPDATE_UNIT_TYPE_FAIL });
            });
    };
};

export const removeUnitType = ({ buildingId, name }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: REMOVE_BILLING_CATEGORY });
        return axios
            .request({
                url: `/api/admin/delete_unit_type`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    buildingId,
                    unitType: name
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: REMOVE_UNIT_TYPE_SUCCESS,
                        payload: name
                    });
                    toast("Successfully deleted billing category.");
                } else {
                    dispatch({ type: REMOVE_UNIT_TYPE_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: REMOVE_UNIT_TYPE_FAIL });
            });
    };
};

export const addBillingCategory = ({ buildingId, billType, name }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: ADD_BILLING_CATEGORY });
        return axios
            .request({
                url: `/api/admin/create_bill_category`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    buildingId,
                    billType,
                    categoryName: name
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: ADD_BILLING_CATEGORY_SUCCESS,
                        payload: {
                            buildingId,
                            name: titleize(name),
                            type: billType
                        }
                    });
                    dispatch({
                        type: SELECT_BUILDING,
                        payload: buildingId
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully added new billing category.");
                } else {
                    dispatch({ type: ADD_BILLING_CATEGORY_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ADD_BILLING_CATEGORY_FAIL });
            });
    };
};

export const updateBillingCategory = ({
    buildingId,
    billType,
    billingCategory,
    newBillingCategory
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: UPDATE_BILLING_CATEGORY });
        return axios
            .request({
                url: `/api/admin/update_billing_category`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    buildingId,
                    billType,
                    oldBillingCategory: billingCategory,
                    newBillingCategory
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: UPDATE_BILLING_CATEGORY_SUCCESS,
                        payload: {
                            billingCategory,
                            newBillingCategory: titleize(newBillingCategory),
                            billType
                        }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully updated billing category.");
                } else {
                    dispatch({ type: UPDATE_BILLING_CATEGORY_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: UPDATE_BILLING_CATEGORY_FAIL });
            });
    };
};

export const removeBillingCategory = ({ buildingId, name }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: REMOVE_BILLING_CATEGORY });
        return axios
            .request({
                url: `/api/admin/delete_billing_category`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    buildingId,
                    categoryName: name
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: REMOVE_BILLING_CATEGORY_SUCCESS,
                        payload: name
                    });
                    toast("Successfully deleted billing category.");
                } else {
                    dispatch({ type: REMOVE_BILLING_CATEGORY_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: REMOVE_BILLING_CATEGORY_FAIL });
            });
    };
};

export const selectBuilding = buildingId => {
    return dispatch => dispatch({ type: SELECT_BUILDING, payload: buildingId });
};

export const setFirstBuilding = () => {
    return dispatch => dispatch({ type: SET_FIRST_BUILDING });
};

export const resetBuilding = () => {
    return dispatch => dispatch({ type: RESET_BUILDING });
};
