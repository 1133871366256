import styled from "styled-components";
import colors from "../utils/colors";

export const TabHeaderContainer = styled.div`
    display: flex;
    height: 46px;
`;

export const ActiveTabHeader = styled.button`
    white-space: nowrap;
    background-color: white;
    font-family: "Poppins",sans-serif;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    border-style: solid;
    border-width:0 0 4px 0;
    padding-right: 60px;
    :focus {
        outline: none;
    }
    color: ${colors.PRIMARY};
    border-color: ${colors.SECONDARY};
`;

export const InactiveTabHeader = styled.button`
    white-space: nowrap;
    background-color: white;
    font-family: "Poppins",sans-serif;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 0 0 0.5px 0;
    padding-right: 60px;
    :focus {
        outline: none;
    }
`;

export const TabNewItemContainer = styled.div`
    width: 100%;
    height: 46px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 0 0 0.5px 0;
    text-align: right;
`;

export const TabHeaderSpacer = styled.div`
    width: 100%;
    height: 46px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 0 0 0.5px 0;
`;
