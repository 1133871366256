import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  FiltersContainer,
  FilterLabel,
  InputDatePicker,
  ClearableInputDatePickerContainer
} from '../styles/Inputs';
import {
  TabHeaderContainer,
  ActiveTabHeader,
  InactiveTabHeader,
  TabHeaderSpacer
} from '../styles/Tabs';
import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
  Pagination
} from '../styles/Tables';

const activityLogsPerPage = 10;

class UnitActivityLogsContainer extends Component {
  state = {
    activityLogsFilter: {
      dateFrom: null,
      dateTo: null
    },
    filteredActivityLogs: null,
    activityLogsOffset: 1
  };

  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.setState({
      filteredActivityLogs: this.filterActivityLogs({
        dateFrom: null,
        dateTo: null
      })
    });
  }

  componentDidUpdate(prevProps) {
    const { tabVisible } = this.props;
    if (tabVisible !== prevProps.tabVisible) {
      if (tabVisible) {
        document.addEventListener('keydown', this.handleKeyDown);
      } else {
        document.removeEventListener('keydown', this.handleKeyDown);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(e) {
    if (e.keyCode === 37) {
      e.preventDefault();
      this.handlePreviousPage();
    } else if (e.keyCode === 39) {
      e.preventDefault();
      this.handleNextPage();
    }
  }

  handleNextPage() {
    const { filteredActivityLogs, activityLogsOffset } = this.state;
    this.setState({
      activityLogsOffset:
        activityLogsOffset <
        Math.ceil(filteredActivityLogs.length / activityLogsPerPage)
          ? activityLogsOffset + 1
          : activityLogsOffset
    });
  }

  handlePreviousPage() {
    const { activityLogsOffset } = this.state;
    this.setState({
      activityLogsOffset:
        activityLogsOffset > 1 ? activityLogsOffset - 1 : activityLogsOffset
    });
  }

  handleFilterActivityLogsDateFromChange(dateFrom) {
    const { activityLogsFilter } = this.state;
    const dateTo = moment(dateFrom).isSameOrAfter(
      activityLogsFilter.dateTo,
      'day'
    )
      ? null
      : activityLogsFilter.dateTo;
    this.setState({
      activityLogsFilter: Object.assign({}, activityLogsFilter, {
        dateFrom,
        dateTo
      }),
      filteredActivityLogs: this.filterActivityLogs({
        dateFrom,
        dateTo
      }),
      offset: 1
    });
  }

  handleFilterActivityLogsDateToChange(dateTo) {
    const { activityLogsFilter } = this.state;
    this.setState({
      activityLogsFilter: Object.assign({}, activityLogsFilter, {
        dateTo
      }),
      filteredActivityLogs: this.filterActivityLogs({
        dateFrom: activityLogsFilter.dateFrom,
        dateTo
      }),
      offset: 1
    });
  }

  filterActivityLogs({ dateFrom, dateTo }) {
    const { activityLogs } = this.props;

    return (
      activityLogs &&
      activityLogs.filter(
        activityLog =>
          (dateFrom
            ? moment(activityLog.createdAt).isSameOrAfter(dateFrom, 'day')
            : true) &&
          (dateFrom && dateTo
            ? moment(activityLog.createdAt).isSameOrBefore(dateTo, 'day')
            : true)
      )
    );
  }

  render() {
    const { tabVisible, onTabChange } = this.props;
    const {
      activityLogsFilter,
      filteredActivityLogs,
      activityLogsOffset
    } = this.state;

    const activityLogsRange = activityLogsOffset * activityLogsPerPage;

    const activityLogRows =
      filteredActivityLogs &&
      filteredActivityLogs.map((activityLog, i) => {
        if (
          i >= activityLogsRange - activityLogsPerPage &&
          i < activityLogsRange
        ) {
          return (
            <TableRow key={'activityLog_' + i} gray={(i % 2 === 0).toString()}>
              <TableCell>
                {activityLog.createdAt
                  ? moment(activityLog.createdAt).format('MM/DD/YYYY')
                  : ''}
              </TableCell>
              <TableCell>{activityLog.actor}</TableCell>
              <TableCell>{activityLog.action}</TableCell>
            </TableRow>
          );
        }
      });

    return (
      <Fragment>
        {tabVisible && (
          <Fragment>
            <TabHeaderContainer>
              <InactiveTabHeader onClick={() => onTabChange('bills')}>
                Bills
              </InactiveTabHeader>
              <InactiveTabHeader onClick={() => onTabChange('payments')}>
                Payments
              </InactiveTabHeader>
              <ActiveTabHeader>Activity Logs</ActiveTabHeader>
              <InactiveTabHeader onClick={() => onTabChange('runningBalance')}>
                Running Balance
              </InactiveTabHeader>
              <TabHeaderSpacer />
            </TabHeaderContainer>
            <FiltersContainer>
              <div style={{ width: '130px' }}>
                <FilterLabel>Date From</FilterLabel>
                <ClearableInputDatePickerContainer>
                  <InputDatePicker
                    selected={activityLogsFilter.dateFrom}
                    selectsStart
                    startDate={activityLogsFilter.dateFrom}
                    endDate={activityLogsFilter.dateTo}
                    isClearable={true}
                    onChange={date =>
                      this.handleFilterActivityLogsDateFromChange(date)
                    }
                  />
                </ClearableInputDatePickerContainer>
              </div>
              <div style={{ width: '130px' }}>
                <FilterLabel>Date To</FilterLabel>
                <ClearableInputDatePickerContainer>
                  <InputDatePicker
                    selected={activityLogsFilter.dateTo}
                    disabled={!activityLogsFilter.dateFrom}
                    selectsEnd
                    minDate={activityLogsFilter.dateFrom}
                    startDate={activityLogsFilter.dateFrom}
                    endDate={activityLogsFilter.dateTo}
                    isClearable={true}
                    onChange={date =>
                      this.handleFilterActivityLogsDateToChange(date)
                    }
                  />
                </ClearableInputDatePickerContainer>
              </div>
            </FiltersContainer>
            <Table>
              <TableHeader>
                <TableCell>Date</TableCell>
                <TableCell>Actor</TableCell>
                <TableCell>Action</TableCell>
              </TableHeader>
              {activityLogRows}
            </Table>
            {filteredActivityLogs &&
              filteredActivityLogs.length > activityLogsPerPage && (
                <Pagination
                  itemsPerPage={activityLogsPerPage}
                  offset={activityLogsOffset}
                  length={filteredActivityLogs.length}
                  handlePreviousPage={() => this.handlePreviousPage()}
                  handleNextPage={() => this.handleNextPage()}
                />
              )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    activityLogs: state.unit.activityLogs
  };
};

export default connect(mapStateToProps)(UnitActivityLogsContainer);
