import React from "react";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { FiX } from "react-icons/fi";

export const modalStyle = ({ height, width }) => ({
    overlay: {
        zIndex: "9",
        backgroundColor: "#233c60e6"
    },
    content: {
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        height: `${height}px`,
        width: "95%",
        maxWidth: `${width}px`,
        padding: "20px 40px",
        borderRadius: "20px",
        overflow: "visible",
        margin: "auto"
    }
});

export const ModalHeaderContainer = styled.div`
    height: 65px;
    border-style: solid;
    border-width: 0 0 0.5px 0;
    border-color: ${colors.LIGHT_GRAY};
    padding: 12.5px 0;
    margin-bottom: 15px;
`;

export const ModalHeader = styled.div`
    color: ${colors.BLACK};
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
`;

export const ModalSubHeader = styled.div`
    color: ${colors.LIGHT_BLACK};
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 300;
`;

export const ModalBody = styled.div`
    position: relative;
    z-index: 1;
    color: ${colors.BLACK};
    font-family: "Poppins", sans-serif;
    font-size: 12px;

    ${props =>
        css`
            height: ${props.height};
        `}

    > div {
        margin-top: 15px;
    }
`;

export const ModalFooter = styled.div`
    position: absolute;
    z-index: 0;
    bottom: 20px;
    right: 40px;
    left: 40px;
    height: 65px;
    padding: 12.5px 0;

    p {
        display: flex;
        font-size: 12px;
        justify-content: center;
        align-items: center;
    }
`;

const CloseButtonContainer = styled.button`
    position: absolute;
    right: 20px;
    background-color: white;
    color: ${colors.LIGHT_BLACK};
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    border: none;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

export const CloseButton = ({ style, onClick }) => (
    <CloseButtonContainer style={style} onClick={onClick}>
        <FiX />
    </CloseButtonContainer>
);
