import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { messageActions } from "reducers/messageReducer";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import {
    addBillingCategory,
    updateBillingCategory
} from "reducers/buildingReducer";
import { InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { Input, Select } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

const billTypeOptions = [
    {
        value: "total_only",
        label: "Total Only"
    },
    {
        value: "floor_sized_dependent",
        label: "Floor Area Dependent"
    },
    {
        value: "rate_type",
        label: "Rate Type"
    }
];

class BillingCategoryModal extends Component {
    state = {
        building: null,
        name: "",
        billType: null,
        isNew: true,
        hasError: false
    };

    componentDidMount() {
        const { billingCategory, building } = this.props;
        const newBuilding = building
            ? {
                  value: building.id,
                  label: building.name
              }
            : null;

        if (billingCategory) {
            this.setState({
                building: newBuilding,
                name: billingCategory.name,
                billType: billTypeOptions.find(
                    billType => billType.value === billingCategory.type
                ),
                isNew: false
            });
        } else {
            this.setState({
                building: newBuilding
            });
        }
    }

    async handleSubmit() {
        const {
            billingCategory,
            billingCategoryNames,
            closeModal,
            dispatch
        } = this.props;
        const { building, billType, name } = this.state;

        const errorMessage =
            !name || !name.trim().length > 0 || !billType
                ? "Please fill up required fields."
                : billingCategoryNames.includes(name)
                ? "Name should be unique."
                : "";

        if (errorMessage === "") {
            if (billingCategory) {
                await dispatch(
                    updateBillingCategory({
                        buildingId: building.value,
                        billingCategory: billingCategory.name,
                        newBillingCategory: name.trim(),
                        billType: billType.value
                    })
                );
            } else {
                await dispatch(
                    addBillingCategory({
                        buildingId: building.value,
                        name: name.trim(),
                        billType: billType.value
                    })
                );
            }
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
            }
        } else {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const { building, billType, name, isNew, hasError } = this.state;
        const { buildingOptions, isModalLoading, modalVisible } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({ height: isNew ? 510 : 430, width: 400 })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>
                        {isNew ? "New" : "Update"} Billing Category
                    </ModalHeader>
                </ModalHeaderContainer>
                <ModalBody height={isNew ? "290px" : "200px"}>
                    {isNew && (
                        <Fragment>
                            <InfoLabel required>BUILDING</InfoLabel>
                            <Select
                                value={building}
                                disabled={isModalLoading}
                                onChange={selectedBuilding =>
                                    this.setState({
                                        building: selectedBuilding
                                    })
                                }
                                options={buildingOptions}
                                isSearchable
                            />
                        </Fragment>
                    )}
                    <InfoLabel style={{ marginTop: "18px" }} required>
                        BILL TYPE
                    </InfoLabel>
                    <Select
                        value={billType}
                        placeholder="Choose Bill Type"
                        required={!billType && hasError}
                        disabled={isModalLoading}
                        onChange={selectedBillType =>
                            this.setState({ billType: selectedBillType })
                        }
                        options={billTypeOptions}
                    />
                    <InfoLabel style={{ marginTop: "18px" }} required>
                        NAME
                    </InfoLabel>
                    <Input
                        value={name}
                        required={hasError}
                        disabled={isModalLoading}
                        onChange={e => this.setState({ name: e.target.value })}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                this.handleSubmit();
                            }
                        }}
                    />
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text={
                                    isNew
                                        ? "ADD BILLING CATEGORY"
                                        : "UPDATE BILLING CATEGORY"
                                }
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text={"CANCEL"}
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        buildingOptions:
            state.buildings &&
            state.buildings.map(building => {
                return { value: building.id, label: building.name };
            }),
        building: state.building,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(BillingCategoryModal);
