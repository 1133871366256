import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { messageActions } from "reducers/messageReducer";
import { generateStatementOfAccount } from "reducers/unitReducer";
import MessageContainer from "./MessageContainer";
import { Loader } from "./Loader";
import { InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { InputDatePicker } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

export const InputDatePickerContainer = styled.div`
    .react-datepicker-popper {
        z-index: 10;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
`;

class StatementOfAccountModal extends Component {
    state = {
        startDate: null,
        endDate: new Date(),
        hasError: false
    };

    async handleSubmit() {
        const { unitId, closeModal, dispatch } = this.props;
        const { startDate, endDate } = this.state;

        if (!startDate || !endDate) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: "Please fill up all required fields."
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(
                generateStatementOfAccount({ unitId, startDate, endDate })
            );
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const { modalVisible, isModalLoading } = this.props;
        const { startDate, endDate, hasError } = this.state;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({ height: 400, width: 400 })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>Generate Statement of Account</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody height="180px">
                    <div>
                        <InfoLabel required>START DATE</InfoLabel>
                        <InputDatePickerContainer>
                            <InputDatePicker
                                selected={startDate}
                                required={!startDate && hasError}
                                disabled={isModalLoading}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                onChange={date =>
                                    this.setState({
                                        startDate: date,
                                        endDate:
                                            moment(date) >= moment(endDate)
                                                ? null
                                                : endDate
                                    })
                                }
                            />
                        </InputDatePickerContainer>
                    </div>
                    <div>
                        <InfoLabel required>END DATE</InfoLabel>
                        <InputDatePickerContainer>
                            <InputDatePicker
                                selected={endDate}
                                selectsEnd
                                required={!endDate && hasError}
                                disabled={isModalLoading || !startDate}
                                minDate={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={date =>
                                    this.setState({
                                        endDate: date
                                    })
                                }
                            />
                        </InputDatePickerContainer>
                    </div>
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text="GENERATE"
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text="CANCEL"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        unitId: state.unit && state.unit.id,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(StatementOfAccountModal);
