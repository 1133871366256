/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import React from "react";
import ReactDOM from "react-dom";
import "index.css";
import styled from "styled-components";
import "react-dates/initialize";
import AdminApp from "../containers/admin/AdminApp";
import AccountApp from "../containers/account/AccountApp";
import AnonymousBillContainer from "../containers/AnonymousBillContainer";
import Provider from "provider";
import { BrowserRouter, Route, Switch } from "react-router-dom";

const MainContainer = styled.div`
    min-height: 100vh;
`;

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(
        <Provider>
            <BrowserRouter>
                <MainContainer>
                    <Switch>
                        <Route
                            exact
                            path="/bills/:billId"
                            render={({ match }) => (
                                <AnonymousBillContainer
                                    billId={match.params.billId}
                                />
                            )}
                        />
                        <Route path="/admin" component={AdminApp} />
                        <Route path="/account" component={AccountApp} />
                    </Switch>
                </MainContainer>
            </BrowserRouter>
        </Provider>,
        document.getElementById("root")
    );
});
