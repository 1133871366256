import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "containers/Spinner";
import { currencyFormat } from "utils/InputPatterns";
import PageHeader from "containers/PageHeader";
import { fetchReservations } from "reducers/reservationReducer";
import { Input, Select, FiltersContainer, FilterLabel } from "styles/Inputs";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    TablePayAction,
    Pagination,
    EmptyListContainer
} from "styles/Tables";
import { ContentContainer } from "styles/Containers";
import { DownloadButton } from "styles/Buttons";
import { exportCSV } from "../../actions/exportCSV";
import { ReserveStatus } from "styles/Status";
import {
    PesoSign,
} from "styles/Containers";

const filterStatusOptions = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "Approved (Pending Payment)",
        label: "Approved (Pending Payment)"
    },
    {
        value: "Pending",
        label: "Pending"
    },
    {
        value: "Reserved (Paid)",
        label: "Reserved (Paid)"
    },
    {
        value: "Denied",
        label: "Denied"
    },
    {
        value: "Cancelled",
        label: "Cancelled"
    }
];

const reservationsPerPage = 10;

class AccountReservationsContainer extends Component {
    state = {
        filter: {
            amenity: "",
            status: {
                value: "All",
                label: "All"
            }
        },
        filteredReservations: null,
        offset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fetchReservations()).then(reservations =>
            this.setState({
                filteredReservations: reservations || []
            })
        );
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredReservations, offset } = this.state;
        this.setState({
            offset:
                offset <
                Math.ceil(filteredReservations.length / reservationsPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    handleFilterAmenityChange(amenity) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                amenity
            }),
            filteredReservations: this.filterReservations({
                amenity,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterStatusChange(status) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                status
            }),
            filteredReservations: this.filterReservations({
                amenity: filter.amenity,
                status
            }),
            offset: 1
        });
    }

    filterReservations({ amenity, status }) {
        const { reservations } = this.props;

        return (
            reservations &&
            reservations.filter(
                reservation =>
                    (amenity
                        ? reservation.amenityName
                              .toLowerCase()
                              .includes(amenity.toLowerCase())
                        : true) &&
                    (status
                        ? status.value === "All" ||
                          reservation.status === status.value
                        : true)
            )
        );
    }

    handleReservationsReport(array) {
        const headers = ["Amenity", "Amount", "Status"];
        const columns = ["amenityName", "amount", "status"];

        exportCSV(array, headers, columns, "Reservations Report");
    }

    render() {
        const { reservations, isLoading } = this.props;
        const { filter, filteredReservations, offset } = this.state;
        const range = offset * reservationsPerPage;

        const reservationRows =
            filteredReservations &&
            filteredReservations.map((reservation, i) => {
                if (i >= range - reservationsPerPage && i < range) {
                    return (
                        <TableLinkRow
                            key={reservation.id}
                            to={`/account/reservations/${reservation.id}`}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{reservation.amenityName}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />{currencyFormat(reservation.amount)}
                            </TableCell>
                            <TableCell>
                                <ReserveStatus
                                    status={reservation.status}>
                                        {reservation.status}
                                </ReserveStatus>
                            </TableCell>
                            {reservation.status ===
                            "Approved (Pending Payment)" ? (
                                <TablePayAction text="PAY NOW" />
                            ) : (
                                <TableRowViewAction text="VIEW" />
                            )}
                        </TableLinkRow>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : reservations && reservations.length > 0 ? (
                    <ContentContainer>
                        <TableTextHeaderContainer>
                            <TableTextHeader>Reservations</TableTextHeader>
                            <DownloadButton
                                onClick={() =>
                                    this.handleReservationsReport(
                                        filteredReservations
                                    )
                                }
                            >
                                Download Report
                            </DownloadButton>
                        </TableTextHeaderContainer>
                        <FiltersContainer>
                            <div style={{ width: "220px" }}>
                                <FilterLabel>Amenity</FilterLabel>
                                <Input
                                    placeholder="e.g. Function Hall Room"
                                    value={filter.amenity}
                                    onChange={e =>
                                        this.handleFilterAmenityChange(
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div style={{ width: "250px" }}>
                                <FilterLabel>Status</FilterLabel>
                                <Select
                                    value={filter.status}
                                    onChange={this.handleFilterStatusChange.bind(
                                        this
                                    )}
                                    options={filterStatusOptions}
                                />
                            </div>
                        </FiltersContainer>
                        <Table>
                            <TableHeader>
                                <TableCell>AMENITY</TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    AMOUNT
                                </TableCell>
                                <TableCell>STATUS</TableCell>
                                <TableCell />
                            </TableHeader>
                            {reservationRows}
                        </Table>
                        {filteredReservations &&
                            filteredReservations.length >
                                reservationsPerPage && (
                                <Pagination
                                    itemsPerPage={reservationsPerPage}
                                    offset={offset}
                                    length={filteredReservations.length}
                                    handlePreviousPage={() =>
                                        this.handlePreviousPage()
                                    }
                                    handleNextPage={() => this.handleNextPage()}
                                />
                            )}
                    </ContentContainer>
                ) : (
                    <EmptyListContainer>
                        There are no reservations yet.
                    </EmptyListContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        reservations: state.reservations,
        isLoading: state.isLoading
    };
};

export default connect(mapStateToProps)(AccountReservationsContainer);
