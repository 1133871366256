import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import colors from "utils/colors";
import { FiMail, FiArrowLeft } from "react-icons/fi";
import styled from "styled-components";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { isValidEmail } from "utils/InputPatterns";
import { forgotPassword } from "reducers/userReducer";
import { messageActions } from "reducers/messageReducer";
import {
    BackgroundContainer,
    LoginContainer,
    Logo,
    LoginHeader,
    LoginSubHeader,
} from "styles/Containers";
import { Input, InputLabel, IconInputContainer } from "styles/Inputs";
import { PrimaryButton } from "styles/Buttons";

const BackContainer = styled(Link)`
    display: flex;
    color: ${colors.LIGHT_BLACK};
    text-decoration: none;
`;

class ForgotPassword extends Component {
    state = {
        email: "",
        hasError: false,
        submitSuccess: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE,
        });
    }

    async handleSubmit() {
        const { dispatch } = this.props;
        const { email } = this.state;
        if (!email || email.length === 0 || !isValidEmail(email)) {
            this.setState({ hasError: true });
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: "Please enter valid email.",
            });
        } else {
            await dispatch(forgotPassword({ email }));
            if (!this.props.errorMessage) {
                this.setState({ hasError: false, submitSuccess: true });
            }
        }
    }

    render() {
        const { isLoading, errorMessage, dispatch } = this.props;
        const { email, hasError, submitSuccess } = this.state;

        return (
            <BackgroundContainer>
                <LoginContainer height="500px">
                    {submitSuccess ? (
                        <Fragment>
                            <Logo />
                            <LoginHeader>Request Sent</LoginHeader>
                            <LoginSubHeader>
                                An email has been sent to your email address,{" "}
                                <span style={{ color: colors.BLUE }}>
                                    {email}.
                                </span>{" "}
                                Follow the directions in the email to reset your
                                password.
                            </LoginSubHeader>
                            <Link
                                to="/account/login"
                                style={{ textDecoration: "none" }}
                            >
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                    }}
                                    text={"GO BACK TO SIGN IN"}
                                />
                            </Link>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <BackContainer
                                onClick={() =>
                                    dispatch({
                                        type: messageActions.CLEAR_MESSAGE,
                                    })
                                }
                                to="/account/login"
                            >
                                <FiArrowLeft
                                    style={{ margin: "3px 4px 0 0" }}
                                />
                                <span>Go Back</span>
                            </BackContainer>
                            <Logo />
                            <LoginHeader>Reset Password</LoginHeader>
                            {errorMessage ? (
                                <MessageContainer
                                    style={{
                                        textAlign: "center",
                                        margin: "20px 0",
                                    }}
                                />
                            ) : (
                                <LoginSubHeader>
                                    Please enter your email address to request a
                                    password reset.
                                </LoginSubHeader>
                            )}
                            <InputLabel>Email address</InputLabel>
                            <IconInputContainer
                                style={{ marginBottom: "20px" }}
                                disabled={isLoading}
                                required={
                                    (!email ||
                                        email.length === 0 ||
                                        !isValidEmail(email)) &&
                                    hasError
                                }
                            >
                                <FiMail />
                                <Input
                                    type="email"
                                    style={{ height: "42px" }}
                                    disabled={isLoading}
                                    onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            this.handleSubmit();
                                        }
                                    }}
                                />
                            </IconInputContainer>
                            {isLoading ? (
                                <Loader text="PROCESSING..." />
                            ) : (
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                    }}
                                    onClick={() => this.handleSubmit()}
                                    text="RESET PASSWORD"
                                />
                            )}
                        </Fragment>
                    )}
                </LoginContainer>
            </BackgroundContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.isLoading,
        errorMessage: state.errorMessage,
    };
};

export default connect(mapStateToProps)(ForgotPassword);
