import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Loader } from "containers/Loader";
import { modalStyle, CloseButton, ModalBody, ModalFooter } from "styles/Modals";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";
import colors from "utils/colors";
import { FiInfo } from "react-icons/fi";
import { currencyFormat } from "utils/InputPatterns";
import {
    FlexContainer,
    FlexColumnContainer,
    PesoSign,
} from "styles/Containers";
import {
    ImageButton,
    PaypalButton,
    DragonPayButton,
} from "styles/Buttons";
import { fetchBill } from "reducers/billReducer";
import { paypalRedirect, dragonPayRedirect } from "reducers/paymentReducer";

const OnlinePayContent = styled.div`
    h2 {
        font-weight: 500;
    }

    p {
        font-size: 12px;
    }

    span {
        font-size: 12px;
    }

    h3 {
        font-weight: 500;
        color: ${colors.BLUE};
        margin-bottom: 0;
        margin-top: 0.3rem;
        font-size: 24px;
        font-weight: 500;

        span {
            font-size: 24px;
        }
    }
`

const OnlinePayBox = styled(FlexColumnContainer)`
    background-color: ${colors.LIGHT_GRAY};
    border-radius: 5px;
    padding: 25px;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
`

const AlertBox = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.PRIMARY};
    font-size: 12px;
    margin: 5px 0;
`
const PayButtonContainer = styled(FlexContainer)`
    justify-content: space-between;
`

class OnlinePaymentModal extends Component {
    componentDidMount() {
        // const { billId, dispatch } = this.props;
        // if (isAuthorized) {
        //     dispatch(fetchBill({ billId }));
        // }
    }

    handleSubmit() {
        const { onSubmit, closeModal } = this.props;
        onSubmit();
        closeModal();
    }

    render() {
        const {
            submitText,
            closeModal,
            cancelText,
            height,
            width,
            isModalLoading,
            isAdmin,
            modalVisible,
            totalAmountDue,
            buildingName,
            unitNumber,
            transactionFee,
        } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={closeModal}
                style={modalStyle({
                    height: height || cancelText ? 600 : 550,
                    width: width || 450,
                    backgroundColor: !isAdmin && "rgb(0, 139, 139, 0.9)"
                })}
            >
                <CloseButton onClick={closeModal} />
                {isModalLoading ? (
                    <Loader text="PROCESSING..." />
                ) : (
                    <Fragment>
                        <ModalBody style={{ marginTop: "30px" }}>
                            <OnlinePayContent>
                                <h2>Payment Information</h2>
                                <p>Amount Due:</p>
                                <OnlinePayBox>
                                    <h3><PesoSign/>{currencyFormat(totalAmountDue)}</h3>
                                </OnlinePayBox>
                                <PayButtonContainer>
                                    <PaypalButton
                                        onClick={() => this.props.handlePaypalClick()}
                                        isLoading={isModalLoading}
                                        style={{ marginBottom: "20px" }}
                                    />
                                    <DragonPayButton
                                        onClick={() =>
                                            this.props.handleDragonPayClick()
                                        }
                                        isLoading={isModalLoading}
                                        style={{ marginBottom: "20px" }}
                                    />
                                </PayButtonContainer>
                                <OnlinePayBox style={{
                                    padding: "5px",
                                }}>
                                    <AlertBox>
                                        <FiInfo style={{ marginRight: "2px" }} />
                                        <span>
                                            Online transactions has a fee of{` `}<PesoSign/>{transactionFee}
                                        </span>
                                    </AlertBox>
                                </OnlinePayBox>
                            </OnlinePayContent>
                        </ModalBody>
                        <ModalFooter
                            style={{ height: cancelText ? "155px" : "100px" }}
                        >
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    backgroundColor: `${colors.RED}`
                                }}
                                onClick={closeModal}
                                text={submitText}
                            />
                            {cancelText && (
                                <PrimaryAltButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                        marginTop: "8px"
                                    }}
                                    onClick={closeModal}
                                    text={cancelText}
                                />
                            )}
                            <p>
                                Note: Cancellation fee and transaction fee applies.
                            </p>
                        </ModalFooter>
                    </Fragment>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        bill: state.bill,
        isLoading: state.isLoading,
        isModalLoading: state.isModalLoading,
        isAdmin: state.isAdmin,
        isAuthorized: state.isAuthorized
    };
};
export default connect(mapStateToProps)(OnlinePaymentModal);
