import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled, { css } from "styled-components";
import { Spinner } from "containers/Spinner";
import PageHeader from "./PageHeader";
import { fetchPayment } from "reducers/paymentReducer";
import { currencyFormat } from "utils/InputPatterns";
import {
    ContentContainer,
    PesoSign,
    GrayCardBox,
    WhiteCardBox,
    FullHRContainer,
    FlexContainer,
    CashValue } from "styles/Containers";
import { PayStatus } from "styles/Status";

const PaymentHRContainer = styled(FullHRContainer)`
    margin: 18px 0;
`

class PaymentContainer extends Component {
    componentDidMount() {
        const { paymentId, isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchPayment(paymentId));
        }
    }

    render() {
        const {
            payment,
            isLoading,
            isAdmin,
            backTo
        } = this.props;

        return (
            <Fragment>
                <PageHeader
                    title={
                        (backTo && backTo.label) || "Back to Payments"
                    }
                    backTo={
                        (backTo && backTo.value) ||
                        `/${isAdmin ? "admin" : "account"}/payments`
                    }
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                        payment ?
                        (
                            <ContentContainer>
                                <FlexContainer>
                                    <GrayCardBox style={{
                                        marginRight: "3rem",
                                    }}>
                                        <h3>Payment Details</h3>
                                        <PaymentHRContainer style={{
                                            marginTop: 0,
                                        }}/>
                                        <FlexContainer>
                                            <p>Total amount paid</p>
                                            <CashValue>
                                                <PesoSign />
                                                {payment.amount}
                                            </CashValue>
                                        </FlexContainer>
                                        <PaymentHRContainer />
                                        <FlexContainer>
                                            <p>Transaction Fee</p>
                                            <CashValue>
                                                <PesoSign />
                                                {
                                                    payment.transactionFee ?
                                                    payment.transactionFee :
                                                        "0.00"
                                                }
                                            </CashValue>
                                        </FlexContainer>
                                        <PaymentHRContainer />
                                        <FlexContainer>
                                            <p>Reference number</p>
                                            <span>{payment.referenceNumber}</span>
                                        </FlexContainer>
                                        <PaymentHRContainer />
                                        <FlexContainer>
                                            <p>Payment method</p>
                                            <span>{payment.method}</span>
                                        </FlexContainer>
                                        <PaymentHRContainer />
                                        <FlexContainer>
                                            <p>Status</p>
                                            <PayStatus status={payment.status}>
                                                {payment.status}
                                            </PayStatus>
                                        </FlexContainer>
                                    </GrayCardBox>
                                    <WhiteCardBox>
                                        <h3>Bill Details</h3>
                                        <FlexContainer>
                                            <p>Bill Category</p>
                                            <span>{payment.billCategory}</span>
                                        </FlexContainer>
                                        <PaymentHRContainer />
                                        <FlexContainer>
                                            <p>Building</p>
                                            <span>{payment.buildingName}</span>
                                        </FlexContainer>
                                        <PaymentHRContainer />
                                        <FlexContainer>
                                            <p>Billing Date and Time</p>
                                            <span>{payment.billDate &&
                                                moment(payment.billDate).format(
                                                    "MM/DD/YYYY hh:mm A"
                                                )}</span>
                                        </FlexContainer>
                                        <PaymentHRContainer />
                                        <FlexContainer>
                                            <p>Due Date</p>
                                            <span>{payment.billDueDate &&
                                                moment(payment.billDueDate).format(
                                                    "MM/DD/YYYY hh:mm A"
                                                )}</span>
                                        </FlexContainer>
                                        <PaymentHRContainer />
                                        <FlexContainer>
                                            <p>Remarks</p>
                                            <span>{payment.remarks ? payment.remarks : "No remarks"}</span>
                                        </FlexContainer>
                                    </WhiteCardBox>
                                </FlexContainer>
                            </ContentContainer>
                        )
                        : <span>Show payment</span>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        payment: state.payment,
        isLoading: state.isLoading,
        isModalLoading: state.isModalLoading,
        isAdmin: state.isAdmin,
        isAuthorized: state.isAuthorized,
    };
};

export default connect(mapStateToProps)(PaymentContainer);
