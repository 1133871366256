import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { FiInfo } from "react-icons/fi";
import moment from "moment";
import { Spinner } from "containers/Spinner";
import PageHeader from "./PageHeader";
import { currencyFormat } from "utils/InputPatterns";
import { fetchBill } from "reducers/billReducer";
import { paypalRedirect, dragonPayRedirect } from "reducers/paymentReducer";
import PaymentModal from "./PaymentModal";
import ConfirmationModal from "./ConfirmationModal";
import OnlinePaymentModal from "./OnlinePaymentModal";
import { BillStatus, PayStatus } from "styles/Status";
import {
    MainHeader,
    SubHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    ContentContainer,
    PesoSign,
} from "styles/Containers";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
} from "styles/Tables";
import {
    AddItemButton,
    NewItemLink,
    NewItemContainer,
    PaypalButton,
    DragonPayButton,
    ClickPayNowbutton,
} from "styles/Buttons";

const PaymentInfoText = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.PRIMARY};
    font-size: 12px;
    margin: 20px 0;
`;

class BillContainer extends Component {
    state = {
        paymentModalVisible: false,
        confirmationModalVisible: false,
        onlinePaymentModalVisible: false,
    };

    componentDidMount() {
        const { billId, isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchBill({ billId }));
        }
    }

    showPaymentModal() {
        this.setState({ paymentModalVisible: true });
    }

    hidePaymentModal() {
        this.setState({ paymentModalVisible: false });
    }

    showConfirmationModal() {
        this.setState({ confirmationModalVisible: true });
    }

    hideConfirmationModal() {
        this.setState({ confirmationModalVisible: false });
    }

    showOnlinePaymentModal() {
        this.setState({ onlinePaymentModalVisible: true });
    }

    hideOnlinePaymentModal() {
        this.setState({ onlinePaymentModalVisible: false });
    }

    handlePaypalClick() {
        const { billId, dispatch } = this.props;
        dispatch(paypalRedirect(billId)).then(() =>
            this.setState({ confirmationModalVisible: true })
        );
    }

    handleDragonPayClick() {
        const { billId, dispatch } = this.props;
        dispatch(dragonPayRedirect(billId)).then(() =>
            this.setState({ confirmationModalVisible: true })
        );
    }

    handleCreditCardPaymentSuccess() {
        const { billId, dispatch } = this.props;
        dispatch(fetchBill({ billId }));
    }

    render() {
        const {
            unit,
            bill,
            isLoading,
            isModalLoading,
            isAdmin,
            backTo,
        } = this.props;

        const {
            paymentModalVisible,
            confirmationModalVisible,
            onlinePaymentModalVisible,
        } = this.state;

        const anonymousPaymentLink =
            bill && window.location.origin.concat(`/bills/${bill.id}`);

        const paymentRows =
            bill &&
            bill.payments &&
            bill.payments.map((payment, i) => (
                <TableLinkRow
                    key={payment.id}
                    to={{
                        pathname: `/${isAdmin ? "admin" : "account"}/payments/${
                            payment.id
                        }`,
                        state: {
                            backTo: {
                                label: "Back to Bill",
                                value: `/admin/bills/${bill.id}`,
                            },
                        },
                    }}
                    gray={(i % 2 === 0).toString()}
                >
                    <TableCell>
                        {payment.date
                            ? moment(payment.date).format("MM/DD/YYYY HH:mm:ss")
                            : ""}
                    </TableCell>
                    <TableCell>{payment.referenceNumber}</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <PesoSign />
                        {currencyFormat(payment.amount)}
                    </TableCell>
                    <TableCell>{payment.remarks}</TableCell>
                    <TableCell>
                        <PayStatus status={payment.status}>
                            {payment.status}
                        </PayStatus>
                    </TableCell>
                </TableLinkRow>
            ));

        const billDiscountInfos =
            bill &&
            bill.discounts &&
            bill.discounts.map((discount) => (
                <div key={discount.id} style={{ display: "table-row" }}>
                    <InfoText>{discount.description}</InfoText>
                    <InfoText
                        style={{
                            display: "table-cell",
                            textAlign: "right",
                            paddingLeft: "15px",
                        }}
                    >
                        <PesoSign />
                        {currencyFormat(discount.amount)}
                    </InfoText>
                </div>
            ));

        return (
            <Fragment>
                <PageHeader
                    title={(backTo && backTo.label) || (unit && "Back to Unit")}
                    backTo={
                        (backTo && backTo.value) ||
                        (unit &&
                            `/${isAdmin ? "admin" : "account"}/units/${
                                unit.id
                            }`)
                    }
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    bill && (
                        <ContentContainer>
                            <div style={{ marginBottom: "30px" }}>
                                <MainHeader>{bill.category}</MainHeader>
                                {isAdmin && (
                                    <SubHeader>
                                        {bill.billType === "total_only"
                                            ? "Total Only"
                                            : bill.billType ===
                                              "floor_sized_dependent"
                                            ? "Floor Sized Dependent"
                                            : bill.billType === "rate_type"
                                            ? "Rate Type"
                                            : ""}
                                    </SubHeader>
                                )}
                                <InfoContainer>
                                    {unit && (
                                        <Fragment>
                                            <div>
                                                <InfoLabel>Building</InfoLabel>
                                                <InfoText>
                                                    {unit.buildingName}
                                                </InfoText>
                                            </div>
                                            <div>
                                                <InfoLabel>Unit</InfoLabel>
                                                <InfoText>
                                                    {unit.number}
                                                </InfoText>
                                            </div>
                                        </Fragment>
                                    )}
                                </InfoContainer>
                                <InfoContainer>
                                    {bill.coverageStart && (
                                        <div>
                                            <InfoLabel>
                                                Coverage Start
                                            </InfoLabel>
                                            <InfoText>
                                                {moment(
                                                    bill.coverageStart
                                                ).format("MM/DD/YYYY")}
                                            </InfoText>
                                        </div>
                                    )}
                                    {bill.coverageEnd && (
                                        <div>
                                            <InfoLabel>Coverage End</InfoLabel>
                                            <InfoText>
                                                {moment(
                                                    bill.coverageEnd
                                                ).format("MM/DD/YYYY")}
                                            </InfoText>
                                        </div>
                                    )}
                                    <div>
                                        <InfoLabel>Bill Date</InfoLabel>
                                        <InfoText>
                                            {bill.billDate &&
                                                moment(bill.billDate).format(
                                                    "MM/DD/YYYY"
                                                )}
                                        </InfoText>
                                    </div>
                                    <div>
                                        <InfoLabel>Due Date</InfoLabel>
                                        <InfoText>
                                            {bill.dueDate &&
                                                moment(bill.dueDate).format(
                                                    "MM/DD/YYYY"
                                                )}
                                        </InfoText>
                                    </div>
                                </InfoContainer>
                                {bill.billType !== "total_only" && (
                                    <InfoContainer>
                                        {bill.billType === "rate_type" && (
                                            <div>
                                                <InfoLabel>
                                                    Utilization
                                                </InfoLabel>
                                                <InfoText>
                                                    {bill.consumption}
                                                </InfoText>
                                            </div>
                                        )}
                                        <div>
                                            <InfoLabel>Rate</InfoLabel>
                                            <InfoText>{bill.rate}</InfoText>
                                        </div>
                                    </InfoContainer>
                                )}
                                {bill.discounts && bill.discounts.length > 0 && (
                                    <Fragment>
                                        <InfoLabel
                                            style={{ marginTop: "18px" }}
                                        >
                                            Discounts
                                        </InfoLabel>
                                        <div style={{ display: "table" }}>
                                            {billDiscountInfos}
                                        </div>
                                    </Fragment>
                                )}
                                <InfoContainer>
                                    <div style={{ textAlign: "right" }}>
                                        <InfoLabel>Total Amount</InfoLabel>
                                        <InfoText>
                                            <PesoSign />
                                            {currencyFormat(
                                                bill.totalAmountDue
                                            )}
                                        </InfoText>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        <InfoLabel>Remaining Balance</InfoLabel>
                                        <InfoText>
                                            <PesoSign />
                                            {currencyFormat(
                                                bill.remainingBalance
                                            )}
                                        </InfoText>
                                    </div>
                                    <div>
                                        <InfoLabel
                                            style={{ marginBottom: "8px" }}
                                        >
                                            Status
                                        </InfoLabel>
                                        <BillStatus
                                            status={bill.status}
                                            style={{
                                                fontSize: `12px`,
                                            }}
                                        >
                                            {bill.status}
                                        </BillStatus>
                                    </div>
                                </InfoContainer>
                                {bill.status === "Overdue" && (
                                    <InfoContainer>
                                        <div style={{ textAlign: "right" }}>
                                            <InfoLabel>Overdue Fee</InfoLabel>
                                            <InfoText>
                                                <PesoSign />
                                                {currencyFormat(
                                                    bill.overdueFee
                                                )}
                                            </InfoText>
                                        </div>
                                    </InfoContainer>
                                )}
                            </div>
                            <PaymentInfoText>
                                <FiInfo style={{ marginRight: "2px" }} />
                                <span>
                                    Online transactions has a fee of{" "}
                                    <PesoSign />
                                    {bill.transactionFee
                                        ? currencyFormat(bill.transactionFee)
                                        : "0.00"}
                                </span>
                            </PaymentInfoText>
                            {bill.status !== "Paid Full" && !isAdmin && (
                                <Fragment>
                                    <ClickPayNowbutton
                                        onClick={() =>
                                            this.showOnlinePaymentModal()
                                            }
                                        style={{
                                                width: "fit-content",
                                            }}
                                        >
                                            Click here to pay now!
                                    </ClickPayNowbutton>
                                    {confirmationModalVisible && (
                                        <ConfirmationModal
                                            body="Page needs to be refreshed to show changes after payment."
                                            modalVisible={
                                                confirmationModalVisible
                                            }
                                            submitText="Refresh now"
                                            cancelText="Maybe later"
                                            onSubmit={() => location.reload()}
                                            closeModal={this.hideConfirmationModal.bind(
                                                this
                                            )}
                                        />
                                    )}
                                    {onlinePaymentModalVisible && (
                                        <OnlinePaymentModal
                                            transactionFee=
                                                {bill.transactionFee
                                                    ? currencyFormat(bill.transactionFee)
                                                    : "0.00"}
                                            totalAmountDue={bill.totalAmountDue}
                                            modalVisible={onlinePaymentModalVisible}
                                            submitText="Close"
                                            onSubmit={this.hideOnlinePaymentModal.bind(
                                                this
                                            )}
                                            closeModal={this.hideOnlinePaymentModal.bind(
                                                this
                                            )}
                                            handlePaypalClick={this.handlePaypalClick.bind(
                                                this
                                            )}
                                            handleDragonPayClick={this.handleDragonPayClick.bind(
                                                this
                                            )}
                                        />
                                    )}
                                </Fragment>
                            )}
                            {bill.payments && bill.payments.length > 0 ? (
                                <Fragment>
                                    <TableTextHeaderContainer>
                                        <TableTextHeader style={{
                                            marginTop: "3rem",
                                        }}>
                                            PAYMENTS
                                        </TableTextHeader>
                                        {bill.status !== "Paid Full" &&
                                            isAdmin && (
                                                <NewItemContainer>
                                                    <NewItemLink
                                                        onClick={() =>
                                                            this.showConfirmationModal()
                                                        }
                                                    >
                                                        Generate Payment Link
                                                    </NewItemLink>
                                                    <span
                                                        style={{
                                                            margin: "0 8px",
                                                        }}
                                                    >
                                                        |
                                                    </span>
                                                    <AddItemButton
                                                        onClick={() =>
                                                            this.showPaymentModal()
                                                        }
                                                        text="NEW PAYMENT"
                                                    />
                                                </NewItemContainer>
                                            )}
                                    </TableTextHeaderContainer>
                                    <Table>
                                        <TableHeader>
                                            <TableCell>Date and Time</TableCell>
                                            <TableCell>
                                                Reference Number
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: "right" }}
                                            >
                                                Amount
                                            </TableCell>
                                            <TableCell>Remarks</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableHeader>
                                        {paymentRows}
                                    </Table>
                                </Fragment>
                            ) : (
                                <div>
                                    {isAdmin && (
                                        <Fragment>
                                            <span>
                                                This bill does not have any
                                                payments yet.{" "}
                                            </span>
                                            <NewItemLink
                                                onClick={() =>
                                                    this.showPaymentModal()
                                                }
                                            >
                                                Create one now
                                            </NewItemLink>
                                            <span> or </span>
                                            <NewItemLink
                                                onClick={() =>
                                                    this.showConfirmationModal()
                                                }
                                            >
                                                generate a payment link
                                            </NewItemLink>
                                            <span>.</span>
                                        </Fragment>
                                    )}
                                </div>
                            )}
                            {paymentModalVisible && (
                                <PaymentModal
                                    modalVisible={paymentModalVisible}
                                    closeModal={this.hidePaymentModal.bind(
                                        this
                                    )}
                                />
                            )}
                            {confirmationModalVisible && isAdmin && (
                                <ConfirmationModal
                                    body={
                                        <a
                                            href={anonymousPaymentLink}
                                            target="_blank"
                                        >
                                            {anonymousPaymentLink}
                                        </a>
                                    }
                                    modalVisible={confirmationModalVisible}
                                    submitText="OK"
                                    onSubmit={this.hideConfirmationModal.bind(
                                        this
                                    )}
                                    closeModal={this.hideConfirmationModal.bind(
                                        this
                                    )}
                                />
                            )}
                        </ContentContainer>
                    )
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unit: state.unit,
        bill: state.bill,
        isLoading: state.isLoading,
        isModalLoading: state.isModalLoading,
        isAdmin: state.isAdmin,
        isAuthorized: state.isAuthorized,
    };
};

export default connect(mapStateToProps)(BillContainer);
