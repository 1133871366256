import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import StatementOfAccountModal from "containers/StatementOfAccountModal";
import { currencyFormat } from "utils/InputPatterns";
import {
    TabHeaderContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    TabNewItemContainer
} from "styles/Tabs";
import { Table, TableHeader, TableCell, TableRow } from "styles/Tables";
import { DownloadButton } from "styles/Buttons";
import {
    PesoSign,
} from "styles/Containers";

class UnitRunningBalanceContainer extends Component {
    state = { statementOfAccountModalVisible: false };

    showStatementOfAccountModal() {
        this.setState({ statementOfAccountModalVisible: true });
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    hideStatementOfAccountModal() {
        this.setState({ statementOfAccountModalVisible: false });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    render() {
        const { runningBalance, tabVisible, onTabChange } = this.props;
        const { statementOfAccountModalVisible } = this.state;

        const runningBalanceRows = runningBalance.map((rb, i) => {
            return (
                <TableRow
                    key={"runningBalance_" + i}
                    gray={(i % 2 === 0).toString()}
                >
                    <TableCell>
                        {rb.date ? moment(rb.date).format("MM/DD/YYYY") : ""}
                    </TableCell>
                    <TableCell>{rb.category}</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <PesoSign />{currencyFormat(rb.charged)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <PesoSign />{currencyFormat(rb.paid)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <PesoSign />{currencyFormat(rb.balance)}
                    </TableCell>
                    <TableCell>{rb.reference}</TableCell>
                </TableRow>
            );
        });

        return (
            <Fragment>
                {tabVisible && (
                    <Fragment>
                        <TabHeaderContainer>
                            <InactiveTabHeader
                                onClick={() => onTabChange("bills")}
                            >
                                Bills
                            </InactiveTabHeader>
                            <InactiveTabHeader
                                onClick={() => onTabChange("payments")}
                            >
                                Payments
                            </InactiveTabHeader>
                            <InactiveTabHeader
                                onClick={() => onTabChange("activityLogs")}
                            >
                                ActivityLogs
                            </InactiveTabHeader>
                            <ActiveTabHeader>Running Balance</ActiveTabHeader>
                            <TabNewItemContainer>
                                <DownloadButton
                                    onClick={() =>
                                        this.showStatementOfAccountModal()
                                    }
                                    style={{
                                        float: "right",
                                        marginTop: "10px"
                                    }}
                                >
                                    Generate Statement of Account
                                </DownloadButton>
                            </TabNewItemContainer>
                        </TabHeaderContainer>
                        <Table>
                            <TableHeader>
                                <TableCell>Date</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    Charged
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    Paid
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    Balance
                                </TableCell>
                                <TableCell>Reference</TableCell>
                            </TableHeader>
                            {runningBalanceRows}
                        </Table>
                        {statementOfAccountModalVisible && (
                            <StatementOfAccountModal
                                modalVisible={statementOfAccountModalVisible}
                                closeModal={this.hideStatementOfAccountModal.bind(
                                    this
                                )}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        runningBalance: (state.unit && state.unit.runningBalance) || []
    };
};

export default connect(mapStateToProps)(UnitRunningBalanceContainer);
