import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountLogin from "./AccountLogin";
import AccountLoggedIn from "./AccountLoggedIn";
import AccountLogout from "./AccountLogout";
import ForgotPassword from "./AccountForgotPassword";
import ResetPassword from "containers/ResetPasswordContainer";
import { ActionCableProvider } from "react-actioncable-provider";
import SignUp from "./AccountSignUp";
import AccountConfirmSignUp from "./AccountConfirmSignUp";
import { Spinner } from "containers/Spinner";
import { accountTestAuth } from "actions/testAuth";
import Modal from "react-modal";
import colors from "utils/colors";

let bugsnagClient = bugsnag("b3b2045e5598932ee712f6549e0abdfd");
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin("react");

toast.configure({
    autoClose: 8000,
    draggable: false,
});

const AppContainer = styled.div`
    flex: 1;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: ${colors.LIGHT_BLACK};
`;

const ToastyContainer = styled.div`
    .Toastify__toast {
        font-family: "Poppins", sans-serif;
        &-body {
            color: black;
        }
    }
`;

class App extends Component {
    componentDidMount() {
        const { isLoggedIn, dispatch, history } = this.props;
        Modal.setAppElement("#root");
        if (isLoggedIn) {
            dispatch(accountTestAuth(history));
        }
    }

    render() {
        const { userEmail, isAppLoading, isLoggedIn } = this.props;

        return (
            <ErrorBoundary>
                <AppContainer>
                    {isLoggedIn ? (
                        isAppLoading ? (
                            <Spinner height="100vh" />
                        ) : userEmail ? (
                            <ActionCableProvider
                                url={API_WS_ROOT + "?token=" + userEmail}
                            >
                                <AccountLoggedIn />
                            </ActionCableProvider>
                        ) : (
                            <ActionCableProvider url={API_WS_ROOT}>
                                <AccountLoggedIn />
                            </ActionCableProvider>
                        )
                    ) : (
                        <Switch>
                            <Route
                                exact
                                path="/account/login"
                                component={AccountLogin}
                            />
                            <Route
                                exact
                                path="/account/forgotPassword"
                                component={ForgotPassword}
                            />
                            <Route
                                exact
                                path="/account/resetPassword/:token"
                                render={({ match }) => (
                                    <ResetPassword token={match.params.token} />
                                )}
                            />
                            <Route
                                exact
                                path="/account/signUp"
                                component={SignUp}
                            />
                            <Route
                                exact
                                path="/account/confirm/:token"
                                render={({ match }) => (
                                    <AccountConfirmSignUp
                                        token={match.params.token}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/account/logout"
                                component={AccountLogout}
                            />
                            <Route path="/account" component={AccountLogin} />
                        </Switch>
                    )}
                </AppContainer>
                <ToastyContainer>
                    <ToastContainer hideProgressBar={true} />
                </ToastyContainer>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAppLoading: state.isAppLoading,
        isLoggedIn: state.isLoggedIn,
        userEmail: state.user && state.user.email,
    };
};

export default withRouter(connect(mapStateToProps)(App));
