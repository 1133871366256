import React, { Component, Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ActionCableConsumer } from "react-actioncable-provider";
import { FiLogOut, FiChevronUp, FiChevronLeft, FiEdit } from "react-icons/fi";
import { FaBell } from "react-icons/fa";
import ChangePasswordModal from "./ChangePasswordModal";
import { adminLogout, accountLogout } from "reducers/userReducer";
import {
    addNotification,
    markAsRead,
    markAllAsRead
} from "reducers/notificationReducer";
import colors from "utils/colors";
import { DropdownMenu, DropdownOption } from "styles/Inputs";

import NavLogo from "images/logo_colored.png";
import moment from "moment";


const PageHeaderContainer = styled.div`
    @media screen and (max-width: 480px) {
        padding: 0 1rem;
    }

    padding: 10px 30px 0px 45px;
    height: 50px;
    border-bottom: 1px solid ${colors.TRANSPARENT_GRAY};
    color: ${colors.BLACK};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UserContainer = styled.div`
    padding: 8px 0;
    min-width: 220px;
    margin: -8px -12px 0 0;
`;

const NotificationsContainer = styled.div``;

const UserOptionsButton = styled.button`
    display: inline-flex;
    align-items: center;
    border: none;
    background-color: white;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

const BackToButton = styled(Link)`
    color: black;
    text-decoration: none;
    :active {
        color: rgba(0, 0, 0, 0.2);
    }
`;

const RightHeaderContainer = styled.div`
    @media screen and (max-width: 480px) {
        display: none;
    }
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
`;

const ProfileContainer = styled.div`
    border-radius: 25px;
    height: 30px;
    width: 30px;
    background-color: ${colors.GRAY};

    @media screen and (min-width: 481px) {
        margin-left: 10px;
    }
`;

const LeftDivider = styled.div`
    border-left: 1px solid ${colors.TRANSPARENT_GRAY};
    height: 35px;
    margin: 0 5px 0 10px;
`;

const NameContainer = styled.div`
    font-weight: 600;
    font-size: 12px;
    color: ${colors.BLACK};
`;

const NewNotificationBadge = styled.div`
    position: absolute;
    top: 8px;
    right: 7px;
    background-color: #ef1f1f;
    border-radius: 35px;
    width: 6px;
    height: 6px;
`;

const NotificationsButton = styled.button`
    position: relative;
    height: 38px;
    font-size: 14px;
    border: none;
    background-color: white;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

const NotificationsHeader = styled.div`
    position: absolute;
    background-color: white;
    width: 278px;
    height: 35px;
    padding: 8px 10px;
    color: ${colors.BLUE};
    font-size: 12px;
    text-align: right;
    justify-content: center;
    border-bottom: 0.5px solid ${colors.TRANSPARENT_GRAY};
`;

const NotificationsFooter = styled.div`
    position: absolute;
    background-color: white;
    width: 278px;
    height: 35px;
    padding: 8px 0;
    text-align: center;
    border-top: 0.5px solid ${colors.TRANSPARENT_GRAY};
`;

const EmptyNotificationsContainer = styled.div`
    height: 230px;
    padding: 105px 0;
    text-align: center;
    color: ${colors.LIGHT_BLACK};
`;

const TriangleContainer = styled.div`
    height: 14px;
    position: absolute;
    top: 38px;
    color: ${colors.LIGHT_GRAY};
`;

const MobileLogoContainer = styled.div`
    @media screen and (min-width: 481px) {
        display: none;
    }

    position: relative;
    display: flex;
    margin-left: -1rem;
`;

const MobileLogoHead = styled.span`
    height: 30px;
    width: 100px;
    margin: 10px;
    margin-left: 50px;
    box-sizing: border-box;
    background-image: url("${NavLogo}");
    background-repeat: no-repeat;
    background-size: contain;

    :before {
        content: "";
        position: absolute;
        left: 25px;
        top: 20px;
        bottom: 0;
        width: 1em;
        height: 2px;
        background: ${colors.BLUE_GREEN};
        box-shadow: 0 10px 0 0 ${colors.BLUE_GREEN}, 0 5px 0 0 ${colors.BLUE_GREEN};
    }
`;

const MobileRightHeaderContainer = styled.div`
    @media screen and (min-width: 481px) {
        display: none;
    }
`;

const MobileRightHeader = ({
    doLogout,
    email,
    isAdmin = false,
    showChangePassword
}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <MobileRightHeaderContainer>
            <ProfileContainer onClick={() => setShowDropdown(!showDropdown)} />
            {showDropdown && (
                <DropdownMenu
                    style={{
                        width: "65%",
                        right: "0.25rem",
                        overflow: "unset"
                    }}
                >
                    {isAdmin && (
                        <DropdownOption onClick={showChangePassword}>
                            <FiEdit />
                            <span> CHANGE PASSWORD</span>
                        </DropdownOption>
                    )}
                    <DropdownOption>
                        <NameContainer> {email}</NameContainer>
                    </DropdownOption>

                    <DropdownOption>
                        <Link
                            to={`/${
                                isAdmin ? "admin" : "account"
                            }/notifications`}
                            style={{
                                color: colors.BLUE,
                                textDecoration: "none"
                            }}
                        >
                            See all notifications
                        </Link>
                    </DropdownOption>

                    <DropdownOption onClick={doLogout}>
                        <FiLogOut />
                        <span> LOGOUT</span>
                    </DropdownOption>
                </DropdownMenu>
            )}
        </MobileRightHeaderContainer>
    );
};

class PageHeader extends Component {
    state = {
        userDropdownMenuVisible: false,
        notificationsDropdownMenuVisible: false,
        changePasswordModalVisible: false
    };

    constructor(props) {
        super(props);
        this.hideUserDropdownMenu = this.hideUserDropdownMenu.bind(this);
        this.hideNotificationsDropdownMenu = this.hideNotificationsDropdownMenu.bind(
            this
        );
    }

    showUserDropdownMenu(e) {
        e.preventDefault();
        this.setState({
            userDropdownMenuVisible: true
        });
        document.addEventListener("click", this.hideUserDropdownMenu);
    }

    hideUserDropdownMenu() {
        this.setState({ userDropdownMenuVisible: false }, () => {
            document.removeEventListener("click", this.hideUserDropdownMenu);
        });
    }

    showNotificationsDropdownMenu(e) {
        e.preventDefault();
        this.setState({
            notificationsDropdownMenuVisible: true
        });
        document.addEventListener("click", this.hideNotificationsDropdownMenu);
    }

    hideNotificationsDropdownMenu() {
        this.setState({ notificationsDropdownMenuVisible: false }, () => {
            document.removeEventListener(
                "click",
                this.hideNotificationsDropdownMenu
            );
        });
    }

    handleNotificationClick(notification) {
        const { dispatch, history } = this.props;
        this.hideNotificationsDropdownMenu();
        dispatch(markAsRead({ notification }, history));
    }

    handleMarkAllAsRead() {
        const { dispatch } = this.props;
        dispatch(markAllAsRead());
    }

    handleLogout() {
        const { isAdmin, dispatch, history } = this.props;

        document.removeEventListener("click", this.hideUserDropdownMenu);
        if (isAdmin) {
            dispatch(adminLogout(history));
        } else {
            dispatch(accountLogout(history));
        }
    }

    render() {
        const {
            user,
            notifications,
            isAdmin,
            title,
            backTo,
            dispatch
        } = this.props;
        const {
            userDropdownMenuVisible,
            notificationsDropdownMenuVisible,
            changePasswordModalVisible
        } = this.state;

        const notificationDropdownOptions =
            notifications &&
            notifications.map(
                (notification, i) =>
                    i < 10 && (
                        <DropdownOption
                            key={notification.id}
                            style={{
                                padding: "10px",
                                borderBottom: `0.5px solid ${colors.TRANSPARENT_GRAY}`,
                                alignItems: `baseline`
                            }}
                            onClick={() =>
                                this.handleNotificationClick(notification)
                            }
                        >
                            <span
                                style={{
                                    fontSize: `12px`,
                                    width: `10%`,
                                    marginRight: `1rem`,
                                    color: `${colors.LIGHT_BLACK}`,
                                }}>
                                {moment(notification.date).format("MM/DD, HH:MM")}
                            </span>
                            <span
                                style={{
                                    paddingLeft: `0.5rem`,
                                    minHeight: `36px`,
                                    borderLeft: `1px solid ${colors.TRANSPARENT_GRAY}`,
                                }}>
                                {notification.text}
                            </span>
                        </DropdownOption>
                    )
            );

        return (
            <PageHeaderContainer>
                <ActionCableConsumer
                    channel={{ channel: "NotificationsChannel" }}
                    onReceived={res => dispatch(addNotification(res))}
                />
                {backTo ? (
                    <BackToButton to={backTo}>
                        <FiChevronLeft style={{ margin: "0 2px -2px 0" }} />
                        <span style={{ fontWeight: "bold" }}>{title}</span>
                    </BackToButton>
                ) : title ? (
                    <span style={{ fontWeight: "bold" }}>{title}</span>
                ) : (
                    <MobileLogoContainer
                        onClick={() => dispatch({ type: "TOGGLE_NAV" })}
                    >
                        <MobileLogoHead />
                    </MobileLogoContainer>
                )}
                <MobileRightHeader
                    doLogout={() => this.handleLogout()}
                    email={user.email}
                    isAdmin={isAdmin}
                    showChangePassword={() =>
                        this.setState({ changePasswordModalVisible: true })
                    }
                />
                <RightHeaderContainer>
                    <NotificationsContainer>
                        <NotificationsButton
                            onClick={e => this.showNotificationsDropdownMenu(e)}
                        >
                            <FaBell />
                            {notifications && notifications.length > 0 && (
                                <NewNotificationBadge />
                            )}
                        </NotificationsButton>
                        {notificationsDropdownMenuVisible && (
                            <Fragment>
                                <TriangleContainer style={{ top: "38px" }}>
                                    <FiChevronUp />
                                </TriangleContainer>
                                <DropdownMenu
                                    style={{
                                        top: "43px",
                                        width: "280px",
                                        height: "315px",
                                        maxHeight: "unset",
                                        padding: "unset",
                                        overflow: "unset"
                                    }}
                                >
                                    <NotificationsHeader
                                        onClick={() =>
                                            this.handleMarkAllAsRead()
                                        }
                                    >
                                        <span style={{ cursor: "pointer" }}>
                                            Mark all as read
                                        </span>
                                    </NotificationsHeader>
                                    <div
                                        style={{
                                            position: "relative",
                                            top: "35px",
                                            height: "275px",
                                            overflowY: "scroll"
                                        }}
                                    >
                                        {notifications &&
                                        notifications.length > 0
                                        ? (
                                            notificationDropdownOptions
                                        )
                                        : (
                                            <EmptyNotificationsContainer>
                                                No new notifications yet.
                                            </EmptyNotificationsContainer>
                                        )}
                                    </div>
                                    <NotificationsFooter>
                                        <Link
                                            to={`/${
                                                isAdmin ? "admin" : "account"
                                            }/notifications`}
                                            style={{
                                                color: colors.BLUE,
                                                textDecoration: "none"
                                            }}
                                            onClick={() =>
                                                document.removeEventListener(
                                                    "click",
                                                    this
                                                        .hideNotificationsDropdownMenu
                                                )
                                            }
                                        >
                                            See all notifications
                                        </Link>
                                    </NotificationsFooter>
                                </DropdownMenu>
                            </Fragment>
                        )}
                    </NotificationsContainer>
                    <LeftDivider />
                    <UserContainer>
                        <div style={{ textAlign: "right" }}>
                            <UserOptionsButton
                                onClick={e => this.showUserDropdownMenu(e)}
                            >
                                <NameContainer> {user.email}</NameContainer>
                                <ProfileContainer />
                            </UserOptionsButton>
                        </div>
                        {userDropdownMenuVisible && (
                            <Fragment>
                                <TriangleContainer style={{ right: "48px" }}>
                                    <FiChevronUp />
                                </TriangleContainer>
                                <DropdownMenu
                                    style={{
                                        width: "200px",
                                        right: "30px",
                                        overflow: "unset"
                                    }}
                                >
                                    {isAdmin && (
                                        <DropdownOption
                                            onClick={() =>
                                                this.setState({
                                                    changePasswordModalVisible: true
                                                })
                                            }
                                        >
                                            <FiEdit
                                                style={{ marginRight: "2px" }}
                                            />
                                            <span> CHANGE PASSWORD</span>
                                        </DropdownOption>
                                    )}
                                    <DropdownOption
                                        onClick={() => this.handleLogout()}
                                    >
                                        <FiLogOut
                                            style={{ marginRight: "2px" }}
                                        />
                                        <span> LOGOUT</span>
                                    </DropdownOption>
                                </DropdownMenu>
                            </Fragment>
                        )}
                    </UserContainer>
                </RightHeaderContainer>
                {changePasswordModalVisible && (
                    <ChangePasswordModal
                        modalVisible={changePasswordModalVisible}
                        closeModal={() =>
                            this.setState({ changePasswordModalVisible: false })
                        }
                    />
                )}
            </PageHeaderContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        notifications: state.notifications.filter(n => n.status === "Unread"),
        isAdmin: state.isAdmin,
        isAuthorized: state.isAuthorized
    };
};

export default withRouter(connect(mapStateToProps)(PageHeader));
