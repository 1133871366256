import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { currencyFormat } from "utils/InputPatterns";
import { BillStatus } from "styles/Status";
import {
    Input,
    Select,
    FiltersContainer,
    FilterLabel,
    InputDatePicker,
    ClearableInputDatePickerContainer
} from "styles/Inputs";
import {
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination,
    EmptyListContainer,
    TableOverflowX
} from "styles/Tables";
import {
    TabHeaderContainer,
    TabNewItemContainer,
    ActiveTabHeader,
    InactiveTabHeader
} from "styles/Tabs";
import {
    PesoSign,
} from "styles/Containers";
import { DownloadButton } from "styles/Buttons";
import { exportCSV } from "actions/exportCSV";

const billFilterStatusOptions = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "Not Paid Full",
        label: "Not Paid Full"
    },
    {
        value: "Unpaid",
        label: "Unpaid"
    },
    {
        value: "Paid Partial",
        label: "Paid Partial"
    },
    {
        value: "Paid Full",
        label: "Paid Full"
    },
    {
        value: "Overdue",
        label: "Overdue"
    }
];

const billsPerPage = 10;

const BillsGrandTotalContainer = styled.div`
    width: 100%;
    text-align: right;
    font-family: "PT Mono", monospace;
    font-size: small;
    margin-top: 12px;
    padding-right: 10px;
    color: ${colors.PRIMARY};
`;

class AdminBillsBillsContainer extends Component {
    state = {
        filter: {
            building: "",
            unit: "",
            dueDateFrom: null,
            dueDateTo: null,
            coverageStart: null,
            coverageEnd: null,
            category: "",
            status: {
                value: "All",
                label: "All"
            }
        },
        filteredBills: null,
        offset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { bills } = this.props;
        this.setState({
            filteredBills: bills || []
        });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredBills, offset } = this.state;
        this.setState({
            offset:
                offset < Math.ceil(filteredBills.length / billsPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    handleFilterBuildingChange(building) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                building
            }),
            filteredBills: this.filterBills({
                building,
                unit: filter.unit,
                dueDateFrom: filter.dueDateFrom,
                dueDateTo: filter.dueDateTo,
                coverageStart: filter.coverageStart,
                coverageEnd: filter.coverageEnd,
                category: filter.category,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterUnitChange(unit) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                unit
            }),
            filteredBills: this.filterBills({
                building: filter.building,
                unit,
                dueDateFrom: filter.dueDateFrom,
                dueDateTo: filter.dueDateTo,
                coverageStart: filter.coverageStart,
                coverageEnd: filter.coverageEnd,
                category: filter.category,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterBillsDueDateFromChange(dueDateFrom) {
        const { filter } = this.state;
        const dueDateTo =
            !dueDateFrom ||
            moment(dueDateFrom).isSameOrAfter(filter.dueDateTo, "day")
                ? null
                : filter.dueDateTo;
        this.setState({
            filter: Object.assign({}, filter, {
                dueDateFrom,
                dueDateTo
            }),
            filteredBills: this.filterBills({
                building: filter.building,
                unit: filter.unit,
                dueDateFrom,
                dueDateTo,
                coverageStart: filter.coverageStart,
                coverageEnd: filter.coverageEnd,
                category: filter.category,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterBillsDueDateToChange(dueDateTo) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                dueDateTo
            }),
            filteredBills: this.filterBills({
                building: filter.building,
                unit: filter.unit,
                dueDateFrom: filter.dueDateFrom,
                dueDateTo,
                coverageStart: filter.coverageStart,
                coverageEnd: filter.coverageEnd,
                category: filter.category,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterCoverageStartChange(coverageStart) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                coverageStart
            }),
            filteredBills: this.filterBills({
                building: filter.building,
                unit: filter.unit,
                dueDateFrom: filter.dueDateFrom,
                dueDateTo: filter.dueDateTo,
                coverageStart,
                coverageEnd: filter.coverageEnd,
                category: filter.category,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterCoverageEndChange(coverageEnd) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                coverageEnd
            }),
            filteredBills: this.filterBills({
                building: filter.building,
                unit: filter.unit,
                dueDateFrom: filter.dueDateFrom,
                dueDateTo: filter.dueDateTo,
                coverageStart: filter.coverageStart,
                coverageEnd,
                category: filter.category,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterBillsCategoryChange(category) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                category
            }),
            filteredBills: this.filterBills({
                building: filter.building,
                unit: filter.unit,
                dueDateFrom: filter.dueDateFrom,
                dueDateTo: filter.dueDateTo,
                coverageStart: filter.coverageStart,
                coverageEnd: filter.coverageEnd,
                category,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterBillsStatusChange(status) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                status
            }),
            filteredBills: this.filterBills({
                building: filter.building,
                unit: filter.unit,
                dueDateFrom: filter.dueDateFrom,
                dueDateTo: filter.dueDateTo,
                coverageStart: filter.coverageStart,
                coverageEnd: filter.coverageEnd,
                category: filter.category,
                status
            }),
            offset: 1
        });
    }

    filterBills({
        building,
        unit,
        dueDateFrom,
        dueDateTo,
        coverageStart,
        coverageEnd,
        category,
        status
    }) {
        const { bills } = this.props;

        return (
            bills &&
            bills.filter(
                bill =>
                    (building
                        ? bill.buildingName
                            ? bill.buildingName
                                  .toLowerCase()
                                  .includes(building.toLowerCase())
                            : false
                        : true) &&
                    (unit
                        ? bill.unitNumber
                            ? bill.unitNumber
                                  .toLowerCase()
                                  .includes(unit.toLowerCase())
                            : false
                        : true) &&
                    (dueDateFrom
                        ? moment(bill.dueDate).isSameOrAfter(dueDateFrom, "day")
                        : true) &&
                    (dueDateFrom && dueDateTo
                        ? moment(bill.dueDate).isSameOrBefore(dueDateTo, "day")
                        : true) &&
                    (coverageStart
                        ? moment(bill.coverageStart).isSameOrAfter(
                              coverageStart,
                              "day"
                          )
                        : true) &&
                    (coverageEnd
                        ? moment(bill.coverageEnd).isSameOrBefore(
                              coverageEnd,
                              "day"
                          )
                        : true) &&
                    (category
                        ? bill.category
                              .toLowerCase()
                              .includes(category.value.toLowerCase())
                        : true) &&
                    (status.value === "Not Paid Full"
                        ? bill.status !== "Paid Full"
                        : status.value === "All"
                        ? true
                        : bill.status === status.label)
            )
        );
    }

    handleBillsReport(array) {
        const headers = [
            "Building Name",
            "Unit No",
            "Due Date",
            "Coverage Start",
            "Coverage End",
            "Category",
            "Remarks",
            "Amount Due",
            "Overdue Fee",
            "Remaining Balance",
            "Status"
        ];
        const columns = [
            "buildingName",
            "unitNumber",
            "dueDate",
            "coverageStart",
            "coverageEnd",
            "category",
            "remarks",
            "totalAmountDue",
            "overdueFee",
            "remainingBalance",
            "status"
        ];

        exportCSV(array, headers, columns, "Bills Report");
    }

    render() {
        const { bills, tabVisible, onTabChange } = this.props;
        const { filter, filteredBills, offset } = this.state;
        const range = offset * billsPerPage;

        const filteredBillRows =
            filteredBills &&
            filteredBills.map((bill, i) => {
                if (i >= range - billsPerPage && i < range) {
                    return (
                        <TableLinkRow
                            key={bill.id}
                            to={{
                                pathname: `/admin/bills/${bill.id}`,
                                state: {
                                    backTo: {
                                        label: "Back to Bills",
                                        value: "/admin/bills"
                                    }
                                }
                            }}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{bill.buildingName}</TableCell>
                            <TableCell>{bill.unitNumber}</TableCell>
                            <TableCell>
                                {bill.dueDate
                                    ? moment(bill.dueDate).format("MM/DD/YYYY")
                                    : ""}
                            </TableCell>
                            <TableCell>
                                {bill.coverageStart && bill.coverageEnd
                                    ? `${moment(bill.coverageStart).format(
                                          "MM/DD/YYYY"
                                      )} - ${moment(bill.coverageEnd).format(
                                          "MM/DD/YYYY"
                                      )}`
                                    : ""}
                            </TableCell>
                            <TableCell>{bill.category}</TableCell>
                            <TableCell>{bill.remarks}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {(bill.totalAmountDue &&
                                    currencyFormat(bill.totalAmountDue)) ||
                                    "0.00"}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {(bill.overdueFee &&
                                    currencyFormat(bill.overdueFee)) ||
                                    "0.00"}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {(bill.remainingBalance &&
                                    currencyFormat(bill.remainingBalance)) ||
                                    "0.00"}
                            </TableCell>
                            <TableCell style={{ textAlign: "-webkit-center" }}>
                                <BillStatus status={bill.status}>
                                    {bill.status}
                                </BillStatus>
                            </TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        const billingCategoryOptions =
            bills &&
            [...new Set(bills.map(bill => bill.category))].map(category => {
                return {
                    value: category,
                    label: category
                };
            });

        const billsGrandTotal = filteredBills
            ? filteredBills.reduce(
                  (acc, curr) => acc + (Number(curr.remainingBalance) || 0),
                  0
              )
            : 0;

        return (
            <Fragment>
                {tabVisible && (
                    <div style={{ marginTop: "10px" }}>
                        <TabHeaderContainer>
                            <ActiveTabHeader>Bills</ActiveTabHeader>
                            <InactiveTabHeader
                                onClick={() => onTabChange("payments")}
                            >
                                Payments
                            </InactiveTabHeader>
                            <TabNewItemContainer>
                                <DownloadButton
                                    onClick={() =>
                                        this.handleBillsReport(filteredBills)
                                    }
                                    style={{
                                        float: "right",
                                        marginTop: "10px"
                                    }}
                                >
                                    Download Reports
                                </DownloadButton>
                            </TabNewItemContainer>
                        </TabHeaderContainer>
                        {bills && bills.length > 0 ? (
                            <Fragment>
                                <FiltersContainer>
                                    <div style={{ width: "170px" }}>
                                        <FilterLabel>Building</FilterLabel>
                                        <Input
                                            placeholder="e.g. Tower 1"
                                            value={filter.building}
                                            onChange={e =>
                                                this.handleFilterBuildingChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "120px" }}>
                                        <FilterLabel>Unit Number</FilterLabel>
                                        <Input
                                            placeholder="e.g. 305"
                                            value={filter.unit}
                                            onChange={e =>
                                                this.handleFilterUnitChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Due Date From</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={filter.dueDateFrom}
                                                selectsStart
                                                startDate={filter.dueDateFrom}
                                                endDate={filter.dueDateTo}
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterBillsDueDateFromChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Due Date To</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={filter.dueDateTo}
                                                disabled={!filter.dueDateFrom}
                                                selectsEnd
                                                minDate={filter.dueDateFrom}
                                                startDate={filter.dueDateFrom}
                                                endDate={filter.dueDateTo}
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterBillsDueDateToChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    {/* <div style={{ width: "130px" }}>
                                <FilterLabel>Coverage Start</FilterLabel>
                                <ClearableInputDatePickerContainer>
                                    <InputDatePicker
                                        selected={filter.coverageStart}
                                        selectsStart
                                        startDate={filter.coverageStart}
                                        endDate={filter.coverageEnd}
                                        isClearable={true}
                                        onChange={date =>
                                            this.handleFilterCoverageStartChange(
                                                date
                                            )
                                        }
                                    />
                                </ClearableInputDatePickerContainer>
                            </div>
                            <div style={{ width: "130px" }}>
                                <FilterLabel>Coverage End</FilterLabel>
                                <ClearableInputDatePickerContainer>
                                    <InputDatePicker
                                        selected={filter.coverageEnd}
                                        selectsEnd
                                        minDate={filter.coverageStart}
                                        startDate={filter.coverageStart}
                                        endDate={filter.coverageEnd}
                                        isClearable={true}
                                        onChange={date =>
                                            this.handleFilterCoverageEndChange(
                                                date
                                            )
                                        }
                                    />
                                </ClearableInputDatePickerContainer>
                            </div> */}
                                    <div style={{ width: "200px" }}>
                                        <FilterLabel>Category</FilterLabel>
                                        <Select
                                            value={filter.category}
                                            onChange={category =>
                                                this.handleFilterBillsCategoryChange(
                                                    category
                                                )
                                            }
                                            options={billingCategoryOptions}
                                            isSearchable
                                            isClearable
                                        />
                                    </div>
                                    <div style={{ width: "150px" }}>
                                        <FilterLabel>Status</FilterLabel>
                                        <Select
                                            value={filter.status}
                                            onChange={status =>
                                                this.handleFilterBillsStatusChange(
                                                    status
                                                )
                                            }
                                            options={billFilterStatusOptions}
                                        />
                                    </div>
                                </FiltersContainer>
                                <TableOverflowX>
                                    <Table>
                                        <TableHeader>
                                            <TableCell>Building</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>Due Date</TableCell>
                                            <TableCell>Coverage</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Remarks</TableCell>
                                            <TableCell
                                                style={{
                                                    textAlign: "right"
                                                }}
                                            >
                                                Amount Due
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    textAlign: "right"
                                                }}
                                            >
                                                Overdue Fee
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    textAlign: "right"
                                                }}
                                            >
                                                Remaining Balance
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    textAlign: "center"
                                                }}
                                            >
                                                Status
                                            </TableCell>
                                            <TableCell />
                                        </TableHeader>
                                        {filteredBillRows}
                                    </Table>
                                </TableOverflowX>
                                {filteredBills &&
                                    filteredBills.length > billsPerPage && (
                                        <Pagination
                                            itemsPerPage={billsPerPage}
                                            offset={offset}
                                            length={filteredBills.length}
                                            handlePreviousPage={() =>
                                                this.handlePreviousPage()
                                            }
                                            handleNextPage={() =>
                                                this.handleNextPage()
                                            }
                                        />
                                    )}
                                <BillsGrandTotalContainer>
                                    TOTAL {filter.status.value.toUpperCase()}{" "}
                                    REMAINING BALANCE{" "}
                                    PHP{" "}
                                    {currencyFormat(billsGrandTotal)}
                                </BillsGrandTotalContainer>
                            </Fragment>
                        ) : (
                            <EmptyListContainer style={{ margin: "30px 0" }}>
                                There are no bills yet.
                            </EmptyListContainer>
                        )}
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        bills: state.bills
    };
};

export default connect(mapStateToProps)(AdminBillsBillsContainer);
