import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import colors from "utils/colors";
import { messageActions } from "reducers/messageReducer";
import {
    BackgroundContainer,
    LoginContainer,
    Logo,
    LoginHeader,
    LoginSubHeader
} from "styles/Containers";
import { PrimaryButton } from "styles/Buttons";

class Logout extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
    }

    render() {
        return (
            <BackgroundContainer>
                <LoginContainer height="500px">
                    <Logo />
                    <LoginHeader>
                        <div>Thank you for using</div>
                        <div style={{ color: colors.SECONDARY }}>
                            OnlineDooze!
                        </div>
                    </LoginHeader>
                    <LoginSubHeader>
                        You have successfully logged out.
                    </LoginSubHeader>
                    <Link to="/admin/login" style={{ textDecoration: "none" }}>
                        <PrimaryButton
                            style={{
                                height: "38px",
                                width: "100%",
                                fontSize: "14px"
                            }}
                            text={"LOGIN AGAIN"}
                        />
                    </Link>
                </LoginContainer>
            </BackgroundContainer>
        );
    }
}

export default connect()(Logout);
