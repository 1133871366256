import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { currencyFormat } from "../utils/InputPatterns";
import {
    Input,
    Select,
    FiltersContainer,
    FilterLabel,
    InputDatePicker,
    ClearableInputDatePickerContainer
} from "../styles/Inputs";
import {
    TabHeaderContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    TabHeaderSpacer
} from "../styles/Tabs";
import {
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination
} from "../styles/Tables";
import {
    PesoSign,
} from "styles/Containers";

const unitStatusOptions = [
    {
        value: "Active",
        label: "Active"
    },
    {
        value: "Inactive",
        label: "Inactive"
    }
];

const unitsPerPage = 10;

class TenantUnitsContainer extends Component {
    state = {
        filter: {
            building: "",
            number: "",
            contractStart: null,
            contractEnd: null,
            status: {
                value: "Active",
                label: "Active"
            }
        },
        filteredUnits: null,
        unitsOffset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        this.setState({
            filteredUnits: this.filterUnits({
                building: "",
                number: "",
                contractStart: null,
                contractEnd: null,
                status: {
                    value: "Active",
                    label: "Active"
                }
            })
        });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        const { tabVisible, modalVisible } = this.props;
        if (
            tabVisible !== prevProps.tabVisible ||
            modalVisible !== prevProps.modalVisible
        ) {
            if (tabVisible && !modalVisible) {
                document.addEventListener("keydown", this.handleKeyDown);
            } else {
                document.removeEventListener("keydown", this.handleKeyDown);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredUnits, unitsOffset } = this.state;
        this.setState({
            unitsOffset:
                unitsOffset < Math.ceil(filteredUnits.length / unitsPerPage)
                    ? unitsOffset + 1
                    : unitsOffset
        });
    }

    handlePreviousPage() {
        const { unitsOffset } = this.state;
        this.setState({
            unitsOffset: unitsOffset > 1 ? unitsOffset - 1 : unitsOffset
        });
    }

    handleFilterBuildingChange(building) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                building
            }),
            filteredUnits: this.filterUnits({
                building,
                number: filter.number,
                contractStart: filter.contractStart,
                contractEnd: filter.contractEnd,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterNumberChange(number) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                number
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                number,
                contractStart: filter.contractStart,
                contractEnd: filter.contractEnd,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterContractStartChange(contractStart) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                contractStart
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                number: filter.number,
                contractStart,
                contractEnd: filter.contractEnd,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterContractEndChange(contractEnd) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                contractEnd
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                number: filter.number,
                contractStart: filter.contractStart,
                contractEnd,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterStatusChange(status) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                status
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                number: filter.number,
                contractStart: filter.contractStart,
                contractEnd: filter.contractEnd,
                status
            }),
            offset: 1
        });
    }

    filterUnits({ building, number, contractStart, contractEnd, status }) {
        const { units } = this.props;

        return (
            units &&
            units.filter(
                unit =>
                    (building
                        ? unit.buildingName
                              .toLowerCase()
                              .includes(building.toLowerCase())
                        : true) &&
                    (number
                        ? unit.number
                              .toLowerCase()
                              .includes(number.toLowerCase())
                        : true) &&
                    (contractStart
                        ? moment(unit.contractStart).isSameOrAfter(
                              contractStart,
                              "day"
                          )
                        : true) &&
                    (contractEnd
                        ? moment(unit.contractEnd).isSameOrBefore(
                              contractEnd,
                              "day"
                          )
                        : true) &&
                    unit.status === status.value
            )
        );
    }

    render() {
        const {
            tenantId,
            units,
            tabVisible,
            onTabChange,
            isAdmin
        } = this.props;
        const { filteredUnits, filter, unitsOffset } = this.state;

        const unitsRange = unitsOffset * unitsPerPage;

        const unitRows =
            filteredUnits &&
            filteredUnits.map((unit, i) => {
                if (i >= unitsRange - unitsPerPage && i < unitsRange) {
                    return (
                        <TableLinkRow
                            key={"unit_" + i}
                            to={{
                                pathname: `/${
                                    isAdmin ? "admin" : "account"
                                }/units/${unit.id}`,
                                state: {
                                    backTo: {
                                        label: "Back to Tenant",
                                        value: `/${
                                            isAdmin ? "admin" : "account"
                                        }/tenants/${tenantId}`
                                    }
                                }
                            }}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{unit.buildingName}</TableCell>
                            <TableCell>{unit.number}</TableCell>
                            <TableCell>{unit.floor}</TableCell>
                            <TableCell>
                                {moment(unit.contractStart).format(
                                    "MM/DD/YYYY"
                                )}{" "}
                                -{" "}
                                {moment(unit.contractEnd).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {(unit.amountDue &&
                                    currencyFormat(unit.amountDue)) ||
                                    "0.00"}
                            </TableCell>
                            <TableCell>
                                {unit.dueDate
                                    ? moment(unit.dueDate).format("MM/DD/YYYY")
                                    : ""}
                            </TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        return (
            <Fragment>
                {tabVisible && (
                    <Fragment>
                        {units && units.length > 0 && (
                            <Fragment>
                                <TabHeaderContainer>
                                    <ActiveTabHeader>Units</ActiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() => onTabChange("bills")}
                                    >
                                        Bills
                                    </InactiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() =>
                                            onTabChange("reservations")
                                        }
                                    >
                                        Reservations
                                    </InactiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() => onTabChange("payments")}
                                    >
                                        Payments
                                    </InactiveTabHeader>
                                    <TabHeaderSpacer />
                                </TabHeaderContainer>
                                <FiltersContainer>
                                    <div style={{ width: "220px" }}>
                                        <FilterLabel>Building</FilterLabel>
                                        <Input
                                            placeholder="e.g. Tower 1"
                                            value={filter.building}
                                            onChange={e =>
                                                this.handleFilterBuildingChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "220px" }}>
                                        <FilterLabel>Unit Number</FilterLabel>
                                        <Input
                                            placeholder="e.g. 305"
                                            value={filter.number}
                                            onChange={e =>
                                                this.handleFilterNumberChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Contract From</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={filter.contractStart}
                                                selectsStart
                                                startDate={filter.contractStart}
                                                endDate={filter.contractEnd}
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterContractStartChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Contract To</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={filter.contractEnd}
                                                selectsEnd
                                                minDate={filter.contractStart}
                                                startDate={filter.contractStart}
                                                endDate={filter.contractEnd}
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterContractEndChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    <div style={{ width: "150px" }}>
                                        <FilterLabel>Status</FilterLabel>
                                        <Select
                                            value={filter.status}
                                            onChange={this.handleFilterStatusChange.bind(
                                                this
                                            )}
                                            options={unitStatusOptions}
                                        />
                                    </div>
                                </FiltersContainer>
                                <Table>
                                    <TableHeader>
                                        <TableCell>Building</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Floor</TableCell>
                                        <TableCell>Contract Period</TableCell>
                                        <TableCell
                                            style={{ textAlign: "right" }}
                                        >
                                            Amount Due
                                        </TableCell>
                                        <TableCell>Due Date</TableCell>
                                        <TableCell />
                                    </TableHeader>
                                    {unitRows}
                                </Table>
                                {units && units.length > unitsPerPage && (
                                    <Pagination
                                        itemsPerPage={unitsPerPage}
                                        offset={unitsOffset}
                                        length={units.length}
                                        handlePreviousPage={() =>
                                            this.handlePreviousPage()
                                        }
                                        handleNextPage={() =>
                                            this.handleNextPage()
                                        }
                                    />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAdmin: state.isAdmin
    };
};

export default connect(mapStateToProps)(TenantUnitsContainer);
