import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MessageContainer from "containers/MessageContainer";
import {
    BackgroundContainer,
    LoginContainer,
    Logo,
    LoginHeader,
    LoginSubHeader,
} from "styles/Containers";
import { PrimaryButton } from "styles/Buttons";
import { Spinner } from "containers/Spinner";
import { confirmAccount } from "reducers/userReducer";

class AccountConfirmSignUp extends Component {
    componentDidMount() {
        const { token, dispatch } = this.props;
        dispatch(confirmAccount(token));
    }

    render() {
        const { isLoading, errorMessage } = this.props;

        return (
            <BackgroundContainer>
                <LoginContainer height="500px">
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <Fragment>
                            <Logo />
                            <LoginHeader>Account Confirmation</LoginHeader>
                            {errorMessage ? (
                                <MessageContainer
                                    style={{
                                        textAlign: "center",
                                        margin: "20px 0",
                                    }}
                                />
                            ) : (
                                <LoginSubHeader>
                                    Account confirmed please log in to continue.
                                </LoginSubHeader>
                            )}
                            <Link
                                to={`/account/login`}
                                style={{ textDecoration: "none" }}
                            >
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                    }}
                                    text={"GO TO LOG IN"}
                                />
                            </Link>
                        </Fragment>
                    )}
                </LoginContainer>
            </BackgroundContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.isLoading,
        errorMessage: state.errorMessage,
    };
};

export default connect(mapStateToProps)(AccountConfirmSignUp);
