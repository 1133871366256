import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import colors from "utils/colors";
import { FiChevronRight, FiArrowRight, FiCalendar, FiPlus, FiFileText } from "react-icons/fi";
import { IoIosPaper } from "react-icons/io";
import moment from "moment";
import { fetchUnits } from "reducers/unitReducer";
import { currencyFormat } from "utils/InputPatterns";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import { ContentContainer, FlexWrapContainer, PesoSign } from "styles/Containers";
import { TextLink } from "styles/Buttons";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableHeader,
    TableRow,
    TableCell
} from "styles/Tables";

const CardContainer = styled.div`
    display: flex;
    height: 190px;
    width: 300px;
    border-radius: 8px;
    margin: 12px 12px 0 0;
    cursor: pointer;
`;

const CardNavigationContainer = styled.div`
    height: 190px;
    width: 24px;
    border-radius: 0 8px 8px 0;
    padding: 75px 4px;
    color: white;

    ${props =>
        props.isOwner
            ? css`
                  background-color: darkcyan;
              `
            : css`
                  background-color: ${colors.BLUE};
              `}
        }
`;

const CardBodyContainer = styled.div`
    height: 190px;
    width: 276px;
    border: solid 0.5px #ddd;
    border-radius: 8px 0 0 8px;
    padding: 25px;
`;

const CardBodyDetailsContainer = styled.div`
`;

const CardBodySeparator = styled.div`
    height: 1px;
    border: solid 0.5px #ddd;
    margin: 12px 0;
`;

const CardBodyDueContainer = styled.div`
    height: 60px;

    svg {
        color: ${colors.BLUE};
        height: 18px;
        width: 18px;
        margin-right: 8px;
    }
`;

const Separator = styled.div`
    height: 1px;
    border: solid 0.5px #ddd;
    margin: 40px 0 20px 0;
`;

const UnitHeader = styled(TableTextHeaderContainer)`
    width: 20%;
    min-width: 250px;
    align-items: baseline;
`

class AccountUnitsContainer extends Component {
    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchUnits()).then(units =>
                this.setState({ filteredUnits: units || [] })
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
    }

    render() {
        const { units, requisitions, isLoading } = this.props;

        const unitCards = units.map(unit => (
            <Link
                key={unit.id}
                to={{
                    pathname: `/account/units/${unit.id}`,
                    state: {
                        backTo: {
                            label: "Back to Units",
                            value: "/account/units"
                        }
                    }
                }}
                style={{ color: "black", textDecoration: "none" }}
            >
                <CardContainer>
                    <CardBodyContainer>
                        <CardBodyDetailsContainer>
                            <div>{unit.propertyName}</div>
                            <FlexWrapContainer>
                                <div style={{ width: `33%` }}>
                                    {unit.buildingName}
                                </div>
                                <div style={{ width: `65%` }}>
                                    Unit {unit.number}
                                </div>
                            </FlexWrapContainer>
                        </CardBodyDetailsContainer>
                        {unit.nearestBill && unit.nearestBill.totalAmountDue && (
                            <Fragment>
                                <CardBodySeparator />
                                <CardBodyDueContainer>
                                    <div style={{ marginBottom: `10px` }}>
                                        {unit.nearestBill.category}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FiCalendar />
                                        <div style={{ marginRight: `4px`,}}>
                                            Due Date:
                                        </div>
                                        <div>
                                            {moment(
                                                unit.nearestBill.dueDate
                                            ).format("MM/DD/YYYY")}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <FiFileText />
                                        <div style={{ marginRight: `4px`,}}>
                                            Amount Due:
                                        </div>
                                        <div>
                                            <PesoSign />
                                            {currencyFormat(
                                                unit.nearestBill.totalAmountDue
                                            )}
                                        </div>
                                    </div>
                                </CardBodyDueContainer>
                            </Fragment>
                        )}
                    </CardBodyContainer>
                    <CardNavigationContainer isOwner={unit.isOwner}>
                        <FiChevronRight />
                    </CardNavigationContainer>
                </CardContainer>
            </Link>
        ));

        const requisitionRows =
            requisitions &&
            requisitions.map((requisition, i) => (
                <TableRow key={requisition.id} gray={(i % 2 === 0).toString()}>
                    <TableCell>{requisition.propertyName}</TableCell>
                    <TableCell>{requisition.buildingName}</TableCell>
                    <TableCell>{requisition.unitNo}</TableCell>
                    <TableCell>{requisition.type}</TableCell>
                    <TableCell>{requisition.status}</TableCell>
                </TableRow>
            ));

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : units && units.length > 0 ? (
                    <ContentContainer>
                        <UnitHeader>
                            <TableTextHeader>Units</TableTextHeader>
                            <div style={{ marginTop: "20px" }}>
                                <Link
                                    to="/account/unitClaiming"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: colors.BLUE,
                                        textDecoration: "none"
                                    }}
                                >
                                    <FiPlus style={{
                                        marginBottom: `3px`,
                                        marginRight: `2px`,
                                    }}/>
                                        Claim a unit
                                </Link>
                            </div>
                        </UnitHeader>
                        {units.find(u => u.isOwner) &&
                            units.find(u => !u.isOwner) && (
                                <div style={{ display: "flex" }}>
                                    <div
                                        style={{
                                            height: "12px",
                                            width: "12px",
                                            backgroundColor: "darkcyan",
                                            margin: "3px 4px 0 0"
                                        }}
                                    />
                                    <span>Owner</span>
                                    <div
                                        style={{
                                            height: "12px",
                                            width: "12px",
                                            backgroundColor: colors.BLUE,
                                            margin: "3px 4px 0 20px"
                                        }}
                                    />
                                    <span>Tenant</span>
                                </div>
                            )}
                        <FlexWrapContainer>{unitCards}</FlexWrapContainer>
                        {requisitions && requisitions.length > 0 && (
                            <Fragment>
                                <Separator />
                                <TableTextHeaderContainer>
                                    <TableTextHeader>
                                        Requisitions
                                    </TableTextHeader>
                                </TableTextHeaderContainer>
                                <Table>
                                    <TableHeader>
                                        <TableCell>Property</TableCell>
                                        <TableCell>Building</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableHeader>
                                    {requisitionRows}
                                </Table>
                            </Fragment>
                        )}
                    </ContentContainer>
                ) : (
                    <ContentContainer>
                        This account has no units yet.{" "}
                        <TextLink to="/account/unitClaiming">
                            Claim one now
                        </TextLink>
                        .
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        units: state.units,
        requisitions: state.requisitions,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AccountUnitsContainer);
