import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "./Spinner";
import PageHeader from "./PageHeader";
import {
    Input,
    IconInputContainer,
    FiltersContainer,
    FilterLabel
} from "styles/Inputs";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination,
    EmptyListContainer,
    TableHeader,
} from "styles/Tables";
import { ContentContainer } from "styles/Containers";
import { FiSearch } from "react-icons/fi";
import { fetchTenants } from "reducers/tenantReducer";

const tenantsPerPage = 10;

class TenantsContainer extends Component {
    state = {
        filteredTenants: null,
        offset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchTenants()).then(tenants =>
                this.setState({ filteredTenants: tenants || [] })
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredTenants, offset } = this.state;
        this.setState({
            offset:
                offset < Math.ceil(filteredTenants.length / tenantsPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    handleFilterChange(name) {
        const { tenants } = this.props;

        const filteredTenants =
            tenants &&
            tenants.filter(tenant =>
                tenant.name.toLowerCase().includes(name.toLowerCase())
            );

        this.setState({
            filteredTenants: filteredTenants,
            offset: 1
        });
    }

    handleFilterEmailChange(email) {
        const { tenants } = this.props;

        const filteredTenants =
            tenants &&
            tenants.filter(tenant =>
                tenant.email.toLowerCase().includes(email.toLowerCase())
            );

        this.setState({
            filteredTenants: filteredTenants,
            offset: 1
        });
    }

    render() {
        const { tenants, isLoading, isAdmin } = this.props;
        const { filteredTenants, tenantModalVisible, offset } = this.state;
        const range = offset * tenantsPerPage;

        const tenantRows =
            filteredTenants &&
            filteredTenants.map((tenant, i) => {
                if (i >= range - tenantsPerPage && i < range) {
                    return (
                        <TableLinkRow
                            key={tenant.id}
                            to={`/${isAdmin ? "admin" : "account"}/tenants/${
                                tenant.id
                            }`}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{tenant.name}</TableCell>
                            <TableCell>{tenant.email}</TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : tenants && tenants.length > 0 ? (
                    <ContentContainer>
                        <TableTextHeaderContainer>
                            <TableTextHeader>Tenants</TableTextHeader>
                        </TableTextHeaderContainer>
                        <FiltersContainer>
                            <div style={{ width: "220px" }}>
                                <FilterLabel>Name</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. Joe"
                                        onChange={e =>
                                            this.handleFilterChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                            <div style={{ width: "220px" }}>
                                <FilterLabel>Email</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. joe@intelimina.com"
                                        onChange={e =>
                                            this.handleFilterEmailChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                        </FiltersContainer>
                        <div>
                            <Table>
                                <TableHeader>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell></TableCell>
                                </TableHeader>
                                {tenantRows}
                            </Table>
                            {filteredTenants &&
                                filteredTenants.length > tenantsPerPage && (
                                    <Pagination
                                        itemsPerPage={tenantsPerPage}
                                        offset={offset}
                                        length={filteredTenants.length}
                                        handlePreviousPage={() =>
                                            this.handlePreviousPage()
                                        }
                                        handleNextPage={() =>
                                            this.handleNextPage()
                                        }
                                    />
                                )}
                        </div>
                    </ContentContainer>
                ) : (
                    <EmptyListContainer>
                        There are no tenants yet.
                    </EmptyListContainer>
                )}
                {tenantModalVisible && (
                    <OwnerModal
                        modalVisible={tenantModalVisible}
                        closeModal={this.hideOwnerModal.bind(this)}
                        onSubmit={this.handleNewOwnerSubmit.bind(this)}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        tenants: state.tenants,
        isLoading: state.isLoading,
        isAdmin: state.isAdmin,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(TenantsContainer);
