import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import colors from "utils/colors";
import { currencyFormat } from "utils/InputPatterns";
import { FlexContainer, PesoSign } from "styles/Containers";
import { InputLabel, ButtonSelect } from "styles/Inputs";

const OverviewContainer = styled.div`
    width: 100%;
    padding: 8px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
`;

const periodOptions = [
    {
        label: "this year",
        value: moment().format("YYYY")
    },
    {
        label: "this month",
        value: moment().format("MM/YYYY")
    },
    {
        label: "today",
        value: moment()
    }
];

class AdminBillsOverview extends Component {
    state = {
        period: {
            label: "this year",
            value: moment().format("YYYY")
        }
    };

    render() {
        const { bills } = this.props;
        const { period } = this.state;

        const collectibles = bills
            ? bills.reduce(
                  (acc, curr) =>
                      isBillInPeriod(curr, period)
                          ? acc + (Number(curr.totalAmountDue) || 0)
                          : acc,
                  0
              )
            : 0;

        const paid = bills
            ? bills.reduce(
                  (acc, curr) =>
                      isBillInPeriod(curr, period)
                          ? acc + (Number(curr.totalAmountPaid) || 0)
                          : acc,
                  0
              )
            : 0;

        const unpaid = bills
            ? bills.reduce(
                  (acc, curr) =>
                      isBillInPeriod(curr, period) && curr.status !== "Overdue"
                          ? acc + (Number(curr.remainingBalance) || 0)
                          : acc,
                  0
              )
            : 0;

        return (
            <OverviewContainer>
                <FlexContainer>
                    <span>Dues as of</span>
                    <ButtonSelect
                        value={period}
                        options={periodOptions}
                        onChange={selectedPeriod =>
                            this.setState({ period: selectedPeriod })
                        }
                        buttonStyle={{
                            height: "20px",
                            minWidth: "100px",
                            marginLeft: "5px",
                            backgroundColor: "white",
                            fontSize: "13px",
                            color: colors.LIGHT_BLACK,
                            border: "solid 0.5px rgba(0, 0, 0, 0.2)"
                        }}
                    />
                </FlexContainer>
                <FlexContainer style={{ marginTop: "5px" }}>
                    <div>
                        <InputLabel>Collectibles</InputLabel>
                        <PesoSign />
                        <span>{currencyFormat(collectibles)}</span>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                        <InputLabel>Paid</InputLabel>
                        <PesoSign />
                        <span>{currencyFormat(paid)}</span>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                        <InputLabel>Unpaid</InputLabel>
                        <PesoSign />
                        <span>{currencyFormat(unpaid)}</span>
                    </div>
                </FlexContainer>
            </OverviewContainer>
        );
    }
}

const isBillInPeriod = (bill, period) =>
    (period.label === "this year" &&
        moment(bill.billDate).format("YYYY") === period.value) ||
    (period.label === "this month" &&
        moment(bill.billDate).format("MM/YYYY") === period.value) ||
    (period.label === "today" &&
        moment(bill.billDate).format("MM/DD/YYYY") ===
            period.value.format("MM/DD/YYYY"));

const mapStateToProps = state => {
    return {
        bills: state.bills
    };
};

export default connect(mapStateToProps)(AdminBillsOverview);
