import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import {
    FiBarChart,
    FiChevronDown,
    FiChevronUp,
    FiSearch,
    FiFilter,
} from "react-icons/fi";
import colors from "utils/colors";
import { currencyFormat } from "utils/InputPatterns";
import { AccountMainHeader, PesoSign } from "styles/Containers";
import { fetchAmenities } from "reducers/amenityReducer";
import {
    ContentContainer,
    FlexContainer,
    FlexWrapContainer,
    LinkContainer
} from "styles/Containers";
import {
    Input,
    IconInputContainer,
    DropdownMenu,
    DropdownOption
} from "styles/Inputs";
import { Pagination } from "styles/Tables";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";

const FiltersContainer = styled.div`
    height: 60px;
    // width: 70%;
    padding: 14px 20px;
    margin-bottom: 50px;
    border: solid 0.5px ${colors.LIGHT_GRAY};
    border-radius: 30px;
    box-shadow: 0 2px 8px -6px rgba(0, 0, 0, 0.2);
`;

const DropdownContainer = styled.div``;

const DropdownButton = styled.button`
    height: 30px;
    min-width: 160px;
    padding: 5px;
    margin-right: 10px;
    border: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props =>
            props.focused &&
            css`
                color: white;
                background-color: ${colors.SECONDARY};
                border-radius: 15px;
            `}
        :focus {
        outline: none;
    }
`;

const AmenityThumbnail = styled.img`
    height: 175px;
    width: 250px;
    border-radius: 6px;
    margin-right: 18px;
    cursor: pointer;
`;

const AmenityThumbnailDetailsContainer = styled.div`
    padding-left: 4px;
    margin-bottom: 18px;
`;

const MobileContainer = styled.div`
    ${props =>
        props.showOnMobile
            ? `
            @media screen and (min-width: 481px) {
                display: none;
            }
            margin-bottom: 0.5rem;
            input {
                width: 100%;
            }
        `
            : `
            @media screen and (max-width:480px) {
                display: none;
            }
        `}
`;

const SeachBar = ({ onChange, showOnMobile }) => (
    <MobileContainer showOnMobile={showOnMobile}>
        <IconInputContainer>
            <FiSearch style={{
                    fontSize: "18px",
                }}/>
            <Input
                style={{
                    height: "30px",
                    width: showOnMobile ? "100%" : "200px",
                    border: "solid 0.5px #a2a2a280",
                    borderRadius: "15px"
                }}
                placeholder="Search amenities"
                onChange={onChange}
            />
        </IconInputContainer>
    </MobileContainer>
);

const amenitiesPerPage = 9;

class AccountAmenitiesContainer extends Component {
    state = {
        filter: {
            property: "",
            name: ""
        },
        filteredAmenities: null,
        propertyFilterDropdownVisible: false,
        offset: 1
    };

    constructor(props) {
        super(props);
        this.hidePropertyFilterDropdown = this.hidePropertyFilterDropdown.bind(
            this
        );
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchAmenities()).then(amenities =>
                this.setState({
                    filteredAmenities: amenities || []
                })
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("click", this.hidePropertyFilterDropdown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredAmenities, offset } = this.state;
        this.setState({
            offset:
                offset < Math.ceil(filteredAmenities.length / amenitiesPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    showPropertyFilterDropdown(e) {
        e.preventDefault();

        this.setState({ propertyFilterDropdownVisible: true }, () => {
            document.addEventListener("click", this.hidePropertyFilterDropdown);
        });
    }

    hidePropertyFilterDropdown(e) {
        this.setState({ propertyFilterDropdownVisible: false }, () => {
            document.removeEventListener(
                "click",
                this.hidePropertyFilterDropdown
            );
        });
    }

    handleFilterPropertyChange(property) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                property
            }),
            filteredAmenities: this.filterAmenities({
                property,
                name: filter.name
            }),
            offset: 1
        });
    }

    handleFilterNameChange(name) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                name
            }),
            filteredAmenities: this.filterAmenities({
                property: filter.property,
                name
            }),
            offset: 1
        });
    }

    filterAmenities({ property, name }) {
        const { amenities } = this.props;

        return (
            amenities &&
            amenities.filter(
                amenity =>
                    (property
                        ? amenity.propertyName
                              .toLowerCase()
                              .includes(property.toLowerCase())
                        : true) &&
                    (name
                        ? amenity.name
                              .toLowerCase()
                              .includes(name.toLowerCase())
                        : true)
            )
        );
    }

    render() {
        const { propertyNames, isLoading } = this.props;
        const {
            filter,
            filteredAmenities,
            propertyFilterDropdownVisible,
            offset
        } = this.state;
        const range = offset * amenitiesPerPage;

        const propertyFilterOptions = propertyNames.map(po => (
            <DropdownOption
                onClick={() => this.handleFilterPropertyChange(po)}
                key={po}
            >
                {po}
            </DropdownOption>
        ));

        const amenityThumbnails =
            filteredAmenities &&
            filteredAmenities.map((amenity, i) => {
                if (i >= range - amenitiesPerPage && i < range) {
                    return (
                        <LinkContainer
                            to={`/account/amenities/${amenity.id}`}
                            key={amenity.id}
                        >
                            <AmenityThumbnail src={amenity.thumbnail} />
                            <AmenityThumbnailDetailsContainer>
                                <div
                                    style={{
                                        color: colors.GRAY,
                                        opacity: "0.5",
                                        fontSize: "10px"
                                    }}
                                >
                                    {amenity.propertyName}
                                </div>
                                <div
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {amenity.name}
                                </div>
                                <div
                                    style={{
                                        color: colors.GRAY,
                                        fontSize: "10px"
                                    }}
                                >
                                    <PesoSign />{currencyFormat(amenity.rate)}/hour
                                </div>
                            </AmenityThumbnailDetailsContainer>
                        </LinkContainer>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ContentContainer>
                        <SeachBar
                            onChange={e =>
                                this.handleFilterNameChange(e.target.value)
                            }
                            showOnMobile
                        />
                        <FiltersContainer>
                            <FlexContainer>
                                <DropdownContainer>
                                    <DropdownButton
                                        focused={propertyFilterDropdownVisible}
                                        onClick={e =>
                                            this.showPropertyFilterDropdown(e)
                                        }
                                    >
                                        <FiFilter
                                            style={{
                                                margin: "0 6px -1px 0",
                                                fontSize: "18px",
                                            }}
                                        />
                                        <span style={{ fontSize: "12px" }}>
                                            {filter.property ||
                                                "Search by Property"}
                                        </span>
                                        {propertyFilterDropdownVisible ? (
                                            <FiChevronUp
                                                style={{
                                                    margin: "0 0 -2px 6px"
                                                }}
                                            />
                                        ) : (
                                            <FiChevronDown
                                                style={{
                                                    margin: "0 0 -2px 6px"
                                                }}
                                            />
                                        )}
                                    </DropdownButton>
                                    {propertyFilterDropdownVisible && (
                                        <DropdownMenu
                                            style={{
                                                width: "unset",
                                                maxHeight: "200px",
                                                minWidth: "160px"
                                            }}
                                        >
                                            <DropdownOption
                                                onClick={() =>
                                                    this.handleFilterPropertyChange(
                                                        ""
                                                    )
                                                }
                                            >
                                                All
                                            </DropdownOption>
                                            {propertyFilterOptions}
                                        </DropdownMenu>
                                    )}
                                </DropdownContainer>
                                <SeachBar
                                    onChange={e =>
                                        this.handleFilterNameChange(
                                            e.target.value
                                        )
                                    }
                                    showOnMobile={false}
                                />
                            </FlexContainer>
                        </FiltersContainer>
                        <AccountMainHeader>Explore amenities</AccountMainHeader>
                        <FlexWrapContainer>{amenityThumbnails}</FlexWrapContainer>
                        {filteredAmenities &&
                            filteredAmenities.length > amenitiesPerPage && (
                                <Pagination
                                    itemsPerPage={amenitiesPerPage}
                                    offset={offset}
                                    length={filteredAmenities.length}
                                    handlePreviousPage={() =>
                                        this.handlePreviousPage()
                                    }
                                    handleNextPage={() => this.handleNextPage()}
                                />
                            )}
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        amenities: state.amenities,
        propertyNames: state.amenities && [
            ...new Set(state.amenities.map(a => a.propertyName))
        ],
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AccountAmenitiesContainer);
