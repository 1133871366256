import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";

const FETCH_RESERVATIONS = "FETCH_RESERVATIONS";
const FETCH_RESERVATIONS_SUCCESS = "FETCH_RESERVATIONS_SUCCESS";
const FETCH_RESERVATIONS_FAIL = "FETCH_RESERVATIONS_FAIL";

const FETCH_RESERVATION = "FETCH_RESERVATION";
const FETCH_RESERVATION_SUCCESS = "FETCH_RESERVATION_SUCCESS";
const FETCH_RESERVATION_FAIL = "FETCH_RESERVATION_FAIL";

const APPROVE_RESERVATION = "APPROVE_RESERVATION";
const APPROVE_RESERVATION_SUCCESS = "APPROVE_RESERVATION_SUCCESS";
const APPROVE_RESERVATION_FAIL = "APPROVE_RESERVATION_FAIL";

const DENY_RESERVATION = "DENY_RESERVATION";
const DENY_RESERVATION_SUCCESS = "DENY_RESERVATION_SUCCESS";
const DENY_RESERVATION_FAIL = "DENY_RESERVATION_FAIL";

const CANCEL_RESERVATION = "CANCEL_RESERVATION";
const CANCEL_RESERVATION_SUCCESS = "CANCEL_RESERVATION_SUCCESS";
const CANCEL_RESERVATION_FAIL = "CANCEL_RESERVATION_FAIL";

export const reservationActions = {
    FETCH_RESERVATIONS,
    FETCH_RESERVATIONS_SUCCESS,
    FETCH_RESERVATIONS_FAIL,
    FETCH_RESERVATION,
    FETCH_RESERVATION_SUCCESS,
    FETCH_RESERVATION_FAIL,
    APPROVE_RESERVATION,
    APPROVE_RESERVATION_SUCCESS,
    APPROVE_RESERVATION_FAIL,
    DENY_RESERVATION,
    DENY_RESERVATION_SUCCESS,
    DENY_RESERVATION_FAIL,
    CANCEL_RESERVATION,
    CANCEL_RESERVATION_SUCCESS,
    CANCEL_RESERVATION_FAIL
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case APPROVE_RESERVATION_SUCCESS:
            return Object.assign({}, state, {
                status: "Approved"
            });
        case DENY_RESERVATION_SUCCESS:
            return Object.assign({}, state, {
                status: "Denied",
                remarks: payload.remarks
            });
        case CANCEL_RESERVATION_SUCCESS:
            return Object.assign({}, state, {
                status: "Cancelled"
            });
        default:
            return state;
    }
};

export const fetchReservations = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_RESERVATIONS });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/reservations`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_RESERVATIONS_SUCCESS,
                        payload: {
                            reservations: res.reservations,
                            payments: res.reservationPayments
                        }
                    });
                    return res.reservations;
                } else {
                    dispatch({ type: FETCH_RESERVATIONS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_RESERVATIONS_FAIL });
            });
    };
};

export const fetchReservation = reservationId => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_RESERVATION });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/reservation`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    id: reservationId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_RESERVATION_SUCCESS,
                        payload: res.reservation
                    });
                } else {
                    dispatch({ type: FETCH_RESERVATION_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_RESERVATION_FAIL });
            });
    };
};

export const approveReservation = id => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: APPROVE_RESERVATION });
        return axios
            .request({
                url: `/api/admin/approve_reservation`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    id
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: APPROVE_RESERVATION_SUCCESS
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully approved reservation.");
                } else {
                    dispatch({ type: APPROVE_RESERVATION_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: APPROVE_RESERVATION_FAIL });
            });
    };
};

export const denyReservation = ({ id, remarks }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: DENY_RESERVATION });
        return axios
            .request({
                url: `/api/admin/deny_reservation`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    id,
                    remarks
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: DENY_RESERVATION_SUCCESS,
                        payload: { remarks }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully denied reservation.");
                } else {
                    dispatch({ type: DENY_RESERVATION_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: DENY_RESERVATION_FAIL });
            });
    };
};

export const cancelReservation = id => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: CANCEL_RESERVATION });
        return axios
            .request({
                url: `/api/account/cancel_reservation`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    id
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: CANCEL_RESERVATION_SUCCESS
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully cancelled reservation.");
                } else {
                    dispatch({ type: CANCEL_RESERVATION_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: CANCEL_RESERVATION_FAIL });
            });
    };
};
