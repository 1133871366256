import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PageHeader from "containers/PageHeader";
import { Spinner } from "containers/Spinner";
import { ContentContainer } from "styles/Containers";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableRow,
    TableRowViewAction,
    TableCell,
    Pagination,
    EmptyListContainer
} from "styles/Tables";
import { Button } from "styles/Buttons";
import {
    markAsRead,
    markAllAsRead,
    notificationRedirect
} from "reducers/notificationReducer";
import moment from "moment";

const notificationsPerPage = 10;

class NotificationsContainer extends Component {
    state = {
        notificationsOffset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { notifications } = this.props;
        const { notificationsOffset } = this.state;
        this.setState({
            notificationsOffset:
                notificationsOffset <
                Math.ceil(notifications.length / notificationsPerPage)
                    ? notificationsOffset + 1
                    : notificationsOffset
        });
    }

    handlePreviousPage() {
        const { notificationsOffset } = this.state;
        this.setState({
            notificationsOffset:
                notificationsOffset > 1
                    ? notificationsOffset - 1
                    : notificationsOffset
        });
    }

    handleNotificationClick(notification) {
        const { isAdmin, dispatch, history } = this.props;
        if (notification.status === "Unread") {
            dispatch(markAsRead({ notification }, history));
        } else {
            notificationRedirect({ notification, isAdmin }, history);
        }
    }

    handleMarkAllAsRead() {
        const { dispatch } = this.props;
        dispatch(markAllAsRead());
    }

    render() {
        const { notifications, isLoading } = this.props;
        const { notificationsOffset } = this.state;

        const notificationsRange = notificationsOffset * notificationsPerPage;

        const notificationRows =
            notifications &&
            notifications.map((notification, i) => {
                if (
                    i >= notificationsRange - notificationsPerPage &&
                    i < notificationsRange
                ) {
                    return (
                        <TableRow
                            key={"notification_" + i}
                            gray={notification.status === "Read"}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                this.handleNotificationClick(notification)
                            }
                        >
                            <TableCell style={{ borderRight: `1px solid #ddd`, width: `10%` }}>
                                {moment(notification.date).format("MM/DD/YY, HH:MMa")}
                            </TableCell>
                            <TableCell>{notification.text}</TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableRow>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : notifications && notifications.length > 0 ? (
                    <ContentContainer>
                        <TableTextHeaderContainer>
                            <TableTextHeader>Notifications</TableTextHeader>
                            <Button onClick={() => this.handleMarkAllAsRead()}>
                                Mark All as Read
                            </Button>
                        </TableTextHeaderContainer>
                        <Table>{notificationRows}</Table>
                        {notifications &&
                            notifications.length > notificationsPerPage && (
                                <Pagination
                                    itemsPerPage={notificationsPerPage}
                                    offset={notificationsOffset}
                                    length={notifications.length}
                                    handlePreviousPage={() =>
                                        this.handlePreviousPage()
                                    }
                                    handleNextPage={() => this.handleNextPage()}
                                />
                            )}
                    </ContentContainer>
                ) : (
                    <EmptyListContainer>
                        There are no notifications yet.
                    </EmptyListContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications,
        isAdmin: state.isAdmin,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(NotificationsContainer);
