import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { fetchOverview } from "reducers/userReducer";
import { currencyFormat } from "utils/InputPatterns";
import { Spinner } from "containers/Spinner";
import colors from "utils/colors";
import PageHeader from "containers/PageHeader";
import {
    ContentContainer,
    FlexWrapContainer,
    HorizontalDivider,
    PesoSign
} from "styles/Containers";
import { BillStatus } from "styles/Status";
import moment from "moment";
import {
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination
} from "styles/Tables";

const billsPerPage = 5;
const reservationsPerPage = 6;
const unitsPerPage = 6;

const DashboardHeader = styled.div`
    display: flex;
    height: 46px;
    margin-bottom: 20px;

    > div:first-child {
        display: flex;
        align-items: center;
        color: ${colors.PRIMARY};
        font-weight: 600;
        white-space: nowrap;
        border-style: solid;
        border-color: ${colors.SECONDARY};
        border-width: 0 0 4px 0;
    }

    > div:last-child {
        width: 100%;
        height: 46px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.2);
        border-width: 0 0 0.5px 0;
    }
`;

const RemainingBalanceCard = styled.div`
    padding: 20px;
    background-color: ${colors.LIGHTER_GRAY};
    height: 150px;
    width: 400px;
    border-radius: 8px;
`;

const RemainingBalanceContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
    color: ${colors.PRIMARY};
    font-size: 28px;
    font-weight: 500;
`;

const CardContainer = styled.div`
    min-height: 180px;
    width: 250px;
    border-radius: 8px;
    margin: 0 12px 12px 0;
    padding: 10px 15px 20px 15px;
    background-color: ${colors.LIGHTER_GRAY};
    cursor: pointer;
`;

const ViewButtonContainer = styled.button`
    position: relative;
    top: 5px;
    left: 200px;
    height: 15px;
    background-color: ${colors.LIGHTER_GRAY};
    color: ${colors.BLUE};
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    border: none;

    :focus {
        outline: none;
    }
`;

const CardHeader = styled.div`
    font-size: large;
    font-weight: 600;
    color: ${colors.BLACK};
`;

class AccountDashboardContainer extends Component {
    state = {
        billsOffset: 1,
        reservationsOffset: 1,
        unitsOffset: 1
    };

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchOverview());
        }
    }

    billsHandleNextPage() {
        const { overview } = this.props;
        const { billsOffset } = this.state;
        const billsLength =
            overview && overview.bills ? overview.bills.length : 0;
        this.setState({
            billsOffset:
                billsOffset < Math.ceil(billsLength / billsPerPage)
                    ? billsOffset + 1
                    : billsOffset
        });
    }

    billsHandlePreviousPage() {
        const { billsOffset } = this.state;
        this.setState({
            billsOffset: billsOffset > 1 ? billsOffset - 1 : billsOffset
        });
    }

    reservationsHandleNextPage() {
        const { overview } = this.props;
        const { reservationsOffset } = this.state;
        const reservationsLength =
            overview && overview.reservations ? overview.bills.length : 0;
        this.setState({
            reservationsOffset:
                reservationsOffset <
                Math.ceil(reservationsLength / reservationsPerPage)
                    ? reservationsOffset + 1
                    : reservationsOffset
        });
    }

    reservationsHandlePreviousPage() {
        const { reservationsOffset } = this.state;
        this.setState({
            reservationsOffset:
                reservationsOffset > 1
                    ? reservationsOffset - 1
                    : reservationsOffset
        });
    }

    unitsHandleNextPage() {
        const { overview } = this.props;
        const { unitsOffset } = this.state;
        const unitsLength =
            overview && overview.reservations ? overview.bills.length : 0;
        this.setState({
            unitsOffset:
                unitsOffset < Math.ceil(unitsLength / unitsPerPage)
                    ? unitsOffset + 1
                    : unitsOffset
        });
    }

    unitsHandlePreviousPage() {
        const { unitsOffset } = this.state;
        this.setState({
            unitsOffset: unitsOffset > 1 ? unitsOffset - 1 : unitsOffset
        });
    }

    render() {
        const { overview, isLoading } = this.props;
        const { billsOffset, reservationsOffset, unitsOffset } = this.state;
        const billsLength =
            overview && overview.bills ? overview.bills.length : 0;
        const reservationsLength =
            overview && overview.reservations
                ? overview.reservations.length
                : 0;
        const unitsLength =
            overview && overview.units ? overview.units.length : 0;

        const totalRemainingBalance =
            overview && overview.bills
                ? overview.bills.reduce(
                      (acc, curr) => acc + (Number(curr.remainingBalance) || 0),
                      0
                  )
                : 0;

        return (
            <Fragment>
                <PageHeader title="Dashboard" />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ContentContainer>
                        <DashboardHeader>
                            <div>Remaining Balance</div>
                            <div />
                        </DashboardHeader>
                        <RemainingBalanceCard>
                            <span>As of {moment().format("MMMM D, YYYY")}</span>
                            <RemainingBalanceContainer>
                                <PesoSign /> {currencyFormat(totalRemainingBalance)}
                            </RemainingBalanceContainer>
                        </RemainingBalanceCard>
                        {billsLength > 0 && (
                            <Fragment>
                                <DashboardHeader style={{ marginTop: "20px" }}>
                                    <div>Pending Bills</div>
                                    <div />
                                </DashboardHeader>
                                <Table>
                                    <TableHeader
                                        style={{
                                            backgroundColor: colors.LIGHTER_GRAY
                                        }}
                                    >
                                        <TableCell>Due Date</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell
                                            style={{ textAlign: "right" }}
                                        >
                                            Remaining Balance
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: "center" }}
                                        >
                                            Status
                                        </TableCell>
                                        <TableCell />
                                    </TableHeader>
                                    <BillRows
                                        bills={overview && overview.bills}
                                        range={billsOffset * billsPerPage}
                                    />
                                </Table>
                                {billsLength > billsPerPage && (
                                    <Pagination
                                        itemsPerPage={billsPerPage}
                                        offset={billsOffset}
                                        length={billsLength}
                                        handlePreviousPage={() =>
                                            this.billsHandlePreviousPage()
                                        }
                                        handleNextPage={() =>
                                            this.billsHandleNextPage()
                                        }
                                    />
                                )}
                            </Fragment>
                        )}
                        {reservationsLength > 0 && (
                            <Fragment>
                                <DashboardHeader style={{ marginTop: "20px" }}>
                                    <div>Upcoming Reservations</div>
                                    <div />
                                </DashboardHeader>
                                <FlexWrapContainer>
                                    <ReservationCards
                                        reservations={
                                            overview && overview.reservations
                                        }
                                        range={
                                            reservationsOffset *
                                            reservationsPerPage
                                        }
                                    />
                                </FlexWrapContainer>
                                {reservationsLength > reservationsPerPage && (
                                    <Pagination
                                        itemsPerPage={reservationsPerPage}
                                        offset={reservationsOffset}
                                        length={reservationsLength}
                                        handlePreviousPage={() =>
                                            this.reservationsHandlePreviousPage()
                                        }
                                        handleNextPage={() =>
                                            this.reservationsHandleNextPage()
                                        }
                                    />
                                )}
                            </Fragment>
                        )}
                        {unitsLength > 0 && (
                            <Fragment>
                                <DashboardHeader
                                    style={{
                                        marginTop:
                                            reservationsLength > 0
                                                ? "8px"
                                                : "20px"
                                    }}
                                >
                                    <div>Units</div>
                                    <div />
                                </DashboardHeader>
                                <FlexWrapContainer>
                                    <UnitCards
                                        units={overview && overview.units}
                                        range={unitsOffset * unitsPerPage}
                                    />
                                </FlexWrapContainer>
                                {unitsLength > unitsPerPage && (
                                    <Pagination
                                        itemsPerPage={unitsPerPage}
                                        offset={unitsOffset}
                                        length={unitsLength}
                                        handlePreviousPage={() =>
                                            this.unitsHandlePreviousPage()
                                        }
                                        handleNextPage={() =>
                                            this.unitsHandleNextPage()
                                        }
                                    />
                                )}
                            </Fragment>
                        )}
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const BillRows = ({ bills, range }) =>
    bills &&
    bills.map((bill, i) => {
        if (i >= range - billsPerPage && i < range) {
            return (
                <TableLinkRow
                    key={bill.id}
                    to={{
                        pathname: `/account/bills/${bill.id}`,
                        state: {
                            backTo: {
                                label: "Back to Dashboard",
                                value: "/account"
                            }
                        }
                    }}
                >
                    <TableCell>
                        {bill.dueDate
                            ? moment(bill.dueDate).format("MM/DD/YYYY")
                            : ""}
                    </TableCell>
                    <TableCell>{bill.category}</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                        <PesoSign />
                        {(bill.remainingBalance &&
                            currencyFormat(bill.remainingBalance)) ||
                            "0.00"}
                    </TableCell>
                    <TableCell style={{ textAlign: "-webkit-center" }}>
                        <BillStatus status={bill.status}>
                            {bill.status}
                        </BillStatus>
                    </TableCell>
                    <TableRowViewAction text="VIEW" />
                </TableLinkRow>
            );
        }
    });

const ReservationCards = ({ reservations, range }) =>
    reservations &&
    reservations.map((reservation, i) => {
        if (i >= range - reservationsPerPage && i < range) {
            return (
                <Link
                    key={reservation.id}
                    to={{
                        pathname: `/account/reservations/${reservation.id}`,
                        state: {
                            backTo: {
                                label: "Back to Dashboard",
                                value: "/account"
                            }
                        }
                    }}
                    style={{ color: "black", textDecoration: "none" }}
                >
                    <CardContainer>
                        <ViewButtonContainer>
                            <FiEye />
                        </ViewButtonContainer>
                        <CardHeader>{reservation.amenityName}</CardHeader>
                        <div>{reservation.propertyName}</div>
                        <HorizontalDivider style={{ margin: "8px 0" }} />
                        {reservation.startDate && (
                            <div>
                                Start Date:{" "}
                                {moment(reservation.startDate).format(
                                    "MMMM D, YYYY"
                                )}
                            </div>
                        )}
                        {reservation.endDate && (
                            <div>
                                End Date:{" "}
                                {moment(reservation.endDate).format(
                                    "MMMM D, YYYY"
                                )}
                            </div>
                        )}
                        <div>Status: {reservation.status}</div>
                    </CardContainer>
                </Link>
            );
        }
    });

const UnitCards = ({ units, range }) =>
    units &&
    units.map((unit, i) => {
        if (i >= range - unitsPerPage && i < range) {
            return (
                <Link
                    key={unit.id}
                    to={{
                        pathname: `/account/units/${unit.id}`,
                        state: {
                            backTo: {
                                label: "Back to Dashboard",
                                value: "/account"
                            }
                        }
                    }}
                    style={{ color: "black", textDecoration: "none" }}
                >
                    <CardContainer style={{ minHeight: "100px", width: "220px" }}>
                        <ViewButtonContainer style={{ left: "170px" }}>
                            <FiEye />
                        </ViewButtonContainer>
                        <CardHeader>Unit {unit.number}</CardHeader>
                        <div>{unit.propertyName}</div>
                    </CardContainer>
                </Link>
            );
        }
    });

const mapStateToProps = state => {
    return {
        overview: state.overview,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AccountDashboardContainer);
