import React from "react";
import styled, { css } from "styled-components";
import colors from "../utils/colors";

const ActivityIndicatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    ${props =>
        props.height &&
        css`
            height: ${props.height};
        `}
`;

const LoaderContainer = styled.div`
    position: relative;
    width: 64px;
    height: 16px;

    div {
        position: absolute;
        width: 8px;
        height: 8px;
        background: ${colors.BLUE_GREEN};
        border-radius: 50%;
        transition: all 2s ease 0s;
        animation: 1s ease-in-out infinite normal none running animation-fade;
    }

    div:nth-child(1) {
        animation-delay: 0.25s;
        top: 8px;
        left: 0;
    }

    div:nth-child(2) {
        animation-delay: 0.5s;
        top: 8px;
        left: 16px;
    }

    div:nth-child(3) {
        animation-delay: 0.75s;
        top: 8px;
        left: 32px;
    }

    div:nth-child(4) {
        animation-delay: 1s;
        top: 8px;
        left: 48px;
    }

    @keyframes animation-fade {
        0% {
            opacity: 0.1;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.1;
        }
    }
`;

const LoadingText = styled.div`
    margin-top: 10px;
    font-size: 14px;
    color: ${colors.LIGHT_BLACK};
`;

export const Loader = ({ height, text }) => {
    return (
        <ActivityIndicatorContainer height={height}>
            <LoaderContainer>
                <div />
                <div />
                <div />
                <div />
            </LoaderContainer>
            {text && <LoadingText>{text}</LoadingText>}
        </ActivityIndicatorContainer>
    );
};
