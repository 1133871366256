import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import {
    FiCalendar,
    FiChevronLeft,
    FiChevronRight,
    FiPlus,
    FiX,
    FiCheck,
    FiCreditCard,
    FiEye
} from "react-icons/fi";
import { GoCalendar } from "react-icons/go";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import colors from "utils/colors";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import BookingModal from "./AdminBookingModal";
import BookingSuccessModal from "./AdminBookingSuccessModal";
import { fetchAmenity, fetchAmenitySchedule } from "reducers/amenityReducer";
import { ContentContainer,
    FlexContainer,
    FullHRContainer,
    AmenityTitle, } from "styles/Containers";
import { Button } from "styles/Buttons";

const CalendarIconContainer = styled.div`
    height: 40px;
    width: 40px;
    padding: 6px;
    color: white;
    background-color: ${colors.SECONDARY};
    border-radius: 5px;
    font-size: 28px;
`;

const HeaderContainer = styled.div`
    margin: -2px 0 0 10px;
`;

const TimesContainer = styled.div`
    max-height: 500px;
    width: 500px;
    margin-right: 20px;
    overflow: auto;
`;

const TimesUnavailableContainer = styled.div`
    height: 264px;
    width: 499px;
    margin-right: 20px;
    color: ${colors.GRAY};
    border: solid 0.5px ${colors.LIGHT_GRAY};
    text-align: center;
    padding: 125px 0;
`;

const TimesDateHeader = styled.div`
    height: 38px;
    background-color: ${colors.LIGHT_GRAY};
    text-align: center;
    padding: 6px 0;
    border: solid 0.5px ${colors.LIGHT_GRAY};
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1.5rem;
`;

const TimeContainer = styled.div`
    width: 100px;
    text-align: center;
    border-style: solid;
    border-width: 0 0.5px 0 0;
    border-color: ${colors.LIGHT_GRAY};
    padding: 6px 0;
`;

const TimeStatusContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    padding: 6px 0;
`;

const AddToBookingButton = styled.button`
    display: flex;
    align-items: center;
    background-color: white;
    border: none;
    font-weight: 400;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

const RemoveFromBookingButton = styled.button`
    display: flex;
    align-items: center;
    background-color: ${colors.BLUE_GREEN};
    border: none;
    font-weight: 400;
    color: white;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

const CalenderContainer = styled.div`
    height: 302px;
    width: 321px;
    border: solid 0.5px ${colors.LIGHT_GRAY};

    strong {
        font-size: 16px;
        font-weight: 600;
    }
`;

const GalleryImage = styled.img`
    height: 150px;
    width: 225px;
    border-radius: 6px;
    margin-right: 18px;
    cursor: pointer;
`;

const ZoomedImageContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #000000e0;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const ZoomedImage = styled.img`
    position: relative;
    height: 500px;
    width: 750px;
    border-radius: 6px;
`;

const HoverButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: white;

    > span:last-child {
        display: none;
    }

    :hover {
        > span:nth-child(2) {
            display: none;
        }

        > span:last-child {
            display: unset;
        }
    }

    :focus {
        outline: none;
    }
`;

class AdminAmenityContainer extends Component {
    state = {
        date: moment()
            .add(2, "days")
            .format("MMMM D, YYYY"),
        bookings: [],
        bookingModalVisible: false,
        bookingModalSuccessVisible: false,
        zoomImage: null
    };

    constructor(props) {
        super(props);
        this.closeZoomedImage = this.closeZoomedImage.bind(this);
    }

    componentDidMount() {
        const { amenityId, isAuthorized, dispatch } = this.props;
        const { date } = this.state;
        if (isAuthorized) {
            dispatch(fetchAmenity({ amenityId, date }));
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.closeZoomedImage);
    }

    dateChangeHandler(date) {
        const { amenityId, dispatch } = this.props;
        dispatch(fetchAmenitySchedule({ amenityId, date }));
        this.setState({ date: moment(date).format("MMMM D, YYYY") });
    }

    addToBookings(time) {
        const { date, bookings } = this.state;
        const dateTime = date + " " + time;
        const existingBooking = bookings.includes(dateTime);
        if (!existingBooking) {
            this.setState({
                bookings: bookings.concat(dateTime)
            });
        }
    }

    removeFromBookings(time) {
        const { date, bookings } = this.state;
        const dateTime = date + " " + time;
        this.setState({
            bookings: bookings.filter(b => b !== dateTime)
        });
    }

    closeBookingReservationSuccessModal() {
        const { amenityId, dispatch } = this.props;
        dispatch(fetchAmenity({ amenityId, date: moment().add(2, "days") }));
        this.setState({
            date: moment()
                .add(2, "days")
                .format("MMMM D, YYYY"),
            bookings: [],
            bookingModalSuccessVisible: false
        });
    }

    handleZoomImage(image, e) {
        e.preventDefault();
        this.setState({ zoomImage: image }, () => {
            document.addEventListener("click", this.closeZoomedImage);
        });
    }

    closeZoomedImage() {
        this.setState({ zoomImage: null }, () => {
            document.removeEventListener("click", this.closeZoomedImage);
        });
    }

    render() {
        const { amenity, amenitySchedule, isLoading } = this.props;
        const {
            date,
            bookings,
            bookingModalVisible,
            bookingModalSuccessVisible,
            zoomImage
        } = this.state;

        const timeRows =
            amenitySchedule && amenitySchedule.isAvailable ? (
                amenitySchedule.reservations.map((as, i) => (
                    <FlexContainer
                        key={"amenity_schedule_" + i}
                        style={{
                            height: "33px",
                            borderStyle: "solid",
                            borderWidth: "0 0.5px 0.5px 0.5px",
                            borderColor: colors.LIGHT_GRAY
                        }}
                    >
                        {as.status === "Reserved (Paid)" ? (
                            <Fragment>
                                <TimeContainer>{as.time}</TimeContainer>
                                <TimeStatusContainer>
                                    <Link
                                        style={{
                                            textDecoration: "none"
                                        }}
                                        to={{
                                            pathname: `/admin/reservations/${as.reservationId}`,
                                            state: {
                                                backTo: {
                                                    label: "Back to Amenity",
                                                    value: `/admin/amenities/${amenity.id}`
                                                }
                                            }
                                        }}
                                    >
                                        <HoverButton
                                            style={{
                                                color: colors.BLUE_GREEN
                                            }}
                                        >
                                            <FiEye
                                                style={{
                                                    fontWeight: "normal",
                                                    margin: "-2px 4px 0 0"
                                                }}
                                            />
                                            <span>
                                                RESERVED (PAID)
                                                {as.unitName &&
                                                    " - " + as.unitName}
                                            </span>
                                            <span>VIEW RESERVATION</span>
                                        </HoverButton>
                                    </Link>
                                </TimeStatusContainer>
                            </Fragment>
                        ) : as.status === "Pending" ? (
                            <Fragment>
                                <TimeContainer>{as.time}</TimeContainer>
                                <TimeStatusContainer>
                                    <Link
                                        style={{
                                            textDecoration: "none"
                                        }}
                                        to={{
                                            pathname: `/admin/reservations`,
                                            state: {
                                                filters: {
                                                    amenity: amenity.name
                                                }
                                            }
                                        }}
                                    >
                                        <HoverButton
                                            style={{
                                                color: colors.BLUE
                                            }}
                                        >
                                            <FiEye
                                                style={{
                                                    fontWeight: "normal",
                                                    margin: "-2px 4px 0 0"
                                                }}
                                            />
                                            <span>PENDING</span>
                                            <span>
                                                VIEW PENDING RESERVATIONS
                                            </span>
                                        </HoverButton>
                                    </Link>
                                </TimeStatusContainer>
                            </Fragment>
                        ) : as.status === "Approved (Pending Payment)" ? (
                            <Fragment>
                                <TimeContainer>{as.time}</TimeContainer>
                                <TimeStatusContainer>
                                    <Link
                                        style={{
                                            textDecoration: "none"
                                        }}
                                        to={{
                                            pathname: `/admin/bills/${as.billId}`,
                                            state: {
                                                backTo: {
                                                    label: "Back to Amenity",
                                                    value: `/admin/amenities/${amenity.id}`
                                                }
                                            }
                                        }}
                                    >
                                        <HoverButton
                                            style={{
                                                color: colors.PRIMARY
                                            }}
                                        >
                                            <FiCreditCard
                                                style={{
                                                    fontWeight: "normal",
                                                    margin: "-2px 4px 0 0"
                                                }}
                                            />
                                            <span>
                                                APPROVED (PENDING PAYMENT)
                                                {as.unitName &&
                                                    " - " + as.unitName}
                                            </span>
                                            <span>PAY NOW</span>
                                        </HoverButton>
                                    </Link>
                                </TimeStatusContainer>
                            </Fragment>
                        ) : as.status === "Open" ? (
                            bookings.includes(date + " " + as.time) ? (
                                <Fragment>
                                    <TimeContainer>{as.time}</TimeContainer>
                                    <TimeStatusContainer
                                        style={{
                                            backgroundColor: colors.BLUE_GREEN
                                        }}
                                    >
                                        <RemoveFromBookingButton
                                            onClick={() =>
                                                this.removeFromBookings(as.time)
                                            }
                                        >
                                            <span style={{
                                                fontSize: "14px",
                                            }}>
                                                BOOKING
                                            </span>
                                            <AiOutlineCloseCircle
                                                style={{
                                                    marginLeft: "6px",
                                                    fontSize: "18px"
                                                }}
                                            />
                                        </RemoveFromBookingButton>
                                    </TimeStatusContainer>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <TimeContainer>{as.time}</TimeContainer>
                                    <TimeStatusContainer>
                                        <AddToBookingButton
                                            onClick={() =>
                                                this.addToBookings(as.time)
                                            }
                                        >
                                            <FiPlus
                                                style={{
                                                    marginRight: "6px",
                                                    fontSize: "16px",
                                                    strokeWidth: "4",

                                                }}
                                            />
                                            <span>Click here to reserve</span>
                                        </AddToBookingButton>
                                    </TimeStatusContainer>
                                </Fragment>
                            )
                        ) : (
                            <Fragment>
                                <TimeContainer style={{ color: colors.GRAY }}>
                                    {as.time}
                                </TimeContainer>
                                <TimeStatusContainer>
                                    <span
                                        style={{
                                            color: colors.GRAY,
                                            fontWeight: "600"
                                        }}
                                    >
                                        Not Available
                                    </span>
                                </TimeStatusContainer>
                            </Fragment>
                        )}
                    </FlexContainer>
                ))
            ) : (
                <TimesUnavailableContainer>
                    No available times on this day.
                </TimesUnavailableContainer>
            );

        return (
            <Fragment>
                <PageHeader
                    title="Back to Amenities"
                    backTo="/admin/amenities"
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    amenity && (
                        <ContentContainer>
                            <FlexContainer
                                style={{
                                    width: "840px",
                                    justifyContent: "space-between"
                                }}
                            >
                                <FlexContainer>
                                    <CalendarIconContainer>
                                        <GoCalendar
                                            style={{ marginBottom: "2px" }}
                                        />
                                    </CalendarIconContainer>
                                    <HeaderContainer>
                                        <h3 style={{ margin: "0" }}>
                                            {amenity.name}: Choose Date and Time
                                        </h3>
                                        <div style={{ fontSize: "12px" }}>
                                            Choose and click preferred date to
                                            book reservation
                                        </div>
                                    </HeaderContainer>
                                </FlexContainer>
                                <Button
                                    onClick={() =>
                                        this.setState({
                                            bookingModalVisible: true
                                        })
                                    }
                                    disabled={bookings.length === 0}
                                >
                                    <span> Book Reservation</span>
                                    <MdKeyboardArrowRight
                                        style={{
                                            fontSize: "14px",
                                            marginLeft: "2px",
                                        }} />
                                </Button>
                            </FlexContainer>
                            <FlexContainer
                                style={{ width: "840px", marginTop: "30px" }}
                            >
                                <TimesContainer>
                                    <TimesDateHeader>
                                        <h4 style={{
                                            margin: "0",
                                            fontWeight: "600",
                                        }}>
                                            {moment(
                                                date,
                                                "MMMM D, YYYY"
                                            ).format("MMMM D, YYYY" + " - " + "dddd")}
                                        </h4>
                                    </TimesDateHeader>
                                    {timeRows}
                                </TimesContainer>
                                <CalenderContainer>
                                    <DayPickerSingleDateController
                                        hideKeyboardShortcutsPanel
                                        noBorder
                                        onDateChange={date =>
                                            this.dateChangeHandler(date)
                                        }
                                        focused={true}
                                        date={moment(date, "MMMM D, YYYY")}
                                        navPrev={
                                            <FiChevronLeft
                                                style={{
                                                    position: "absolute",
                                                    top: "28px",
                                                    left: "22px"
                                                }}
                                            />
                                        }
                                        navNext={
                                            <FiChevronRight
                                                style={{
                                                    position: "absolute",
                                                    top: "28px",
                                                    right: "22px"
                                                }}
                                            />
                                        }
                                        isOutsideRange={day =>
                                            day.isBefore(
                                                moment().add(2, "days"),
                                                "day"
                                            )
                                        }
                                    />
                                </CalenderContainer>
                            </FlexContainer>
                            <AmenityTitle>
                                About the space
                            </AmenityTitle>
                            <FullHRContainer />
                            <FlexContainer>
                                <p style={{
                                    fontSize: "12px",
                                    margin: "0",
                                }}>
                                    { amenity.description }
                                </p>
                            </FlexContainer>
                            <AmenityTitle>
                                Gallery
                            </AmenityTitle>
                            <FullHRContainer />
                            <FlexContainer>
                                <GalleryImage
                                    onClick={e =>
                                        this.handleZoomImage(
                                            amenity.thumbnail,
                                            e
                                        )
                                    }
                                    src={amenity.thumbnail}
                                />
                            </FlexContainer>
                            {bookingModalVisible && (
                                <BookingModal
                                    modalVisible={bookingModalVisible}
                                    bookings={bookings}
                                    onSuccess={() =>
                                        this.setState({
                                            bookingModalVisible: false,
                                            bookingModalSuccessVisible: true
                                        })
                                    }
                                    closeModal={() =>
                                        this.setState({
                                            bookingModalVisible: false
                                        })
                                    }
                                />
                            )}
                            {bookingModalSuccessVisible && (
                                <BookingSuccessModal
                                    modalVisible={bookingModalSuccessVisible}
                                    closeModal={() =>
                                        this.closeBookingReservationSuccessModal()
                                    }
                                />
                            )}
                            {zoomImage && (
                                <ZoomedImageContainer>
                                    <FiX
                                        style={{
                                            position: "absolute",
                                            top: "20px",
                                            right: "20px",
                                            fontSize: "25px",
                                            color: "#f5f5f5",
                                            cursor: "pointer"
                                        }}
                                    />
                                    <ZoomedImage src={zoomImage} />
                                </ZoomedImageContainer>
                            )}
                        </ContentContainer>
                    )
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        amenity: state.amenity,
        amenitySchedule: state.amenitySchedule,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AdminAmenityContainer);
