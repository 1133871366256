import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { FiMail, FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import { accountSignUp } from "reducers/userReducer";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import AppLogo from "../../../assets/images/logo.png";
import colors from "utils/colors";
import { isValidEmail } from "utils/InputPatterns";
import { messageActions } from "reducers/messageReducer";
import {
    BackgroundContainer,
    LoginContainer,
    Logo,
    LogoContainer,
    LoginHeader,
    LoginSubHeader,
    LoginFooterContainer,
    InfoContainer,
    LinkContainer
} from "styles/Containers";
import {
    InputLabel,
    IconInputContainer,
    IconInputIconContainer,
    Input,
    Select
} from "styles/Inputs";
import { PrimaryButton, TextLink } from "styles/Buttons";

const LogoImage = styled.img`
    width: 100px;
`;

const Header = styled.div`
    font-size: 18px;

    @media (max-width: 440px) {
        font-size: 15px;
    }
`;

class SignUp extends Component {
    state = {
        type: null,
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        secureTextPassword: true,
        secureTextConfirm: true,
        emailError: "",
        passwordError: "",
        confirmPasswordError: "",
        submitSuccess: false
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
    }

    async handleSubmit() {
        const { dispatch } = this.props;
        const { email, password, confirmPassword } = this.state;
        let hasError = false;

        if (!email || !isValidEmail(email)) {
            this.setState({ emailError: "Please enter valid email" });
            hasError = true;
        } else {
            this.setState({ emailError: "" });
        }

        if (!password || password.length < 8) {
            this.setState({
                passwordError: "Password must be atleast 8 characters"
            });
            hasError = true;
        } else if (password.includes(" ")) {
            this.setState({
                passwordError: "Password must not contain any whitespace"
            });
            hasError = true;
        } else {
            this.setState({ passwordError: "" });
        }

        if (password !== confirmPassword) {
            this.setState({
                confirmPasswordError: "Password did not match"
            });
            hasError = true;
        } else {
            this.setState({ confirmPasswordError: "" });
        }

        if (hasError) {
            dispatch({
                type: messageActions.CLEAR_MESSAGE
            });
        } else {
            await dispatch(accountSignUp(this.state));
            if (!this.props.errorMessage) {
                this.setState({ submitSuccess: true });
            }
        }
    }

    render() {
        const {
            type,
            email,
            secureTextPassword,
            secureTextConfirm,
            emailError,
            passwordError,
            confirmPasswordError,
            submitSuccess
        } = this.state;
        const { isLoading, errorMessage, dispatch } = this.props;

        const typeOptions = [
            { value: "Individual", label: "Individual" },
            { value: "Company", label: "Company" }
        ];

        return (
            <BackgroundContainer>
                <LoginContainer
                    height={!type || submitSuccess ? "500px" : "720px"}
                >
                    {submitSuccess ? (
                        <Fragment>
                            <Logo />
                            <LoginHeader>Sign Up Successful</LoginHeader>
                            <LoginSubHeader>
                                An email has been sent to your email address,{" "}
                                <span style={{ color: colors.BLUE }}>
                                    {email}.
                                </span>
                            </LoginSubHeader>
                            <LinkContainer to="/account/login">
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px"
                                    }}
                                    text={"GO BACK TO SIGN IN"}
                                />
                            </LinkContainer>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <LogoContainer>
                                <LogoImage src={AppLogo} />
                                <div style={{ marginLeft: "12px" }}>
                                    <Header
                                        style={{
                                            marginTop: "30px"
                                        }}
                                    >
                                        Welcome to OnlineDooze
                                    </Header>
                                    <Header style={{ fontWeight: "bold" }}>
                                        Sign Up for an Account
                                    </Header>
                                </div>
                            </LogoContainer>
                            {errorMessage ? (
                                <MessageContainer
                                    style={{
                                        textAlign: "center",
                                        margin: "15px 0"
                                    }}
                                />
                            ) : (
                                <div style={{ height: "50px" }} />
                            )}
                            <InputLabel required>
                                <span>Type</span>
                            </InputLabel>
                            <Select
                                value={type}
                                disabled={isLoading}
                                onChange={selectedType =>
                                    this.setState({
                                        type: selectedType
                                    })
                                }
                                options={typeOptions}
                            />
                            {type && (
                                <Fragment>
                                    {type.value === "Individual" ? (
                                        <InfoContainer>
                                            <div>
                                                <InputLabel required>
                                                    <span>First Name</span>
                                                </InputLabel>
                                                <Input
                                                    disabled={isLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            firstName:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <InputLabel required>
                                                    <span>Last Name</span>
                                                </InputLabel>
                                                <Input
                                                    disabled={isLoading}
                                                    onChange={e =>
                                                        this.setState({
                                                            lastName:
                                                                e.target.value
                                                        })
                                                    }
                                                />
                                            </div>
                                        </InfoContainer>
                                    ) : (
                                        <Fragment>
                                            <InputLabel
                                                style={{ marginTop: "15px" }}
                                                required
                                            >
                                                <span>Name</span>
                                            </InputLabel>
                                            <Input
                                                disabled={isLoading}
                                                onChange={e =>
                                                    this.setState({
                                                        name: e.target.value
                                                    })
                                                }
                                            />
                                        </Fragment>
                                    )}
                                    <InputLabel
                                        style={{ marginTop: "15px" }}
                                        required
                                    >
                                        <span>Email address</span>
                                        <span>{emailError}</span>
                                    </InputLabel>
                                    <IconInputContainer
                                        required={emailError}
                                        disabled={isLoading}
                                    >
                                        <FiMail />
                                        <Input
                                            type="email"
                                            style={{ height: "42px" }}
                                            disabled={isLoading}
                                            onChange={e =>
                                                this.setState({
                                                    email: e.target.value
                                                })
                                            }
                                            onKeyDown={e => {
                                                if (
                                                    e.key === "Enter" &&
                                                    this.refs
                                                ) {
                                                    e.preventDefault();
                                                    this.refs[
                                                        "password"
                                                    ].focus();
                                                }
                                            }}
                                        />
                                    </IconInputContainer>
                                    <InputLabel
                                        style={{ marginTop: "15px" }}
                                        required
                                    >
                                        <span>Password</span>
                                        <span>{passwordError}</span>
                                    </InputLabel>
                                    <IconInputIconContainer
                                        required={passwordError}
                                        disabled={isLoading}
                                    >
                                        <FiLock />
                                        <Input
                                            type={
                                                secureTextPassword
                                                    ? "password"
                                                    : "text"
                                            }
                                            style={{ height: "42px" }}
                                            disabled={isLoading}
                                            onChange={e =>
                                                this.setState({
                                                    password: e.target.value
                                                })
                                            }
                                        />
                                        {secureTextPassword ? (
                                            <FiEye
                                                onClick={() =>
                                                    this.setState({
                                                        secureTextPassword: !secureTextPassword
                                                    })
                                                }
                                            />
                                        ) : (
                                            <FiEyeOff
                                                onClick={() =>
                                                    this.setState({
                                                        secureTextPassword: !secureTextPassword
                                                    })
                                                }
                                            />
                                        )}
                                    </IconInputIconContainer>
                                    <InputLabel
                                        style={{ marginTop: "15px" }}
                                        required
                                    >
                                        <span>Retype Password</span>
                                        <span>{confirmPasswordError}</span>
                                    </InputLabel>
                                    <IconInputIconContainer
                                        required={confirmPasswordError}
                                        disabled={isLoading}
                                    >
                                        <FiLock />
                                        <Input
                                            type={
                                                secureTextConfirm
                                                    ? "password"
                                                    : "text"
                                            }
                                            style={{ height: "42px" }}
                                            disabled={isLoading}
                                            onChange={e =>
                                                this.setState({
                                                    confirmPassword:
                                                        e.target.value
                                                })
                                            }
                                        />
                                        {secureTextConfirm ? (
                                            <FiEye
                                                onClick={() =>
                                                    this.setState({
                                                        secureTextConfirm: !secureTextConfirm
                                                    })
                                                }
                                            />
                                        ) : (
                                            <FiEyeOff
                                                onClick={() =>
                                                    this.setState({
                                                        secureTextConfirm: !secureTextConfirm
                                                    })
                                                }
                                            />
                                        )}
                                    </IconInputIconContainer>
                                    {isLoading ? (
                                        <Loader text="PROCESSING..." />
                                    ) : (
                                        <PrimaryButton
                                            style={{
                                                height: "38px",
                                                width: "100%",
                                                marginTop: "20px",
                                                fontSize: "14px"
                                            }}
                                            onClick={() => this.handleSubmit()}
                                            text="SIGN UP"
                                        />
                                    )}
                                </Fragment>
                            )}
                            {!isLoading && (
                                <LoginFooterContainer>
                                    <div>Have an account?</div>
                                    <div>
                                        <TextLink
                                            onClick={() =>
                                                dispatch({
                                                    type:
                                                        messageActions.CLEAR_MESSAGE
                                                })
                                            }
                                            to="/account/login"
                                        >
                                            Login as Tenant/Owner
                                        </TextLink>
                                        {/* {" "}
                                        |{" "}
                                        <TextLink to="/account">
                                            Login as Guest
                                        </TextLink> */}
                                    </div>
                                </LoginFooterContainer>
                            )}
                        </Fragment>
                    )}
                </LoginContainer>
            </BackgroundContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading,
        errorMessage: state.errorMessage
    };
};

export default connect(mapStateToProps)(SignUp);
