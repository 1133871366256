import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import colors from "utils/colors";
import { currencyFormat } from "utils/InputPatterns";
import {
    Input,
    Select,
    FiltersContainer,
    FilterLabel,
    InputDatePicker,
    ClearableInputDatePickerContainer
} from "styles/Inputs";
import {
    Table,
    TableHeader,
    TableLinkRow,
    TableRowViewAction,
    TableCell,
    TableOverflowX,
    Pagination,
    EmptyListContainer
} from "styles/Tables";
import {
    TabHeaderContainer,
    TabNewItemContainer,
    ActiveTabHeader,
    InactiveTabHeader
} from "styles/Tabs";
import { exportCSV } from "actions/exportCSV";
import { DownloadButton } from "styles/Buttons";
import { PayStatus } from "styles/Status";
import {
    PesoSign,
} from "styles/Containers";

const paymentsPerPage = 10;

const filterStatusOptions = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "Inactive",
        label: "Inactive"
    },
    {
        value: "Processing",
        label: "Processing"
    },
    {
        value: "Completed",
        label: "Completed"
    },
    {
        value: "Failed",
        label: "Failed"
    }
];

const PaymentsGrandTotalContainer = styled.div`
    width: 100%;
    text-align: right;
    font-family: "PT Mono", monospace;
    font-size: small;
    margin-top: 12px;
    padding-right: 10px;
    color: ${colors.PRIMARY};
`;

class AdminBillsPaymentsContainer extends Component {
    state = {
        paymentsFilter: {
            building: "",
            unit: "",
            dateFrom: null,
            dateTo: null,
            status: {
                value: "All",
                label: "All"
            }
        },
        filteredPayments: null,
        paymentsOffset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { payments } = this.props;
        this.setState({ filteredPayments: payments || [] });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredPayments, paymentsOffset } = this.state;
        this.setState({
            paymentsOffset:
                paymentsOffset <
                Math.ceil(filteredPayments.length / paymentsPerPage)
                    ? paymentsOffset + 1
                    : paymentsOffset
        });
    }

    handlePreviousPage() {
        const { paymentsOffset } = this.state;
        this.setState({
            paymentsOffset:
                paymentsOffset > 1 ? paymentsOffset - 1 : paymentsOffset
        });
    }

    handleFilterPaymentsBuildingChange(building) {
        const { paymentsFilter } = this.state;
        this.setState({
            paymentsFilter: Object.assign({}, paymentsFilter, {
                building
            }),
            filteredPayments: this.filterPayments({
                building,
                unit: paymentsFilter.unit,
                dateFrom: paymentsFilter.dateFrom,
                dateTo: paymentsFilter.dateTo,
                status: paymentsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterPaymentsUnitChange(unit) {
        const { paymentsFilter } = this.state;
        this.setState({
            paymentsFilter: Object.assign({}, paymentsFilter, {
                unit
            }),
            filteredPayments: this.filterPayments({
                building: paymentsFilter.building,
                unit,
                dateFrom: paymentsFilter.dateFrom,
                dateTo: paymentsFilter.dateTo,
                status: paymentsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterPaymentsDateFromChange(dateFrom) {
        const { paymentsFilter } = this.state;
        const dateTo = moment(dateFrom).isSameOrAfter(
            paymentsFilter.dateTo,
            "day"
        )
            ? null
            : paymentsFilter.dateTo;
        this.setState({
            paymentsFilter: Object.assign({}, paymentsFilter, {
                dateFrom,
                dateTo
            }),
            filteredPayments: this.filterPayments({
                building: paymentsFilter.building,
                unit: paymentsFilter.unit,
                dateFrom,
                dateTo,
                status: paymentsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterPaymentsDateToChange(dateTo) {
        const { paymentsFilter } = this.state;
        this.setState({
            paymentsFilter: Object.assign({}, paymentsFilter, {
                dateTo
            }),
            filteredPayments: this.filterPayments({
                building: paymentsFilter.building,
                unit: paymentsFilter.unit,
                dateFrom: paymentsFilter.dateFrom,
                dateTo,
                status: paymentsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterPaymentsStatusChange(status) {
        const { paymentsFilter } = this.state;
        this.setState({
            paymentsFilter: Object.assign({}, paymentsFilter, {
                status
            }),
            filteredPayments: this.filterPayments({
                building: paymentsFilter.building,
                unit: paymentsFilter.unit,
                dateFrom: paymentsFilter.dateFrom,
                dateTo: paymentsFilter.dateTo,
                status
            }),
            offset: 1
        });
    }

    filterPayments({ building, unit, dateFrom, dateTo, status }) {
        const { payments } = this.props;

        return (
            payments &&
            payments.filter(
                payment =>
                    (building
                        ? payment.buildingName
                            ? payment.buildingName
                                  .toLowerCase()
                                  .includes(building.toLowerCase())
                            : false
                        : true) &&
                    (unit
                        ? payment.unitNumber
                            ? payment.unitNumber
                                  .toLowerCase()
                                  .includes(unit.toLowerCase())
                            : false
                        : true) &&
                    (dateFrom
                        ? moment(payment.date).isSameOrAfter(dateFrom, "day")
                        : true) &&
                    (dateFrom && dateTo
                        ? moment(payment.date).isSameOrBefore(dateTo, "day")
                        : true) &&
                    (status
                        ? status.value === "All" ||
                          payment.status === status.value
                        : true)
            )
        );
    }

    handlePaymentsReports(array) {
        const headers = [
            "Payment Date",
            "Building Name",
            "Unit",
            "Reference No",
            "Category",
            "Amount",
            "Method",
            "Remarks",
            "Status"
        ];
        const columns = [
            "date",
            "buildingName",
            "unitNumber",
            "referenceNumber",
            "billCategory",
            "amount",
            "method",
            "remarks",
            "status"
        ];

        exportCSV(array, headers, columns, "Payments Report");
    }

    render() {
        const { payments, tabVisible, onTabChange } = this.props;
        const { paymentsFilter, filteredPayments, paymentsOffset } = this.state;

        const paymentsRange = paymentsOffset * paymentsPerPage;

        const paymentRows =
            filteredPayments &&
            filteredPayments.map((payment, i) => {
                if (i >= paymentsRange - paymentsPerPage && i < paymentsRange) {
                    return (
                        <TableLinkRow
                            key={payment.id}
                            to={{
                                pathname: `/admin/payments/${payment.id}`,
                                state: {
                                    backTo: {
                                        label: "Back to Bills",
                                        value: `/admin/bills`
                                    }
                                }
                            }}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>
                                {payment.date
                                    ? moment(payment.date).format(
                                          "MM/DD/YYYY HH:mm:ss"
                                      )
                                    : ""}
                            </TableCell>
                            <TableCell>{payment.buildingName}</TableCell>
                            <TableCell>{payment.unitNumber}</TableCell>
                            <TableCell>{payment.referenceNumber}</TableCell>
                            <TableCell>{payment.billCategory}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {currencyFormat(payment.amount)}
                            </TableCell>
                            <TableCell>{payment.method}</TableCell>
                            <TableCell>{payment.remarks}</TableCell>
                            <TableCell>
                                <PayStatus status={payment.status}>
                                    {payment.status}
                                </PayStatus>
                            </TableCell>
                            <TableRowViewAction text="VIEW BILL" />
                        </TableLinkRow>
                    );
                }
            });

        const paymentsGrandTotal = filteredPayments
            ? filteredPayments.reduce(
                  (acc, curr) => acc + (Number(curr.amount) || 0),
                  0
              )
            : 0;

        return (
            <Fragment>
                {tabVisible && (
                    <div style={{ marginTop: "10px" }}>
                        <TabHeaderContainer>
                            <InactiveTabHeader
                                onClick={() => onTabChange("bills")}
                            >
                                Bills
                            </InactiveTabHeader>
                            <ActiveTabHeader>Payments</ActiveTabHeader>
                            <TabNewItemContainer>
                                <DownloadButton
                                    onClick={() =>
                                        this.handlePaymentsReports(
                                            filteredPayments
                                        )
                                    }
                                    style={{
                                        float: "right",
                                        marginTop: "10px"
                                    }}
                                >
                                    Download Reports
                                </DownloadButton>
                            </TabNewItemContainer>
                        </TabHeaderContainer>
                        {payments && payments.length > 0 ? (
                            <Fragment>
                                <FiltersContainer>
                                    <div style={{ width: "170px" }}>
                                        <FilterLabel>Building</FilterLabel>
                                        <Input
                                            placeholder="e.g. Tower 1"
                                            value={paymentsFilter.building}
                                            onChange={e =>
                                                this.handleFilterPaymentsBuildingChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "120px" }}>
                                        <FilterLabel>Unit Number</FilterLabel>
                                        <Input
                                            placeholder="e.g. 305"
                                            value={paymentsFilter.unit}
                                            onChange={e =>
                                                this.handleFilterPaymentsUnitChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Date From</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={
                                                    paymentsFilter.dateFrom
                                                }
                                                selectsStart
                                                startDate={
                                                    paymentsFilter.dateFrom
                                                }
                                                endDate={paymentsFilter.dateTo}
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterPaymentsDateFromChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Date To</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={paymentsFilter.dateTo}
                                                disabled={
                                                    !paymentsFilter.dateFrom
                                                }
                                                selectsEnd
                                                minDate={
                                                    paymentsFilter.dateFrom
                                                }
                                                startDate={
                                                    paymentsFilter.dateFrom
                                                }
                                                endDate={paymentsFilter.dateTo}
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterPaymentsDateToChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    <div style={{ width: "150px" }}>
                                        <FilterLabel>Status</FilterLabel>
                                        <Select
                                            value={paymentsFilter.status}
                                            onChange={selectedStatus =>
                                                this.handleFilterPaymentsStatusChange(
                                                    selectedStatus
                                                )
                                            }
                                            options={filterStatusOptions}
                                        />
                                    </div>
                                </FiltersContainer>
                                <TableOverflowX>
                                    <Table>
                                        <TableHeader>
                                            <TableCell>Date and Time</TableCell>
                                            <TableCell>Building</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>Reference Number</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell
                                                style={{ textAlign: "right" }}
                                            >
                                                Amount
                                            </TableCell>
                                            <TableCell>Method</TableCell>
                                            <TableCell>Remarks</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell />
                                        </TableHeader>
                                        {paymentRows}
                                    </Table>
                                </TableOverflowX>
                                {filteredPayments &&
                                    filteredPayments.length >
                                        paymentsPerPage && (
                                        <Pagination
                                            itemsPerPage={paymentsPerPage}
                                            offset={paymentsOffset}
                                            length={filteredPayments.length}
                                            handlePreviousPage={() =>
                                                this.handlePreviousPage()
                                            }
                                            handleNextPage={() =>
                                                this.handleNextPage()
                                            }
                                        />
                                    )}
                                <PaymentsGrandTotalContainer>
                                    TOTAL{" "}
                                    {paymentsFilter.status.value.toUpperCase()}{" "}
                                    AMOUNT PHP{" "}{currencyFormat(paymentsGrandTotal)}
                                </PaymentsGrandTotalContainer>
                            </Fragment>
                        ) : (
                            <EmptyListContainer style={{ margin: "30px 0" }}>
                                There are no payments yet.
                            </EmptyListContainer>
                        )}
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        payments: state.payments,
        isLoading: state.isLoading
    };
};

export default connect(mapStateToProps)(AdminBillsPaymentsContainer);
