import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import BillingCategoryModal from "./BillingCategoryModal";
import ConfirmationModal from "containers/ConfirmationModal";
import {
    removeBillingCategory,
    selectBuilding
} from "reducers/buildingReducer";
import { Select, FiltersContainer, FilterLabel } from "styles/Inputs";
import {
    TabHeaderContainer,
    TabNewItemContainer,
    ActiveTabHeader,
    InactiveTabHeader
} from "styles/Tabs";
import {
    Table,
    TableHeader,
    TableRow,
    TableCell,
    TableRowEditAction,
    TableRowRemoveAction,
    EmptyListContainer
} from "styles/Tables";
import { AddItemButton, NewItemLink } from "styles/Buttons";

class BuildingBillingCategoriesContainer extends Component {
    state = {
        billingCategory: null,
        billingCategoryModalVisible: false,
        confirmationModalVisible: false
    };

    showBillingCategoryModal(billingCategory) {
        if (billingCategory) {
            this.setState({
                billingCategory,
                billingCategoryModalVisible: true
            });
        } else {
            this.setState({
                billingCategory: null,
                billingCategoryModalVisible: true
            });
        }
    }

    hideBillingCategoryModal() {
        this.setState({ billingCategoryModalVisible: false });
    }

    showConfirmationModal(billingCategory) {
        this.setState({
            billingCategory,
            confirmationModalVisible: true
        });
    }

    hideConfirmationModal() {
        this.setState({ confirmationModalVisible: false });
    }

    handleRemoveBillingCategory() {
        const { building, dispatch } = this.props;
        const { billingCategory } = this.state;
        dispatch(
            removeBillingCategory({
                buildingId: building.id,
                name: billingCategory.name
            })
        );
    }

    handleBuildingChange(selectedBuilding) {
        const { dispatch } = this.props;
        dispatch(selectBuilding(selectedBuilding.value));
    }

    render() {
        const {
            billingCategories,
            building,
            buildingOptions,
            tabVisible,
            onTabChange
        } = this.props;
        const {
            billingCategory,
            billingCategoryModalVisible,
            confirmationModalVisible
        } = this.state;
        const selectedBuilding = building && {
            value: building.id,
            label: building.name
        };

        const billingCategoryRows =
            billingCategories &&
            billingCategories.map((billingCategory, i) => (
                <TableRow gray={i % 2 === 0} key={billingCategory.name}>
                    <TableCell>{billingCategory.name}</TableCell>
                    <TableCell>
                        {billTypeHelper(billingCategory.type)}
                    </TableCell>
                    <TableRowEditAction
                        onClick={() =>
                            this.showBillingCategoryModal(billingCategory)
                        }
                        text="EDIT"
                    />
                    <TableRowRemoveAction
                        onClick={() =>
                            this.showConfirmationModal(billingCategory)
                        }
                        text="REMOVE"
                    />
                </TableRow>
            ));

        return (
            <Fragment>
                {tabVisible && (
                    <Fragment>
                        <TabHeaderContainer>
                            <InactiveTabHeader
                                onClick={() => onTabChange("unitTypes")}
                            >
                                Unit Types
                            </InactiveTabHeader>
                            <ActiveTabHeader>
                                Billing Categories
                            </ActiveTabHeader>
                            <InactiveTabHeader
                                onClick={() => onTabChange("uploads")}
                            >
                                Uploads
                            </InactiveTabHeader>
                            <TabNewItemContainer>
                                <AddItemButton
                                    onClick={() =>
                                        this.showBillingCategoryModal()
                                    }
                                    text="NEW BILLING CATEGORY"
                                    style={{
                                        float: "right",
                                        marginTop: "10px"
                                    }}
                                />
                            </TabNewItemContainer>
                        </TabHeaderContainer>
                        <FiltersContainer>
                            <div style={{ width: "200px" }}>
                                <FilterLabel>Building</FilterLabel>
                                <Select
                                    value={selectedBuilding}
                                    onChange={this.handleBuildingChange.bind(
                                        this
                                    )}
                                    options={buildingOptions}
                                    isSearchable
                                />
                            </div>
                        </FiltersContainer>
                        {billingCategories && billingCategories.length > 0 ? (
                            <Fragment>
                                <Table>
                                    <TableHeader>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell />
                                        <TableCell />
                                    </TableHeader>
                                    {billingCategoryRows}
                                </Table>
                            </Fragment>
                        ) : (
                            <EmptyListContainer>
                                No billing categories yet,{" "}
                                <NewItemLink
                                    onClick={() =>
                                        this.showBillingCategoryModal()
                                    }
                                >
                                    create one now.
                                </NewItemLink>
                            </EmptyListContainer>
                        )}
                        {billingCategoryModalVisible && (
                            <BillingCategoryModal
                                billingCategory={billingCategory}
                                billingCategoryNames={
                                    billingCategories && billingCategory
                                        ? billingCategories.map(
                                              bc =>
                                                  bc.name !==
                                                      billingCategory.name &&
                                                  bc.name
                                          )
                                        : billingCategories &&
                                          billingCategories.map(bc => bc.name)
                                }
                                modalVisible={billingCategoryModalVisible}
                                closeModal={this.hideBillingCategoryModal.bind(
                                    this
                                )}
                            />
                        )}
                        {confirmationModalVisible && (
                            <ConfirmationModal
                                body="Are you sure you want to remove this billing category?"
                                modalVisible={confirmationModalVisible}
                                onSubmit={this.handleRemoveBillingCategory.bind(
                                    this
                                )}
                                submitText="YES"
                                closeModal={this.hideConfirmationModal.bind(
                                    this
                                )}
                                cancelText="NO"
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

const billTypeHelper = billType => {
    switch (billType) {
        case "total_only":
            return "Total Only";
        case "floor_sized_dependent":
            return "Floor Area Dependent";
        case "rate_type":
            return "Rate Type";
        default:
            return "";
    }
};

const mapStateToProps = state => {
    return {
        buildingOptions:
            state.buildings &&
            state.buildings.map(building => {
                return { value: building.id, label: building.name };
            }),
        building: state.building,
        billingCategories: state.building && state.building.billingCategories
    };
};

export default connect(mapStateToProps)(BuildingBillingCategoriesContainer);
