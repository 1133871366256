const DISPLAY_MESSAGE = "DISPLAY_MESSAGE";
const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const messageActions = {
    DISPLAY_MESSAGE,
    CLEAR_MESSAGE
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case DISPLAY_MESSAGE:
            return payload
        case CLEAR_MESSAGE:
            return null
        default:
            return state;
    }
};
