import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { currencyFormat } from "utils/InputPatterns";
import { Input, Select, FiltersContainer, FilterLabel } from "styles/Inputs";
import {
    TabHeaderContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    TabHeaderSpacer
} from "styles/Tabs";
import {
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination
} from "styles/Tables";
import {
    PesoSign,
} from "styles/Containers";

const filterDuesOptions = [
    {
        value: "all",
        label: "All"
    },
    {
        value: "with_dues",
        label: "With Dues"
    },
    {
        value: "without_dues",
        label: "Without Dues"
    }
];

const unitsPerPage = 10;

class OwnerUnitsContainer extends Component {
    state = {
        filter: {
            building: "",
            number: "",
            floor: "",
            dues: {
                value: "all",
                label: "All"
            }
        },
        filteredUnits: null,
        unitsOffset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { units } = this.props;
        this.setState({ filteredUnits: units });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        const { tabVisible, modalVisible } = this.props;
        if (
            tabVisible !== prevProps.tabVisible ||
            modalVisible !== prevProps.modalVisible
        ) {
            if (tabVisible && !modalVisible) {
                document.addEventListener("keydown", this.handleKeyDown);
            } else {
                document.removeEventListener("keydown", this.handleKeyDown);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredUnits, unitsOffset } = this.state;
        this.setState({
            unitsOffset:
                unitsOffset < Math.ceil(filteredUnits.length / unitsPerPage)
                    ? unitsOffset + 1
                    : unitsOffset
        });
    }

    handlePreviousPage() {
        const { unitsOffset } = this.state;
        this.setState({
            unitsOffset: unitsOffset > 1 ? unitsOffset - 1 : unitsOffset
        });
    }

    handleFilterBuildingChange(building) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                building
            }),
            filteredUnits: this.filterUnits({
                building,
                unitOwner: filter.unitOwner,
                floor: filter.floor,
                dues: filter.dues
            }),
            offset: 1
        });
    }

    handleFilterNumberChange(number) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                number
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                number,
                floor: filter.floor,
                dues: filter.dues
            }),
            offset: 1
        });
    }

    handleFilterFloorChange(floor) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                floor
            }),
            filteredUnits: this.filterUnits({
                building: filter.buildingFilter,
                unitOwner: filter.unitOwner,
                floor,
                dues: filter.dues
            }),
            offset: 1
        });
    }

    handleFilterDuesChange(dues) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                dues
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                unitOwner: filter.unitOwner,
                floor: filter.floor,
                dues
            }),
            offset: 1
        });
    }

    filterUnits({ building, number, floor, dues }) {
        const { units } = this.props;

        return (
            units &&
            units.filter(
                unit =>
                    (building
                        ? unit.buildingName
                              .toLowerCase()
                              .includes(building.toLowerCase())
                        : true) &&
                    (number
                        ? unit.number
                              .toLowerCase()
                              .includes(number.toLowerCase())
                        : true) &&
                    unit.floor.includes(floor) &&
                    (dues.value === "with_dues"
                        ? unit.amountDue > 0
                        : dues.value === "without_dues"
                        ? !unit.amountDue || unit.amountDue === 0
                        : true)
            )
        );
    }

    render() {
        const { ownerId, units, tabVisible, onTabChange } = this.props;
        const { filteredUnits, filter, unitsOffset } = this.state;

        const unitsRange = unitsOffset * unitsPerPage;

        const unitRows =
            filteredUnits &&
            filteredUnits.map((unit, i) => {
                if (i >= unitsRange - unitsPerPage && i < unitsRange) {
                    return (
                        <TableLinkRow
                            key={unit.id}
                            to={{
                                pathname: `/admin/units/${unit.id}`,
                                state: {
                                    backTo: {
                                        label: "Back to Owner",
                                        value: `/admin/owners/${ownerId}`
                                    }
                                }
                            }}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{unit.buildingName}</TableCell>
                            <TableCell>{unit.number}</TableCell>
                            <TableCell>{unit.floor}</TableCell>
                            <TableCell>
                                {unit.tenant && unit.tenant.name}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {(unit.amountDue &&
                                    currencyFormat(unit.amountDue)) ||
                                    "0.00"}
                            </TableCell>
                            <TableCell>
                                {unit.dueDate
                                    ? moment(unit.dueDate).format("MM/DD/YYYY")
                                    : ""}
                            </TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        return (
            <Fragment>
                {tabVisible && (
                    <Fragment>
                        {units && units.length > 0 && (
                            <Fragment>
                                <TabHeaderContainer>
                                    <ActiveTabHeader>Units</ActiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() => onTabChange("bills")}
                                    >
                                        Bills
                                    </InactiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() =>
                                            onTabChange("reservations")
                                        }
                                    >
                                        Reservations
                                    </InactiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() => onTabChange("payments")}
                                    >
                                        Payments
                                    </InactiveTabHeader>
                                    <TabHeaderSpacer />
                                </TabHeaderContainer>
                                <FiltersContainer>
                                    <div style={{ width: "220px" }}>
                                        <FilterLabel>Building</FilterLabel>
                                        <Input
                                            placeholder="e.g. Tower 1"
                                            value={filter.building}
                                            onChange={e =>
                                                this.handleFilterBuildingChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "220px" }}>
                                        <FilterLabel>Unit Number</FilterLabel>
                                        <Input
                                            placeholder="e.g. 305"
                                            value={filter.number}
                                            onChange={e =>
                                                this.handleFilterNumberChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "80px" }}>
                                        <FilterLabel>Floor</FilterLabel>
                                        <Input
                                            placeholder="e.g. 3"
                                            value={filter.floor}
                                            onChange={e =>
                                                this.handleFilterFloorChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "150px" }}>
                                        <FilterLabel>Dues</FilterLabel>
                                        <Select
                                            value={filter.dues}
                                            onChange={this.handleFilterDuesChange.bind(
                                                this
                                            )}
                                            options={filterDuesOptions}
                                        />
                                    </div>
                                </FiltersContainer>
                                <Table>
                                    <TableHeader>
                                        <TableCell>Building</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Floor</TableCell>
                                        <TableCell>Tenant</TableCell>
                                        <TableCell
                                            style={{ textAlign: "right" }}
                                        >
                                            Amount Due
                                        </TableCell>
                                        <TableCell>Due Date</TableCell>
                                        <TableCell />
                                    </TableHeader>
                                    {unitRows}
                                </Table>
                                {units && units.length > unitsPerPage && (
                                    <Pagination
                                        itemsPerPage={unitsPerPage}
                                        offset={unitsOffset}
                                        length={units.length}
                                        handlePreviousPage={() =>
                                            this.handlePreviousPage()
                                        }
                                        handleNextPage={() =>
                                            this.handleNextPage()
                                        }
                                    />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default connect()(OwnerUnitsContainer);
