import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import colors from "utils/colors";
import moment from "moment";
import { FiInfo } from "react-icons/fi";
import { Spinner } from "containers/Spinner";
import { currencyFormat } from "utils/InputPatterns";
import { fetchAnonymousBill } from "reducers/billReducer";
import { paypalRedirect, dragonPayRedirect } from "reducers/paymentReducer";
import MessageContainer from "./MessageContainer";
import ConfirmationModal from "./ConfirmationModal";
import Modal from "react-modal";
import { BillStatus } from "styles/Status";
import {
    BackgroundContainer,
    MainHeader,
    SubHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    PesoSign,
} from "styles/Containers";
import { PaypalButton, DragonPayButton, NewItemLink, ClickPayNowbutton } from "styles/Buttons";
import OnlinePaymentModal from "./OnlinePaymentModal";

toast.configure({
    autoClose: 8000,
    draggable: false
});

const ToastyContainer = styled.div`
    .Toastify__toast {
        font-family: "Poppins", sans-serif;
        &-body {
            color: black;
        }
    }
`;

const BillContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 600px;
    padding: 20px 50px;
    background-color: white;
    justify-content: center;
    border-radius: 30px;
`;

const PaymentStatusProcessingContainer = styled.div`
    color: ${colors.PRIMARY};
`;

const PaymentInfoText = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.PRIMARY};
    font-size: 12px;
    margin: 20px 0;
`;

class AnonymousBillContainer extends Component {
    state = {
        confirmationModalVisible: false,
        onlinePaymentModalVisible: false,
    };

    componentDidMount() {
        const { billId, dispatch } = this.props;
        Modal.setAppElement("#root");
        dispatch(fetchAnonymousBill(billId));
    }

    handlePaypalClick() {
        const { billId, dispatch } = this.props;
        dispatch(paypalRedirect(billId)).then(() =>
            this.setState({ confirmationModalVisible: true })
        );
    }

    handleDragonPayClick() {
        const { billId, dispatch } = this.props;
        dispatch(dragonPayRedirect(billId)).then(() =>
            this.setState({ confirmationModalVisible: true })
        );
    }

    handleCreditCardPaymentSuccess() {
        const { billId, dispatch } = this.props;
        dispatch(fetchAnonymousBill(billId));
    }

    showOnlinePaymentModal() {
        this.setState({ onlinePaymentModalVisible: true });
    }

    hideOnlinePaymentModal() {
        this.setState({ onlinePaymentModalVisible: false });
    }

    render() {
        const { bill, isLoading, isModalLoading } = this.props;
        const { confirmationModalVisible, onlinePaymentModalVisible } = this.state;
        const billDiscountInfos =
            bill &&
            bill.discounts &&
            bill.discounts.map(discount => (
                <div key={discount.id} style={{ display: "table-row" }}>
                    <InfoText>{discount.description}</InfoText>
                    <InfoText
                        style={{
                            display: "table-cell",
                            textAlign: "right",
                            paddingLeft: "15px"
                        }}
                    >
                        <PesoSign />
                        {currencyFormat(discount.amount)}
                    </InfoText>
                </div>
            ));

        // console.log(bill);

        return (
            <BackgroundContainer>
                <BillContainer>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <Fragment>
                            {bill && (
                                <Fragment>
                                    <div
                                        style={{
                                            marginBottom: "30px",
                                            overflowY: "auto"
                                        }}
                                    >
                                        <MainHeader>{bill.category}</MainHeader>
                                        <SubHeader>
                                            {bill.billType === "total_only"
                                                ? "Total Only"
                                                : bill.billType ===
                                                  "floor_sized_dependent"
                                                ? "Floor Sized Dependent"
                                                : bill.billType === "rate_type"
                                                ? "Rate Type"
                                                : ""}
                                        </SubHeader>
                                        <InfoContainer>
                                            {bill.coverageStart && (
                                                <div>
                                                    <InfoLabel>
                                                        Coverage Start
                                                    </InfoLabel>
                                                    <InfoText>
                                                        {moment(
                                                            bill.coverageStart
                                                        ).format("MM/DD/YYYY")}
                                                    </InfoText>
                                                </div>
                                            )}
                                            {bill.coverageEnd && (
                                                <div>
                                                    <InfoLabel>
                                                        Coverage End
                                                    </InfoLabel>
                                                    <InfoText>
                                                        {moment(
                                                            bill.coverageEnd
                                                        ).format("MM/DD/YYYY")}
                                                    </InfoText>
                                                </div>
                                            )}
                                            <div>
                                                <InfoLabel>Bill Date</InfoLabel>
                                                <InfoText>
                                                    {bill.billDate &&
                                                        moment(
                                                            bill.billDate
                                                        ).format("MM/DD/YYYY")}
                                                </InfoText>
                                            </div>
                                            <div>
                                                <InfoLabel>Due Date</InfoLabel>
                                                <InfoText>
                                                    {bill.dueDate &&
                                                        moment(
                                                            bill.dueDate
                                                        ).format("MM/DD/YYYY")}
                                                </InfoText>
                                            </div>
                                        </InfoContainer>
                                        {bill.billType !== "total_only" && (
                                            <InfoContainer>
                                                {bill.billType ===
                                                    "rate_type" && (
                                                    <div>
                                                        <InfoLabel>
                                                            Utilization
                                                        </InfoLabel>
                                                        <InfoText>
                                                            {bill.consumption}
                                                        </InfoText>
                                                    </div>
                                                )}
                                                <div>
                                                    <InfoLabel>Rate</InfoLabel>
                                                    <InfoText>
                                                        {bill.rate}
                                                    </InfoText>
                                                </div>
                                            </InfoContainer>
                                        )}
                                        {bill.discounts &&
                                            bill.discounts.length > 0 && (
                                                <Fragment>
                                                    <InfoLabel
                                                        style={{
                                                            marginTop: "18px"
                                                        }}
                                                    >
                                                        Discounts
                                                    </InfoLabel>
                                                    <div
                                                        style={{
                                                            display: "table"
                                                        }}
                                                    >
                                                        {billDiscountInfos}
                                                    </div>
                                                </Fragment>
                                            )}
                                        <InfoContainer>
                                            <div style={{ textAlign: "right" }}>
                                                <InfoLabel>
                                                    Total Amount
                                                </InfoLabel>
                                                <InfoText>
                                                    <PesoSign />
                                                    {currencyFormat(
                                                        bill.totalAmountDue
                                                    )}
                                                </InfoText>
                                            </div>
                                            <div style={{ textAlign: "right" }}>
                                                <InfoLabel>
                                                    Remaining Balance
                                                </InfoLabel>
                                                <InfoText>
                                                    <PesoSign />
                                                    {currencyFormat(
                                                        bill.remainingBalance
                                                    )}
                                                </InfoText>
                                            </div>
                                            <div>
                                                <InfoLabel
                                                    style={{
                                                        marginBottom: "8px"
                                                    }}
                                                >
                                                    Status
                                                </InfoLabel>
                                                <BillStatus
                                                    status={bill.status}
                                                    style={{
                                                        fontSize: `12px`,

                                                    }}>
                                                    {bill.status}
                                                </BillStatus>
                                            </div>
                                        </InfoContainer>
                                        {bill.status === "Overdue" && (
                                            <InfoContainer>
                                                <div
                                                    style={{
                                                        textAlign: "right"
                                                    }}
                                                >
                                                    <InfoLabel>
                                                        Overdue Fee
                                                    </InfoLabel>
                                                    <InfoText>
                                                        <PesoSign />
                                                        {currencyFormat(
                                                            bill.overdueFee
                                                        )}
                                                    </InfoText>
                                                </div>
                                            </InfoContainer>
                                        )}
                                    </div>
                                    <PaymentInfoText>
                                        <FiInfo
                                            style={{
                                                marginRight: "2px"
                                            }}
                                        />
                                        <span>
                                            Online transactions has a fee of{" "}
                                            <PesoSign />
                                            {bill.transactionFee
                                                ?   currencyFormat(
                                                        bill.transactionFee
                                                    )
                                                :   "0.00"}
                                        </span>
                                    </PaymentInfoText>
                                    {bill.paymentStatus === "Processing" ? (
                                        <PaymentStatusProcessingContainer>
                                            <FiInfo
                                                style={{
                                                    marginBottom: "-2.5px"
                                                }}
                                            />
                                            <span>
                                                {" "}
                                                Payment is now being processed.
                                                You can check back later and
                                                refresh this page for an update
                                                on the status. Thank you.
                                            </span>
                                        </PaymentStatusProcessingContainer>
                                    ) : (
                                        bill.status !== "Paid Full" && (
                                            <Fragment>
                                                <ClickPayNowbutton
                                                onClick={() =>
                                                    this.showOnlinePaymentModal()
                                                    }
                                                style={{
                                                    width: "fit-content",
                                                }}
                                                >
                                                    Click here to pay now!
                                                </ClickPayNowbutton>
                                                {confirmationModalVisible && (
                                                    <ConfirmationModal
                                                        body="Page needs to be refreshed to show changes after payment."
                                                        modalVisible={
                                                            confirmationModalVisible
                                                        }
                                                        submitText="Refresh now"
                                                        cancelText="Maybe later"
                                                        onSubmit={() =>
                                                            location.reload()
                                                        }
                                                        closeModal={() =>
                                                            this.setState({
                                                                confirmationModalVisible: false
                                                            })
                                                        }
                                                    />
                                                )}
                                                {onlinePaymentModalVisible && (
                                                    <OnlinePaymentModal
                                                        transactionFee=
                                                            {bill.transactionFee
                                                                ? currencyFormat(bill.transactionFee)
                                                                : "0.00"}
                                                        totalAmountDue={bill.totalAmountDue}
                                                        modalVisible={onlinePaymentModalVisible}
                                                        submitText="Close"
                                                        onSubmit={this.hideOnlinePaymentModal.bind(
                                                            this
                                                        )}
                                                        closeModal={this.hideOnlinePaymentModal.bind(
                                                            this
                                                        )}
                                                        handlePaypalClick={this.handlePaypalClick.bind(
                                                            this
                                                        )}
                                                        handleDragonPayClick={this.handleDragonPayClick.bind(
                                                            this
                                                        )}
                                                    />
                                                )}
                                            </Fragment>
                                        )
                                    )}
                                </Fragment>
                            )}
                            <MessageContainer />
                        </Fragment>
                    )}
                </BillContainer>
                <ToastyContainer>
                    <ToastContainer hideProgressBar={true} />
                </ToastyContainer>
            </BackgroundContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        bill: state.bill,
        isLoading: state.isLoading,
        isModalLoading: state.isModalLoading
    };
};

export default connect(mapStateToProps)(AnonymousBillContainer);
