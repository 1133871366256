import user, { userActions } from "./userReducer";
import { propertyActions } from "./propertyReducer";
import building, { buildingActions } from "./buildingReducer";
import unit, { unitActions } from "./unitReducer";
import owner, { ownerActions } from "./ownerReducer";
import bill, { billActions } from "./billReducer";
import payment, { paymentActions } from "./paymentReducer";
import tenant, { tenantActions } from "./tenantReducer";
import { uploadActions } from "./uploadReducer";
import amenity, { amenityActions } from "./amenityReducer";
import reservation, { reservationActions } from "./reservationReducer";
import notification, { notificationActions } from "./notificationReducer";
import message, { messageActions } from "./messageReducer";

function jwtoken(state, action) {
    const { payload } = action;
    switch (action.type) {
        case userActions.LOGIN:
            const { auth_token } = payload;
            return auth_token;
        case userActions.LOGOUT:
            return null;
        default:
            return state;
    }
}

const appState = {
    CLOSE_NAV: "CLOSE_NAV",
    TOGGLE_NAV: "TOGGLE_NAV",
};

export const defaultState = {
    showNav: false,
    isAppLoading: false,
    isLoading: false,
    isModalLoading: false,
    jwtoken: null,
    isLoggedIn: false,
    isAuthorized: false,
    isAdmin: false,
    errorMessage: null,

    user: {
        email: null,
        name: null,
        lastName: null,
        isResettingPassword: false,
        passwordSuccess: 0,
        role: null,
    },

    properties: [],
    property: null,

    buildings: [],
    building: null,

    units: [],
    unit: null,
    unclaimedUnits: [],

    bills: [],
    bill: null,

    payments: [],
    payment: null,

    owners: [],
    owner: null,

    tenants: [],
    tenant: null,

    amenities: [],
    amenity: null,
    amenitySchedule: null,

    reservations: [],
    reservation: null,

    requisitions: [],
    requisition: null,

    notifications: [],
    overview: null,

    uploadResults: null,
};

export default (state = defaultState, action) => {
    const { payload } = action;
    switch (action.type) {
        case userActions.SIGNING_IN:
            return { ...state, isAppLoading: true, isLoading: true };
        case userActions.LOGIN_FAIL:
            return { ...state, isAppLoading: false, isLoading: false };

        case userActions.LOGIN:
        case userActions.LOGOUT:
            return {
                ...state,
                ...defaultState,
                isAppLoading: false,
                isLoading: false,
                isLoggedIn: userActions.LOGIN === action.type,
                isAuthorized: userActions.LOGIN === action.type,
                isAdmin: userActions.LOGIN === action.type && payload.isAdmin,
                jwtoken: jwtoken(state.jwtoken, action),
                user: user(state.user, action),
                buildings:
                    action.type === userActions.LOGIN && payload.user.buildings
                        ? payload.user.buildings
                        : [],
                notifications:
                    action.type === userActions.LOGIN &&
                    payload.user.notifications
                        ? payload.user.notifications
                        : [],
            };

        case userActions.AUTH_SUCCESS:
            return {
                ...state,
                isAppLoading: false,
                isLoading: false,
                isAuthorized: true,
                isAdmin: payload.isAdmin,
                user: user(state.user, action),
                buildings: payload.user.buildings || [],
                notifications: payload.user.notifications || [],
            };

        case propertyActions.FETCH_PROPERTIES:
        case buildingActions.FETCH_BUILDINGS:
        case buildingActions.REMOVE_BILLING_CATEGORY:
        case unitActions.FETCH_UNITS:
        case unitActions.FETCH_UNCLAIMED_UNITS:
        case unitActions.FETCH_UNIT:
        case unitActions.FETCH_REQUISITIONS:
        case unitActions.FETCH_REQUISITION:
        case unitActions.APPROVE_REQUISITION:
        case billActions.FETCH_BILLS:
        case billActions.FETCH_BILL:
        case paymentActions.FETCH_PAYMENTS:
        case paymentActions.FETCH_PAYMENT:
        case ownerActions.FETCH_OWNERS:
        case ownerActions.FETCH_OWNER:
        case tenantActions.FETCH_TENANTS:
        case tenantActions.FETCH_TENANT:
        case amenityActions.FETCH_AMENITIES:
        case amenityActions.FETCH_AMENITY:
        case amenityActions.FETCH_AMENITY_SCHEDULE:
        case reservationActions.FETCH_RESERVATION:
        case reservationActions.FETCH_RESERVATIONS:
        case reservationActions.APPROVE_RESERVATION:
        case notificationActions.FETCH_NOTIFICATIONS:
        case notificationActions.MARK_AS_READ:
        case notificationActions.MARK_ALL_AS_READ:
        case userActions.RESET_PASSWORD:
        case userActions.CONFIRM_ACCOUNT:
        case userActions.ACCOUNT_SIGN_UP:
        case userActions.FETCH_OVERVIEW:
            return {
                ...state,
                isLoading: true,
            };

        case propertyActions.FETCH_PROPERTIES_FAIL:
        case buildingActions.FETCH_BUILDINGS_FAIL:
        case buildingActions.REMOVE_UNIT_TYPE_FAIL:
        case buildingActions.REMOVE_BILLING_CATEGORY_FAIL:
        case unitActions.FETCH_UNITS_FAIL:
        case unitActions.FETCH_UNCLAIMED_UNITS_FAIL:
        case unitActions.FETCH_UNIT_FAIL:
        case unitActions.FETCH_REQUISITIONS_FAIL:
        case unitActions.FETCH_REQUISITION_FAIL:
        case unitActions.APPROVE_REQUISITION_FAIL:
        case billActions.FETCH_BILLS_FAIL:
        case billActions.FETCH_BILL_FAIL:
        case paymentActions.FETCH_PAYMENTS_FAIL:
        case paymentActions.FETCH_PAYMENT_FAIL:
        case ownerActions.FETCH_OWNERS_FAIL:
        case ownerActions.FETCH_OWNER_FAIL:
        case tenantActions.FETCH_TENANTS_FAIL:
        case tenantActions.FETCH_TENANT_FAIL:
        case amenityActions.FETCH_AMENITIES_FAIL:
        case amenityActions.FETCH_AMENITY_FAIL:
        case amenityActions.FETCH_AMENITY_SCHEDULE_FAIL:
        case reservationActions.FETCH_RESERVATION_FAIL:
        case reservationActions.FETCH_RESERVATIONS_FAIL:
        case reservationActions.APPROVE_RESERVATION_FAIL:
        case notificationActions.FETCH_NOTIFICATIONS_FAIL:
        case notificationActions.MARK_AS_READ_FAIL:
        case notificationActions.MARK_ALL_AS_READ_FAIL:
        case userActions.RESET_PASSWORD_SUCCESS:
        case userActions.RESET_PASSWORD_FAIL:
        case userActions.CONFIRM_ACCOUNT_SUCCESS:
        case userActions.CONFIRM_ACCOUNT_FAIL:
        case userActions.ACCOUNT_SIGN_UP_SUCCESS:
        case userActions.ACCOUNT_SIGN_UP_FAIL:
        case userActions.FETCH_OVERVIEW_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        case propertyActions.FETCH_PROPERTIES_SUCCESS:
            return {
                ...state,
                properties: payload,
                isLoading: false,
            };

        case buildingActions.FETCH_BUILDINGS_SUCCESS:
            return {
                ...state,
                buildings: payload,
                isLoading: false,
            };

        case unitActions.FETCH_UNITS_SUCCESS:
            return {
                ...state,
                units: payload.units,
                owners: payload.owners || [],
                requisitions: payload.requisitions || [],
                building: null,
                isLoading: false,
            };

        case unitActions.FETCH_UNCLAIMED_UNITS_SUCCESS:
            return {
                ...state,
                unclaimedUnits: payload,
                isLoading: false,
            };

        case unitActions.RESET_UNCLAIMED_UNITS:
            return {
                ...state,
                unclaimedUnits: [],
                isLoading: false,
            };

        case unitActions.FETCH_UNIT_SUCCESS:
            return {
                ...state,
                unit: payload.unit,
                tenants: payload.tenants,
                building:
                    state.buildings &&
                    state.buildings.find(
                        (building) => building.id === payload.unit.buildingId
                    ),
                isLoading: false,
            };

        case unitActions.FETCH_REQUISITIONS_SUCCESS:
            return {
                ...state,
                requisitions: payload,
                isLoading: false,
            };

        case unitActions.FETCH_REQUISITION_SUCCESS:
            return {
                ...state,
                requisition: payload,
                isLoading: false,
            };

        case unitActions.APPROVE_REQUISITION_SUCCESS:
            return {
                ...state,
                requisition: unit(state.requisition, action),
                isLoading: false,
            };

        case billActions.FETCH_BILLS_SUCCESS:
            return {
                ...state,
                bills: payload.bills,
                payments: payload.payments,
                isLoading: false,
            };

        case billActions.FETCH_BILL_SUCCESS:
            return {
                ...state,
                bill: payload.bill,
                isLoading: false,
            };

        case paymentActions.FETCH_PAYMENTS_SUCCESS:
            return {
                ...state,
                payments: payload,
                isLoading: false,
            };

        case paymentActions.FETCH_PAYMENT_SUCCESS:
            return {
                ...state,
                payment: payload,
                isLoading: false,
            };

        case ownerActions.FETCH_OWNERS_SUCCESS:
            return {
                ...state,
                owners: payload,
                isLoading: false,
            };

        case ownerActions.FETCH_OWNER_SUCCESS:
            return {
                ...state,
                owner: payload.owner,
                units: payload.units,
                bills: payload.bills,
                reservations: payload.reservations,
                payments: payload.payments,
                isLoading: false,
            };

        case tenantActions.FETCH_TENANTS_SUCCESS:
            return {
                ...state,
                tenants: payload,
                isLoading: false,
            };

        case tenantActions.FETCH_TENANT_SUCCESS:
            return {
                ...state,
                tenant: payload.tenant,
                units: payload.units,
                bills: payload.bills,
                reservations: payload.reservations,
                payments: payload.payments,
                isLoading: false,
            };

        case amenityActions.FETCH_AMENITIES_SUCCESS:
            return {
                ...state,
                amenities: payload,
                isLoading: false,
            };

        case amenityActions.FETCH_AMENITY_SUCCESS:
            return {
                ...state,
                amenity: payload.amenity,
                amenitySchedule: payload.schedule,
                buildings: payload.buildings || state.buildings,
                units: payload.units,
                isLoading: false,
            };

        case amenityActions.FETCH_AMENITY_SCHEDULE_SUCCESS:
            return {
                ...state,
                amenitySchedule: payload,
                isLoading: false,
            };

        case reservationActions.FETCH_RESERVATIONS_SUCCESS:
            return {
                ...state,
                reservations: payload.reservations,
                payments: payload.payments,
                isLoading: false,
            };

        case reservationActions.FETCH_RESERVATION_SUCCESS:
            return {
                ...state,
                reservation: payload,
                isLoading: false,
            };

        case reservationActions.APPROVE_RESERVATION_SUCCESS:
            return {
                ...state,
                reservation: reservation(state.reservation, action),
                isLoading: false,
            };

        case notificationActions.FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: payload,
                isLoading: false,
            };

        case notificationActions.MARK_AS_READ_SUCCESS:
        case notificationActions.MARK_ALL_AS_READ_SUCCESS:
            return {
                ...state,
                notifications: notification(state.notifications, action),
                isLoading: false,
            };

        case notificationActions.ADD_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.concat([payload]),
            };

        case userActions.FETCH_OVERVIEW_SUCCESS:
            return {
                ...state,
                overview: payload,
                isLoading: false,
            };

        case userActions.FORGOT_PASSWORD:
        case userActions.CHANGE_PASSWORD:
        case buildingActions.ADD_UNIT_TYPE:
        case buildingActions.UPDATE_UNIT_TYPE:
        case buildingActions.ADD_BILLING_CATEGORY:
        case buildingActions.UPDATE_BILLING_CATEGORY:
        case unitActions.ADD_UNIT:
        case unitActions.UPDATE_UNIT:
        case unitActions.CLAIM_UNIT:
        case unitActions.DENY_REQUISITION:
        case unitActions.GENERATE_STATEMENT_OF_ACCOUNT:
        case billActions.ADD_BILL:
        case ownerActions.ADD_OWNER:
        case ownerActions.UPDATE_OWNER:
        case paymentActions.ADD_PAYMENT:
        case paymentActions.ADD_PAYMENT_RESERVATION:
        case paymentActions.PAYPAL_REDIRECT:
        case paymentActions.DRAGONPAY_REDIRECT:
        case paymentActions.CREDIT_CARD_PAYMENT:
        case tenantActions.ADD_TENANT:
        case tenantActions.UPDATE_TENANT:
        case uploadActions.UPLOAD_UNITS:
        case amenityActions.ADD_AMENITY:
        case amenityActions.UPDATE_AMENITY:
        case amenityActions.RESERVE_AMENITY:
        case reservationActions.DENY_RESERVATION:
        case reservationActions.CANCEL_RESERVATION:
            return {
                ...state,
                isModalLoading: true,
            };

        case userActions.CHANGE_PASSWORD_SUCCESS:
        case userActions.CHANGE_PASSWORD_FAIL:
        case userActions.FORGOT_PASSWORD_SUCCESS:
        case userActions.FORGOT_PASSWORD_FAIL:
        case buildingActions.ADD_UNIT_TYPE_FAIL:
        case buildingActions.UPDATE_UNIT_TYPE_FAIL:
        case buildingActions.ADD_BILLING_CATEGORY_FAIL:
        case buildingActions.UPDATE_BILLING_CATEGORY_FAIL:
        case unitActions.ADD_UNIT_FAIL:
        case unitActions.UPDATE_UNIT_FAIL:
        case unitActions.CLAIM_UNIT_FAIL:
        case unitActions.DENY_REQUISITION_FAIL:
        case unitActions.GENERATE_STATEMENT_OF_ACCOUNT_SUCCESS:
        case unitActions.GENERATE_STATEMENT_OF_ACCOUNT_FAIL:
        case billActions.ADD_BILL_FAIL:
        case ownerActions.ADD_OWNER_FAIL:
        case ownerActions.UPDATE_OWNER_FAIL:
        case paymentActions.ADD_PAYMENT_FAIL:
        case paymentActions.ADD_PAYMENT_RESERVATION_FAIL:
        case paymentActions.PAYPAL_REDIRECT_FAIL:
        case paymentActions.PAYPAL_REDIRECT_SUCCESS:
        case paymentActions.DRAGONPAY_REDIRECT_FAIL:
        case paymentActions.DRAGONPAY_REDIRECT_SUCCESS:
        case paymentActions.CREDIT_CARD_PAYMENT_FAIL:
        case paymentActions.CREDIT_CARD_PAYMENT_SUCCESS:
        case tenantActions.ADD_TENANT_FAIL:
        case tenantActions.UPDATE_TENANT_FAIL:
        case uploadActions.UPLOAD_UNITS_FAIL:
        case amenityActions.ADD_AMENITY_FAIL:
        case amenityActions.UPDATE_AMENITY_FAIL:
        case amenityActions.RESERVE_AMENITY_FAIL:
        case reservationActions.DENY_RESERVATION_FAIL:
        case reservationActions.CANCEL_RESERVATION_FAIL:
            return {
                ...state,
                isModalLoading: false,
            };

        case buildingActions.ADD_UNIT_TYPE_SUCCESS:
        case buildingActions.ADD_BILLING_CATEGORY_SUCCESS:
            const updatedBuildings = building(state.buildings, action);
            return {
                ...state,
                buildings: updatedBuildings,
                isModalLoading: false,
            };

        case buildingActions.UPDATE_UNIT_TYPE_SUCCESS:
        case buildingActions.UPDATE_BILLING_CATEGORY_SUCCESS:
            const updatedBuilding = building(state.building, action);
            return {
                ...state,
                buildings: state.buildings.map((b) =>
                    b.id === updatedBuilding.id ? updatedBuilding : b
                ),
                building: updatedBuilding,
                isModalLoading: false,
            };

        case buildingActions.REMOVE_UNIT_TYPE_SUCCESS:
        case buildingActions.REMOVE_BILLING_CATEGORY_SUCCESS:
            const updatedBuildingRemoval = building(state.building, action);
            return {
                ...state,
                buildings: state.buildings.map((b) =>
                    b.id === updatedBuildingRemoval.id
                        ? updatedBuildingRemoval
                        : b
                ),
                building: updatedBuildingRemoval,
                isLoading: false,
            };

        case buildingActions.SELECT_BUILDING:
            return {
                ...state,
                building: building(state.buildings, action),
            };

        case buildingActions.RESET_BUILDING:
            return {
                ...state,
                building: null,
            };

        case buildingActions.SET_FIRST_BUILDING:
            return {
                ...state,
                building: state.buildings && state.buildings[0],
            };

        case unitActions.ADD_UNIT_SUCCESS:
            return {
                ...state,
                units: unit(state.units, action),
                isModalLoading: false,
            };
        case unitActions.UPDATE_UNIT_SUCCESS:
            return {
                ...state,
                unit: unit(state.unit, action),
                isModalLoading: false,
            };

        case billActions.ADD_BILL_SUCCESS:
            return {
                ...state,
                unit: bill(state.unit, action),
                isModalLoading: false,
            };

        case ownerActions.ADD_OWNER_SUCCESS:
            return {
                ...state,
                owners: owner(state.owners, action),
                isModalLoading: false,
            };

        case ownerActions.UPDATE_OWNER_SUCCESS:
            return {
                ...state,
                owner: owner(state.owner, action),
                isModalLoading: false,
            };

        case tenantActions.ADD_TENANT_SUCCESS:
            return {
                ...state,
                tenants: tenant(state.tenants, action),
                isModalLoading: false,
            };

        case tenantActions.UPDATE_TENANT_SUCCESS:
            return {
                ...state,
                tenant: tenant(state.tenant, action),
                isModalLoading: false,
            };

        case paymentActions.ADD_PAYMENT_SUCCESS:
            return {
                ...state,
                bill: payment(state.bill, action),
                isModalLoading: false,
            };

        case paymentActions.ADD_PAYMENT_RESERVATION_SUCCESS:
            return {
                ...state,
                reservation: payment(state.reservation, action),
                isModalLoading: false,
            };

        case uploadActions.UPLOAD_UNITS_SUCCESS:
            return {
                ...state,
                uploadResults: payload,
                isModalLoading: false,
            };

        case uploadActions.RESET_UPLOAD_RESULTS:
            return {
                ...state,
                uploadResults: null,
            };

        case amenityActions.RESERVE_AMENITY_SUCCESS:
            return {
                ...state,
                payment: payload.payment,
                isModalLoading: false,
            };

        case amenityActions.ADD_AMENITY_SUCCESS:
        case amenityActions.UPDATE_AMENITY_SUCCESS:
            return {
                ...state,
                amenities: amenity(state.amenities, action),
                isModalLoading: false,
            };

        case amenityActions.RESET_AMENITY:
            return {
                ...state,
                amenity: null,
            };

        case reservationActions.DENY_RESERVATION_SUCCESS:
        case reservationActions.CANCEL_RESERVATION_SUCCESS:
            return {
                ...state,
                reservation: reservation(state.reservation, action),
                isModalLoading: false,
            };

        case unitActions.CLAIM_UNIT_SUCCESS:
            return {
                ...state,
                unclaimedUnits: unit(state.unclaimedUnits, action),
                isModalLoading: false,
            };

        case unitActions.DENY_REQUISITION_SUCCESS:
            return {
                ...state,
                requisition: unit(state.requisition, action),
                isModalLoading: false,
            };

        case messageActions.DISPLAY_MESSAGE:
        case messageActions.CLEAR_MESSAGE:
            return {
                ...state,
                errorMessage: message(state.errorMessage, action),
            };

        case appState.CLOSE_NAV:
            return { ...state, showNav: false };
        case appState.TOGGLE_NAV:
            return { ...state, showNav: !state.showNav };

        default:
            return state;
    }
};
