import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "./Spinner";
import PageHeader from "./PageHeader";
import {
    Input,
    IconInputContainer,
    FiltersContainer,
    FilterLabel
} from "styles/Inputs";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    TableHeader,
    Table,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination,
    EmptyListContainer
} from "../styles/Tables";
import { ContentContainer } from "../styles/Containers";
import { FiSearch } from "react-icons/fi";
import { fetchOwners } from "reducers/ownerReducer";

const ownersPerPage = 10;

class OwnersContainer extends Component {
    state = {
        filteredOwners: [],
        offset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchOwners()).then(owners =>
                this.setState({ filteredOwners: owners || [] })
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredOwners, offset } = this.state;
        this.setState({
            offset:
                offset < Math.ceil(filteredOwners.length / ownersPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    handleFilterChange(name) {
        const { owners } = this.props;

        const filteredOwners =
            owners &&
            owners.filter(owner =>
                owner.name.toLowerCase().includes(name.toLowerCase())
            );

        this.setState({
            filteredOwners: filteredOwners,
            offset: 1
        });
    }

    render() {
        const { owners, isLoading } = this.props;
        const { filteredOwners, ownerModalVisible, offset } = this.state;
        const range = offset * ownersPerPage;

        const ownerRows =
            filteredOwners &&
            filteredOwners.map((owner, i) => {
                if (i >= range - ownersPerPage && i < range) {
                    return (
                        <TableLinkRow
                            key={owner.id}
                            to={`/admin/owners/${owner.id}`}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{owner.name}</TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : owners && owners.length > 0 ? (
                    <ContentContainer>
                        <TableTextHeaderContainer>
                            <TableTextHeader>Owners</TableTextHeader>
                        </TableTextHeaderContainer>
                        <FiltersContainer>
                            <div style={{ width: "220px" }}>
                                <FilterLabel>Name</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. Joe"
                                        onChange={e =>
                                            this.handleFilterChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                        </FiltersContainer>
                        <div>
                            <Table>
                                <TableHeader>
                                    <TableCell>Name</TableCell>
                                    <TableCell></TableCell>
                                </TableHeader>
                                {ownerRows}
                            </Table>
                            {filteredOwners &&
                                filteredOwners.length > ownersPerPage && (
                                    <Pagination
                                        itemsPerPage={ownersPerPage}
                                        offset={offset}
                                        length={filteredOwners.length}
                                        handlePreviousPage={() =>
                                            this.handlePreviousPage()
                                        }
                                        handleNextPage={() =>
                                            this.handleNextPage()
                                        }
                                    />
                                )}
                        </div>
                    </ContentContainer>
                ) : (
                    <EmptyListContainer>
                        There are no owners yet.
                    </EmptyListContainer>
                )}
                {ownerModalVisible && (
                    <OwnerModal
                        modalVisible={ownerModalVisible}
                        closeModal={this.hideOwnerModal.bind(this)}
                        onSubmit={this.handleNewOwnerSubmit.bind(this)}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        owners: state.owners,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(OwnersContainer);
