import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { messageActions } from "reducers/messageReducer";
import { currencyFormat } from "utils/InputPatterns";
import colors from "utils/colors";
import { reserveAmenity } from "reducers/amenityReducer";
import { InfoContainer, InfoLabel, PesoSign } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalSubHeader,
    ModalBody,
    ModalFooter,
} from "styles/Modals";
import { PrimaryButton } from "styles/Buttons";
import { Select } from "styles/Inputs";
import styled from "styled-components";

const TableContainer = styled.div`
    max-height: 200px;
    overflow-y: scroll;
`;

const Table = styled.div`
    display: table;
    border-collapse: separate;
    width: 100%;
    background-color: ${colors.LIGHTER_GRAY};
    border-bottom: solid 0.5px ${colors.LIGHT_GRAY};
`;

const TableHeaderCell = styled.div`
    display: table-cell;
    position: sticky;
    top: 0;
    padding: 8px;
    background-color: ${colors.LIGHTER_GRAY};
    border-style: solid;
    border-width: 0.5px 0;
    border-color: ${colors.LIGHT_GRAY};
`;

const TableRow = styled.div`
    display: table-row;
    border-style: solid;
    border-width: 0 0.5px;
    border-color: ${colors.LIGHT_GRAY};

    > div:first-child {
        border-left: solid 0.5px ${colors.LIGHT_GRAY};
    }

    > div:last-child {
        border-right: solid 0.5px ${colors.LIGHT_GRAY};
    }
`;

const TableCell = styled.div`
    display: table-cell;
    padding: 8px;
`;

const TotalAmountDueContainer = styled.div`
    heigth: 60px;
    background-color: ${colors.LIGHTER_GRAY};
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;
`;

class AccountBookingModal extends Component {
    state = {
        building: null,
        unit: null,
        hasError: false,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE,
        });
    }

    async handleSubmit() {
        const { amenity, bookings, onSuccess, dispatch } = this.props;
        const { unit } = this.state;

        if (!unit) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: "Please select a unit.",
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(
                reserveAmenity({
                    amenityId: amenity.id,
                    bookings,
                    amount: bookings.length * amenity.rate,
                    unitId: unit.value,
                })
            );
            if (!this.props.errorMessage) {
                dispatch({
                    type: messageActions.CLEAR_MESSAGE,
                });
                onSuccess();
            }
        }
    }

    render() {
        const {
            amenity,
            bookings,
            buildingOptions,
            units,
            modalVisible,
            closeModal,
            isModalLoading,
        } = this.props;
        const { building, unit, hasError } = this.state;

        const unitOptions =
            building &&
            units &&
            units
                .filter((u) => u.buildingId === building.value)
                .map((u) => {
                    return { value: u.id, label: u.name };
                });

        const sortedBookings = bookings.sort(
            (a, b) =>
                moment(a, "MMMM D, YYYY h:mm A") -
                moment(b, "MMMM D, YYYY h:mm A")
        );

        const compiledBookings = sortedBookings.reduce((acc, curr) => {
            const currMoment = moment(curr, "MMMM D, YYYY h:mm A");
            const lastBooking = acc.length > 0 && acc[acc.length - 1];

            return lastBooking &&
                lastBooking.date + " " + lastBooking.endTime === curr
                ? acc.map((a, i) =>
                      i === acc.length - 1
                          ? Object.assign({}, a, {
                                endTime: currMoment
                                    .add(1, "h")
                                    .format("h:mm A"),
                                times: a.times + 1,
                            })
                          : a
                  )
                : acc.concat({
                      date: currMoment.format("MMMM D, YYYY"),
                      startTime: currMoment.format("h:mm A"),
                      endTime: currMoment.add(1, "h").format("h:mm A"),
                      times: 1,
                  });
        }, []);

        const bookingRows = compiledBookings.map((b, i) => (
            <TableRow key={"booking_" + i}>
                <TableCell>{b.date}</TableCell>
                <TableCell>
                    {b.startTime} - {b.endTime}
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                    <PesoSign /> {currencyFormat(amenity.rate * b.times)}
                </TableCell>
            </TableRow>
        ));

        const totalAmountDue = bookings.length * amenity.rate;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={isModalLoading ? null : closeModal}
                style={modalStyle({ height: 580, width: 500 })}
            >
                <CloseButton onClick={isModalLoading ? null : closeModal} />
                <ModalHeaderContainer>
                    <ModalHeader>Book Reservation</ModalHeader>
                    <ModalSubHeader>{amenity.name}</ModalSubHeader>
                </ModalHeaderContainer>
                <ModalBody height="410px">
                    <InfoContainer>
                        <div style={{ width: "222.5px" }}>
                            <InfoLabel>BUILDING</InfoLabel>
                            <Select
                                value={building}
                                disabled={isModalLoading}
                                onChange={(selectedBuilding) =>
                                    this.setState({
                                        building: selectedBuilding,
                                        unit: null,
                                    })
                                }
                                options={buildingOptions}
                            />
                        </div>
                        <div style={{ width: "222.5px" }}>
                            <InfoLabel>UNIT</InfoLabel>
                            <Select
                                value={unit}
                                disabled={isModalLoading || !building}
                                required={!unit && hasError}
                                onChange={(selectedUnit) =>
                                    this.setState({
                                        unit: selectedUnit,
                                    })
                                }
                                options={unitOptions}
                                isSearchable
                            />
                        </div>
                    </InfoContainer>
                    <div>
                        Rate: <PesoSign />
                        {currencyFormat(amenity.rate)}/hour
                    </div>
                    <div>
                        <div>Overview:</div>
                        <TableContainer>
                            <Table>
                                <TableRow>
                                    <TableHeaderCell>Date</TableHeaderCell>
                                    <TableHeaderCell>Time</TableHeaderCell>
                                    <TableHeaderCell
                                        style={{ textAlign: "right" }}
                                    >
                                        Amount
                                    </TableHeaderCell>
                                </TableRow>
                                {bookingRows}
                            </Table>
                        </TableContainer>
                    </div>
                    <div>
                        <div>Total Due:</div>
                        <TotalAmountDueContainer>
                            <PesoSign />
                            {currencyFormat(totalAmountDue)}
                        </TotalAmountDueContainer>
                    </div>
                    <MessageContainer />
                </ModalBody>
                <ModalFooter>
                    {isModalLoading ? (
                        <Loader text="BOOKING..." />
                    ) : (
                        <PrimaryButton
                            style={{
                                height: "38px",
                                width: "100%",
                                fontSize: "14px",
                            }}
                            onClick={() => this.handleSubmit()}
                            text="CONFIRM"
                        />
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        amenity: state.amenity,
        buildingOptions:
            state.units &&
            state.units.reduce((acc, curr) => {
                return acc.length > 0
                    ? acc.find((a) => a.value === curr.buildingId)
                        ? acc
                        : acc.concat({
                              value: curr.buildingId,
                              label: curr.buildingName,
                          })
                    : acc.concat({
                          value: curr.buildingId,
                          label: curr.buildingName,
                      });
            }, []),
        units: state.units,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage,
    };
};
export default connect(mapStateToProps)(AccountBookingModal);
