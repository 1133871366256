import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { fetchOverview } from "reducers/userReducer";
import { Spinner } from "containers/Spinner";
import colors from "utils/colors";
import { currencyFormat } from "utils/InputPatterns";
import PageHeader from "containers/PageHeader";
import {
    ContentContainer,
    FlexContainer,
    FlexCenterContainer,
    PesoSign,
} from "styles/Containers";
import { TableTextHeaderContainer, TableTextHeader } from "styles/Tables";
import { ButtonSelect } from "styles/Inputs";

const thisYear = moment().format("YYYY");
const thisMonth = moment().format("MMMM");

const periodOptions = [
    {
        label: `This year ${thisYear}`,
        value: "This year"
    },
    {
        label: `This month ${thisMonth}`,
        value: "This month"
    },
    {
        label: "Today",
        value: "Today"
    }
];

const DashboardCard = styled.div`
    padding: 20px;
    background-color: ${colors.LIGHTER_GRAY};
`;

const DashboardCardHeader = styled.div`
    color: ${colors.PRIMARY};
    font-weight: 600;
    font-size: large;
`;

class AdminDashboardContainer extends Component {
    state = {
        period: {
            label: `This year ${thisYear}`,
            value: "This year"
        }
    };

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchOverview());
        }
    }

    render() {
        const { overview, isLoading } = this.props;
        const { period } = this.state;

        const occupancyRate =
            overview && overview.unitsWithTenants && overview.numberOfUnits
                ? Math.round(
                      (overview.unitsWithTenants / overview.numberOfUnits) *
                          10000
                  ) / 100
                : 0;

        const totalPayments = overview
            ? period.value === "This year"
                ? overview.paymentsThisYear
                : period.value === "This month"
                ? overview.paymentsThisMonth
                : period.value === "Today"
                ? overview.paymentsToday
                : 0
            : 0;

        const totalDues = overview
            ? period.value === "This year"
                ? overview.unpaidDuesThisYear
                : period.value === "This month"
                ? overview.unpaidDuesThisMonth
                : period.value === "Today"
                ? overview.unpaidDuesToday
                : 0
            : 0;

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ContentContainer>
                        <TableTextHeaderContainer>
                            <TableTextHeader>Dashboard</TableTextHeader>
                            <ButtonSelect
                                value={period}
                                options={periodOptions}
                                onChange={selectedPeriod =>
                                    this.setState({ period: selectedPeriod })
                                }
                                buttonStyle={{ minWidth: "130px" }}
                            />
                        </TableTextHeaderContainer>
                        <FlexContainer>
                            <DashboardCard
                                style={{ height: "300px", width: "50%" }}
                            >
                                <DashboardCardHeader>
                                    Occupancy Rate
                                </DashboardCardHeader>
                                <FlexCenterContainer
                                    style={{ height: "230px" }}
                                >
                                    <div>
                                        <div
                                            style={{
                                                fontSize: "75px",
                                                color: colors.SECONDARY,
                                                textAlign: "center"
                                            }}
                                        >
                                            {occupancyRate}%
                                        </div>
                                        {overview &&
                                            overview.unitsWithTenants &&
                                            overview.numberOfUnits && (
                                                <div
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {overview.unitsWithTenants}{" "}
                                                    out of{" "}
                                                    {overview.numberOfUnits}
                                                </div>
                                            )}
                                    </div>
                                </FlexCenterContainer>
                            </DashboardCard>
                            <div
                                style={{
                                    height: "300px",
                                    width: "calc(50% - 10px)",
                                    marginLeft: "10px"
                                }}
                            >
                                <DashboardCard
                                    style={{
                                        height: "145px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <DashboardCardHeader>
                                        Total Collected Bills
                                    </DashboardCardHeader>
                                    <FlexCenterContainer
                                        style={{ height: "75px" }}
                                    >
                                        <span style={{ fontSize: "30px" }}>
                                            <PesoSign />
                                            {currencyFormat(totalPayments)}
                                        </span>
                                    </FlexCenterContainer>
                                </DashboardCard>
                                <DashboardCard style={{ height: "145px" }}>
                                    <DashboardCardHeader>
                                        Total Unpaid Dues
                                    </DashboardCardHeader>
                                    <FlexCenterContainer
                                        style={{ height: "75px" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "30px",
                                                color: colors.RED
                                            }}
                                        >
                                            <PesoSign />
                                            {currencyFormat(totalDues)}
                                        </span>
                                    </FlexCenterContainer>
                                </DashboardCard>
                            </div>
                        </FlexContainer>
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        overview: state.overview,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AdminDashboardContainer);
