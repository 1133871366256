import styled, { css } from "styled-components";
import colors from "utils/colors";

// old design
//
// export const BillStatus = styled.div`
//     color: white;
//     width: fit-content;
//     font-size: 14px;
//     font-family: "PT Sans", sans-serif;
//     padding: 4px;
//     border-radius: 4px;
//     white-space: nowrap;

//     ${props =>
//         props.status === "Unpaid"
//             ? css`
//                   background-color: gray;
//               `
//             : props.status === "Paid Partial"
//             ? css`
//                   background-color: ${colors.BLUE};
//               `
//             : props.status === "Paid Full"
//             ? css`
//                   background-color: #3a8c37;
//               `
//             : props.status === "Overdue"
//             ? css`
//                   background-color: red;
//               `
//             : css`
//                   background-color: black;
//               `}
// `;


export const BillStatus = styled.div`
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    font-family: "Poppins",sans-serif;

    ${props =>
        props.status === "Unpaid"
            ? css`
                  color: #1A1A1A;
              `
            : props.status === "Paid Partial"
            ? css`
                  color: #1BBC9B;
              `
            : props.status === "Paid Full"
            ? css`
                  color: #1BBC9B;
              `
            : props.status === "Overdue"
            ? css`
                  color: #FB2634;
              `
            : css`
                  color: black;
              `}
`;


export const PayStatus = styled.div`
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    font-family: "Poppins",sans-serif;

    ${props =>
        props.status === "Processing"
            ? css`
                  color: #1A1A1A;
              `
            : props.status === "Completed"
            ? css`
                  color: #1BBC9B;
              `
            : props.status === "Inactive"
            ? css`
                  color: #8E8E8E;
              `
            : props.status === "Failed"
            ? css`
                  color: #FB2634;
              `
            : css`
                  color: black;
              `}
`;


export const ReserveStatus = styled.div`
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    font-family: "Poppins",sans-serif;

    ${props =>
        props.status === "Pending"
            ? css`
                  color: #1A1A1A;
              `
            : props.status === "Reserved (Paid)"
            ? css`
                  color: #1BBC9B;
              `
            : props.status === "Denied"
            ? css`
                  color: #8E8E8E;
              `
            : props.status === "Approved (Pending Payment)"
            ? css`
                  color: #2699FB;
              `
            : props.status === "Approved"
            ? css`
                  color: #2699FB;
              `
            : css`
                  color: black;
              `}
`;