export default {
    PRIMARY: "#233C60",
    SECONDARY: "#FBB03E",
    RED: "#E72835",
    RED_PINK: "#EF476F",
    BLUE: "#2680EB",
    BLUE_GREEN: "#27A7A4",
    LIGHTER_GRAY: "#F3F3F3",
    LIGHT_GRAY: "#ECECEC",
    GRAY: "#A2A2A2",
    TRANSPARENT_GRAY: "#A2A2A266",
    LIGHT_BLACK: "#565656",
    SEMI_BLACK: "#242424",
    BLACK: "#252525",
};
