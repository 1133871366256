export const positiveNumber = e => {
    // e, +, -
    if ([69, 187, 189].includes(e.keyCode)) {
        e.preventDefault();
    }
};

export const currencyFormat = curr => {
    return Number(curr)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const isValidEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export function titleize(sentence) {
    if (!sentence.split) return sentence;
    const titleizeWord = function(string) {
            return (
                string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
            );
        },
        result = [];
    sentence.split(" ").forEach(function(w) {
        result.push(titleizeWord(w));
    });
    return result.join(" ");
}