import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import { Spinner } from "./Spinner";
import PageHeader from "./PageHeader";
import OwnerModal from "./OwnerModal";
import { fetchOwner } from "../reducers/ownerReducer";
import OwnerUnitsContainer from "./OwnerUnitsContainer";
import OwnerBillsContainer from "./OwnerBillsContainer";
import OwnerReservationsContainer from "./OwnerReservationsContainer";
import OwnerPaymentsContainer from "./OwnerPaymentsContainer";
import {
    MainHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    ProfileImage,
    HeaderContainer,
    ContentContainer
} from "../styles/Containers";

var md5 = require("md5");

class OwnerContainer extends Component {
    state = {
        ownerModalVisible: false,
        tabVisible: "units"
    };

    componentDidMount() {
        const { ownerId, isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchOwner(ownerId));
        }
    }

    showOwnerModal() {
        this.setState({ ownerModalVisible: true });
    }

    hideOwnerModal() {
        this.setState({ ownerModalVisible: false });
    }

    tabChangeHandler(tabVisible) {
        this.setState({ tabVisible });
    }

    render() {
        const {
            owner,
            units,
            bills,
            reservations,
            payments,
            isLoading,
            backTo
        } = this.props;
        const { ownerModalVisible, tabVisible } = this.state;

        const hashedEmail = owner && owner.email && md5(owner.email);

        return (
            <Fragment>
                <PageHeader
                    title={(backTo && backTo.label) || "Back to Owners"}
                    backTo={(backTo && backTo.value) || "/admin/owners"}
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    owner && (
                        <ContentContainer>
                            <HeaderContainer>
                                <ProfileImage
                                    background={`https://www.gravatar.com/avatar/${hashedEmail}?s=150&d=mm`}
                                />
                                <div
                                    style={{
                                        flexDirection: "column",
                                        width: "85%"
                                    }}
                                >
                                    <MainHeader style={{ display: "flex" }}>
                                        {owner.name}
                                        <FiEdit
                                            style={{
                                                margin: "0px 0px -2px 4px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() =>
                                                this.showOwnerModal()
                                            }
                                        />
                                    </MainHeader>
                                    <InfoContainer>
                                        <div style={{ width: "25%" }}>
                                            <InfoLabel>Type</InfoLabel>
                                            <InfoText>
                                                {owner.ownerType}
                                            </InfoText>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <InfoLabel>Email</InfoLabel>
                                            <InfoText>{owner.email}</InfoText>
                                        </div>
                                        <div
                                            style={{
                                                width: "25%",
                                                marginLeft: "0"
                                            }}
                                        >
                                            <InfoLabel>Phone Number</InfoLabel>
                                            <InfoText>
                                                {owner.phoneNumber}
                                            </InfoText>
                                        </div>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <div style={{ width: "25%" }}>
                                            <InfoLabel>Contact Name</InfoLabel>
                                            <InfoText>
                                                {owner.contactFirstName +
                                                    " " +
                                                    owner.contactLastName}
                                            </InfoText>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <InfoLabel>Contact Email</InfoLabel>
                                            <InfoText>
                                                {owner.contactEmail}
                                            </InfoText>
                                        </div>
                                        <div
                                            style={{
                                                width: "25%",
                                                marginLeft: "0"
                                            }}
                                        >
                                            <InfoLabel>
                                                Contact Phone Number
                                            </InfoLabel>
                                            <InfoText>
                                                {owner.contactPhoneNumber}
                                            </InfoText>
                                        </div>
                                    </InfoContainer>
                                </div>
                            </HeaderContainer>
                            <OwnerUnitsContainer
                                tabVisible={tabVisible === "units"}
                                ownerId={owner.id}
                                units={units}
                                onTabChange={this.tabChangeHandler.bind(this)}
                                modalVisible={ownerModalVisible}
                            />
                            <OwnerBillsContainer
                                tabVisible={tabVisible === "bills"}
                                ownerId={owner.id}
                                bills={bills}
                                onTabChange={this.tabChangeHandler.bind(this)}
                                modalVisible={ownerModalVisible}
                            />
                            <OwnerReservationsContainer
                                tabVisible={tabVisible === "reservations"}
                                ownerId={owner.id}
                                reservations={reservations}
                                onTabChange={this.tabChangeHandler.bind(this)}
                                modalVisible={ownerModalVisible}
                            />
                            <OwnerPaymentsContainer
                                tabVisible={tabVisible === "payments"}
                                ownerId={owner.id}
                                payments={payments}
                                onTabChange={this.tabChangeHandler.bind(this)}
                                modalVisible={ownerModalVisible}
                            />
                        </ContentContainer>
                    )
                )}
                {ownerModalVisible && (
                    <OwnerModal
                        owner={owner}
                        modalVisible={ownerModalVisible}
                        closeModal={this.hideOwnerModal.bind(this)}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        owner: state.owner,
        units: state.units,
        bills: state.bills,
        reservations: state.reservations,
        payments: state.payments,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(OwnerContainer);
