import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "utils/colors";
import SideNavLogo from "../../../assets/images/logonavwhite.png";
import {
    FiLayout,
    FiHome,
    FiUser,
    FiUsers,
    FiInbox,
    FiColumns,
    FiBook,
    FiSettings
} from "react-icons/fi";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { SideNavLinkContainer } from "styles/Containers";

const NavigationContainer = styled.div`
    width: 205px;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    background-color: ${colors.PRIMARY};
    font-size: 14px;
    font-weight: 600;
`;

const ActiveTabStyle = {
    backgroundColor: "white",
    color: colors.PRIMARY
};

const SideLogoContainer = styled.div`
    height: 30px;
    width: 110px;
    background-image: ${props => `url(${props.background})`};
    background-size: contain;
    background-repeat: no-repeat;
    margin: 25px;
    margin-bottom: 20px;

    img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const SideNavHelpContainer = styled(SideNavLinkContainer)`
    position: absolute;
    bottom: 0;
`

class AdminNavigation extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {}

    render() {

        return(
            <NavigationContainer>
                <Link to="/admin">
                    <SideLogoContainer background={SideNavLogo} />
                </Link>
                <SideNavLinkContainer to="/admin" exact activeStyle={ActiveTabStyle}>
                    <FiLayout />
                    DASHBOARD
                </SideNavLinkContainer>
                <SideNavLinkContainer to="/admin/units" activeStyle={ActiveTabStyle}>
                    <FiHome />
                    UNITS
                </SideNavLinkContainer>
                <SideNavLinkContainer to="/admin/owners" activeStyle={ActiveTabStyle}>
                    <FiUser />
                    OWNERS
                </SideNavLinkContainer>
                <SideNavLinkContainer to="/admin/tenants" activeStyle={ActiveTabStyle}>
                    <FiUsers />
                    TENANTS
                </SideNavLinkContainer>
                <SideNavLinkContainer to="/admin/bills" activeStyle={ActiveTabStyle}>
                    <FiInbox />
                    BILLS
                </SideNavLinkContainer>
                <SideNavLinkContainer
                    to="/admin/amenities"
                    activeStyle={ActiveTabStyle}
                >
                    <FiColumns />
                    AMENITIES
                </SideNavLinkContainer>
                <SideNavLinkContainer
                    to="/admin/reservations"
                    activeStyle={ActiveTabStyle}
                >
                    <FiBook />
                    RESERVATIONS
                </SideNavLinkContainer>
                <SideNavLinkContainer to="/admin/settings" activeStyle={ActiveTabStyle}>
                    <FiSettings />
                    SETTINGS
                </SideNavLinkContainer>
                <SideNavHelpContainer
                    to="#"
                    onClick={this.props.showModal}
                    >
                        <IoIosHelpCircleOutline />
                        HELP
                </SideNavHelpContainer>
            </NavigationContainer>
        )
    }
}

export default AdminNavigation;