import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { messageActions } from "reducers/messageReducer";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { addUnitType, updateUnitType } from "reducers/buildingReducer";
import { InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { Input, Select } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

class UnitTypeModal extends Component {
    state = {
        building: null,
        name: "",
        isNew: true,
        hasError: false
    };

    componentDidMount() {
        const { unitType, building } = this.props;
        const newBuilding = building
            ? {
                  value: building.id,
                  label: building.name
              }
            : null;

        if (unitType) {
            this.setState({
                building: newBuilding,
                name: unitType,
                isNew: false
            });
        } else {
            this.setState({
                building: newBuilding
            });
        }
    }

    async handleSubmit() {
        const { unitType, unitTypeNames, closeModal, dispatch } = this.props;
        const { building, name } = this.state;

        const errorMessage =
            !name || !name.trim().length > 0
                ? "Please enter valid name."
                : unitTypeNames.includes(name)
                ? "Name should be unique."
                : "";

        if (errorMessage === "") {
            if (unitType) {
                await dispatch(
                    updateUnitType({
                        buildingId: building.value,
                        unitType,
                        newUnitType: name.trim()
                    })
                );
            } else {
                await dispatch(
                    addUnitType({
                        buildingId: building.value,
                        name: name.trim()
                    })
                );
            }
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
            }
        } else {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const { building, name, isNew, hasError } = this.state;
        const { buildingOptions, isModalLoading, modalVisible } = this.props;
        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={this.handleClose.bind(this)}
                style={modalStyle({ height: isNew ? 420 : 340, width: 400 })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>
                        {isNew ? "New" : "Update"} Unit Type
                    </ModalHeader>
                </ModalHeaderContainer>
                <ModalBody height={isNew ? "200px" : "110px"}>
                    {isNew && (
                        <Fragment>
                            <InfoLabel required>BUILDING</InfoLabel>
                            <Select
                                value={building}
                                disabled={isModalLoading}
                                onChange={selectedBuilding =>
                                    this.setState({
                                        building: selectedBuilding
                                    })
                                }
                                options={buildingOptions}
                                isSearchable
                            />
                        </Fragment>
                    )}
                    <InfoLabel style={{ marginTop: "18px" }} required>
                        NAME
                    </InfoLabel>
                    <Input
                        value={name}
                        required={hasError}
                        disabled={isModalLoading}
                        onChange={e => this.setState({ name: e.target.value })}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                this.handleSubmit();
                            }
                        }}
                    />
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text={
                                    isNew ? "ADD UNIT TYPE" : "UPDATE UNIT TYPE"
                                }
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text={"CANCEL"}
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        buildingOptions:
            state.buildings &&
            state.buildings.map(building => {
                return { value: building.id, label: building.name };
            }),
        building: state.building,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(UnitTypeModal);
