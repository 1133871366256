import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { FiCheckCircle } from "react-icons/fi";
import { currencyFormat } from "utils/InputPatterns";
import { Loader } from "containers/Loader";
import { modalStyle, CloseButton, ModalBody, ModalFooter } from "styles/Modals";
import { LinkContainer, PesoSign } from "styles/Containers";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";
import colors from "utils/colors";


class AdminBookingSuccessModal extends Component {
    render() {
        const {
            payment,
            modalVisible,
            closeModal,
            isModalLoading
        } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={isModalLoading ? null : closeModal}
                style={modalStyle({ height: 350, width: 400 })}
            >
                <CloseButton onClick={isModalLoading ? null : closeModal} />
                <ModalBody
                    height="200px"
                    style={{ textAlign: "center", padding: "42px 0" }}
                >
                    <FiCheckCircle
                        style={{
                            fontSize: "60px",
                            color: colors.BLUE_GREEN
                        }}
                    />
                    <div>
                        {payment ? (
                            <span>
                                Booking reserved. Paid with amount of <PesoSign />
                                {currencyFormat(payment.amount)}, reference
                                number {payment.referenceNumber}.
                            </span>
                        ) : (
                            <span>Booking approved with payment pending.</span>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter style={{ height: "130px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={closeModal}
                                text="Book more (back to today's view)"
                            />
                            <LinkContainer to="/admin/amenities">
                                <PrimaryAltButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        marginTop: "10px",
                                        fontSize: "14px"
                                    }}
                                    text="Browse amenities"
                                />
                            </LinkContainer>
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        payment: state.payment,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(AdminBookingSuccessModal);
