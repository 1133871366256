import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "./AdminLogin";
import AdminLoggedIn from "./AdminLoggedIn";
import AdminLogout from "./AdminLogout";
import { Spinner } from "containers/Spinner";
import { adminTestAuth } from "actions/testAuth";
import Modal from "react-modal";
import { Route, Switch, withRouter } from "react-router-dom";
import ForgotPassword from "./AdminForgotPassword";
import ResetPassword from "containers/ResetPasswordContainer";
import { ActionCableProvider } from "react-actioncable-provider";
import colors from "utils/colors";

let bugsnagClient = bugsnag("b3b2045e5598932ee712f6549e0abdfd");
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin("react");

toast.configure({
    autoClose: 8000,
    draggable: false,
});

const AppContainer = styled.div`
    flex: 1;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: ${colors.LIGHT_BLACK};
`;

const ToastyContainer = styled.div`
    .Toastify__toast {
        font-family: "PT Sans", sans-serif;
        &-body {
            color: black;
        }
    }
`;

class AdminApp extends Component {
    componentDidMount() {
        const { isLoggedIn, dispatch, history } = this.props;
        Modal.setAppElement("#root");
        if (isLoggedIn) {
            dispatch(adminTestAuth(history));
        }
    }

    render() {
        const { userEmail, isAppLoading, isLoggedIn } = this.props;

        return (
            <ErrorBoundary>
                <AppContainer>
                    {isLoggedIn ? (
                        isAppLoading ? (
                            <Spinner height="100vh" />
                        ) : userEmail ? (
                            <ActionCableProvider
                                url={API_WS_ROOT + "?token=" + userEmail}
                            >
                                <AdminLoggedIn />
                            </ActionCableProvider>
                        ) : (
                            <ActionCableProvider url={API_WS_ROOT}>
                                <AdminLoggedIn />
                            </ActionCableProvider>
                        )
                    ) : (
                        <Switch>
                            <Route
                                exact
                                path="/admin/forgotPassword"
                                component={ForgotPassword}
                            />
                            <Route
                                exact
                                path="/admin/resetPassword/:token"
                                render={({ match }) => (
                                    <ResetPassword
                                        token={match.params.token}
                                        isAdmin
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/admin/logout"
                                component={AdminLogout}
                            />
                            <Route path="/admin" component={AdminLogin} />
                        </Switch>
                    )}
                </AppContainer>
                <ToastyContainer>
                    <ToastContainer hideProgressBar={true} />
                </ToastyContainer>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAppLoading: state.isAppLoading,
        isLoggedIn: state.isLoggedIn,
        userEmail: state.user && state.user.email,
    };
};

export default withRouter(connect(mapStateToProps)(AdminApp));
