import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import AdminBillsOverview from "containers/admin/AdminBillsOverview";
import AdminBillsBillsContainer from "./AdminBillsBillsContainer";
import AdminBillsPaymentsContainer from "./AdminBillsPaymentsContainer";
import { fetchBills } from "reducers/billReducer";
import { ContentContainer } from "styles/Containers";

class AdminBillsContainer extends Component {
    state = {
        tabVisible: "bills"
    };

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchBills());
        }
    }

    render() {
        const { isLoading } = this.props;
        const { tabVisible } = this.state;

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ContentContainer>
                        <AdminBillsOverview />
                        <AdminBillsBillsContainer
                            tabVisible={tabVisible === "bills"}
                            onTabChange={tabSelected =>
                                this.setState({
                                    tabVisible: tabSelected
                                })
                            }
                        />
                        <AdminBillsPaymentsContainer
                            tabVisible={tabVisible === "payments"}
                            onTabChange={tabSelected =>
                                this.setState({
                                    tabVisible: tabSelected
                                })
                            }
                        />
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AdminBillsContainer);
