import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { FiCheck } from "react-icons/fi";
import colors from "utils/colors";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { Table, TableHeader, TableRow, TableCell } from "styles/Tables";
import { PrimaryButton } from "styles/Buttons";
import { Spinner } from "containers/Spinner";

class UploadUnitsResultModal extends Component {
    handleClose() {
        const { closeModal } = this.props;
        closeModal();
    }

    render() {
        const { uploadResults, isModalLoading, modalVisible } = this.props;

        const unitRows =
            uploadResults &&
            uploadResults.failed &&
            uploadResults.failed.map((unit, i) => (
                <TableRow
                    key={"unit_" + i}
                    style={{ backgroundColor: "#f443367a" }}
                >
                    <TableCell>{unit.row}</TableCell>
                    <TableCell>{unit.unitNo}</TableCell>
                    <TableCell>
                        {unit.ownerName ||
                            unit.ownerFirstName + " " + unit.ownerLastName}
                    </TableCell>
                    <TableCell>{unit.ownerEmail}</TableCell>
                    <TableCell>{unit.message}</TableCell>
                </TableRow>
            ));

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({
                    height: 600,
                    width: 600
                })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>Upload Units Result</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody
                    height="400px"
                    style={{
                        overflowX: "auto"
                    }}
                >
                    {isModalLoading ? (
                        <Spinner text="Uploading..." />
                    ) : (
                        uploadResults && (
                            <Fragment>
                                <div>
                                    Uploaded {uploadResults.numberOfUnits} units
                                    {uploadResults.numberOfOwners > 0 &&
                                        " with " +
                                            uploadResults.numberOfOwners +
                                            " new owners"}
                                    .
                                    {uploadResults.numberOfUnits > 0 && (
                                        <FiCheck
                                            style={{
                                                margin: "0 0 -3px 3px",
                                                color: colors.SUCCESS
                                            }}
                                        />
                                    )}
                                </div>
                                {uploadResults.failed &&
                                    uploadResults.failed.length > 0 && (
                                        <Fragment>
                                            <div style={{ marginTop: "20px" }}>
                                                {uploadResults.failed.length}{" "}
                                                Failed:
                                            </div>
                                            <Table>
                                                <TableHeader>
                                                    <TableCell>Row</TableCell>
                                                    <TableCell>Unit</TableCell>
                                                    <TableCell>Owner</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>
                                                        Reason
                                                    </TableCell>
                                                </TableHeader>
                                                {unitRows}
                                            </Table>
                                        </Fragment>
                                    )}
                            </Fragment>
                        )
                    )}
                </ModalBody>
                <ModalFooter>
                    {!isModalLoading && (
                        <PrimaryButton
                            style={{
                                height: "38px",
                                width: "100%",
                                fontSize: "14px"
                            }}
                            onClick={() => this.handleClose()}
                            text="OK"
                        />
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        uploadResults: state.uploadResults,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(UploadUnitsResultModal);
