import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import UnitModal from "./UnitModal";
import { currencyFormat } from "utils/InputPatterns";
import { fetchUnits } from "reducers/unitReducer";
import { FiSearch } from "react-icons/fi";
import {
    Input,
    IconInputContainer,
    FiltersContainer,
    FilterLabel,
    Select
} from "styles/Inputs";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination,
    EmptyListContainer,
} from "styles/Tables";
import { Button, NewItemLink, AddItemButton } from "styles/Buttons";
import { ContentContainer, PesoSign } from "styles/Containers";
import colors from "utils/colors";


const filterDuesOptions = [
    {
        value: "all",
        label: "All"
    },
    {
        value: "with_dues",
        label: "With Dues"
    },
    {
        value: "without_dues",
        label: "Without Dues"
    }
];

const unitsPerPage = 10;

class AdminUnitsContainer extends Component {
    state = {
        filter: {
            building: "",
            unitNumber: "",
            owner: "",
            dues: {
                value: "all",
                label: "All"
            }
        },
        filteredUnits: [],
        unitModalVisible: false,
        offset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchUnits()).then(units =>
                this.setState({ filteredUnits: units || [] })
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    showUnitModal() {
        this.setState({ unitModalVisible: true });
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    hideUnitModal() {
        this.setState({ unitModalVisible: false });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNewUnitSubmit() {
        const { units } = this.props;
        this.setState({
            filter: {
                building: "",
                unitNumber: "",
                owner: "",
                dues: {
                    value: "all",
                    label: "All"
                }
            },
            filteredUnits: units,
            offset: Math.ceil(units.length / unitsPerPage)
        });
    }

    handleNextPage() {
        const { filteredUnits, offset } = this.state;
        this.setState({
            offset:
                offset < Math.ceil(filteredUnits.length / unitsPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    handleFilterBuildingChange(building) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                building
            }),
            filteredUnits: this.filterUnits({
                building,
                unitNumber: filter.unitNumber,
                owner: filter.owner,
                dues: filter.dues
            }),
            offset: 1
        });
    }

    handleFilterUnitNumberChange(unitNumber) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                unitNumber
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                unitNumber,
                owner: filter.owner,
                dues: filter.dues
            }),
            offset: 1
        });
    }

    handleFilterOwnerChange(owner) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                owner
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                unitNumber: filter.unitNumber,
                owner,
                dues: filter.dues
            }),
            offset: 1
        });
    }

    handleFilterDuesChange(dues) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                dues
            }),
            filteredUnits: this.filterUnits({
                building: filter.building,
                unitNumber: filter.unitNumber,
                owner: filter.owner,
                dues
            }),
            offset: 1
        });
    }

    filterUnits({ building, unitNumber, owner, dues }) {
        const { units } = this.props;

        return (
            units &&
            units.filter(
                unit =>
                    (building
                        ? unit.buildingName
                              .toLowerCase()
                              .includes(building.toLowerCase())
                        : true) &&
                    (unitNumber
                        ? unit.number
                              .toLowerCase()
                              .includes(unitNumber.toLowerCase())
                        : true) &&
                    (owner
                        ? unit.ownerName
                              .toLowerCase()
                              .includes(owner.toLowerCase())
                        : true) &&
                    (dues.value === "with_dues"
                        ? unit.amountDue > 0
                        : dues.value === "without_dues"
                        ? !unit.amountDue || unit.amountDue === 0
                        : true)
            )
        );
    }

    render() {
        const { units, isLoading } = this.props;
        const { filter, filteredUnits, unitModalVisible, offset } = this.state;
        const range = offset * unitsPerPage;

        const filteredUnitRows =
            filteredUnits &&
            filteredUnits.map((unit, i) => {
                if (i >= range - unitsPerPage && i < range) {
                    return (
                        <TableLinkRow
                            key={unit.id}
                            to={`/admin/units/${unit.id}`}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{unit.buildingName}</TableCell>
                            <TableCell>{unit.number}</TableCell>
                            <TableCell>{unit.ownerName}</TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                                {unit.dueDate
                                    ? moment(unit.dueDate).format("MM/DD/YYYY")
                                    : ""}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {(unit.amountDue &&
                                    currencyFormat(unit.amountDue)) ||
                                    "0.00"}
                            </TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : units && units.length > 0 ? (
                    <ContentContainer>
                        <TableTextHeaderContainer>
                            <TableTextHeader>Units</TableTextHeader>
                            <div style={{ display: "flex" }}>
                                <Link to="/admin/requisitions">
                                    <Button
                                        style={{
                                            backgroundColor: colors.PRIMARY,
                                            marginRight: "8px"
                                        }}
                                    >
                                        Unit Requisitions
                                    </Button>
                                </Link>
                                <AddItemButton
                                    onClick={() => this.showUnitModal()}
                                    text="Add New Unit"
                                />
                            </div>
                        </TableTextHeaderContainer>
                        <FiltersContainer>
                            <div style={{ width: "200px" }}>
                                <FilterLabel>Building</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder={"e.g. Tower 1"}
                                        value={filter.building}
                                        onChange={e =>
                                            this.handleFilterBuildingChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                            <div style={{ width: "200px" }}>
                                <FilterLabel>Unit No.</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. 305"
                                        value={filter.unitNumber}
                                        onChange={e =>
                                            this.handleFilterUnitNumberChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                            <div style={{ width: "200px" }}>
                                <FilterLabel>Owner</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. Joe"
                                        value={filter.owner}
                                        onChange={e =>
                                            this.handleFilterOwnerChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                            <div style={{ width: "150px" }}>
                                <FilterLabel>Dues</FilterLabel>
                                <Select
                                    value={filter.dues}
                                    onChange={this.handleFilterDuesChange.bind(
                                        this
                                    )}
                                    options={filterDuesOptions}
                                />
                            </div>
                        </FiltersContainer>
                        <div>
                            <Table>
                                <TableHeader>
                                    <TableCell>Building</TableCell>
                                    <TableCell>Unit No.</TableCell>
                                    <TableCell>Owner</TableCell>
                                    <TableCell style={{ textAlign: "left" }}>
                                        Due Date
                                    </TableCell>
                                    <TableCell style={{ textAlign: "right" }}>
                                        Amount Due
                                    </TableCell>
                                    <TableCell />
                                </TableHeader>
                                {filteredUnitRows}
                            </Table>
                            {filteredUnits &&
                                filteredUnits.length > unitsPerPage && (
                                    <Pagination
                                        itemsPerPage={unitsPerPage}
                                        offset={offset}
                                        length={filteredUnits.length}
                                        handlePreviousPage={() =>
                                            this.handlePreviousPage()
                                        }
                                        handleNextPage={() =>
                                            this.handleNextPage()
                                        }
                                    />
                                )}
                        </div>
                    </ContentContainer>
                ) : (
                    <EmptyListContainer>
                        There are no units.{" "}
                        <NewItemLink onClick={() => this.showUnitModal()}>
                            Create one now.
                        </NewItemLink>
                    </EmptyListContainer>
                )}
                {unitModalVisible && (
                    <UnitModal
                        modalVisible={unitModalVisible}
                        closeModal={this.hideUnitModal.bind(this)}
                        onSubmit={this.handleNewUnitSubmit.bind(this)}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        units: state.units,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AdminUnitsContainer);
