import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { FiMail, FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import { accountLogin } from "reducers/userReducer";
import MessageContainer from "containers/MessageContainer";
import {
    BackgroundContainer,
    LoginContainer,
    Logo,
    LoginHeader,
    LoginFooterContainer
} from "styles/Containers";
import {
    Input,
    InputLabel,
    IconInputContainer,
    IconInputIconContainer
} from "styles/Inputs";
import { PrimaryButton, TextLink } from "styles/Buttons";
import { Spinner } from "containers/Spinner";

const ForgotPasswordLinkContainer = styled.div`
    margin-top: 8px;
    text-align: right;
`;

class Login extends Component {
    state = {
        email: "",
        password: "",
        secureTextEntry: true
    };

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    onPressLogin() {
        const { dispatch, history } = this.props;
        const { email, password } = this.state;

        dispatch(
            accountLogin(
                {
                    username: email,
                    password: password
                },
                history
            )
        );
    }

    toggleSecureText() {
        this.setState({ secureTextEntry: !this.state.secureTextEntry });
    }

    render() {
        const { secureTextEntry } = this.state;
        const { isLoading } = this.props;
        return (
            <BackgroundContainer>
                <LoginContainer height="560px">
                    {isLoading ? (
                        <Spinner text="Logging you in..." />
                    ) : (
                        <Fragment>
                            <Logo />
                            <LoginHeader>Log In to OnlineDooze</LoginHeader>
                            <MessageContainer
                                style={{
                                    textAlign: "center",
                                    margin: "15px 0"
                                }}
                            />
                            <InputLabel>Email address</InputLabel>
                            <IconInputContainer
                                style={{ marginBottom: "20px" }}
                                disabled={isLoading}
                            >
                                <FiMail />
                                <Input
                                    type="email"
                                    style={{ height: "42px" }}
                                    disabled={isLoading}
                                    onChange={e =>
                                        this.setState({ email: e.target.value })
                                    }
                                    onKeyDown={e => {
                                        if (e.key === "Enter" && this.refs) {
                                            e.preventDefault();
                                            this.refs["password"].focus();
                                        }
                                    }}
                                />
                            </IconInputContainer>
                            <InputLabel>Password</InputLabel>
                            <IconInputIconContainer disabled={isLoading}>
                                <FiLock />
                                <Input
                                    type={secureTextEntry ? "password" : "text"}
                                    style={{ height: "42px" }}
                                    disabled={isLoading}
                                    onChange={e =>
                                        this.setState({
                                            password: e.target.value
                                        })
                                    }
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            this.onPressLogin();
                                        }
                                    }}
                                    ref="password"
                                />
                                {secureTextEntry ? (
                                    <FiEye
                                        onClick={this.toggleSecureText.bind(
                                            this
                                        )}
                                    />
                                ) : (
                                    <FiEyeOff
                                        onClick={this.toggleSecureText.bind(
                                            this
                                        )}
                                    />
                                )}
                            </IconInputIconContainer>
                            {!isLoading && (
                                <ForgotPasswordLinkContainer>
                                    <TextLink to="/account/forgotPassword">
                                        Forgot Password
                                    </TextLink>
                                </ForgotPasswordLinkContainer>
                            )}
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    marginTop: "20px",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.onPressLogin()}
                                text="LOG IN"
                            />
                            {!isLoading && (
                                <Fragment>
                                    {/* <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    marginTop: "10px",
                                    fontSize: "14px"
                                }}
                                text={"LOG IN AS GUEST"}
                            /> */}
                                    <LoginFooterContainer>
                                        Don't have an account?{" "}
                                        <TextLink to="/account/signUp">
                                            Sign Up here
                                        </TextLink>
                                    </LoginFooterContainer>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </LoginContainer>
            </BackgroundContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading
    };
};

export default connect(mapStateToProps)(Login);
