import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { messageActions } from "reducers/messageReducer";
import { changePassword } from "reducers/userReducer";
import MessageContainer from "./MessageContainer";
import { Loader } from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import { InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { SecuredInput } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

class ChangePasswordModal extends Component {
    state = {
        oldPassword: "",
        secureOldPassword: true,
        newPassword: "",
        secureNewPassword: true,
        confirmPassword: "",
        secureConfirmPassword: true,
        hasError: false,
        submitSuccess: false
    };

    async handleSubmit() {
        const { dispatch } = this.props;
        const { oldPassword, newPassword, confirmPassword } = this.state;

        let errorMessage = "";
        errorMessage =
            oldPassword.length < 8 ||
            newPassword.length < 8 ||
            confirmPassword.length < 8
                ? "Password should contain atleast 8 characters."
                : errorMessage;
        errorMessage =
            newPassword !== confirmPassword
                ? errorMessage.length > 0
                    ? errorMessage +
                      " Confirm password must be equal to new password."
                    : "Confirm password must be equal to new password."
                : errorMessage;

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(
                changePassword({
                    oldPassword,
                    newPassword,
                    confirmPassword
                })
            );
            if (!this.props.errorMessage) {
                this.setState({ hasError: false, submitSuccess: true });
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const {
            oldPassword,
            secureOldPassword,
            newPassword,
            secureNewPassword,
            confirmPassword,
            secureConfirmPassword,
            hasError,
            submitSuccess
        } = this.state;
        const {
            isModalLoading,
            isAdmin,
            modalVisible,
            closeModal
        } = this.props;
        return (
            <Fragment>
                {!submitSuccess ? (
                    <Modal
                        isOpen={modalVisible}
                        onRequestClose={
                            isModalLoading ? null : this.handleClose.bind(this)
                        }
                        style={modalStyle({
                            height: 550,
                            width: 420,
                            backgroundColor: !isAdmin && "rgb(0, 139, 139, 0.9)"
                        })}
                    >
                        <CloseButton
                            onClick={() =>
                                isModalLoading ? null : this.handleClose()
                            }
                        />
                        <ModalHeaderContainer>
                            <ModalHeader>Change Password</ModalHeader>
                        </ModalHeaderContainer>
                        <ModalBody height="330px">
                            <InfoLabel required>OLD PASSWORD</InfoLabel>
                            <SecuredInput
                                required={oldPassword.length < 8 && hasError}
                                disabled={isModalLoading}
                                isSecured={secureOldPassword}
                                onChange={e =>
                                    this.setState({
                                        oldPassword: e.target.value
                                    })
                                }
                                onKeyDown={e => {
                                    if (e.key === "Enter" && this.refs) {
                                        e.preventDefault();
                                        this.refs["newPassword"].focus();
                                    }
                                }}
                                onClick={() =>
                                    this.setState({
                                        secureOldPassword: !secureOldPassword
                                    })
                                }
                            />
                            <InfoLabel required>NEW PASSWORD</InfoLabel>
                            <SecuredInput
                                required={newPassword.length < 8 && hasError}
                                disabled={isModalLoading}
                                isSecured={secureNewPassword}
                                onChange={e =>
                                    this.setState({
                                        newPassword: e.target.value
                                    })
                                }
                                onKeyDown={e => {
                                    if (e.key === "Enter" && this.refs) {
                                        e.preventDefault();
                                        this.refs["confirmPassword"].focus();
                                    }
                                }}
                                onClick={() =>
                                    this.setState({
                                        secureNewPassword: !secureNewPassword
                                    })
                                }
                                ref="newPassword"
                            />
                            <InfoLabel required>CONFIRM PASSWORD</InfoLabel>
                            <SecuredInput
                                required={
                                    (confirmPassword.length < 8 ||
                                        newPassword !== confirmPassword) &&
                                    hasError
                                }
                                disabled={isModalLoading}
                                isSecured={secureConfirmPassword}
                                onChange={e =>
                                    this.setState({
                                        confirmPassword: e.target.value
                                    })
                                }
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        this.handleSubmit();
                                    }
                                }}
                                onClick={() =>
                                    this.setState({
                                        secureConfirmPassword: !secureConfirmPassword
                                    })
                                }
                                ref="confirmPassword"
                            />
                            <MessageContainer />
                        </ModalBody>
                        <ModalFooter style={{ height: "110px" }}>
                            {isModalLoading ? (
                                <Loader text="PROCESSING..." />
                            ) : (
                                <Fragment>
                                    <PrimaryButton
                                        style={{
                                            height: "38px",
                                            width: "100%",
                                            fontSize: "14px"
                                        }}
                                        onClick={() => this.handleSubmit()}
                                        text="CONFIRM"
                                    />
                                    <PrimaryAltButton
                                        style={{
                                            height: "38px",
                                            width: "100%",
                                            fontSize: "14px",
                                            marginTop: "8px"
                                        }}
                                        onClick={() => this.handleClose()}
                                        text="CANCEL"
                                    />
                                </Fragment>
                            )}
                        </ModalFooter>
                    </Modal>
                ) : (
                    <ConfirmationModal
                        body="Your password has been successfuly changed."
                        modalVisible={submitSuccess}
                        submitText="OK"
                        onSubmit={closeModal}
                        closeModal={closeModal}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage,
        isAdmin: state.isAdmin
    };
};
export default connect(mapStateToProps)(ChangePasswordModal);
