import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";
import { messageActions } from "reducers/messageReducer";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { addPaymentReservation } from "reducers/paymentReducer";
import { InfoContainer, InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import {
    Input,
    TextArea,
    InputDatePickerContainer,
    InputDatePicker,
    InputCurrency
} from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

class AdminPaymentReservationModal extends Component {
    state = {
        date: new Date(),
        referenceNumber: "",
        amount: null,
        remarks: "",
        hasError: false
    };

    componentDidMount() {
        const { bill } = this.props;
        this.setState({ amount: Number(bill.balanceWithOverdue) });
    }

    handleAmountChange(e, maskedValue, floatValue) {
        this.setState({ amount: floatValue });
    }

    async handleSubmit() {
        const { bill, closeModal, dispatch } = this.props;
        const { date, amount } = this.state;

        let errorMessage = "";
        errorMessage =
            !amount || !date
                ? "Please fill up all required fields."
                : errorMessage;
        errorMessage =
            amount && bill.balanceWithOverdue < amount
                ? errorMessage.length > 0
                    ? errorMessage + " Amount is more than remaining balance."
                    : "Amount is more than remaining balance."
                : errorMessage;

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(
                addPaymentReservation({ billId: bill.id, payment: this.state })
            );
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const { bill, modalVisible, isModalLoading } = this.props;
        const { date, amount, remarks, hasError } = this.state;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({ height: 470, width: 600 })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>New Payment</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody height="250px">
                    <InfoContainer style={{ marginTop: 0 }}>
                        <div style={{ width: "130px" }}>
                            <InfoLabel required>DATE</InfoLabel>
                            <InputDatePickerContainer>
                                <InputDatePicker
                                    selected={date}
                                    required={!date && hasError}
                                    disabled={isModalLoading}
                                    onChange={selectedDate =>
                                        this.setState({
                                            date: selectedDate
                                        })
                                    }
                                />
                            </InputDatePickerContainer>
                        </div>
                        <div style={{ width: "180px" }}>
                            <InfoLabel>REFERENCE NUMBER</InfoLabel>
                            <Input
                                disabled={isModalLoading}
                                onChange={e =>
                                    this.setState({
                                        referenceNumber: e.target.value
                                    })
                                }
                            />
                        </div>
                        <div style={{ width: "220px" }}>
                            <InfoLabel required>AMOUNT</InfoLabel>
                            <InputCurrency
                                disabled={isModalLoading}
                                required={
                                    (!amount ||
                                        bill.balanceWithOverdue < amount) &&
                                    hasError
                                }
                                allowEmpty
                                value={amount}
                                onChangeEvent={this.handleAmountChange.bind(
                                    this
                                )}
                            />
                        </div>
                    </InfoContainer>
                    <div style={{ marginTop: "18px" }}>
                        <InfoLabel>REMARKS</InfoLabel>
                        <TextArea
                            rows={4}
                            disabled={isModalLoading}
                            onChange={e =>
                                this.setState({
                                    remarks: e.target.value
                                })
                            }
                        />
                    </div>
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text="ADD PAYMENT"
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text="CANCEL"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(AdminPaymentReservationModal);
