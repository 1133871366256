import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Loader } from "containers/Loader";
import { modalStyle, CloseButton, ModalBody, ModalFooter } from "styles/Modals";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

class ConfirmationModal extends Component {
    handleSubmit() {
        const { onSubmit, closeModal } = this.props;
        onSubmit();
        closeModal();
    }

    render() {
        const {
            submitText,
            closeModal,
            cancelText,
            height,
            width,
            body,
            isModalLoading,
            isAdmin,
            modalVisible
        } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={closeModal}
                style={modalStyle({
                    height: height || cancelText ? 230 : 180,
                    width: width || 400,
                    backgroundColor: !isAdmin && "rgb(0, 139, 139, 0.9)"
                })}
            >
                <CloseButton onClick={closeModal} />
                {isModalLoading ? (
                    <Loader text="PROCESSING..." />
                ) : (
                    <Fragment>
                        <ModalBody style={{ marginTop: "30px" }}>
                            {body}
                        </ModalBody>
                        <ModalFooter
                            style={{ height: cancelText ? "110px" : "65px" }}
                        >
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text={submitText}
                            />
                            {cancelText && (
                                <PrimaryAltButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                        marginTop: "8px"
                                    }}
                                    onClick={closeModal}
                                    text={cancelText}
                                />
                            )}
                        </ModalFooter>
                    </Fragment>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isModalLoading: state.isModalLoading,
        isAdmin: state.isAdmin
    };
};
export default connect(mapStateToProps)(ConfirmationModal);
