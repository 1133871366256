import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";

const FETCH_PROPERTIES = "FETCH_PROPERTIES";
const FETCH_PROPERTIES_SUCCESS = "FETCH_PROPERTIES_SUCCESS";
const FETCH_PROPERTIES_FAIL = "FETCH_PROPERTIES_FAIL";

const FETCH_PROPERTY = "FETCH_PROPERTY";
const FETCH_PROPERTY_SUCCESS = "FETCH_PROPERTY_SUCCESS";
const FETCH_PROPERTY_FAIL = "FETCH_PROPERTY_FAIL";

export const propertyActions = {
    FETCH_PROPERTIES,
    FETCH_PROPERTIES_SUCCESS,
    FETCH_PROPERTIES_FAIL,
    FETCH_PROPERTY,
    FETCH_PROPERTY_SUCCESS,
    FETCH_PROPERTY_FAIL
};

export const fetchProperties = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_PROPERTIES });
        return axios
            .request({
                url: `/api/properties`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_PROPERTIES_SUCCESS,
                        payload: res.properties
                    });
                } else {
                    dispatch({ type: FETCH_PROPERTIES_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_PROPERTIES_FAIL });
            });
    };
};

export const fetchProperty = propertyId => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_PROPERTY });
        return axios
            .request({
                url: `/api/property`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    id: propertyId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_PROPERTY_SUCCESS,
                        payload: res.property
                    });
                } else {
                    dispatch({ type: FETCH_PROPERTY_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_PROPERTY_FAIL });
            });
    };
};
