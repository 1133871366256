import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
    FiEye,
    FiEdit,
    FiTrash2,
    FiCreditCard,
    FiChevronLeft,
    FiChevronRight
} from "react-icons/fi";
import colors from "../utils/colors";

export const TableTextHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
`;

export const TableTextHeader = styled.span`
    font-size: 20px;
    font-weight: 500;
`;

export const TableOverflowX = styled.div`
    overflow-x: scroll;
`

export const Table = styled.div`
    display: table;
    border-collapse: collapse;
    width: 100%;
    padding-right: 40px;
    margin-top: 30px;
`;

export const TableHeader = styled.div`
    display: table-row;
    border: 1px solid #ddd;
    color: black;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    background-color: #f5f5f5;
`;

export const TableRow = styled.div`
    display: table-row;
    border: 1px solid #ddd;
    color: black;
    text-decoration: none;
    font-family: "Poppins", sans-serif;

    ${props =>
        props.gray === "" &&
        css`
            background-color: #f5f5f5;
        `}
`;

export const TableLinkRow = styled(Link)`
    display: table-row;
    border: 1px solid #ddd;
    color: black;
    text-decoration: none;
    font-family: "Poppins", sans-serif;

    ${props =>
        props.gray === "" &&
        css`
            background-color: #f5f5f5;
        `}
`;

export const TableCell = styled.div`
    display: table-cell;
    padding: 12px 18px;
`;

const TableActionCell = styled.div`
    display: table-cell;
    padding: 8px;
    border-left: 1px solid #ddd;
    text-align: center;
    align-items: center;
    cursor: pointer;
`;

const TableRowLabel = styled.span`
    font-size: 12px;
`;

export const EmptyListContainer = styled.div`
    margin: 30px;
`;

const PaginationContainer = styled.div`
    display: inline-flex;
    margin-top: 30px;
`;

const PaginationButton = styled.button`
    width: 24px;
    background-color: white;
    cursor: pointer;
    border: none;

    :active {
        color: rgba(0, 0, 0, 0.2);
    }

    :focus {
        outline: none;
    }
`;

export const TableRowViewAction = ({ text }) => (
    <TableActionCell style={{
            color: colors.BLUE,
            whiteSpace: "nowrap",
            minWidth: `85px`,
            width: `100px`,
        }}>
        <FiEye
            style={{
                marginBottom: "-4px",
                marginRight: "4px",
                height: "18px",
                width: "18px",
            }}
        />
        <TableRowLabel> {text}</TableRowLabel>
    </TableActionCell>
);

export const TableRowEditAction = ({ onClick, text }) => (
    <TableActionCell onClick={onClick}>
        <FiEdit
            style={{
                marginBottom: "-3px"
            }}
        />
        <TableRowLabel> {text}</TableRowLabel>
    </TableActionCell>
);

export const TablePayAction = ({ text }) => (
    <TableActionCell style={{ color: colors.BLUE }}>
        <FiCreditCard
            style={{
                marginBottom: "-3px"
            }}
        />
        <TableRowLabel> {text}</TableRowLabel>
    </TableActionCell>
);

export const TableRowRemoveAction = ({ onClick, text }) => (
    <TableActionCell onClick={onClick} style={{ color: "red" }}>
        <FiTrash2
            style={{
                marginBottom: "-3px",
                color: "red"
            }}
        />
        <TableRowLabel> {text}</TableRowLabel>
    </TableActionCell>
);

export const Pagination = ({
    itemsPerPage,
    offset,
    range = offset * itemsPerPage,
    length,
    handlePreviousPage,
    handleNextPage
}) => (
    <div style={{ textAlign: "right" }}>
        <PaginationContainer>
            {offset === 1 ? (
                <PaginationButton>
                    <FiChevronLeft
                        style={{
                            color: "rgba(0,0,0,0.2)",
                            marginTop: "1px"
                        }}
                    />
                </PaginationButton>
            ) : (
                <PaginationButton onClick={handlePreviousPage}>
                    <FiChevronLeft
                        style={{
                            marginTop: "1px"
                        }}
                    />
                </PaginationButton>
            )}
            <span
                style={{
                    fontSize: "small"
                }}
            >
                {`${range - itemsPerPage + 1} - ${
                    range < length ? range : length
                }`}{" "}
                of {length}
            </span>
            {range >= length ? (
                <PaginationButton>
                    <FiChevronRight
                        style={{
                            color: "rgba(0,0,0,0.2)",
                            marginTop: "1px"
                        }}
                    />
                </PaginationButton>
            ) : (
                <PaginationButton onClick={handleNextPage}>
                    <FiChevronRight
                        style={{
                            marginTop: "1px"
                        }}
                    />
                </PaginationButton>
            )}
        </PaginationContainer>
    </div>
);
