import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { resetPassword } from "reducers/userReducer";
import { messageActions } from "reducers/messageReducer";
import {
    BackgroundContainer,
    LoginContainer,
    Logo,
    LoginHeader,
    LoginSubHeader,
} from "styles/Containers";
import { Input, InputLabel, IconInputIconContainer } from "styles/Inputs";
import { PrimaryButton } from "styles/Buttons";

class ResetPassword extends Component {
    state = {
        password: "",
        secureTextEntry: true,
        hasError: false,
        submitSuccess: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE,
        });
    }

    async handleSubmit() {
        const { token, isAdmin, dispatch } = this.props;
        const { password } = this.state;
        if (password.length < 8) {
            this.setState({ hasError: true });
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: "Password should contain atleast 8 characters.",
            });
        } else {
            await dispatch(resetPassword({ token, password, isAdmin }));
            if (!this.props.errorMessage) {
                this.setState({ hasError: false, submitSuccess: true });
            }
        }
    }

    render() {
        const { isAdmin, isLoading, errorMessage, dispatch } = this.props;
        const {
            password,
            secureTextEntry,
            hasError,
            submitSuccess,
        } = this.state;

        return (
            <BackgroundContainer>
                <LoginContainer height="500px">
                    {submitSuccess ? (
                        <Fragment>
                            <Logo />
                            <LoginHeader>Reset Password</LoginHeader>
                            <LoginSubHeader>
                                Reset password successful. Please log in again.
                            </LoginSubHeader>
                            <Link
                                to={`/${isAdmin ? "admin" : "account"}/login`}
                                style={{ textDecoration: "none" }}
                            >
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                    }}
                                    text={"GO BACK TO SIGN IN"}
                                />
                            </Link>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Logo />
                            <LoginHeader>Reset Password</LoginHeader>
                            {errorMessage ? (
                                <MessageContainer
                                    style={{
                                        textAlign: "center",
                                        margin: "20px 0",
                                    }}
                                />
                            ) : (
                                <LoginSubHeader>
                                    Please enter a new password.
                                </LoginSubHeader>
                            )}
                            <InputLabel>New password</InputLabel>
                            <IconInputIconContainer
                                disabled={isLoading}
                                style={{ marginBottom: "20px" }}
                            >
                                <FiLock />
                                <Input
                                    type={secureTextEntry ? "password" : "text"}
                                    style={{ height: "42px" }}
                                    required={password.length < 8 && hasError}
                                    disabled={isLoading}
                                    onChange={(e) =>
                                        this.setState({
                                            password: e.target.value,
                                        })
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            this.handleSubmit();
                                        }
                                    }}
                                    ref="password"
                                />
                                {secureTextEntry ? (
                                    <FiEye
                                        onClick={() =>
                                            this.setState({
                                                secureTextEntry: false,
                                            })
                                        }
                                    />
                                ) : (
                                    <FiEyeOff
                                        onClick={() =>
                                            this.setState({
                                                secureTextEntry: true,
                                            })
                                        }
                                    />
                                )}
                            </IconInputIconContainer>
                            {isLoading ? (
                                <Loader text="PROCESSING..." />
                            ) : (
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                    }}
                                    onClick={() => this.handleSubmit()}
                                    text="RESET PASSWORD"
                                />
                            )}
                        </Fragment>
                    )}
                </LoginContainer>
            </BackgroundContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.isLoading,
        errorMessage: state.errorMessage,
    };
};

export default connect(mapStateToProps)(ResetPassword);
