import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { messageActions } from "reducers/messageReducer";
import Modal from "react-modal";
import { InfoContainer, InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { Select } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";
import { resetUploadResults, uploadUnits } from "reducers/uploadReducer";

class UploadModal extends Component {
    state = {
        building: null,
        file: null,
        hasError: false
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(resetUploadResults());
    }

    async handleSubmit() {
        const { building, file } = this.state;
        const { onSuccess, dispatch } = this.props;

        let errorMessage = "";
        errorMessage = building ? errorMessage : "Please choose a building.";
        errorMessage = file
            ? errorMessage
            : errorMessage + " Please choose a file.";

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(uploadUnits(this.state));
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                onSuccess();
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const { building, hasError } = this.state;
        const { buildingOptions, isModalLoading, modalVisible } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({
                    height: 400,
                    width: 420
                })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>Upload Units</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody height="180px">
                    <Fragment>
                        <InfoContainer>
                            <div style={{ width: "360px" }}>
                                <InfoLabel required>BUILDING</InfoLabel>
                                <Select
                                    value={building}
                                    disabled={isModalLoading}
                                    required={!building && hasError}
                                    onChange={selectedBuilding =>
                                        this.setState({
                                            building: selectedBuilding
                                        })
                                    }
                                    options={buildingOptions}
                                    isSearchable
                                />
                            </div>
                        </InfoContainer>
                        <InfoContainer>
                            <input
                                type="file"
                                onChange={e =>
                                    this.setState({
                                        file: e.target.files[0]
                                    })
                                }
                                accept=".csv"
                                disabled={isModalLoading}
                            />
                        </InfoContainer>
                        {!isModalLoading && (
                            <InfoContainer>
                                <a
                                    href="/csv_templates/bulk_upload.csv"
                                    style={{ textDecoration: "none" }}
                                    download
                                >
                                    Download Template
                                </a>
                            </InfoContainer>
                        )}
                        <MessageContainer />
                    </Fragment>
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text="SUBMIT"
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text="CANCEL"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        buildingOptions:
            state.buildings &&
            state.buildings.map(building => {
                return { value: building.id, label: building.name };
            }),
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(UploadModal);
