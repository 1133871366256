import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import moment from "moment";
import colors from "utils/colors";
import UnitBillModal from "./UnitBillModal";
import { currencyFormat } from "utils/InputPatterns";
import { BillStatus } from "styles/Status";
import {
    Select,
    FiltersContainer,
    FilterLabel,
    InputDatePicker,
    ClearableInputDatePickerContainer
} from "styles/Inputs";
import {
    TabHeaderContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    TabNewItemContainer,
    TabHeaderSpacer
} from "styles/Tabs";
import {
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination
} from "styles/Tables";
import { NewItemLink, AddItemButton } from "styles/Buttons";
import {
    PesoSign,
} from "styles/Containers";

const billFilterStatusOptions = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "Not Paid Full",
        label: "Not Paid Full"
    },
    {
        value: "Unpaid",
        label: "Unpaid"
    },
    {
        value: "Paid Partial",
        label: "Paid Partial"
    },
    {
        value: "Paid Full",
        label: "Paid Full"
    },
    {
        value: "Overdue",
        label: "Overdue"
    }
];

const billsPerPage = 10;

const BillsGrandTotalContainer = styled.div`
    width: 100%;
    text-align: right;
    font-family: "PT Mono", monospace;
    font-size: small;
    margin-top: 12px;
    padding-right: 10px;
    color: ${colors.PRIMARY};
`;

class UnitBillsContainer extends Component {
    state = {
        unitBillModalVisible: false,
        billsFilter: {
            dueDateFrom: null,
            dueDateTo: null,
            coverageStart: null,
            coverageEnd: null,
            category: "",
            status: {
                value: "All",
                label: "All"
            }
        },
        filteredBills: null,
        billsOffset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { bills } = this.props;
        this.setState({
            filteredBills: bills
        });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        const { tabVisible } = this.props;
        if (tabVisible !== prevProps.tabVisible) {
            if (tabVisible) {
                document.addEventListener("keydown", this.handleKeyDown);
            } else {
                document.removeEventListener("keydown", this.handleKeyDown);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    showUnitBillModal() {
        this.setState({ unitBillModalVisible: true });
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    hideUnitBillModal() {
        this.setState({ unitBillModalVisible: false });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    handleNewBillSubmit() {
        this.setState({
            billsFilter: {
                dueDateFrom: null,
                dueDateTo: null,
                coverageStart: null,
                coverageEnd: null,
                category: "",
                status: {
                    value: "All",
                    label: "All"
                }
            },
            filteredBills: this.filterBills({
                dueDateFrom: null,
                dueDateTo: null,
                coverageStart: null,
                coverageEnd: null,
                category: "",
                status: {
                    value: "All",
                    label: "All"
                }
            })
        });
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredBills, billsOffset } = this.state;
        this.setState({
            billsOffset:
                billsOffset < Math.ceil(filteredBills.length / billsPerPage)
                    ? billsOffset + 1
                    : billsOffset
        });
    }

    handlePreviousPage() {
        const { billsOffset } = this.state;
        this.setState({
            billsOffset: billsOffset > 1 ? billsOffset - 1 : billsOffset
        });
    }

    handleFilterBillsDueDateFromChange(dueDateFrom) {
        const { billsFilter } = this.state;
        const dueDateTo =
            !dueDateFrom ||
            moment(dueDateFrom).isSameOrAfter(billsFilter.dueDateTo, "day")
                ? null
                : billsFilter.dueDateTo;
        this.setState({
            billsFilter: Object.assign({}, billsFilter, {
                dueDateFrom,
                dueDateTo
            }),
            filteredBills: this.filterBills({
                dueDateFrom,
                dueDateTo,
                coverageStart: billsFilter.coverageStart,
                coverageEnd: billsFilter.coverageEnd,
                category: billsFilter.category,
                status: billsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterBillsDueDateToChange(dueDateTo) {
        const { billsFilter } = this.state;
        this.setState({
            billsFilter: Object.assign({}, billsFilter, {
                dueDateTo
            }),
            filteredBills: this.filterBills({
                dueDateFrom: billsFilter.dueDateFrom,
                dueDateTo,
                coverageStart: billsFilter.coverageStart,
                coverageEnd: billsFilter.coverageEnd,
                category: billsFilter.category,
                status: billsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterCoverageStartChange(coverageStart) {
        const { billsFilter } = this.state;
        this.setState({
            billsFilter: Object.assign({}, billsFilter, {
                coverageStart
            }),
            filteredBills: this.filterBills({
                dueDateFrom: billsFilter.dueDateFrom,
                dueDateTo: billsFilter.dueDateTo,
                coverageStart,
                coverageEnd: billsFilter.coverageEnd,
                category: billsFilter.category,
                status: billsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterCoverageEndChange(coverageEnd) {
        const { billsFilter } = this.state;
        this.setState({
            billsFilter: Object.assign({}, billsFilter, {
                coverageEnd
            }),
            filteredBills: this.filterBills({
                dueDateFrom: billsFilter.dueDateFrom,
                dueDateTo: billsFilter.dueDateTo,
                coverageStart: billsFilter.coverageStart,
                coverageEnd,
                category: billsFilter.category,
                status: billsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterBillsCategoryChange(category) {
        const { billsFilter } = this.state;
        this.setState({
            billsFilter: Object.assign({}, billsFilter, {
                category
            }),
            filteredBills: this.filterBills({
                dueDateFrom: billsFilter.dueDateFrom,
                dueDateTo: billsFilter.dueDateTo,
                coverageStart: billsFilter.coverageStart,
                coverageEnd: billsFilter.coverageEnd,
                category,
                status: billsFilter.status
            }),
            offset: 1
        });
    }

    handleFilterBillsStatusChange(status) {
        const { billsFilter } = this.state;
        this.setState({
            billsFilter: Object.assign({}, billsFilter, {
                status
            }),
            filteredBills: this.filterBills({
                dueDateFrom: billsFilter.dueDateFrom,
                dueDateTo: billsFilter.dueDateTo,
                coverageStart: billsFilter.coverageStart,
                coverageEnd: billsFilter.coverageEnd,
                category: billsFilter.category,
                status
            }),
            offset: 1
        });
    }

    filterBills({
        dueDateFrom,
        dueDateTo,
        coverageStart,
        coverageEnd,
        category,
        status
    }) {
        const { bills } = this.props;

        return (
            bills &&
            bills.filter(
                bill =>
                    (dueDateFrom
                        ? moment(bill.dueDate).isSameOrAfter(dueDateFrom, "day")
                        : true) &&
                    (dueDateFrom && dueDateTo
                        ? moment(bill.dueDate).isSameOrBefore(dueDateTo, "day")
                        : true) &&
                    (coverageStart
                        ? moment(bill.coverageStart).isSameOrAfter(
                              coverageStart,
                              "day"
                          )
                        : true) &&
                    (coverageEnd
                        ? moment(bill.coverageEnd).isSameOrBefore(
                              coverageEnd,
                              "day"
                          )
                        : true) &&
                    (category
                        ? bill.category
                              .toLowerCase()
                              .includes(category.value.toLowerCase())
                        : true) &&
                    (status.value === "Not Paid Full"
                        ? bill.status !== "Paid Full"
                        : status.value === "All"
                        ? true
                        : bill.status === status.label)
            )
        );
    }

    render() {
        const {
            unitId,
            bills,
            billingCategoryOptions,
            tabVisible,
            onTabChange,
            isAdmin
        } = this.props;
        const {
            billsFilter,
            filteredBills,
            billsOffset,
            unitBillModalVisible
        } = this.state;

        const billsRange = billsOffset * billsPerPage;

        const billRows =
            filteredBills &&
            filteredBills.map((bill, i) => {
                if (i >= billsRange - billsPerPage && i < billsRange) {
                    return (
                        <TableLinkRow
                            key={bill.id}
                            to={`/${isAdmin ? "admin" : "account"}/bills/${
                                bill.id
                            }`}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>
                                {bill.dueDate
                                    ? moment(bill.dueDate).format("MM/DD/YYYY")
                                    : ""}
                            </TableCell>
                            <TableCell>
                                {bill.coverageStart && bill.coverageEnd
                                    ? `${moment(bill.coverageStart).format(
                                          "MM/DD/YYYY"
                                      )} - ${moment(bill.coverageEnd).format(
                                          "MM/DD/YYYY"
                                      )}`
                                    : ""}
                            </TableCell>
                            <TableCell>{bill.category}</TableCell>
                            <TableCell>{bill.remarks}</TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {bill.totalAmountDue
                                    ? currencyFormat(bill.totalAmountDue)
                                    : "0.00"}
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                                <PesoSign />
                                {bill.remainingBalance
                                    ? currencyFormat(bill.remainingBalance)
                                    : "0.00"}
                            </TableCell>
                            <TableCell style={{ textAlign: "-webkit-center" }}>
                                <BillStatus status={bill.status}>
                                    {bill.status}
                                </BillStatus>
                            </TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        const billsGrandTotal = filteredBills
            ? filteredBills.reduce(
                  (acc, curr) => acc + (Number(curr.remainingBalance) || 0),
                  0
              )
            : 0;

        return (
            <Fragment>
                {tabVisible && (
                    <Fragment>
                        {bills.length > 0 ? (
                            <Fragment>
                                <TabHeaderContainer>
                                    <ActiveTabHeader>Bills</ActiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() => onTabChange("payments")}
                                    >
                                        Payments
                                    </InactiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() =>
                                            onTabChange("activityLogs")
                                        }
                                    >
                                        Activity Logs
                                    </InactiveTabHeader>
                                    <InactiveTabHeader
                                        onClick={() =>
                                            onTabChange("runningBalance")
                                        }
                                    >
                                        Running Balance
                                    </InactiveTabHeader>
                                    {isAdmin ? (
                                        <TabNewItemContainer>
                                            <AddItemButton
                                                onClick={() =>
                                                    this.showUnitBillModal()
                                                }
                                                text="NEW BILL"
                                                style={{
                                                    float: "right",
                                                    marginTop: "10px"
                                                }}
                                            />
                                        </TabNewItemContainer>
                                    ) : (
                                        <TabHeaderSpacer />
                                    )}
                                </TabHeaderContainer>
                                <FiltersContainer>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Due Date From</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={
                                                    billsFilter.dueDateFrom
                                                }
                                                selectsStart
                                                startDate={
                                                    billsFilter.dueDateFrom
                                                }
                                                endDate={billsFilter.dueDateTo}
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterBillsDueDateFromChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Due Date To</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={billsFilter.dueDateTo}
                                                disabled={
                                                    !billsFilter.dueDateFrom
                                                }
                                                selectsEnd
                                                minDate={
                                                    billsFilter.dueDateFrom
                                                }
                                                startDate={
                                                    billsFilter.dueDateFrom
                                                }
                                                endDate={billsFilter.dueDateTo}
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterBillsDueDateToChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    {/* <div style={{ width: "130px" }}>
                                        <FilterLabel>
                                            Coverage Start
                                        </FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={
                                                    billsFilter.coverageStart
                                                }
                                                selectsStart
                                                startDate={
                                                    billsFilter.coverageStart
                                                }
                                                endDate={
                                                    billsFilter.coverageEnd
                                                }
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterCoverageStartChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div>
                                    <div style={{ width: "130px" }}>
                                        <FilterLabel>Coverage End</FilterLabel>
                                        <ClearableInputDatePickerContainer>
                                            <InputDatePicker
                                                selected={
                                                    billsFilter.coverageEnd
                                                }
                                                selectsEnd
                                                minDate={
                                                    billsFilter.coverageStart
                                                }
                                                startDate={
                                                    billsFilter.coverageStart
                                                }
                                                endDate={
                                                    billsFilter.coverageEnd
                                                }
                                                isClearable={true}
                                                onChange={date =>
                                                    this.handleFilterCoverageEndChange(
                                                        date
                                                    )
                                                }
                                            />
                                        </ClearableInputDatePickerContainer>
                                    </div> */}
                                    <div style={{ width: "200px" }}>
                                        <FilterLabel>Category</FilterLabel>
                                        <Select
                                            value={billsFilter.category}
                                            onChange={category =>
                                                this.handleFilterBillsCategoryChange(
                                                    category
                                                )
                                            }
                                            options={billingCategoryOptions}
                                            isClearable
                                            isSearchable
                                        />
                                    </div>
                                    <div style={{ width: "150px" }}>
                                        <FilterLabel>Status</FilterLabel>
                                        <Select
                                            value={billsFilter.status}
                                            onChange={status =>
                                                this.handleFilterBillsStatusChange(
                                                    status
                                                )
                                            }
                                            options={billFilterStatusOptions}
                                            isSearchable
                                        />
                                    </div>
                                </FiltersContainer>
                                <Table>
                                    <TableHeader>
                                        <TableCell>Due Date</TableCell>
                                        <TableCell>Coverage</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell
                                            style={{ textAlign: "right" }}
                                        >
                                            Amount Due
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: "right" }}
                                        >
                                            Remaning Balance
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: "center" }}
                                        >
                                            Status
                                        </TableCell>
                                        <TableCell />
                                    </TableHeader>
                                    {billRows}
                                </Table>
                                {filteredBills &&
                                    filteredBills.length > billsPerPage && (
                                        <Pagination
                                            itemsPerPage={billsPerPage}
                                            offset={billsOffset}
                                            length={filteredBills.length}
                                            handlePreviousPage={() =>
                                                this.handlePreviousPage()
                                            }
                                            handleNextPage={() =>
                                                this.handleNextPage()
                                            }
                                        />
                                    )}
                                <BillsGrandTotalContainer>
                                    TOTAL{" "}
                                    {billsFilter.status.value.toUpperCase()}{" "}
                                    REMAINING BALANCE{" "}
                                    PHP{" "}{currencyFormat(billsGrandTotal)}
                                </BillsGrandTotalContainer>
                            </Fragment>
                        ) : (
                            <div>
                                This unit does not have any dues.{" "}
                                {isAdmin && (
                                    <NewItemLink
                                        onClick={() => this.showUnitBillModal()}
                                    >
                                        Charge this unit now.
                                    </NewItemLink>
                                )}
                            </div>
                        )}
                        {unitBillModalVisible && (
                            <UnitBillModal
                                modalVisible={unitBillModalVisible}
                                closeModal={this.hideUnitBillModal.bind(this)}
                                onSubmit={this.handleNewBillSubmit.bind(this)}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        unitId: state.unit && state.unit.id,
        billingCategoryOptions: state.buildings
            ? state.buildings
                  .find(building => building.id === state.unit.buildingId)
                  .billingCategories.map(billingCategory => {
                      return {
                          value: billingCategory.name,
                          label: billingCategory.name
                      };
                  })
            : state.unit &&
              state.unit.bills &&
              [...new Set(state.unit.bills.map(bill => bill.category))].map(
                  category => {
                      return {
                          value: category,
                          label: category
                      };
                  }
              ),
        bills: state.unit.bills || [],
        isAdmin: state.isAdmin
    };
};

export default connect(mapStateToProps)(UnitBillsContainer);
