import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { FiCheck, FiX } from "react-icons/fi";
import styled from "styled-components";
import colors from "utils/colors";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import {
    adminFetchRequisition,
    approveRequisition,
    denyRequisition
} from "reducers/unitReducer";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import {
    ContentContainer,
    MainHeader,
    InfoContainer,
    InfoLabel,
    InfoText
} from "styles/Containers";
import { TextArea } from "styles/Inputs";
import { Button, PrimaryButton, PrimaryAltButton } from "styles/Buttons";

const ActionsContainer = styled.div`
    display: flex;
    width: 185px;
    padding: 8px;
    margin-top: 18px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
`;

class AdminRequisitionContainer extends Component {
    state = {
        denyModalVisible: false,
        denyRemarks: ""
    };

    componentDidMount() {
        const { requisitionId, dispatch } = this.props;
        dispatch(adminFetchRequisition(requisitionId));
    }

    submitApproveHandler() {
        const { requisitionId, dispatch } = this.props;
        dispatch(approveRequisition(requisitionId));
    }

    async submitDenyHandler() {
        const { requisitionId, dispatch } = this.props;
        const { denyRemarks } = this.state;
        await dispatch(
            denyRequisition({ id: requisitionId, remarks: denyRemarks })
        );
        if (!this.props.errorMessage) {
            this.setState({ hasError: false });
            this.closeDenyModalHandler();
        }
    }

    closeDenyModalHandler() {
        this.setState({ denyModalVisible: false, denyRemarks: "" });
    }

    render() {
        const { requisition, isLoading, isModalLoading } = this.props;
        const { denyModalVisible } = this.state;

        return (
            <Fragment>
                <PageHeader
                    title="Back to Requisitions"
                    backTo={`/admin/requisitions`}
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    requisition && (
                        <ContentContainer>
                            <MainHeader>Unit {requisition.unitNo}</MainHeader>
                            <InfoContainer>UNIT INFORMATION</InfoContainer>
                            <InfoContainer>
                                <div>
                                    <InfoLabel>Building</InfoLabel>
                                    <InfoText>
                                        {requisition.buildingName}
                                    </InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Floor</InfoLabel>
                                    <InfoText>{requisition.floor}</InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Area</InfoLabel>
                                    <InfoText>{requisition.area}sqm</InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Type</InfoLabel>
                                    <InfoText>{requisition.unitType}</InfoText>
                                </div>
                            </InfoContainer>
                            <InfoContainer>ACCOUNT INFORMATION</InfoContainer>
                            <InfoContainer>
                                <div>
                                    <InfoLabel>Name</InfoLabel>
                                    <InfoText>
                                        {requisition.accountName}
                                    </InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Email</InfoLabel>
                                    <InfoText>
                                        {requisition.accountEmail}
                                    </InfoText>
                                </div>
                            </InfoContainer>
                            <InfoContainer>
                                REQUISITION INFORMATION
                            </InfoContainer>
                            <InfoContainer>
                                <div>
                                    <InfoLabel>Type</InfoLabel>
                                    <InfoText>{requisition.type}</InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Status</InfoLabel>
                                    <InfoText>{requisition.status}</InfoText>
                                </div>
                                {requisition.remarks && (
                                    <div>
                                        <InfoLabel>Remarks</InfoLabel>
                                        <InfoText>
                                            {requisition.remarks}
                                        </InfoText>
                                    </div>
                                )}
                            </InfoContainer>
                            {(!requisition.status ||
                                requisition.status === "Proposed") && (
                                <ActionsContainer>
                                    <Button
                                        style={{
                                            float: "none",
                                            backgroundColor: colors.RED_PINK,
                                            marginRight: "10px"
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                denyModalVisible: true
                                            })
                                        }
                                    >
                                        <FiX style={{ marginRight: "2px" }} />
                                        <span>DENY</span>
                                    </Button>
                                    <Button
                                        style={{ float: "none" }}
                                        onClick={() =>
                                            this.submitApproveHandler()
                                        }
                                    >
                                        <FiCheck
                                            style={{ marginRight: "2px" }}
                                        />
                                        <span>APPROVE</span>
                                    </Button>
                                </ActionsContainer>
                            )}
                            {denyModalVisible && (
                                <Modal
                                    isOpen={denyModalVisible}
                                    onRequestClose={
                                        isModalLoading
                                            ? null
                                            : this.closeDenyModalHandler.bind(
                                                  this
                                              )
                                    }
                                    style={modalStyle({
                                        height: 360,
                                        width: 400
                                    })}
                                >
                                    <CloseButton
                                        onClick={() =>
                                            isModalLoading
                                                ? null
                                                : this.closeDenyModalHandler()
                                        }
                                    />
                                    <ModalHeaderContainer>
                                        <ModalHeader>
                                            Deny Requisition
                                        </ModalHeader>
                                    </ModalHeaderContainer>
                                    <ModalBody height="140px">
                                        <InfoLabel>REMARKS</InfoLabel>
                                        <TextArea
                                            rows={4}
                                            disabled={isModalLoading}
                                            onChange={e =>
                                                this.setState({
                                                    denyRemarks: e.target.value
                                                })
                                            }
                                        />
                                        <MessageContainer />
                                    </ModalBody>
                                    <ModalFooter style={{ height: "110px" }}>
                                        {isModalLoading ? (
                                            <Loader text="PROCESSING..." />
                                        ) : (
                                            <Fragment>
                                                <PrimaryButton
                                                    style={{
                                                        height: "38px",
                                                        width: "100%",
                                                        fontSize: "14px"
                                                    }}
                                                    onClick={() =>
                                                        this.submitDenyHandler()
                                                    }
                                                    text="SUBMIT"
                                                />
                                                <PrimaryAltButton
                                                    style={{
                                                        height: "38px",
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        marginTop: "8px"
                                                    }}
                                                    onClick={() =>
                                                        this.closeDenyModalHandler()
                                                    }
                                                    text="CANCEL"
                                                />
                                            </Fragment>
                                        )}
                                    </ModalFooter>
                                </Modal>
                            )}
                        </ContentContainer>
                    )
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        requisition: state.requisition,
        isLoading: state.isLoading,
        isModalLoading: state.isModalLoading
    };
};

export default connect(mapStateToProps)(AdminRequisitionContainer);
