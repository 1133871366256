import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { FiCheckCircle } from "react-icons/fi";
import { Loader } from "containers/Loader";
import { modalStyle, CloseButton, ModalBody, ModalFooter } from "styles/Modals";
import { LinkContainer } from "styles/Containers";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";
import colors from "utils/colors";

class AccountBookingSuccessModal extends Component {
    render() {
        const { modalVisible, closeModal, isModalLoading } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={isModalLoading ? null : closeModal}
                style={modalStyle({ height: 350, width: 400 })}
            >
                <CloseButton onClick={isModalLoading ? null : closeModal} />
                <ModalBody
                    height="200px"
                    style={{ textAlign: "center", padding: "42px 0" }}
                >
                    <FiCheckCircle
                        style={{
                            fontSize: "60px",
                            color: colors.BLUE_GREEN
                        }}
                    />
                    <div>
                        <div>Thank you!</div>
                        <div>Your reservation has been sent for approval.</div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ height: "130px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={closeModal}
                                text="Book more (back to today's view)"
                            />
                            <LinkContainer to="/account/amenities">
                                <PrimaryAltButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        marginTop: "10px",
                                        fontSize: "14px"
                                    }}
                                    text="Browse amenities"
                                />
                            </LinkContainer>
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(AccountBookingSuccessModal);
