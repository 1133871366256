import moment from "moment";

export const exportCSV = (array, headers, columns, filename) => {
    const data = array.map(obj => {
        return columns.map(col => (col === "date") || (col.includes("Date")) || (col.includes("coverage"))
            ?   (moment(obj[col]).format("MM/DD/YYYY"))  == "Invalid date"
                ?  " "
                :  (moment(obj[col]).format("MM/DD/YYYY"))
            :   (obj[col])
        ).join(",")
    }).join("\n");


    const csv = headers.join(",") + "\n" + data;

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${filename}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}