import axios from "axios";
import { userActions } from "../reducers/userReducer";
import { messageActions } from "../reducers/messageReducer";

export const adminTestAuth = (history) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: messageActions.CLEAR_MESSAGE });
        dispatch({ type: userActions.SIGNING_IN });
        axios
            .request({
                url: `/api/admin/test_auth`,
                method: "POST",
                data: {
                    token: state && state.jwtoken,
                },
            })
            .then((res) => res.data)
            .then((data) => {
                dispatch({
                    type: userActions.AUTH_SUCCESS,
                    payload: Object.assign({}, data, { isAdmin: true }),
                });
            })
            .catch((err) => {
                console.warn(err);
                history.push("/admin/login");
                dispatch({ type: userActions.LOGOUT });
                dispatch({
                    type: messageActions.DISPLAY_MESSAGE,
                    payload: "Please login.",
                });
            });
    };
};

export const accountTestAuth = (history) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: messageActions.CLEAR_MESSAGE });
        dispatch({ type: userActions.SIGNING_IN });
        axios
            .request({
                url: `/api/account/test_auth`,
                method: "POST",
                data: {
                    token: state && state.jwtoken,
                },
            })
            .then((res) => res.data)
            .then((data) => {
                dispatch({
                    type: userActions.AUTH_SUCCESS,
                    payload: data,
                });
            })
            .catch((err) => {
                console.warn(err);
                history.push("/account/login");
                dispatch({ type: userActions.LOGOUT });
                dispatch({
                    type: messageActions.DISPLAY_MESSAGE,
                    payload: "Please login.",
                });
            });
    };
};
