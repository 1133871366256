import React, { Component } from "react";
import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from "react-currency-input";
import {
    FiEye,
    FiEyeOff,
    FiChevronUp,
    FiChevronDown,
    FiX
} from "react-icons/fi";
import colors from "utils/colors";
import { Button } from "./Buttons";

export const Input = styled.input`
    height: 38px;
    width: 100%;
    padding: 2px 8px 4px 8px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 14px;

    :focus {
        outline: none;
    }

    :disabled {
        background-color: #f5f5f5;
    }

    ::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }

    ${props =>
        props.required &&
        css`
            border-color: red;
        `}
`;

export const TextArea = styled.textarea`
    width: 100%;
    padding: 2px 8px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 18px;

    :disabled {
        background-color: #f5f5f5;
    }

    ${props =>
        props.required &&
        css`
            border-color: red;
        `}
`;

export const FiltersContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;

    > div:not(:first-child) {
        margin-left: 15px;
    }
`;

export const FilterLabel = styled.div`
    font-size: small;
    font-weight: 500;
    margin-bottom: 4px;
`;

export const InputLabel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-weight: 600;

    > span:nth-child(2) {
        margin-top: 4px;
        font-size: 10px;
        font-weight: 300;
        color: red;
    }

    ${props =>
        props.required &&
        css`
            > span:first-child {
                ::after {
                    content: " *";
                    color: red;
                }
            }
        `}
`;

export const InputIconContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    svg:last-child {
        position: absolute;
        right: 10px;
    }

    input {
        width: 100%;
        padding-right: 35px;
    }

    ${props =>
        props.disabled &&
        css`
            background-color: #f5f5f5;
        `}

    ${props =>
        props.required &&
        css`
            border-color: red;
        `}
`;

export const IconInputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    svg:first-child {
        position: absolute;
        left: 10px;
    }

    input {
        width: 100%;
        padding-left: 35px;
    }

    ${props =>
        props.disabled &&
        css`
            background-color: #f5f5f5;
        `}

    ${props =>
        props.required &&
        css`
            border-color: red;
        `}
`;

export const IconInputIconContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    svg:first-child {
        position: absolute;
        left: 10px;
    }

    svg:last-child {
        position: absolute;
        right: 10px;
    }

    input {
        width: 100%;
        padding: 0 35px;
    }

    ${props =>
        props.disabled &&
        css`
            background-color: #f5f5f5;
        `}

    ${props =>
        props.required &&
        css`
            border-color: red;
        `}
`;

export const InputDatePickerContainer = styled.div`
    .react-datepicker-popper {
        z-index: 10;
    }
`;

export const InputDatePicker = styled(DatePicker)`
    height: 38px;
    width: 100%;
    padding: 2px 8px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 18px;

    :disabled {
        background-color: #f5f5f5;
    }

    ${props =>
        props.required &&
        css`
            border-color: red;
        `}
`;

export const ClearableInputDatePickerContainer = styled.div`
    .react-datepicker__close-icon {
        margin-top: 1px;
    }
`;

export const FlippedInputDatePickerContainer = styled.div`
    .react-datepicker__triangle {
        left: unset;
        right: 15px;
    }
`;

export const InputCurrency = styled(CurrencyInput)`
    height: 38px;
    width: 100%;
    padding: 2px 8px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 18px;

    :disabled {
        background-color: #f5f5f5;
    }

    ${props =>
        props.required &&
        css`
            border-color: red;
        `}
`;

export const SecuredInput = React.forwardRef(
    ({ required, disabled, isSecured, onChange, onKeyDown, onClick }, ref) => (
        <InputIconContainer required={required} disabled={disabled}>
            <Input
                type={isSecured ? "password" : "text"}
                disabled={disabled}
                onChange={onChange}
                onKeyDown={onKeyDown}
                ref={ref}
                style={{ fontSize: "18px" }}
            />
            {isSecured ? (
                <FiEye onClick={onClick} style={{ color: colors.PRIMARY }} />
            ) : (
                <FiEyeOff onClick={onClick} style={{ color: colors.PRIMARY }} />
            )}
        </InputIconContainer>
    )
);

const DropdownContainer = styled.div`
    position: relative;
`;

const DropdownButton = styled.button`
    position: relative;
    display: flex;
    height: 38px;
    width: 100%;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    text-align: left;
    align-items: center;
    background-color: white;
    cursor: pointer;

    svg {
        position: absolute;
        right: 10px;
    }

    :focus {
        outline: none;
    }

    :disabled {
        background-color: #f5f5f5;
    }

    ${props =>
        props.required &&
        css`
            border-color: red;
        `}
`;

export const DropdownMenu = styled.div`
    position: absolute;
    max-height: 150px;
    width: 100%;
    margin-top: 5px;
    padding: 10px 0;
    border: solid 0.5px ${colors.LIGHT_GRAY};
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 8px -6px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    z-index: 10;

    ${props =>
        props.width &&
        css`
            width: ${props.width};
        `}
`;

export const DropdownOption = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 10px;
    cursor: pointer;

    ${props =>
        props.disabled
            ? css`
                  color: ${colors.LIGHT_GRAY};
                  cursor: default;
              `
            : css`
                  :hover {
                      background-color: ${colors.LIGHT_GRAY};
                  }
              `}
`;

export class Select extends Component {
    state = {
        dropdownVisible: false,
        filter: "",
        filteredOptions: []
    };

    constructor(props) {
        super(props);
        this.hideDropdown = this.hideDropdown.bind(this);
    }

    componentDidMount() {
        const { value, options } = this.props;
        this.setState({
            filter: value ? value.label : "",
            filteredOptions: options
        });
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (value !== prevProps.value) {
            this.setState({ filter: value ? value.label : "" });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.hideDropdown);
    }

    showDropdown(e) {
        const { options, isSearchable } = this.props;

        e.preventDefault();
        this.setState({ filteredOptions: options, dropdownVisible: true });

        if (isSearchable) {
            this.refs["filter"].focus();
        }
        document.addEventListener("click", this.hideDropdown);
    }

    hideDropdown() {
        const { value, options } = this.props;
        this.setState(
            {
                dropdownVisible: false,
                filter: value ? value.label : "",
                filteredOptions:
                    value && options.length > 0
                        ? options.filter(o =>
                              o.label
                                  .toLowerCase()
                                  .includes(value.label.toLowerCase())
                          )
                        : options
            },
            () => {
                document.removeEventListener("click", this.hideDropdown);
            }
        );
    }

    handleFilterChange(filter) {
        const { options } = this.props;
        this.setState({
            filter,
            filteredOptions:
                options.length > 0
                    ? options.filter(o =>
                          o.label.toLowerCase().includes(filter.toLowerCase())
                      )
                    : []
        });
    }

    render() {
        const { dropdownVisible, filter, filteredOptions } = this.state;
        const {
            value,
            placeholder,
            required,
            disabled,
            onChange,
            isSearchable,
            isClearable
        } = this.props;

        const optionRows =
            filteredOptions && filteredOptions.length > 0 ? (
                filteredOptions.map(o => (
                    <DropdownOption onClick={() => onChange(o)} key={o.label}>
                        {o.label}
                    </DropdownOption>
                ))
            ) : (
                <DropdownOption style={{ textAlign: "center" }} disabled>
                    No options available
                </DropdownOption>
            );

        return (
            <DropdownContainer>
                {isSearchable ? (
                    <InputIconContainer
                        style={{ cursor: "pointer" }}
                        onClick={e =>
                            dropdownVisible
                                ? this.setState({ dropdownVisible: false })
                                : !disabled && this.showDropdown(e)
                        }
                    >
                        <Input
                            value={filter}
                            required={required}
                            disabled={disabled}
                            onChange={e =>
                                this.handleFilterChange(e.target.value)
                            }
                            placeholder={placeholder || "Select one"}
                            style={{ cursor: "pointer" }}
                            ref="filter"
                        />
                        {isClearable && value && (
                            <FiX
                                style={{ position: "absolute", right: "25px" }}
                                onClick={e => {
                                    this.setState({ filter: "" });
                                    onChange(null);
                                    e.stopPropagation();
                                }}
                            />
                        )}
                        {dropdownVisible ? <FiChevronUp /> : <FiChevronDown />}
                    </InputIconContainer>
                ) : (
                    <DropdownButton
                        required={required}
                        disabled={disabled}
                        onClick={e =>
                            dropdownVisible
                                ? this.setState({ dropdownVisible: false })
                                : !disabled && this.showDropdown(e)
                        }
                    >
                        {!value ? (
                            <span
                                style={{
                                    width: "100%",
                                    color: colors.GRAY,
                                    paddingLeft: "4px"
                                }}
                            >
                                {placeholder || "Select one"}
                            </span>
                        ) : (
                            <span
                                style={{
                                    width: "100%",
                                    paddingLeft: "4px"
                                }}
                            >
                                {value.label}
                            </span>
                        )}
                        {isClearable && value && (
                            <FiX
                                style={{ right: "25px" }}
                                onClick={e => {
                                    this.setState({ filter: "" });
                                    onChange(null);
                                    e.stopPropagation();
                                }}
                            />
                        )}
                        {dropdownVisible ? <FiChevronUp /> : <FiChevronDown />}
                    </DropdownButton>
                )}
                {dropdownVisible && <DropdownMenu>{optionRows}</DropdownMenu>}
            </DropdownContainer>
        );
    }
}

export class ButtonSelect extends Component {
    state = {
        dropdownVisible: false
    };

    constructor(props) {
        super(props);
        this.hideDropdown = this.hideDropdown.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.hideDropdown);
    }

    showDropdown(e) {
        e.preventDefault();
        this.setState({ dropdownVisible: true });
        document.addEventListener("click", this.hideDropdown);
    }

    hideDropdown() {
        this.setState({ dropdownVisible: false }, () => {
            document.removeEventListener("click", this.hideDropdown);
        });
    }

    render() {
        const { dropdownVisible } = this.state;
        const {
            value,
            options,
            disabled,
            onChange,
            buttonStyle,
            menuStyle
        } = this.props;

        const optionRows =
            options && options.length > 0 ? (
                options.map(o => (
                    <DropdownOption onClick={() => onChange(o)} key={o.label}>
                        {o.label}
                    </DropdownOption>
                ))
            ) : (
                <DropdownOption style={{ textAlign: "center" }} disabled>
                    No options available
                </DropdownOption>
            );

        return (
            <DropdownContainer>
                <Button
                    disabled={disabled}
                    onClick={e =>
                        dropdownVisible
                            ? this.setState({ dropdownVisible: false })
                            : !disabled && this.showDropdown(e)
                    }
                    style={buttonStyle}
                >
                    <span
                        style={{
                            width: "100%",
                            paddingLeft: "4px"
                        }}
                    >
                        {value.label}
                    </span>
                    {dropdownVisible ? (
                        <FiChevronUp style={{ marginLeft: "5px" }} />
                    ) : (
                        <FiChevronDown style={{ marginLeft: "5px" }} />
                    )}
                </Button>
                {dropdownVisible && (
                    <DropdownMenu style={menuStyle}>{optionRows}</DropdownMenu>
                )}
            </DropdownContainer>
        );
    }
}
