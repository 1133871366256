import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SideNavLogo from "../../../assets/images/logonavwhite.png";
import {
    FiLayout,
    FiHome,
    FiUsers,
    FiFile,
    FiCreditCard,
    FiColumns,
    FiBook,
    FiSettings
} from "react-icons/fi";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { SideNavLinkContainer } from "styles/Containers";
import colors from "utils/colors";

const NavigationContainer = styled.div`
    @media screen and (max-width: 480px) {
        width: ${props => (props.showNav ? "200px" : "0")};
        transition: all 1s ease-out;
    }
    width: 205px;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    background-color: darkcyan;
    font-size: 14px;
    font-weight: 600;
`;

const ActiveTabStyle = {
    backgroundColor: "white",
    color: colors.PRIMARY
};

const SideLogoContainer = styled.div`
    @media screen and (max-width: 480px) {
        height: 30px;
        margin: 10px 50px;

        :before {
            content: "";
            position: absolute;
            left: 25px;
            top: 20px;
            bottom: 0;
            width: 1em;
            height: 2px;
            background: white;
            box-shadow: 0 10px 0 0 white, 0 5px 0 0 white;
        }
    }

    height: 30px;
    width: 110px;
    background-image: ${props => `url(${props.background})`};
    background-size: contain;
    background-repeat: no-repeat;
    margin: 25px;
    margin-bottom: 20px;

    img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const MobileNavLogoLink = styled.div`
    @media screen and (min-width: 481px) {
        display: none;
    }
`;

const DesktopNavLogoLink = styled(Link)`
    @media screen and (max-width: 480px) {
        display: none;
    }
`;

const SideNavHelpContainer = styled(SideNavLinkContainer)`
    position: absolute;
    bottom: 0;
`

const AccountNavigation =({ showModal, hasTenants, showNav, dispatch }) => {
    const closeNav = () => dispatch({ type: "CLOSE_NAV" });
    // console.log(showModal);

    return (
        <NavigationContainer showNav={showNav}>
            <MobileNavLogoLink onClick={() => dispatch({ type: "TOGGLE_NAV" })}>
                <SideLogoContainer background={SideNavLogo} showNav={showNav} />
            </MobileNavLogoLink>
            <DesktopNavLogoLink to="/account/units">
                <SideLogoContainer background={SideNavLogo} />
            </DesktopNavLogoLink>
            <SideNavLinkContainer
                onClick={closeNav}
                to="/account"
                exact
                activeStyle={ActiveTabStyle}
            >
                <FiLayout />
                DASHBOARD
            </SideNavLinkContainer>
            <SideNavLinkContainer
                onClick={closeNav}
                to="/account/units"
                activeStyle={ActiveTabStyle}
            >
                <FiHome />
                UNITS
            </SideNavLinkContainer>
            {hasTenants && (
                <SideNavLinkContainer
                    onClick={closeNav}
                    to="/account/tenants"
                    activeStyle={ActiveTabStyle}
                >
                    <FiUsers />
                    TENANTS
                </SideNavLinkContainer>
            )}
            <SideNavLinkContainer
                onClick={closeNav}
                to="/account/bills"
                activeStyle={ActiveTabStyle}
            >
                <FiFile />
                BILLS
            </SideNavLinkContainer>
            <SideNavLinkContainer
                onClick={closeNav}
                to="/account/payments"
                activeStyle={ActiveTabStyle}
            >
                <FiCreditCard />
                PAYMENTS
            </SideNavLinkContainer>
            <SideNavLinkContainer
                onClick={closeNav}
                to="/account/amenities"
                activeStyle={ActiveTabStyle}
            >
                <FiColumns />
                AMENITIES
            </SideNavLinkContainer>
            <SideNavLinkContainer
                onClick={closeNav}
                to="/account/reservations"
                activeStyle={ActiveTabStyle}
            >
                <FiBook />
                RESERVATIONS
            </SideNavLinkContainer>
            <SideNavLinkContainer
                onClick={closeNav}
                to="/account/settings"
                exact
                activeStyle={ActiveTabStyle}
            >
                <FiSettings />
                ACCOUNT SETTINGS
            </SideNavLinkContainer>
            <SideNavHelpContainer
                to="#"
                onClick={showModal}
                >
                    <IoIosHelpCircleOutline />
                    HELP
            </SideNavHelpContainer>
        </NavigationContainer>
    );
};

const mapStateToProps = state => {
    return {
        unitId: state.units && state.units.length === 1 && state.units[0].id,
        hasTenants: state.user.hasTenants,
        showNav: state.showNav
    };
};

export default connect(mapStateToProps)(AccountNavigation);
