import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";

const FETCH_OWNERS = "FETCH_OWNERS";
const FETCH_OWNERS_SUCCESS = "FETCH_OWNERS_SUCCESS";
const FETCH_OWNERS_FAIL = "FETCH_OWNERS_FAIL";

const ADD_OWNER = "ADD_OWNER";
const ADD_OWNER_SUCCESS = "ADD_OWNER_SUCCESS";
const ADD_OWNER_FAIL = "ADD_OWNER_FAIL";

const UPDATE_OWNER = "UPDATE_OWNER";
const UPDATE_OWNER_SUCCESS = "UPDATE_OWNER_SUCCESS";
const UPDATE_OWNER_FAIL = "UPDATE_OWNER_FAIL";

const FETCH_OWNER = "FETCH_OWNER";
const FETCH_OWNER_SUCCESS = "FETCH_OWNER_SUCCESS";
const FETCH_OWNER_FAIL = "FETCH_OWNER_FAIL";

export const ownerActions = {
    ADD_OWNER,
    ADD_OWNER_SUCCESS,
    ADD_OWNER_FAIL,
    UPDATE_OWNER,
    UPDATE_OWNER_SUCCESS,
    UPDATE_OWNER_FAIL,
    FETCH_OWNER,
    FETCH_OWNER_SUCCESS,
    FETCH_OWNER_FAIL,
    FETCH_OWNERS,
    FETCH_OWNERS_SUCCESS,
    FETCH_OWNERS_FAIL
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case ADD_OWNER_SUCCESS:
            return state.concat(payload);
        case UPDATE_OWNER_SUCCESS:
            return Object.assign({}, state, payload);
        default:
            return state;
    }
};

export const fetchOwners = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_OWNERS });
        return axios
            .request({
                url: `/api/admin/owners`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_OWNERS_SUCCESS,
                        payload: res.owners
                    });
                    return res.owners;
                } else {
                    dispatch({ type: FETCH_OWNERS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_OWNERS_FAIL });
            });
    };
};

export const updateOwner = ({
    id,
    type,
    name,
    firstName,
    lastName,
    email,
    phoneNumber,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhoneNumber
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: UPDATE_OWNER });
        return axios
            .request({
                url: `/api/admin/update_owner`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    id,
                    ownerType: type.label,
                    ownerName: type.value === "Company" ? name : null,
                    ownerFirstName:
                        type.value === "Individual" ? firstName : null,
                    ownerLastName:
                        type.value === "Individual" ? lastName : null,
                    ownerEmail: email,
                    ownerPhoneNumber: phoneNumber,
                    contactFirstName,
                    contactLastName,
                    contactEmail,
                    contactPhoneNumber
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: UPDATE_OWNER_SUCCESS,
                        payload: {
                            id,
                            ownerType: type.label,
                            name:
                                type.value === "Company"
                                    ? name
                                    : firstName + " " + lastName,
                            firstName:
                                type.value === "Individual" ? firstName : null,
                            lastName:
                                type.value === "Individual" ? lastName : null,
                            email,
                            phoneNumber,
                            contactFirstName,
                            contactLastName,
                            contactEmail,
                            contactPhoneNumber
                        }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully updated owner.");
                } else {
                    dispatch({ type: UPDATE_OWNER_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: UPDATE_OWNER_FAIL });
            });
    };
};

export const fetchOwner = ownerId => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_OWNER });
        return axios
            .request({
                url: `/api/admin/owner`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    id: ownerId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_OWNER_SUCCESS,
                        payload: {
                            owner: res.owner,
                            units: res.units,
                            bills: res.dues,
                            reservations: res.reservations,
                            payments: res.payments
                        }
                    });
                } else {
                    dispatch({ type: FETCH_OWNER_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please login."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_OWNER_FAIL });
            });
    };
};
