import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import Navigation from "./AccountNavigation";
import AccountUnitsContainer from "./AccountUnitsContainer";
import AccountUnitContainer from "./AccountUnitContainer";
import AccountRequisitionContainer from "./AccountRequisitionContainer";
import AccountAmenitiesContainer from "./AccountAmenitiesContainer";
import AccountAmenityContainer from "./AccountAmenityContainer";
import AccountBillsContainer from "./AccountBillsContainer";
import BillContainer from "../BillContainer";
import TenantsContainer from "../TenantsContainer";
import TenantContainer from "../TenantContainer";
import ProfileContainer from "./ProfileContainer";
import PaymentContainer from "../PaymentContainer";
import AccountPaymentsContainer from "./AccountPaymentsContainer";
import AccountReservationsContainer from "./AccountReservationsContainer";
import AccountReservationContainer from "./AccountReservationContainer";
import NotificationsContainer from "../NotificationsContainer";
import AccountDashboardContainer from "./AccountDashboardContainer";
import HelpModalContainer from "containers/HelpModalContainer";

const MainContainer = styled.div`
    @media screen and (min-width: 481px) {
        margin-left: 205px;
    }

    background-color: white;
    height: 100vh;
    overflow: scroll;
`;

class LoggedIn extends Component {
    state = {
        showHelpModal: false,
    };

    componentDidMount() {}

    toggleHelpModal(value) {
        this.setState({ showHelpModal: value });
    }

    render() {
        const { showHelpModal } = this.state;
        const { toggleHelpModal } = this.props;

        return (
            <MainContainer>
                <Navigation
                    showModal={() => {
                        this.toggleHelpModal(true);
                    }}
                />
                {showHelpModal && (
                    <HelpModalContainer
                        hideModal={() => {
                            this.toggleHelpModal(false);
                        }}
                    />
                )}
                <Switch>
                    <Route
                        exact
                        path="/account/units/:unitId"
                        render={({ match, location }) => (
                            <AccountUnitContainer
                                unitId={match.params.unitId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/account/bills/:billId"
                        render={({ match, location }) => (
                            <BillContainer
                                unitId={match.params.unitId}
                                billId={match.params.billId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/account/unitClaiming"
                        component={AccountRequisitionContainer}
                    />
                    <Route
                        exact
                        path="/account/units"
                        component={AccountUnitsContainer}
                    />
                    <Route
                        exact
                        path="/account/bills"
                        component={AccountBillsContainer}
                    />
                    <Route
                        exact
                        path="/account/payments/:paymentId"
                        render={({ match, location }) => (
                            <PaymentContainer
                            paymentId={match.params.paymentId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/account/payments"
                        component={AccountPaymentsContainer}
                    />
                    <Route
                        exact
                        path="/account/tenants/:tenantId"
                        render={({ match, location }) => (
                            <TenantContainer
                                tenantId={match.params.tenantId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/account/tenants"
                        component={TenantsContainer}
                    />
                    <Route
                        exact
                        path="/account/amenities/:amenityId"
                        render={({ match }) => (
                            <AccountAmenityContainer
                                amenityId={match.params.amenityId}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/account/amenities"
                        component={AccountAmenitiesContainer}
                    />
                    <Route
                        exact
                        path="/account/reservations/:reservationId"
                        render={({ match, location }) => (
                            <AccountReservationContainer
                                reservationId={match.params.reservationId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/account/reservations"
                        component={AccountReservationsContainer}
                    />
                    <Route
                        exact
                        path="/account/notifications"
                        component={NotificationsContainer}
                    />
                    <Route
                        exact
                        path="/account/settings"
                        component={ProfileContainer}
                    />
                    <Route
                        path="/account"
                        component={AccountDashboardContainer}
                    />
                </Switch>
            </MainContainer>
        );
    }
}

export default withRouter(connect()(LoggedIn));
