import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer, { defaultState } from "reducers";
import thunk from "redux-thunk";

const sessionStorageMiddleware = store => {
    return next => action => {
        const result = next(action);
        const state = store.getState();

        localStorage.setItem("jwtoken", JSON.stringify(state.jwtoken));

        return result;
    };
};

const composeEnhancers = Boolean(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const enhancer = composeEnhancers(
    applyMiddleware(thunk, sessionStorageMiddleware)
);

const parseJSON = value => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

const stateFromStorage = () => {
    const jwtoken = parseJSON(localStorage.getItem("jwtoken")) || {};
    const isLoggedIn = Object.keys(jwtoken).length > 0;

    return {
        jwtoken,
        isLoggedIn
    };
};

const store = createStore(
    rootReducer,
    { ...defaultState, ...stateFromStorage() },
    enhancer
);

export default props => {
    return (
        <Provider store={store} {...props}>
            {props.children}
        </Provider>
    );
};
