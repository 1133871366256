import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import AccountRequisitionModal from "./AccountRequisitionModal";
import AccountRequisitionSuccessModal from "./AccountRequisitionSuccessModal";
import { fetchProperties } from "reducers/propertyReducer";
import { fetchBuildings } from "reducers/buildingReducer";
import {
    accountFetchUnclaimedUnits,
    resetUnclaimedUnits
} from "reducers/unitReducer";
import { InputLabel, Select } from "styles/Inputs";
import { ContentContainer } from "styles/Containers";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableRow,
    TableCell,
    TableRowViewAction,
    Pagination,
    EmptyListContainer
} from "styles/Tables";

const unitsPerPage = 10;

const typeOptions = [
    {
        value: "Owner",
        label: "Owner"
    },
    {
        value: "Tenant",
        label: "Tenant"
    }
];

class AccountRequisitionContainer extends Component {
    state = {
        property: null,
        building: null,
        type: null,
        unit: null,
        offset: 1,
        unitClaimingModalVisible: false,
        unitClaimingSuccessModalVisible: false
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchProperties());
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(resetUnclaimedUnits());
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { unclaimedUnits } = this.props;
        const { offset } = this.state;
        this.setState({
            offset:
                offset < Math.ceil(unclaimedUnits.length / unitsPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    handlePropertyChange(property) {
        const { dispatch } = this.props;
        this.setState({ property, building: null });
        dispatch(fetchBuildings(property.value));
    }

    handleFetchUnits({ building, type }) {
        const { dispatch } = this.props;
        this.setState({ building, type });
        if (building && type) {
            dispatch(
                accountFetchUnclaimedUnits({
                    buildingId: building.value,
                    requisitionType: type.value
                })
            );
        }
    }

    render() {
        const { properties, buildings, unclaimedUnits, isLoading } = this.props;
        const {
            property,
            building,
            type,
            unit,
            offset,
            unitClaimingModalVisible,
            unitClaimingSuccessModalVisible
        } = this.state;
        const range = offset * unitsPerPage;

        const propertyOptions =
            properties &&
            properties.map(p => {
                return {
                    value: p.id,
                    label: p.name
                };
            });

        const buildingOptions =
            buildings &&
            buildings.map(b => {
                return {
                    value: b.id,
                    label: b.name
                };
            });

        const unclaimedUnitRows =
            unclaimedUnits &&
            unclaimedUnits.map((unit, i) => {
                if (i >= range - unitsPerPage && i < range) {
                    return (
                        <TableRow
                            key={unit.id}
                            gray={(i % 2 === 0).toString()}
                            onClick={() =>
                                this.setState({
                                    unit,
                                    unitClaimingModalVisible: true
                                })
                            }
                        >
                            <TableCell>{unit.number}</TableCell>
                            <TableCell>{unit.status}</TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableRow>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader title="Back to Home" backTo="/account/units" />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ContentContainer>
                        <TableTextHeaderContainer>
                            <TableTextHeader>Claim Units</TableTextHeader>
                        </TableTextHeaderContainer>
                        <div style={{ marginTop: "20px" }}>
                            <InputLabel>Property</InputLabel>
                            <Select
                                value={property}
                                disabled={isLoading}
                                onChange={selectedProperty =>
                                    this.handlePropertyChange(selectedProperty)
                                }
                                options={propertyOptions}
                            />
                            <InputLabel style={{ marginTop: "12px" }}>
                                Building
                            </InputLabel>
                            <Select
                                value={building}
                                disabled={isLoading || !property}
                                onChange={selectedBuilding =>
                                    this.handleFetchUnits({
                                        building: selectedBuilding,
                                        type
                                    })
                                }
                                options={buildingOptions}
                            />
                            <InputLabel style={{ marginTop: "12px" }}>
                                Claim Type
                            </InputLabel>
                            <Select
                                value={type}
                                disabled={isLoading || !building}
                                onChange={selectedType =>
                                    this.handleFetchUnits({
                                        building,
                                        type: selectedType
                                    })
                                }
                                options={typeOptions}
                            />
                        </div>
                        {unclaimedUnits && unclaimedUnits.length > 0 ? (
                            <div>
                                <InputLabel style={{ marginTop: "12px" }}>
                                    Units
                                </InputLabel>
                                <Table style={{ marginTop: "0" }}>
                                    {unclaimedUnitRows}
                                </Table>
                                {unclaimedUnits &&
                                    unclaimedUnits.length > unitsPerPage && (
                                        <Pagination
                                            itemsPerPage={unitsPerPage}
                                            offset={offset}
                                            length={unclaimedUnits.length}
                                            handlePreviousPage={() =>
                                                this.handlePreviousPage()
                                            }
                                            handleNextPage={() =>
                                                this.handleNextPage()
                                            }
                                        />
                                    )}
                            </div>
                        ) : (
                            <EmptyListContainer>
                                There are no claimable units here, try changing
                                property and/or building.
                            </EmptyListContainer>
                        )}
                        {unitClaimingModalVisible && (
                            <AccountRequisitionModal
                                modalVisible={unitClaimingModalVisible}
                                unit={unit}
                                type={type}
                                onSuccess={() =>
                                    this.setState({
                                        unitClaimingModalVisible: false,
                                        unitClaimingSuccessModalVisible: true
                                    })
                                }
                                closeModal={() =>
                                    this.setState({
                                        unitClaimingModalVisible: false
                                    })
                                }
                            />
                        )}
                        {unitClaimingSuccessModalVisible && (
                            <AccountRequisitionSuccessModal
                                modalVisible={unitClaimingSuccessModalVisible}
                                closeModal={() =>
                                    this.setState({
                                        unitClaimingSuccessModalVisible: false
                                    })
                                }
                            />
                        )}
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        properties: state.properties,
        buildings: state.buildings,
        unclaimedUnits: state.unclaimedUnits,
        isLoading: state.isLoading
    };
};

export default connect(mapStateToProps)(AccountRequisitionContainer);
