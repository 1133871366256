import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import { FiSearch } from "react-icons/fi";
import { adminFetchRequisitions } from "reducers/unitReducer";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Table,
    TableHeader,
    TableLinkRow,
    TableCell,
    TableRowViewAction,
    Pagination,
    EmptyListContainer
} from "styles/Tables";
import { ContentContainer } from "styles/Containers";
import {
    Input,
    IconInputContainer,
    Select,
    FiltersContainer,
    FilterLabel
} from "styles/Inputs";

const requisitionsPerPage = 10;

const filterTypeOptions = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "Owner",
        label: "Owner"
    },
    {
        value: "Tenant",
        label: "Tenant"
    }
];

const filterStatusOptions = [
    {
        value: "All",
        label: "All"
    },
    {
        value: "Proposed",
        label: "Proposed"
    },
    {
        value: "Approved",
        label: "Approved"
    },
    {
        value: "Denied",
        label: "Denied"
    }
];

class AdminRequisitionsContainer extends Component {
    state = {
        filter: {
            building: "",
            unit: "",
            name: "",
            email: "",
            type: {
                value: "All",
                label: "All"
            },
            status: {
                value: "Proposed",
                label: "Proposed"
            }
        },
        filteredRequisitions: null,
        offset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(adminFetchRequisitions()).then(requisitions =>
                this.setState({
                    filteredRequisitions: requisitions
                        ? requisitions.filter(r => r.status === "Proposed")
                        : []
                })
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { requisitions } = this.props;
        const { offset } = this.state;
        this.setState({
            offset:
                offset < Math.ceil(requisitions.length / requisitionsPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    handleFilterBuildingChange(building) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                building
            }),
            filteredRequisitions: this.filterRequisitions({
                building,
                unit: filter.unit,
                name: filter.name,
                email: filter.email,
                type: filter.type,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterUnitChange(unit) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                unit
            }),
            filteredRequisitions: this.filterRequisitions({
                building: filter.building,
                unit,
                name: filter.name,
                email: filter.email,
                type: filter.type,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterNameChange(name) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                name
            }),
            filteredRequisitions: this.filterRequisitions({
                building: filter.building,
                unit: filter.unit,
                name,
                email: filter.email,
                type: filter.type,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterEmailChange(email) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                email
            }),
            filteredRequisitions: this.filterRequisitions({
                building: filter.building,
                unit: filter.unit,
                name: filter.name,
                email,
                type: filter.type,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterTypeChange(type) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                type
            }),
            filteredRequisitions: this.filterRequisitions({
                building: filter.building,
                unit: filter.unit,
                name: filter.name,
                email: filter.email,
                type,
                status: filter.status
            }),
            offset: 1
        });
    }

    handleFilterStatusChange(status) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                status
            }),
            filteredRequisitions: this.filterRequisitions({
                building: filter.building,
                unit: filter.unit,
                name: filter.name,
                email: filter.email,
                type: filter.type,
                status
            }),
            offset: 1
        });
    }

    filterRequisitions({ building, unit, name, email, type, status }) {
        const { requisitions } = this.props;

        return (
            requisitions &&
            requisitions.filter(
                requisition =>
                    (building
                        ? requisition.buildingName
                              .toLowerCase()
                              .includes(building.toLowerCase())
                        : true) &&
                    (unit
                        ? requisition.unitNo
                              .toLowerCase()
                              .includes(unit.toLowerCase())
                        : true) &&
                    (name
                        ? requisition.accountName
                              .toLowerCase()
                              .includes(name.toLowerCase())
                        : true) &&
                    (email
                        ? requisition.accountEmail
                              .toLowerCase()
                              .includes(email.toLowerCase())
                        : true) &&
                    (type
                        ? type.value === "All" ||
                          requisition.type === type.value
                        : true) &&
                    (status
                        ? status.value === "All" ||
                          requisition.status === status.value
                        : true)
            )
        );
    }

    render() {
        const { requisitions, isLoading } = this.props;
        const { filter, filteredRequisitions, offset } = this.state;
        const range = offset * requisitionsPerPage;

        const requisitionRows =
            filteredRequisitions &&
            filteredRequisitions.map((requisition, i) => {
                if (i >= range - requisitionsPerPage && i < range) {
                    return (
                        <TableLinkRow
                            key={requisition.id}
                            to={`/admin/requisitions/${requisition.id}`}
                            gray={(i % 2 === 0).toString()}
                        >
                            <TableCell>{requisition.buildingName}</TableCell>
                            <TableCell>{requisition.unitNo}</TableCell>
                            <TableCell>{requisition.accountName}</TableCell>
                            <TableCell>{requisition.accountEmail}</TableCell>
                            <TableCell>{requisition.type}</TableCell>
                            <TableCell>{requisition.status}</TableCell>
                            <TableRowViewAction text="VIEW" />
                        </TableLinkRow>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader title="Back to Units" backTo="/admin/units" />
                {isLoading ? (
                    <Spinner />
                ) : requisitions && requisitions.length > 0 ? (
                    <ContentContainer>
                        <TableTextHeaderContainer>
                            <TableTextHeader>Requisitions</TableTextHeader>
                        </TableTextHeaderContainer>
                        <FiltersContainer>
                            <div style={{ width: "150px" }}>
                                <FilterLabel>Building</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. 305"
                                        value={filter.building}
                                        onChange={e =>
                                            this.handleFilterBuildingChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                            <div style={{ width: "150px" }}>
                                <FilterLabel>Unit</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. 305"
                                        value={filter.unit}
                                        onChange={e =>
                                            this.handleFilterUnitChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                            {/* <div style={{ width: "220px" }}>
                                <FilterLabel>Name</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. Joe"
                                        value={filter.name}
                                        onChange={e =>
                                            this.handleFilterNameChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                            <div style={{ width: "200px" }}>
                                <FilterLabel>Email</FilterLabel>
                                <IconInputContainer>
                                    <FiSearch />
                                    <Input
                                        placeholder="e.g. joe@mail.com"
                                        value={filter.email}
                                        onChange={e =>
                                            this.handleFilterEmailChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </IconInputContainer>
                            </div>
                            <div style={{ width: "150px" }}>
                                <FilterLabel>Type</FilterLabel>
                                <Select
                                    value={filter.type}
                                    onChange={selectedType =>
                                        this.handleFilterTypeChange(
                                            selectedType
                                        )
                                    }
                                    options={filterTypeOptions}
                                />
                            </div> */}
                            <div style={{ width: "150px" }}>
                                <FilterLabel>Status</FilterLabel>
                                <Select
                                    value={filter.status}
                                    onChange={selectedStatus =>
                                        this.handleFilterStatusChange(
                                            selectedStatus
                                        )
                                    }
                                    options={filterStatusOptions}
                                />
                            </div>
                        </FiltersContainer>
                        <Table>
                            <TableHeader>
                                <TableCell>Building</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableHeader>
                            {requisitionRows}
                        </Table>
                        {filteredRequisitions &&
                            filteredRequisitions.length >
                                requisitionsPerPage && (
                                <Pagination
                                    itemsPerPage={requisitionsPerPage}
                                    offset={offset}
                                    length={filteredRequisitions.length}
                                    handlePreviousPage={() =>
                                        this.handlePreviousPage()
                                    }
                                    handleNextPage={() => this.handleNextPage()}
                                />
                            )}
                    </ContentContainer>
                ) : (
                    <EmptyListContainer>
                        There are no requisitions.
                    </EmptyListContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        requisitions: state.requisitions,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AdminRequisitionsContainer);
