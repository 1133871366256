import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { messageActions } from "reducers/messageReducer";
import { Loader } from "containers/Loader";
import MessageContainer from "./MessageContainer";
import { updateOwner } from "reducers/ownerReducer";
import { isValidEmail } from "utils/InputPatterns";
import { InfoContainer, InfoLabel } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { Input, Select } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

const ownerTypeOptions = [
    {
        value: "Individual",
        label: "Individual"
    },
    {
        value: "Company",
        label: "Company"
    }
];

class OwnerModal extends Component {
    state = {
        id: null,
        type: null,
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        contactFirstName: "",
        contactLastName: "",
        contactEmail: "",
        contactPhoneNumber: "",
        hasError: false
    };

    componentDidMount() {
        const { owner } = this.props;
        if (owner) {
            this.setState({
                id: owner.id,
                type: { value: owner.ownerType, label: owner.ownerType },
                name: owner.name || "",
                firstName: owner.firstName || "",
                lastName: owner.lastName || "",
                email: owner.email || "",
                phoneNumber: owner.phoneNumber || "",
                contactFirstName: owner.contactFirstName || "",
                contactLastName: owner.contactLastName || "",
                contactEmail: owner.contactEmail || "",
                contactPhoneNumber: owner.contactPhoneNumber || ""
            });
        }
    }

    async handleSubmit() {
        const { ownerEmails, onSubmit, closeModal, dispatch } = this.props;
        const {
            type,
            name,
            firstName,
            lastName,
            email,
            phoneNumber,
            contactFirstName,
            contactLastName,
            contactEmail,
            contactPhoneNumber
        } = this.state;

        let errorMessage = "";
        const requiredFieldsFilled =
            type &&
            email.trim().length > 0 &&
            phoneNumber.trim().length > 0 &&
            contactFirstName.trim().length > 0 &&
            contactLastName.trim().length > 0 &&
            contactEmail.trim().length > 0 &&
            contactPhoneNumber.trim().length > 0 &&
            (type.value === "Company"
                ? name.trim().length > 0
                : type.value === "Individual" &&
                  firstName.trim().length > 0 &&
                  lastName.trim().length > 0);
        const emailNotUnique = ownerEmails.includes(email.trim().toLowerCase());

        errorMessage = !requiredFieldsFilled
            ? "Please fill up all required fields."
            : errorMessage;
        errorMessage =
            contactEmail && !isValidEmail(contactEmail)
                ? errorMessage.length > 0
                    ? errorMessage + " Invalid email."
                    : "Invalid email."
                : emailNotUnique
                ? errorMessage.length > 0
                    ? errorMessage + " Email must be unique."
                    : "Email must be unique."
                : errorMessage;

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(updateOwner(this.state));
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
                if (Boolean(onSubmit)) {
                    onSubmit();
                }
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const {
            ownerEmails,
            isOwnerWithBills,
            isModalLoading,
            modalVisible
        } = this.props;
        const {
            type,
            name,
            firstName,
            lastName,
            email,
            phoneNumber,
            contactFirstName,
            contactLastName,
            contactEmail,
            contactPhoneNumber,
            hasError
        } = this.state;
        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({
                    height: isOwnerWithBills
                        ? 540
                        : type && type.value === "Company"
                        ? 600
                        : 680,
                    width: 420
                })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>UPDATE OWNER</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody
                    height={
                        isOwnerWithBills
                            ? "320px"
                            : type && type.value === "Company"
                            ? "380px"
                            : "460px"
                    }
                >
                    <InfoContainer style={{ marginTop: 0 }}>
                        OWNER INFORMATION
                    </InfoContainer>
                    <InfoContainer>
                        {!isOwnerWithBills && (
                            <div style={{ width: "182.5px" }}>
                                <InfoLabel required>OWNER TYPE</InfoLabel>
                                <Select
                                    value={type}
                                    disabled={isModalLoading}
                                    onChange={selectedOwnerType =>
                                        this.setState({
                                            type: selectedOwnerType
                                        })
                                    }
                                    options={ownerTypeOptions}
                                    placeholder="Select Owner Type"
                                />
                            </div>
                        )}
                        {type && type.value === "Company" && !isOwnerWithBills && (
                            <div style={{ width: "182.5px" }}>
                                <InfoLabel required>COMPANY NAME</InfoLabel>
                                <Input
                                    placeholder="e.g. Ipsum"
                                    value={name}
                                    required={
                                        (!name || name.trim().length === 0) &&
                                        hasError
                                    }
                                    disabled={isModalLoading}
                                    onChange={e =>
                                        this.setState({
                                            name: e.target.value
                                        })
                                    }
                                />
                            </div>
                        )}
                    </InfoContainer>
                    {type && (
                        <Fragment>
                            {type.value === "Individual" && !isOwnerWithBills && (
                                <InfoContainer>
                                    <div style={{ width: "182.5px" }}>
                                        <InfoLabel required>
                                            FIRST NAME
                                        </InfoLabel>
                                        <Input
                                            placeholder="e.g. Joe"
                                            value={firstName}
                                            required={
                                                (!firstName ||
                                                    firstName.trim().length ===
                                                        0) &&
                                                hasError
                                            }
                                            disabled={isModalLoading}
                                            onChange={e =>
                                                this.setState({
                                                    firstName: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div style={{ width: "182.5px" }}>
                                        <InfoLabel required>
                                            LAST NAME
                                        </InfoLabel>
                                        <Input
                                            placeholder="e.g. Star"
                                            value={lastName}
                                            required={
                                                (!lastName ||
                                                    lastName.trim().length ===
                                                        0) &&
                                                hasError
                                            }
                                            disabled={isModalLoading}
                                            onChange={e =>
                                                this.setState({
                                                    lastName: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </InfoContainer>
                            )}
                            <InfoContainer>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>EMAIL</InfoLabel>
                                    <Input
                                        placeholder="e.g. example@mail.com"
                                        value={email}
                                        required={
                                            (!email ||
                                                email.trim().length === 0 ||
                                                ownerEmails.includes(
                                                    email.trim().toLowerCase()
                                                )) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={e =>
                                            this.setState({
                                                email: e.target.value
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>NUMBER</InfoLabel>
                                    <Input
                                        placeholder="e.g. +6398765432100"
                                        type="number"
                                        value={phoneNumber}
                                        required={
                                            (!phoneNumber ||
                                                phoneNumber.trim().length ===
                                                    0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={e =>
                                            this.setState({
                                                phoneNumber: e.target.value
                                            })
                                        }
                                    />
                                </div>
                            </InfoContainer>
                            <InfoContainer>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        CONTACT FIRST NAME
                                    </InfoLabel>
                                    <Input
                                        placeholder="e.g. Joe"
                                        value={contactFirstName}
                                        required={
                                            (!contactFirstName ||
                                                contactFirstName.trim()
                                                    .length === 0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={e =>
                                            this.setState({
                                                contactFirstName: e.target.value
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        CONTACT LAST NAME
                                    </InfoLabel>
                                    <Input
                                        placeholder="e.g. Star"
                                        value={contactLastName}
                                        required={
                                            (!contactLastName ||
                                                contactLastName.trim()
                                                    .length === 0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={e =>
                                            this.setState({
                                                contactLastName: e.target.value
                                            })
                                        }
                                    />
                                </div>
                            </InfoContainer>
                            <InfoContainer>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        CONTACT EMAIL
                                    </InfoLabel>
                                    <Input
                                        placeholder="e.g. example@mail.com"
                                        value={contactEmail}
                                        required={
                                            (!contactEmail ||
                                                contactEmail.trim().length ===
                                                    0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={e =>
                                            this.setState({
                                                contactEmail: e.target.value
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        CONTACT NUMBER
                                    </InfoLabel>
                                    <Input
                                        placeholder="e.g. +6398765432100"
                                        type="number"
                                        value={contactPhoneNumber}
                                        required={
                                            (!contactPhoneNumber ||
                                                contactPhoneNumber.trim()
                                                    .length === 0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={e =>
                                            this.setState({
                                                contactPhoneNumber:
                                                    e.target.value
                                            })
                                        }
                                    />
                                </div>
                            </InfoContainer>
                        </Fragment>
                    )}
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text="UPDATE OWNER"
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text="CANCEL"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        ownerEmails: state.owners
            ? state.owners.map(
                  o =>
                      o.id !== state.owner.id &&
                      o.email &&
                      o.email.toLowerCase()
              )
            : [],
        isOwnerWithBills: Boolean(
            state.owner &&
                state.owner.units &&
                state.owner.units.find(u => u.bills && u.bills.length > 0)
        ),
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(OwnerModal);
